const Inputs = ({ label, placeHolder, type, value, onChange, name }) => {
	return (
		<div className=" grid gap-2">
			<label
				className=" text-[#110E14] font-Inter font-normal text-base"
				htmlFor="">
				{label}
			</label>
			{type === "textArea" ? (
				<textarea
					className=" w-full h-[183px] rounded-lg"
					value={value}
					onChange={onChange}
					name={name}
					placeholder={placeHolder}
				/>
			) : (
				<input
					placeholder={placeHolder}
					className=" w-full border h-12 rounded-lg border-[#D2D5DA]"
					type={"text" || type}
					value={value}
					onChange={onChange}
					name={name}
				/>
			)}
		</div>
	);
};

export default Inputs;
