import React, { useEffect, useRef, useState } from "react";
import SmallNav from "../../components/small-nav/small-nav";
import DefaultHeader from "../../components/default-header/default-header";
import ColorSelector from "../../components/color-selector/color-selector";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import Button from "../../components/button/button";
import { manageWorkspace } from "../../data/Reducers/WorkspaceReducer";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { IconEye, IconEyeClosed } from "@tabler/icons-react";
// import { useURL } from "../../data/Config";
import ModalContainer from "../../components/modal-container/modal-container";
import {
	TOKEN,
	getUser,
	logout,
	setUser,
} from "../../data/Reducers/UserReducer";
import { v4 } from "uuid";
import { AiFillDelete } from "react-icons/ai";
import { BiCheckbox, BiRadioCircle } from "react-icons/bi";
import { MdArrowDropDownCircle } from "react-icons/md";
import { Enable2FAComponent } from "../../screens/authenticator";
import { IoLanguage } from "react-icons/io5";
import ReactQuill from "react-quill";

export const AnswerTypeArr = [
	{ type: "text", display: "Short Answer", writeableAdmin: false },
	{ type: "textarea", display: "Paragraph", writeableAdmin: false },
	{ type: "radio", display: "Multiple Choice", writeableAdmin: true },
	{ type: "checkbox", display: "Checkboxes", writeableAdmin: true },
	{ type: "select", display: "Dropdown", writeableAdmin: true },
	{ type: "date", display: "Date", writeableAdmin: false },
	{ type: "time", display: "Time", writeableAdmin: false },
];

export const OtherQuestionArr = [
	{
		question: `Which donor organization are you currently working closely with?`,
		name: "currentDonorOrganization",
	},
	{
		question: `Do you have referral for your services?`,
		name: "serviceReferral",
		subquestion: `This question helps the Foundation to verify the projects or capabilities of your organization.`,
		placeholder: `List name(s) of organization(s) that can provide referral for your services.`,
	},
	{
		question: `Are you a part of an innovation hub or geospatial data community who can vouch for your organization's capabilities?`,
		name: "endorsementProficiencies",
		placeholder: `List any innovation hubs or geospatial data communities that can vouch for your organization's capabilities.`,
	},
	{
		question: `What is the largest investment you have received for a project?`,
		name: "largestInvestment",
		placeholder: `List projects and investments you have received for a project.`,
	},
];

const Setup = () => {
	let [loading, setLoading] = useState(null),
		dispatch = useDispatch(),
		{ auth, workspace } = useSelector(s => s);

	let tabViews =
		auth?.user?.privilege === "organization"
			? ["Profile", "Settings", "Privacy Policy", "Experience"]
			: ["Profile", "Settings"];
	let [active, setActive] = useState(tabViews[0]);

	const handleNav = item => {
		setActive(item);
	};

	// useEffect(() => {
	// 	if (!["organization"]?.includes(auth?.user?.privilege))
	// 		setActive(tabViews?.[1]);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [auth?.user]);

	let navigate = useNavigate(),
		[property, setProperty] = useState({
			logo: "",
			color: workspace?.data?.color || "",
			workspaceName: workspace?.data?.workspaceName || "",
		}),
		logoInputRef = useRef(),
		[privacyPolicy, setPrivacy] = useState("");

	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes("image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			// setLogo(file);

			setProperty(prevRows => {
				let newRows = { ...prevRows };
				newRows.logo = file;
				return newRows;
			});
		}
	};

	useEffect(() => {
		dispatch(manageWorkspace("get"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (workspace?.data) {
			setProperty({ ...property, ...workspace?.data });
			if (workspace?.data?.privacyPolicy)
				setPrivacy(workspace?.data?.privacyPolicy);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [workspace?.data]);

	const onSubmitMain = que => async e => {
		e?.preventDefault();
		try {
			console.log({ que, active });
			if (!["Privacy Policy", "Experience"]?.includes(active)) {
				if (!property?.workspaceName) {
					return toast.info("Workspace name is required");
				}
				// if (!property?.logo || !property?.color)
				// 	return toast.info("Logo/Color is required");
			}

			setLoading(true);
			let res,
				body = {};

			if (property?.color) body.color = property?.color;
			if (property?.workspaceName) body.workspaceName = property?.workspaceName;
			if (privacyPolicy) body.privacyPolicy = privacyPolicy;
			if (que && Array?.isArray(que)) body.questions = que;

			// if (property?.logo) {
			// 	try {
			// 		let post = new FormData();
			// 		post.append(`file`, property?.logo);

			// 		let resImg = await axios.post(`/api/v1/file`, post, {
			// 			headers: {
			// 				"Content-Type": "multipart/form-data",
			// 			},
			// 		});

			// 		body.file = resImg?.data?.data?.[0];
			// 	} catch (err) {
			// 		if (err) console.log({ error: err.response?.data, err });
			// 		if (err?.response?.status === 429) toast.error(err?.response?.data);
			// 		let error = err.response?.data?.error;
			// 		if (error && error?.length > 1) {
			// 			dispatch(returnErrors({ error, status: err?.response?.status }));
			// 		} else {
			// 			toast.error(
			// 				err?.response?.data?.message ||
			// 					err?.response?.data?.error?.[0]?.message ||
			// 					err?.message
			// 			);
			// 		}
			// 		setLoading(false);
			// 		return;
			// 	}
			// }

			if (property?.logo) {
				body.intendedFileName = "file";
				body.intendedFile = property?.logo;
			}
			console.log({ body });
			res = await axios.post(
				`/api/v1/organization/workspace`,
				{ ...body },
				{
					headers: {
						"Content-Type": body?.intendedFile
							? "multipart/form-data"
							: "application/json",
					},
				}
			);

			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			dispatch(manageWorkspace("get"));
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
		setLoading(false);
	};

	return (
		<div>
			<DefaultHeader
				mainTitle={
					!["organization"]?.includes(auth?.user?.privilege) ? "Setup" : active
				}
			/>
			<div className="bg-white rounded-lg p-4 mb-10">
				{/* {["organization"]?.includes(auth?.user?.privilege) && ( */}
				<SmallNav menu={tabViews} handleNav={handleNav} active={active} />
				{/* )} */}

				<div className="">
					{active === "Profile" && auth?.user?.privilege === "organization" && (
						<form onSubmit={onSubmitMain()} className="max-w-xl">
							<div className="mt-8">
								<p className="font-medium text-xl xxl:text-2xl">Logo</p>
								<p className="text-sm text-gray-500 mt-2">
									Logo should not be more than 1MB
								</p>
								<div
									onClick={() => logoInputRef?.current?.click()}
									className={`border-black border-2 w-52 mt-4 rounded-lg cursor-pointer h-16 flex items-center justify-center flex-col ${
										property?.logo || workspace?.data?.file?.url ? "" : "p-2"
									}`}>
									<img
										src={
											property?.logo
												? URL.createObjectURL(property?.logo)
												: workspace?.data?.file?.url || null
										}
										alt=""
										className="h-full w-full rounded-lg object-contain"
									/>
									{!property?.logo && !workspace?.data?.file?.url && (
										<p className="text-center">Click to upload</p>
									)}
									<input
										ref={logoInputRef}
										type="file"
										style={{ width: "0" }}
										className="absolute -z-10"
										onChange={handleChangeImage}
									/>
								</div>
							</div>
							<div className="mt-8">
								<p className="font-medium text-xl xxl:text-2xl">Theme Color</p>
								<p className="text-sm text-gray-500 mt-2">
									Choose a theme color
								</p>
							</div>
							<div className="mt-4">
								<ColorSelector
									state={property?.color}
									setState={col => setProperty({ ...property, color: col })}
								/>
							</div>
							<fieldset className="flex flex-col mb-6">
								<label
									className="mb-1 text-sm md:text-lg font-semibold tracking-wide text-gray-600"
									htmlFor="email">
									Workspace Name
								</label>
								<input
									className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
									name={"workspaceName"}
									id={"workspaceName"}
									type={"text"}
									onChange={e =>
										setProperty({
											...property,
											workspaceName: e?.target?.value,
										})
									}
									placeholder="Rolodex"
									value={property?.workspaceName}
								/>
							</fieldset>
							{/* <fieldset className="flex flex-col mb-6">
								<label
									className="mb-1 text-sm md:text-lg font-semibold tracking-wide text-gray-600"
									htmlFor="email">
									Email Address
								</label>
								<input
									className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
									name={"email"}
									id={"email"}
									type={"email"}
									onChange={e =>
										setProperty({ ...property, name: e?.target?.value })
									}
									placeholder="gist@gatesfoundation.com"
									value={property?.name}
								/>
							</fieldset> */}
							<div className="flex items-center gap-8 mb-4">
								<button
									type="button"
									onClick={() => navigate("/")}
									className="w-full flex justify-center bg-gray-200 hover:brightness-100  text-black p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500">
									Back
								</button>
								<Button
									type="submit"
									loading={loading}
									children="Apply Changes"
									css="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
									buttonType={"primary"}
								/>
							</div>
						</form>
					)}
					{active === "Profile" && auth?.user?.privilege !== "organization" && (
						<ProfileUpdate />
					)}
					{active === "Settings" && <Settings />}
					{active === "Privacy Policy" && (
						<PrivacyPolicy
							setPrivacy={setPrivacy}
							privacyPolicy={privacyPolicy}
							handleSubmit={onSubmitMain}
							loading={loading}
						/>
					)}
					{active === "Experience" && (
						<ExperienceMakeup
							handleSubmit={onSubmitMain}
							loading={loading}
							questions={
								workspace?.data?.questions?.length > 0
									? workspace?.data?.questions
									: null
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default Setup;

const UpdatePassword = ({ handleBack }) => {
	let [loading, setLoading] = useState(null),
		dispatch = useDispatch(),
		navigate = useNavigate(),
		[showPassword, setShowPassword] = useState(null);

	const methods = useForm({
		defaultValues: {
			password: "",
		},
	});
	const onSubmitMain = async e => {
		try {
			console.log({ e });
			if (e?.password !== e?.confirmPassword)
				return toast.info("Password do not match");
			setLoading(true);
			let res,
				body = {
					password: e?.password,
				};

			res = await axios.put(`/api/v1/user/setup-password`, { ...body });

			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			navigate("/");
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
		setLoading(false);
	};

	return (
		<>
			<FormProvider {...methods}>
				<form
					onSubmit={methods.handleSubmit(onSubmitMain)}
					className="m max-w-2xl">
					<Controller
						name="password"
						control={methods.control}
						rules={{
							required: "This field is required",
							pattern: {
								value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
								message:
									"Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
							},
						}}
						render={({ field: { onChange, name } }) => (
							<fieldset className="flex flex-col mb-6">
								<div className="flex items-center justify-between">
									<label
										className="mb-1 text-sm md:text-lg font-semibold tracking-wide"
										htmlFor="password">
										New Password
									</label>
								</div>
								<div className="relative">
									<input
										className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
										name={name}
										id={name}
										onChange={onChange}
										type={showPassword ? "text" : "password"}
										placeholder="New Password"
									/>
									<div
										className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
										onClick={() => setShowPassword(!showPassword)}>
										{showPassword ? <IconEye /> : <IconEyeClosed />}
									</div>
								</div>
								{methods.formState.errors.password && (
									<span className="text sm md:text-base font-bold text-red-600">
										{methods.formState.errors.password.message}
									</span>
								)}
							</fieldset>
						)}
					/>
					<Controller
						name="confirmPassword"
						control={methods.control}
						rules={{
							required: "This field is required",
							pattern: {
								value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
								message:
									"Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
							},
						}}
						render={({ field: { onChange, name } }) => (
							<fieldset className="flex flex-col mb-6">
								<div className="flex items-center justify-between">
									<label
										className="mb-1 text-sm md:text-lg font-semibold tracking-wide"
										htmlFor="confirmPassword">
										Confirm New Password
									</label>
								</div>
								<div className="relative">
									<input
										className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
										name={name}
										id={name}
										onChange={onChange}
										type={showPassword ? "text" : "password"}
										placeholder="Confirm New Password"
									/>
									<div
										className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
										onClick={() => setShowPassword(!showPassword)}>
										{showPassword ? <IconEye /> : <IconEyeClosed />}
									</div>
								</div>
								{methods.formState.errors.confirmPassword && (
									<span className="text sm md:text-base font-bold text-red-600">
										{methods.formState.errors.confirmPassword.message}
									</span>
								)}
							</fieldset>
						)}
					/>
					<div className="flex items-center gap-8 mb-4">
						<button
							type="button"
							//   onClick={() => navigate("/")}
							onClick={handleBack}
							className="w-full flex justify-center bg-gray-200 hover:brightness-100  text-black p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500">
							Back
						</button>
						{/* <button
							type="submit"
							className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500">
							Apply changes
						</button> */}
						<Button
							type="submit"
							loading={loading}
							children="Apply Changes"
							// eslint-disable-next-line react/style-prop-object
							css="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
							// loadCss="text-bluerolodex"
							// onClick={onSubmit}
							buttonType={"primary"}
						/>
					</div>
				</form>
			</FormProvider>
		</>
	);
};

const Settings = () => {
	const [active, setActive] = useState(""),
		{ auth } = useSelector(s => s),
		dispatch = useDispatch(),
		[loading, setLoading] = useState(null),
		[step, setStep] = useState(""),
		[code, setCode] = useState(""),
		[qr, setQr] = useState(""),
		navigate = useNavigate(),
		handleSubmit = async e => {
			e.preventDefault();
			if (step !== "enable") if (!code) return;
			setLoading(true);
			try {
				let url = `/api/v1/user/is2fa-initiate`;
				if (step === "validate") url = `/api/v1/user/is2fa-validate`;
				if (step === "disable") url = `/api/v1/user/is2fa-disable`;
				var res;
				if (step === "enable") res = await axios.post(url);
				else res = await axios.put(url, { token: code });
				console.log({ res: res?.data }, step);
				toast.success(res.data.msg || res?.data?.message);
				setStep(step === "enable" ? "validate" : "");
				if (step !== "enable") {
					// dispatch({ type: UPDATE_USER, payload: res?.data });
					dispatch(setUser(res?.data));
				} else {
					setQr(res?.data?.data);
				}
				setLoading(false);
			} catch (err) {
				console.log({ err });
				let error = err.response?.data?.error;
				if (error) {
					error.forEach(error => toast.error(error.msg || error?.message));
				}

				if (err?.response?.status === 429) toast.error(err?.response?.data);
				setLoading(false);
			}
			setLoading(false);
		};

	const googleTranslateElementInit = () => {
		new window.google.translate.TranslateElement(
			{
				pageLanguage: "en",
				autoDisplay: false,
			},
			"google_translate_element"
		);
	};
	useEffect(() => {
		var addScript = document.createElement("script");
		addScript.setAttribute(
			"src",
			"//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
		);
		document.body.appendChild(addScript);
		window.googleTranslateElementInit = googleTranslateElementInit;
	}, []);

	const settings = [
		{
			title: "Change password",
			subText: "Change your password to a stronger one",
			icon: (
				<svg
					width="48"
					height="48"
					viewBox="0 0 48 48"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<rect width="48" height="48" rx="8" fill="#F2FBF9" />
					<g clip-path="url(#clip0_4054_34457)">
						<path
							d="M18 20V19C18 17.4087 18.6321 15.8826 19.7574 14.7574C20.8826 13.6321 22.4087 13 24 13C25.5913 13 27.1174 13.6321 28.2426 14.7574C29.3679 15.8826 30 17.4087 30 19V20H32C32.2652 20 32.5196 20.1054 32.7071 20.2929C32.8946 20.4804 33 20.7348 33 21V33C33 33.2652 32.8946 33.5196 32.7071 33.7071C32.5196 33.8946 32.2652 34 32 34H16C15.7348 34 15.4804 33.8946 15.2929 33.7071C15.1054 33.5196 15 33.2652 15 33V21C15 20.7348 15.1054 20.4804 15.2929 20.2929C15.4804 20.1054 15.7348 20 16 20H18ZM31 22H17V32H31V22ZM23 27.732C22.6187 27.5119 22.3207 27.1721 22.1522 26.7653C21.9838 26.3586 21.9542 25.9076 22.0681 25.4823C22.1821 25.057 22.4332 24.6813 22.7825 24.4132C23.1318 24.1452 23.5597 23.9999 24 23.9999C24.4403 23.9999 24.8682 24.1452 25.2175 24.4132C25.5668 24.6813 25.8179 25.057 25.9319 25.4823C26.0458 25.9076 26.0162 26.3586 25.8478 26.7653C25.6793 27.1721 25.3813 27.5119 25 27.732V30H23V27.732ZM20 20H28V19C28 17.9391 27.5786 16.9217 26.8284 16.1716C26.0783 15.4214 25.0609 15 24 15C22.9391 15 21.9217 15.4214 21.1716 16.1716C20.4214 16.9217 20 17.9391 20 19V20Z"
							fill="#05A38F"
						/>
					</g>
					<defs>
						<clipPath id="clip0_4054_34457">
							<rect
								width="24"
								height="24"
								fill="white"
								transform="translate(12 12)"
							/>
						</clipPath>
					</defs>
				</svg>
			),
			show: true,
		},
		{
			title: "Update Profile Details",
			subText: "Update Your Onboarding details",
			icon: (
				<svg
					width="48"
					height="48"
					viewBox="0 0 48 48"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<rect width="48" height="48" rx="8" fill="#F2FBF9" />
					<g clip-path="url(#clip0_4054_34457)">
						<path
							d="M18 20V19C18 17.4087 18.6321 15.8826 19.7574 14.7574C20.8826 13.6321 22.4087 13 24 13C25.5913 13 27.1174 13.6321 28.2426 14.7574C29.3679 15.8826 30 17.4087 30 19V20H32C32.2652 20 32.5196 20.1054 32.7071 20.2929C32.8946 20.4804 33 20.7348 33 21V33C33 33.2652 32.8946 33.5196 32.7071 33.7071C32.5196 33.8946 32.2652 34 32 34H16C15.7348 34 15.4804 33.8946 15.2929 33.7071C15.1054 33.5196 15 33.2652 15 33V21C15 20.7348 15.1054 20.4804 15.2929 20.2929C15.4804 20.1054 15.7348 20 16 20H18ZM31 22H17V32H31V22ZM23 27.732C22.6187 27.5119 22.3207 27.1721 22.1522 26.7653C21.9838 26.3586 21.9542 25.9076 22.0681 25.4823C22.1821 25.057 22.4332 24.6813 22.7825 24.4132C23.1318 24.1452 23.5597 23.9999 24 23.9999C24.4403 23.9999 24.8682 24.1452 25.2175 24.4132C25.5668 24.6813 25.8179 25.057 25.9319 25.4823C26.0458 25.9076 26.0162 26.3586 25.8478 26.7653C25.6793 27.1721 25.3813 27.5119 25 27.732V30H23V27.732ZM20 20H28V19C28 17.9391 27.5786 16.9217 26.8284 16.1716C26.0783 15.4214 25.0609 15 24 15C22.9391 15 21.9217 15.4214 21.1716 16.1716C20.4214 16.9217 20 17.9391 20 19V20Z"
							fill="#05A38F"
						/>
					</g>
					<defs>
						<clipPath id="clip0_4054_34457">
							<rect
								width="24"
								height="24"
								fill="white"
								transform="translate(12 12)"
							/>
						</clipPath>
					</defs>
				</svg>
			),
			show:
				["company"]?.includes(auth?.user?.privilege) ||
				["admin"]?.includes(auth?.user?.role?.title?.toLowerCase()),
		},
		{
			title: auth?.user?.status
				? "Disable Account Temporarily"
				: "Activate account",
			action: auth?.user?.status ? "disable" : "enable",
			subText: `${
				auth?.user?.status ? "Disable" : "Enable"
			} your account for a while till you want to use again`,
			icon: (
				<svg
					width="48"
					height="48"
					viewBox="0 0 48 48"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<rect width="48" height="48" rx="8" fill="#FFF5F2" />
					<g clip-path="url(#clip0_4054_34467)">
						<path
							d="M27.936 14.5L33.5 20.067V27.937L27.936 33.5H20.066L14.5 27.936V20.066L20.066 14.5H27.936ZM27.107 16.5H20.894L16.501 20.895V27.108L20.894 31.502H27.107L31.501 27.108V20.894L27.107 16.501V16.5ZM23 27H25V29H23V27ZM23 19H25V25H23V19Z"
							fill="#FE974C"
						/>
					</g>
					<defs>
						<clipPath id="clip0_4054_34467">
							<rect
								width="24"
								height="24"
								fill="white"
								transform="translate(12 12)"
							/>
						</clipPath>
					</defs>
				</svg>
			),
			show: true,
		},
		{
			title:
				auth?.user?.is2FAEnabled === "enable" ? "Disable 2FA" : "Activate 2FA",
			action: auth?.user?.is2FAEnabled === "enable" ? "disable" : "enable",
			subText: "2-Factor Authentication to your account",
			icon: (
				<svg
					width="48"
					height="48"
					viewBox="0 0 48 48"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<rect width="48" height="48" rx="8" fill="#F2FBF9" />
					<g clip-path="url(#clip0_4054_34457)">
						<path
							d="M18 20V19C18 17.4087 18.6321 15.8826 19.7574 14.7574C20.8826 13.6321 22.4087 13 24 13C25.5913 13 27.1174 13.6321 28.2426 14.7574C29.3679 15.8826 30 17.4087 30 19V20H32C32.2652 20 32.5196 20.1054 32.7071 20.2929C32.8946 20.4804 33 20.7348 33 21V33C33 33.2652 32.8946 33.5196 32.7071 33.7071C32.5196 33.8946 32.2652 34 32 34H16C15.7348 34 15.4804 33.8946 15.2929 33.7071C15.1054 33.5196 15 33.2652 15 33V21C15 20.7348 15.1054 20.4804 15.2929 20.2929C15.4804 20.1054 15.7348 20 16 20H18ZM31 22H17V32H31V22ZM23 27.732C22.6187 27.5119 22.3207 27.1721 22.1522 26.7653C21.9838 26.3586 21.9542 25.9076 22.0681 25.4823C22.1821 25.057 22.4332 24.6813 22.7825 24.4132C23.1318 24.1452 23.5597 23.9999 24 23.9999C24.4403 23.9999 24.8682 24.1452 25.2175 24.4132C25.5668 24.6813 25.8179 25.057 25.9319 25.4823C26.0458 25.9076 26.0162 26.3586 25.8478 26.7653C25.6793 27.1721 25.3813 27.5119 25 27.732V30H23V27.732ZM20 20H28V19C28 17.9391 27.5786 16.9217 26.8284 16.1716C26.0783 15.4214 25.0609 15 24 15C22.9391 15 21.9217 15.4214 21.1716 16.1716C20.4214 16.9217 20 17.9391 20 19V20Z"
							fill="#05A38F"
						/>
					</g>
					<defs>
						<clipPath id="clip0_4054_34457">
							<rect
								width="24"
								height="24"
								fill="white"
								transform="translate(12 12)"
							/>
						</clipPath>
					</defs>
				</svg>
			),
			show: true,
		},
		{
			title: "Delete Account",
			subText: "I am no longer interested in using this account",
			action: "delete",
			icon: (
				<svg
					width="48"
					height="48"
					viewBox="0 0 48 48"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<rect width="48" height="48" rx="8" fill="#FFF3F2" />
					<g clip-path="url(#clip0_4054_34477)">
						<path
							d="M29 18H34V20H32V33C32 33.2652 31.8946 33.5196 31.7071 33.7071C31.5196 33.8946 31.2652 34 31 34H17C16.7348 34 16.4804 33.8946 16.2929 33.7071C16.1054 33.5196 16 33.2652 16 33V20H14V18H19V15C19 14.7348 19.1054 14.4804 19.2929 14.2929C19.4804 14.1054 19.7348 14 20 14H28C28.2652 14 28.5196 14.1054 28.7071 14.2929C28.8946 14.4804 29 14.7348 29 15V18ZM30 20H18V32H30V20ZM25.414 26L27.182 27.768L25.768 29.182L24 27.414L22.232 29.182L20.818 27.768L22.586 26L20.818 24.232L22.232 22.818L24 24.586L25.768 22.818L27.182 24.232L25.414 26ZM21 16V18H27V16H21Z"
							fill="#EA483B"
						/>
					</g>
					<defs>
						<clipPath id="clip0_4054_34477">
							<rect
								width="24"
								height="24"
								fill="white"
								transform="translate(12 12)"
							/>
						</clipPath>
					</defs>
				</svg>
			),
			show: true,
		},
		{
			title: "Translate Page",
			subText: "Select your preferred language",
			icon: <IoLanguage />,
			show: true,
		},
	];

	const handleClick = title => {
		if (title?.includes("2FA")) {
			setStep(auth?.user?.is2FAEnabled === "enable" ? "disable" : "enable");
		} else if (title?.includes("Update Profile Details")) {
			navigate(
				`/verify?token=${localStorage?.getItem(TOKEN)}&email=${
					auth?.user?.email
				}`
			);
		} else if (title.includes("Translate Page")) {
			return;
		} else setActive(title);
	};

	const handleBack = () => {
		setActive("");
		setStep("");
	};

	const onSubmitMain = async e => {
		try {
			if (e) e?.preventDefault();
			console.log({ e });
			if (!active || (active && active === settings?.[0]?.title))
				return toast.info("Action required");
			setLoading(true);
			let res = active?.includes("Delete Account")
				? await axios.put(
						`/api/v1/user/manage-account?type=${
							settings?.find(it => it?.title === active)?.action
						}`,
						{
							type: settings?.find(it => it?.title === active)?.action,
							invitations: [{ invitationId: auth?.user?._id }],
						}
				  )
				: await axios.post(
						`/api/v1/user/manage-account?type=${
							settings?.find(it => it?.title === active)?.action
						}`
				  );
			if (active?.includes("Delete Account")) {
				await dispatch(logout());
				if (window.location.origin?.includes("geo-rolodex"))
					window.location.replace("https://www.geo-rolodex.com/home");
				else navigate("/login");
			} else dispatch(getUser(res?.data));

			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			handleBack();
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
		setLoading(false);
	};

	return (
		<div>
			{active !== settings?.[0]?.title && (
				<div>
					{settings
						?.filter(it => it?.show)
						?.map((setting, i) => (
							<SettingsCard
								key={i}
								title={setting.title}
								subText={
									setting.title === "Translate Page" ? (
										<div>
											<div id="google_translate_element"></div>
										</div>
									) : (
										setting.subText
									)
								}
								icon={setting.icon}
								handleClick={handleClick}
							/>
						))}
				</div>
			)}
			{active === settings?.[0]?.title && (
				<UpdatePassword handleBack={handleBack} />
			)}
			<ModalContainer
				title={active}
				width={"max-w-sm"}
				show={active && active !== settings?.[0]?.title}
				close={() => setActive("")}>
				<div className="mx-20">
					<form className="space-y-4">
						<div className="my-auto w-100">
							<p className="text2 Lexend text-center">
								Do you want to {active}?
							</p>
							<div className="pt-4 flex">
								<Button
									buttonType={"primary"}
									children={"Yes"}
									type="button"
									css={"w-fit me-2 py-3"}
									loading={loading}
									onClick={onSubmitMain}
								/>
								<Button
									buttonType={"secondary"}
									children={"No"}
									type="button"
									css={"w-fit ms-2 py-3"}
									onClick={() => setActive("")}
								/>
							</div>
						</div>
					</form>
				</div>
			</ModalContainer>
			<ModalContainer
				width={"max-w-md"}
				show={step}
				close={() => setStep(null)}
				title={`${
					auth?.user?.is2FAEnabled === "enable" ? "Disable" : "Enable"
				} 2FA Authentication`}>
				{step !== "enable" ? (
					<>
						{step === "validate" && qr ? (
							<>
								<small className="mb-4 tw-text-base tw-font-medium d-block">
									Please scan the qrcode and input the token in your
									Authenticator App
								</small>
								<img src={qr} alt="QRCode" className="img-fluid mx-auto" />
								<Button
									buttonType={"primary"}
									children={"Input Token"}
									type="button"
									css={
										"w-full py-3 text-center flex justify-center items-center"
									}
									loading={loading}
									onClick={() => setQr("")}
								/>
							</>
						) : (
							<>
								<Enable2FAComponent
									handleSubmit={handleSubmit}
									loading={loading}
									code={code}
									setCode={setCode}
									subtext={"Enter the code generated in your Authenticator app"}
								/>
							</>
						)}
					</>
				) : (
					<div className="flex mx-auto">
						<div className="pt-4 flex">
							<Button
								buttonType={"primary"}
								children={"Enable"}
								type="button"
								css={"w-fit me-2 py-3"}
								loading={loading}
								onClick={handleSubmit}
							/>
							<Button
								buttonType={"secondary"}
								children={"Cancel"}
								type="button"
								css={"w-fit ms-2 py-3"}
								onClick={() => setStep("")}
							/>
						</div>
					</div>
				)}
			</ModalContainer>
		</div>
	);
};

const SettingsCard = ({ title, subText, icon, handleClick }) => {
  return (
    <div
      className="flex justify-between items-end p-4 py-6 border-b border-[#EEEEEE] cursor-pointer"
      onClick={() => handleClick(title)}
    >
      <div className="flex items-center gap-4">
        <span>{icon}</span>
        <div>
          <p className="font-semibold text-md">{title}</p>
          <p className="font-xs text-[#777E90]">{subText}</p>
        </div>
      </div>
      <p className="text-[#1B3C8E] text-xs underline cursor-pointer">
        {title === "Change password" && title}
      </p>
    </div>
  );
};

const PrivacyPolicy = ({
  privacyPolicy,
  setPrivacy,
  handleSubmit,
  loading,
}) => {
  let navigate = useNavigate();
  return (
    <form onSubmit={handleSubmit()} className="max-w-xl">
      <fieldset className="flex flex-col mb-6 mt-3">
        {/* <label
					className="mb-1 text-sm md:text-lg font-semibold tracking-wide text-gray-600"
					htmlFor="email">
					Workspace Name
				</label> */}
        <ReactQuill
          theme="snow"
          value={privacyPolicy}
          onChange={(e) => setPrivacy(e)}
          className="max-w-[600px]"
        />
      </fieldset>
      <div className="flex items-center gap-8 mb-4">
        <button
          type="button"
          onClick={() => navigate("/")}
          className="w-full flex justify-center bg-gray-200 hover:brightness-100  text-black p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
        >
          Back
        </button>
        <Button
          type="submit"
          loading={loading}
          children="Apply Changes"
          css="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
          buttonType={"primary"}
        />
      </div>
    </form>
  );
};

const ExperienceMakeup = ({ handleSubmit, loading, questions }) => {
  let navigate = useNavigate();

  const init2 = { kipId: v4() },
    init = { question: "", answerType: "text", queId: v4(), subTitle: [init2] },
    [formInfo, setFormInfo] = useState([init]),
    dispatch = useDispatch();

  const addKpi = (formIndex) => {
    // setKpi([...kpi, {weight: "", self_score: "" , manager_score:'', weighted_score:''}]);
    const newForm = [...formInfo];
    newForm[formIndex].subTitle.push(init2);
    setFormInfo(newForm);
  };

  const removeKpi = (formIndex, kpiIndex) => {
    console.log(formIndex, kpiIndex);
    const newForm = [...formInfo];
    newForm[formIndex].subTitle.splice(kpiIndex, 1);
    setFormInfo(newForm);
  };

  const handleKpi = (event, formIndex, kpiIndex) => {
    const newInputs = [...formInfo];
    let { name, value } = event.target;
    // newInputs[formIndex].subTitle[kpiIndex][name] = value;
    let clonedObject = { ...newInputs[formIndex].subTitle[kpiIndex] };
    clonedObject = { ...clonedObject, [name]: value };

    let arr1 = [
      ...newInputs[formIndex].subTitle?.map((it, i) =>
        i === kpiIndex ? clonedObject : it
      ),
    ];

    let arr2 = [
      ...newInputs?.map((it, i) =>
        i === formIndex
          ? {
              ...it,
              subTitle: arr1,
            }
          : it
      ),
    ];
    setFormInfo(arr2);
    // }
  };
  const handleFormTitle = (event, formIndex) => {
    const newFormInput = [...formInfo];
    let { name, value, type, checked } = event.target;
    if (["checkbox", "radio"]?.includes(type))
      newFormInput[formIndex][name] = checked;
    if (!["checkbox", "radio"]?.includes(type))
      newFormInput[formIndex][name] = value;
    setFormInfo(newFormInput);
  };

  const addForm = () => {
    setFormInfo([...formInfo, { title: "", subTitle: [init2], queId: v4() }]);
  };
  const removeForm = (formIndex) => {
    console.log(formIndex);
    const newFormTitle = [...formInfo];
    newFormTitle.splice(formIndex, 1);
    setFormInfo(newFormTitle);
  };

  useEffect(() => {
    if (questions) setFormInfo(questions);
  }, [questions]);

  const onSubmit = async (event) => {
    event.preventDefault();

    let errArr = [];

    if (formInfo?.length === 0) return toast.info("Questions required");

    for (let e = 0; e < formInfo.length; e++) {
      let element = formInfo?.[e];
      if (!element?.question)
        errArr.push({
          message: `Question ${e + 1} is required`,
          path: "question",
        });
      if (!element?.answerType)
        errArr.push({
          message: `Question ${e + 1} answer Type is required`,
          path: "answerType",
        });
      if (
        AnswerTypeArr?.find((ix) => ix?.type === element?.answerType)
          ?.writeableAdmin
      ) {
        if (!element?.subTitle)
          errArr.push({
            message: `Question ${e + 1} options is required`,
            path: "subTitle",
          });
        if (element?.subTitle?.length === 0)
          errArr.push({
            message: `Question ${e + 1} options is required`,
            path: "subTitle",
          });

        // console.log({ element });
        for (let o = 0; o < element?.subTitle.length; o++) {
          let elementOption = element?.subTitle?.[o];
          if (!elementOption?.kpiname)
            errArr.push({
              message: `Question ${e + 1} Option value ${o + 1} is required`,
              path: "kpiname",
            });
        }
      }
    }

    // console.log({ returnedData });
    if (errArr?.length > 0) return dispatch(returnErrors({ error: errArr }));

    console.log({ formInfo });

    handleSubmit(formInfo)();
  };

  return (
    <form onSubmit={onSubmit} className="max-w-xl">
      {formInfo?.map((each, formIndex) => (
        <div key={formIndex} className="mt-8 shadow-md pl-4 pb-4">
          <div className=" flex justify-between">Question</div>
          <div className="flex justify-between flex-col">
            <div className="w-full">
              <div className="flex px-3 py-1 bg-[#F9FFFF] w-full font-bold items-center">
                <p className="mr-1">{formIndex + 1}.</p>
                <input
                  type="text"
                  name="question"
                  onChange={(event) => handleFormTitle(event, formIndex)}
                  placeholder={`Question ${formIndex + 1}`}
                  className="border-none outline-none w-full"
                  value={each?.question}
                />
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <div className="flex justify-center items-center w-[50%]">
                <label htmlFor="answwerType">Answer Type</label>
                <select
                  name="answerType"
                  className="w-full mt-1 border-[#CCCCCC] text-center"
                  onChange={(event) => handleFormTitle(event, formIndex)}
                  value={each?.answerType}
                  id=""
                >
                  {AnswerTypeArr?.map((ic, s) => (
                    <option value={ic?.type} key={s}>
                      {ic?.display}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex justify-center gap-5 items-center w-[50%]">
                <label htmlFor={`required${formIndex}`}>Required</label>
                <input
                  name="required"
                  type="checkbox"
                  className="mt-1 border-[#CCCCCC] text-center"
                  onChange={(event) => handleFormTitle(event, formIndex)}
                  value={each?.required}
                  checked={each?.required}
                  id={`required${formIndex}`}
                />
              </div>
            </div>
          </div>
          {AnswerTypeArr?.find((ix) => ix?.type === each?.answerType)
            ?.writeableAdmin && (
            <div className="pl-5">
              <>
                {each.subTitle?.map((item, kpiIndex) => (
                  <div key={kpiIndex} className="w-full my-1 pe-5">
                    <div className="flex justify-between">
                      <div className="flex justify-center items-center w-[5%]">
                        {AnswerTypeArr?.find(
                          (ix) => ix?.type === each?.answerType
                        )?.type === "radio" ? (
                          <>
                            <BiRadioCircle size={30} />
                          </>
                        ) : AnswerTypeArr?.find(
                            (ix) => ix?.type === each?.answerType
                          )?.type === "checkbox" ? (
                          <>
                            <BiCheckbox size={30} />
                          </>
                        ) : (
                          <>
                            <MdArrowDropDownCircle size={30} />
                          </>
                        )}
                      </div>
                      <div className="w-[90%] border rounded-xl">
                        <div className="flex px-3 py-1 w-full font-bold items-center">
                          <p className="mr-1">{kpiIndex + 1}.</p>
                          <input
                            type="text"
                            name="kpiname" // Unique name attribute
                            onChange={(event) =>
                              handleKpi(event, formIndex, kpiIndex)
                            }
                            placeholder={`Option ${kpiIndex + 1}`}
                            className="border-none outline-none w-[90%]"
                            value={item?.kpiname}
                          />
                        </div>
                      </div>
                    </div>
                    {each?.subTitle?.length > 1 && (
                      <div className="flex justify-end my-1.5 w-[98%]">
                        <button
                          type="button"
                          onClick={() => removeKpi(formIndex, kpiIndex)}
                          className="bg-red-600 text-white w-[24px] font-bold rounded-full"
                        >
                          -
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </>

              <div className="flex justify-end w-[98%]">
                <button
                  type="button"
                  onClick={() => addKpi(formIndex)}
                  className=" text-white bg-[#0681CD] w-[24px] rounded-full pb-[2px] font-bold"
                >
                  +
                </button>
              </div>
            </div>
          )}
          {formInfo?.length > 1 && (
            <div className="flex justify-end w-[98%] mt-4">
              <button
                type="button"
                onClick={() => removeForm(formIndex)}
                className=" text-white bg-red-600 p-2 flex justify-center items-center rounded-xl  font-bold"
              >
                <AiFillDelete className="text-2xl" />
              </button>
            </div>
          )}
        </div>
      ))}

      <div className="flex justify-end w-[98%] mt-4">
        <button
          onClick={addForm}
          type="button"
          className=" text-white bg-[#0681CD] text-3xl w-[35px] rounded-full pb-[2px] font-bold"
        >
          +
        </button>
        {/* <button>submit</button> */}
      </div>
      <div className="flex items-center gap-8 py-4">
        <button
          type="button"
          onClick={() => navigate("/")}
          className="w-full flex justify-center bg-gray-200 hover:brightness-100  text-black p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
        >
          Back
        </button>
        <Button
          type="submit"
          loading={loading}
          children="Apply Changes"
          css="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
          buttonType={"primary"}
        />
      </div>
    </form>
  );
};

export const Enable2FABox = () => {
  let { auth } = useSelector((s) => s);
  let [step, setStep] = useState(""),
    [code, setCode] = useState(""),
    [qr, setQr] = useState(""),
    dispatch = useDispatch(),
    [loading, setLoading] = useState(false),
    handleSubmit = async (e) => {
      e.preventDefault();
      if (step !== "enable") if (!code) return;
      setLoading(true);
      try {
        let url = `/api/v2/user/is2fa-initiate`;
        if (step === "validate") url = `/api/v2/user/is2fa-validate`;
        if (step === "disable") url = `/api/v2/user/is2fa-disable`;
        var res;
        if (step === "enable") res = await axios.post(url);
        else res = await axios.put(url, { token: code });
        console.log({ res: res?.data }, step);
        toast.success(res.data.msg || res?.data?.message);
        setStep(step === "enable" ? "validate" : "");
        if (step !== "enable") {
          // dispatch({ type: UPDATE_USER, payload: res?.data });
          dispatch(setUser(res?.data));
        } else {
          setQr(res?.data?.data);
        }
        setLoading(false);
      } catch (err) {
        console.log({ err });
        let error = err.response?.data?.error;
        if (error) {
          error.forEach((error) => toast.error(error.msg || error?.message));
        }

        if (err?.response?.status === 429) toast.error(err?.response?.data);
        setLoading(false);
      }
      setLoading(false);
    };

  return (
    <form className="py-4 py-md-5">
      <h6 className="Lexend fw-600 fontReduceBig text-uppercase mt-3 mt-md-5">
        2FA Authentication Settings
      </h6>
      <small className="">
        {/* Please enter your current password to change your password. */}
      </small>
      <div className="d-flex align-items-center my-3">
        <Button
          onClick={() =>
            setStep(
              auth?.user?.is2FAEnabled === "enable" ? "disable" : "enable"
            )
          }
          loading={loading}
          css={`
            ${auth?.user?.is2FAEnabled === "enable"
              ? "btn-danger2"
              : "btn-primary1"} text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-white
          `}
          title={
            auth?.user?.is2FAEnabled === "enable" ? "Disable 2FA" : "Enable 2FA"
          }
          width="w-auto"
          type="button"
        />
      </div>
      <ModalContainer
        width={"max-w-sm"}
        show={step}
        close={() => setStep(null)}
        title={`${
          auth?.user?.is2FAEnabled === "enable" ? "Disable" : "Enable"
        } 2FA Authentication`}
      >
        {step !== "enable" ? (
          <>
            {step === "validate" && qr ? (
              <>
                <small className="mb-4 tw-text-base tw-font-medium d-block">
                  Please scan the qrcode and input the token in your
                  Authenticator App
                </small>
                <img src={qr} alt="QRCode" className="img-fluid mx-auto" />
                <Button
                  onClick={() => setQr("")}
                  title={"Input Token"}
                  css="btn btn-primary1 text-capitalize py-3 w-100 my-4"
                  type="button"
                />
              </>
            ) : (
              <>
                <Enable2FAComponent
                  handleSubmit={handleSubmit}
                  loading={loading}
                  code={code}
                  setCode={setCode}
                  subtext={"Enter the code generated in your Authenticator app"}
                />
              </>
            )}
          </>
        ) : (
          <div className="d-flex mx-auto">
            <div className="d-flex align-items-center my-3 mx-auto">
              <Button
                onClick={handleSubmit}
                loading={loading}
                css="btn-primary1 text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-white"
                title={"Enable"}
                width="w-auto"
              />
              <button
                onClick={() => setStep("")}
                className={`btn btn-outline-primary1 text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-primary1`}
              >
                cancel
              </button>
            </div>
          </div>
        )}
      </ModalContainer>
    </form>
  );
};

const ProfileUpdate = () => {
  let [loading, setLoading] = useState(null),
    [data, setData] = useState(null),
    dispatch = useDispatch(),
    navigate = useNavigate(),
    { auth } = useSelector((s) => s),
    textChange = (e) => {
      let { name, value } = e?.target;
      setData((prev) => {
        let newPrev = { ...prev };
        newPrev[name] = value;
        return newPrev;
      });
    };

  const onSubmitMain = async (e) => {
    e?.preventDefault();
    try {
      if (Object.values(data)?.length === 0) return;
      setLoading(true);
      console.log({ data });
      let res = await axios.put(`/api/v1/user`, { ...data });

      console.log({ resp: res?.data });
      toast.success(res?.data?.message);
      dispatch(setUser(res?.data));
    } catch (err) {
      if (err) console.log({ error: err.response?.data, err });
      if (err?.response?.status === 429) toast.error(err?.response?.data);
      let error = err.response?.data?.error;
      if (error && error?.length > 1) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
      } else {
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data?.error?.[0]?.message ||
            err?.message
        );
      }
    }
    setLoading(false);
  };

  return (
    <>
      <form onSubmit={onSubmitMain} className="max-w-xl">
        <fieldset className="flex flex-col mb-6">
          <label
            className="mb-1 text-sm md:text-lg font-semibold tracking-wide text-gray-600"
            htmlFor="email"
          >
            Name
          </label>
          <input
            className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
            name={"organizationName"}
            id={"organizationName"}
            type={"text"}
            onChange={textChange}
            placeholder="Rolodex"
            value={data?.organizationName || auth?.user?.organizationName}
          />
        </fieldset>
        <fieldset className="flex flex-col my-4">
          <label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
            LinkedIn URL
          </label>
          <input
            className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
            type={"url"}
            placeholder="Your linkedIn link"
            id={"linkedInUrl"}
            name="linkedInUrl"
            onChange={textChange}
            value={data?.linkedInUrl || auth?.user?.linkedInUrl || "https://"}
          />
          {/* <div className="flex gap-2">
								<div className="p-4 border border-gray-300 rounded-md">
									https://
								</div>
							</div> */}
        </fieldset>
        <fieldset className="flex flex-col my-4">
          <label className="text-sm md:text-lg f-bold tracking-wide">Bio</label>
          <small className="b text-gray-400">Tell us about you</small>
          <textarea
            className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none placeholder:text-lg"
            placeholder="Write a short bio here"
            row={6}
            style={{
              resize: "none",
              height: "10rem",
            }}
            id={"companyBio"}
            name="companyBio"
            onChange={textChange}
            value={data?.companyBio || auth?.user?.companyBio}
          />
        </fieldset>
        <div className="flex items-center gap-8 mb-4">
          <button
            type="button"
            onClick={() => navigate("/")}
            className="w-full flex justify-center bg-gray-200 hover:brightness-100  text-black p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
          >
            Back
          </button>
          <Button
            type="submit"
            loading={loading}
            children="Apply Changes"
            css="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
            buttonType={"primary"}
          />
        </div>
      </form>
    </>
  );
};
