import React, { useContext, useState, useEffect } from "react";
import { GlobalState } from "../../data/Context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Brand from "../brand/brand";
// import { logout } from "../../data/Reducers/UserReducer";
// import Brand from "../brand/brand";
// import DefaultHeader from "../default-header/default-header";
// import { BiLogIn } from "react-icons/bi";
import { logout } from "../../data/Reducers/UserReducer";
import {
  // Tooltip,
  BasicTooltip,
} from "../tooltip/tooltip";
import { useIdleTimer } from "react-idle-timer";
import Button from "../button/button";
import ModalContainer from "../modal-container/modal-container";

export const IdleLogout = () => {
	let navigate = useNavigate(),
		dispatch = useDispatch();
	let handleLogOut = async e => {
		e?.preventDefault();
		console.log({ time: getLastActiveTime() });
		await dispatch(logout());
		if (window.location.origin?.includes("geo-rolodex"))
			window.location.replace("https://www.geo-rolodex.com/home");
		else navigate("/login");
	};

	const { getLastActiveTime } = useIdleTimer({
		timeout: 1000 * 60 * 10,
		onIdle: handleLogOut,
		debounce: 500,
	});
	return <></>;
};

const Sidebar = ({ children, auth }) => {
	const {
		sidebarList,
		nav,
		toggleNav,
		orgTheme,
		unboardingScreen,
		setUnboardingScreen,
		// unboarding,
		setUnboarding,
	} = useContext(GlobalState);
	const location = useLocation(),
		navigate = useNavigate(),
		dispatch = useDispatch(),
		{ workspace } = useSelector(s => s),
		[isOpen, setIsOpen] = useState(null);

	const logoutUser = () => {
		dispatch(logout());
		if (window.location.origin?.includes("geo-rolodex"))
			window.location.replace("https://www.geo-rolodex.com/home");
		else navigate("/login");
	};

	useEffect(() => {
		const starter = localStorage.getItem("Starter");
		const starterList = JSON.parse(localStorage.getItem("starterList"));
		console.log({ starter, starterList });
		if (starter === "started" && starterList?.includes(location?.pathname)) {
			setUnboardingScreen(true);
		}
		// setUnboardingList(starterList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	useEffect(() => {
		if (auth?.user)
			document.title =
				workspace?.data?.workspaceName ||
				auth?.user?.organization?.organizationName ||
				auth?.user?.organizationName;
	}, [auth?.user, workspace?.data]);

	// console.log({ sidebarList });
	// if (!auth?.isAuth) return;
	return (
		<div className={auth?.isAuth && "min-h-screen"}>
			{auth?.isAuth ? <IdleLogout /> : null}
			{unboardingScreen && (
				<div
					className="fixed inset-0 z-50"
					onClick={() => {
						setUnboarding(true);
						setUnboardingScreen(false);
					}}></div>
			)}
			<div className="fixed inset-0 bg-[#F7F7F7]"></div>
			{auth?.isAuth &&
				(nav ? (
					<aside
						id="logo-sidebar"
						className={`fixed top-0 left-0 z-40 w-64 h-full transition-transform bg-white scrollbar-hide ${
							nav ? "translate-x-0" : "-translate-x-full md:translate-x-0"
						}`}
						aria-label="Sidebar">
						<div class="px-3 py-4 overflow-y-auto max-h-screen scrollbar-hide">
							<div className="py-1 mb-8 flex items-center justify-between gap-x-4">
								<button
									data-drawer-target="logo-sidebar"
									data-drawer-toggle="logo-sidebar"
									aria-controls="logo-sidebar"
									type="button"
									className={`inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200`}
									onClick={toggleNav}>
									<span className="sr-only">Open sidebar</span>
									<svg
										className="w-6 h-6"
										aria-hidden="true"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path
											clip-rule="evenodd"
											fill-rule="evenodd"
											d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
									</svg>
								</button>
								<Brand />
							</div>
							<div className="flex flex-col justify-between h-full">
								<ul className="font-medium space-y-4">
									{sidebarList
										?.filter(it => it?.show)
										// ?.filter((it) => it.name !== (!unboarding && "Get Started"))
										?.map(
											(list, idx) =>
												list.permission.includes("superadmin") && (
													// <Tooltip
													// 	place={"right"}
													// 	isOpen={
													// 		location.pathname === list.url && unboarding
													// 	}
													// 	refElement={
													<li
														key={idx}
														className={`text-xs capitalize text-gray-500 py-1 px-2 whitespace-nowrap ${
															location.pathname?.includes(list?.url)
																? "active2"
																: ""
														}`}
														style={{
															background:
																orgTheme &&
																location.pathname?.includes(list?.url)
																	? orgTheme
																	: "",
															borderColor:
																orgTheme &&
																location.pathname?.includes(list?.url)
																	? `${orgTheme}d8`
																	: "",
														}}
														onClick={() => {
															if (document.body.clientWidth < 992 && nav)
																toggleNav();
														}}>
														<Link
															to={list.url}
															className={`flex text-xs items-center px-2 rounded-lg text-gray-500`}>
															<span>{list.icon}</span>
															<span className="flex-1 ml-3 whitespace-nowrap text-sm">
																{list.name}
															</span>
															<span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300">
																{}
															</span>
														</Link>
													</li>
													// 	}
													// />
												)
										)}
								</ul>
								<ul className="mt-20">
									<li
										className={`text-xs capitalize text-gray-500 py-1 px-2 whitespace-nowrap ${
											location.pathname?.includes("help") ? "active2" : ""
										}`}
										style={{
											background:
												orgTheme && location.pathname?.includes("help")
													? orgTheme
													: "",
											borderColor:
												orgTheme && location.pathname?.includes("help")
													? `${orgTheme}d8`
													: "",
										}}
										onClick={() => {
											if (document.body.clientWidth < 992 && nav) toggleNav();
										}}>
										<Link
											to={"/help"}
											className={`flex items-center px-2 text-xs rounded-lg text-gray-500`}>
											{/* <span className="icon"> */}
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="currentColor"
												xmlns="http://www.w3.org/2000/svg">
												<path
													opacity="0.3"
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M5 3C3.34315 3 2 4.34315 2 6V15C2 16.6569 3.34315 18 5 18H9.01653L11.0887 21.1255C11.5036 21.7513 12.4344 21.7137 12.7975 21.0566L14.4862 18H19C20.6569 18 22 16.6569 22 15V6C22 4.34315 20.6569 3 19 3H5Z"
												/>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M6 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H6C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7ZM6 11H11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13H6C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11Z"
												/>
											</svg>
											{/* </span> */}
											<span className="flex-1 ml-3 whitespace-nowrap text-xs">
												{"Help"}
											</span>
										</Link>
									</li>
									<li
										className="px-2"
										title="Logout"
										onClick={() => setIsOpen(true)}>
										<Link
											to="#"
											className="flex items-center text-xs gap-4 p-1.5 text-gray-500 mt-8">
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path
													opacity="0.3"
													d="M14.0017 7C14.0017 7.55229 14.4493 8 15.0013 8C15.5534 8 16.0009 7.55229 16.0009 7V6C16.0009 3.79086 14.2108 2 12.0026 2H6.00608C3.7979 2 2.00781 3.79086 2.00781 6L2.00781 18C2.00781 20.2091 3.7979 22 6.00608 22H12.0095C14.2177 22 16.0078 20.2091 16.0078 18V17C16.0078 16.4477 15.5603 16 15.0082 16C14.4562 16 14.0087 16.4477 14.0087 17V18C14.0087 19.1046 13.1136 20 12.0095 20H6.00608C4.90199 20 4.00694 19.1046 4.00694 18L4.00694 6C4.00694 4.89543 4.90199 4 6.00608 4H12.0026C13.1067 4 14.0017 4.89543 14.0017 6V7Z"
													fill="#111014"
												/>
												<rect
													opacity="0.3"
													x="20"
													y="11"
													width="2"
													height="12"
													rx="1"
													transform="rotate(90 20 11)"
													fill="#111014"
												/>
												<path
													d="M17.2929 9.70711C16.9024 9.31658 16.9024 8.68342 17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289L21.7071 11.2929C22.0976 11.6834 22.0976 12.3166 21.7071 12.7071L18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071C16.9024 15.3166 16.9024 14.6834 17.2929 14.2929L19.5858 12L17.2929 9.70711Z"
													fill="#111014"
												/>
											</svg>
											<span className="text nav-text">{`Logout`}</span>
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</aside>
				) : (
					<aside
						id="logo-sidebar"
						className={`fixed top-0 left-0 z-40 w-24 h-screen transition-transform bg-white scrollbar-hide ${
							nav ? "translate-x-0" : "-translate-x-full md:translate-x-0"
						}`}
						aria-label="Sidebar">
						<div className="px-3 py-4 overflow-y-auto max-h-screen scrollbar-hide">
							<div className="py-1 mb-8 flex items-center justify-between gap-x-4">
								<button
									data-drawer-target="logo-sidebar"
									data-drawer-toggle="logo-sidebar"
									aria-controls="logo-sidebar"
									type="button"
									className={`inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200`}
									onClick={toggleNav}>
									<span className="sr-only">Open sidebar</span>
									<svg
										className="w-6 h-6"
										aria-hidden="true"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path
											clip-rule="evenodd"
											fill-rule="evenodd"
											d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
									</svg>
								</button>
								{/* <Brand /> */}
							</div>
							<div className="flex flex-col justify-between h-full">
								<ul className="font-medium space-y-4 flex flex-col">
									{sidebarList
										?.filter(it => it?.show)
										// ?.filter((it) => it.name !== (!unboarding && "Get Started"))
										?.map(
											(list, idx) =>
												list.permission.includes("superadmin") && (
													// <Tooltip
													// 	place={"right"}
													// 	isOpen={
													// 		location.pathname === list.url && unboarding
													// 	}
													// 	refElement={
													<li
														key={idx}
														className={`text-xs capitalize text-gray-500 py-1 px-2 whitespace-nowrap ${
															location.pathname?.includes(list?.url)
																? "active2"
																: ""
														}`}
														style={{
															background:
																orgTheme &&
																location.pathname?.includes(list?.url)
																	? orgTheme
																	: "",
															borderColor:
																orgTheme &&
																location.pathname?.includes(list?.url)
																	? `${orgTheme}d8`
																	: "",
															fill: location.pathname?.includes(list?.url)
																? "white"
																: "",
														}}
														onClick={() => {
															if (document.body.clientWidth < 992 && nav)
																toggleNav();
														}}>
														<BasicTooltip
															tooltip={list.name}
															children={
																<Link
																	to={list.url}
																	className={`flex items-center px-2 rounded-lg text-gray-500`}>
																	<span className="">{list.icon}</span>
																</Link>
															}
														/>
													</li>
													// 	}
													// />
												)
										)}
								</ul>
								<ul className="mt-20">
									<li
										className={`text-sm capitalize text-gray-500 py-1 px-2 whitespace-nowrap ${
											location.pathname?.includes("help") ? "active2" : ""
										}`}
										style={{
											background:
												orgTheme && location.pathname?.includes("help")
													? orgTheme
													: "",
											borderColor:
												orgTheme && location.pathname?.includes("help")
													? `${orgTheme}d8`
													: "",
										}}
										onClick={() => {
											if (document.body.clientWidth < 992 && nav) toggleNav();
										}}
										title="help">
										{/* <BasicTooltip
                      tooltip={"help"}
                      children={ */}
										<Link
											to={"/help"}
											className={`flex items-center px-2 rounded-lg text-gray-500`}>
											<span className="icon">
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="black"
													xmlns="http://www.w3.org/2000/svg">
													<path
														opacity="0.3"
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M5 3C3.34315 3 2 4.34315 2 6V15C2 16.6569 3.34315 18 5 18H9.01653L11.0887 21.1255C11.5036 21.7513 12.4344 21.7137 12.7975 21.0566L14.4862 18H19C20.6569 18 22 16.6569 22 15V6C22 4.34315 20.6569 3 19 3H5Z"
													/>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M6 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H6C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7ZM6 11H11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13H6C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11Z"
													/>
												</svg>
											</span>
										</Link>
										{/* } */}
										{/* /> */}
									</li>
									<li
										className="px-2"
										title="Logout"
										onClick={() => setIsOpen(true)}>
										<BasicTooltip
											tooltip={"log out"}
											children={
												<Link
													to="#"
													className="flex items-center gap-4 p-1.5 text-gray-500 mt-8">
													<svg
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															opacity="0.3"
															d="M14.0017 7C14.0017 7.55229 14.4493 8 15.0013 8C15.5534 8 16.0009 7.55229 16.0009 7V6C16.0009 3.79086 14.2108 2 12.0026 2H6.00608C3.7979 2 2.00781 3.79086 2.00781 6L2.00781 18C2.00781 20.2091 3.7979 22 6.00608 22H12.0095C14.2177 22 16.0078 20.2091 16.0078 18V17C16.0078 16.4477 15.5603 16 15.0082 16C14.4562 16 14.0087 16.4477 14.0087 17V18C14.0087 19.1046 13.1136 20 12.0095 20H6.00608C4.90199 20 4.00694 19.1046 4.00694 18L4.00694 6C4.00694 4.89543 4.90199 4 6.00608 4H12.0026C13.1067 4 14.0017 4.89543 14.0017 6V7Z"
															fill="#111014"
														/>
														<rect
															opacity="0.3"
															x="20"
															y="11"
															width="2"
															height="12"
															rx="1"
															transform="rotate(90 20 11)"
															fill="#111014"
														/>
														<path
															d="M17.2929 9.70711C16.9024 9.31658 16.9024 8.68342 17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289L21.7071 11.2929C22.0976 11.6834 22.0976 12.3166 21.7071 12.7071L18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071C16.9024 15.3166 16.9024 14.6834 17.2929 14.2929L19.5858 12L17.2929 9.70711Z"
															fill="#111014"
														/>
													</svg>
												</Link>
											}
										/>
									</li>
								</ul>
							</div>
						</div>
					</aside>
				))}
			<div
				className={`${
					auth?.isAuth &&
					(nav ? "md:ml-64 mx-auto px-4" : "md:ml-24 mx-auto px-4")
				} min-h-screen relative z-10`}
				// onClick={() => document.body.clientWidth < 992 && nav && toggleNav()}
			>
				{children}
				{/* <div class={`${auth?.isAuth && "container mx-auto px-4"} max-h-screen overflow-auto`}>
				</div> */}
			</div>
			<ModalContainer
				width={"max-w-md"}
				show={isOpen}
				close={() => setIsOpen(null)}
				title={`Logout Confirmation`}>
				<p className="text2 Lexend text-center">Do you want to log out?</p>
				<div className="flex mx-auto justify-center items-center">
					<div className="pt-4 flex">
						<Button
							buttonType={"primary"}
							children={"Yes"}
							type="button"
							css={"w-fit me-2 py-3"}
							onClick={() => {
								setIsOpen(null);
								logoutUser();
							}}
						/>
						<Button
							buttonType={"secondary"}
							children={"Cancel"}
							type="button"
							css={"w-fit ms-2 py-3"}
							onClick={() => setIsOpen(null)}
						/>
					</div>
				</div>
			</ModalContainer>
		</div>
	);
};

export default Sidebar;

export const DropdownNavMenu = ({ name, links }) => {
  const [dropdown, setDropdown] = useState(false);
  return (
    <li>
      <button
        type="button"
        className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
        onClick={() => setDropdown(!dropdown)}
      >
        <svg
          className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 18 21"
        >
          <path d="M15 12a1 1 0 0 0 .962-.726l2-7A1 1 0 0 0 17 3H3.77L3.175.745A1 1 0 0 0 2.208 0H1a1 1 0 0 0 0 2h.438l.6 2.255v.019l2 7 .746 2.986A3 3 0 1 0 9 17a2.966 2.966 0 0 0-.184-1h2.368c-.118.32-.18.659-.184 1a3 3 0 1 0 3-3H6.78l-.5-2H15Z" />
        </svg>
        <span className="flex-1 ml-3 text-left whitespace-nowrap">{name}</span>
        <svg
          className="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      <ul className={`${!dropdown && "hidden"} py-2 space-y-2`}>
        {links.map((link) => (
          <li>
            <Link
              to={link.url}
              className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
            >
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

export const DefaultLilnk = ({ name, url }) => {
  return (
    <li>
      <Link
        to={url}
        className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
      >
        <svg
          className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 18 18"
        >
          <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
        </svg>
        <span className="flex-1 ml-3 whitespace-nowrap">{name}</span>
        <span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">
          Pro
        </span>
      </Link>
    </li>
  );
};
