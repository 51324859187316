import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { GlobalState } from "../data/Context";

const Home = () => {
	// const { sidebarList } = useContext(GlobalState);
	// console.log(sidebarList);
	let navigate = useNavigate(),
		location = useLocation();

	useEffect(() => {
		let nav = "/signup/setup-organization";
		const hostname = window.location.hostname;
		const parts = hostname.split(".");

		if (window.location?.origin?.endsWith("geo-rolodex.com")) {
			nav = "/landing";
			if (parts?.length > 2) if (parts?.[0] !== "www") nav = "/login";
		}
		if (window.location?.origin?.endsWith("map.policyvault.africa")) {
			if (parts?.length > 3) nav = "/login";
		}
		navigate(nav);
	}, [navigate, location?.pathname]);
	return <></>;
};

export default Home;
