/* eslint-disable no-unused-vars */
/* eslint-disable react/style-prop-object */
import { useContext, useEffect, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import { PiDownloadSimpleFill } from "react-icons/pi";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/button/button";
// import { SearchDropdownSelect } from "../../components/search-dropdown/search-dropdown";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import { FaTimes } from "react-icons/fa";
import ModalContainer from "../../components/modal-container/modal-container";
import Extractor from "../../data/useExtractor";
import { BiDownload } from "react-icons/bi";
import { GlobalState } from "../../data/Context";
import { toCamel } from "../../App";
// import RawSample from "../../assets/Rolodex Raw Data Sample.xlsx";
// import RawSample2 from "../../assets/Rolodex Organization Company Sample.xlsx";
import moment from "moment";
import { manageMap } from "../../data/Reducers/MapReducer";
import { manageRawData } from "../../data/Reducers/RawDataReducer";
import { ShortenName } from "../../components/contact-card/contact-card";
import { getProgress } from "../../data/Reducers/SocketReducer";
import { OrgDataSample, RawDataSample } from "./defaultData";
import { CSVLink } from "react-csv";
import { setStarter } from "../../data/Reducers/UserReducer";

const Steps = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = [
    "create your dataset",
    "invite organisations",
    "start mapping",
  ];
  // const navigate = useNavigate();
  const currentRoute = searchParams.get("step");

  useEffect(() => {
    if (!currentRoute) {
      setSearchParams({ step: "create your dataset" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let { orgTheme } = useContext(GlobalState),
    { auth, workspace } = useSelector((s) => s),
    navigate = useNavigate();

  useEffect(() => {
    if (auth?.user) if (auth?.user?.privilege !== "organization") navigate(-1);
  }, [auth?.user, navigate]);

  return (
    <div>
      <div className="inset-0 bg-white fixed"></div>
      <div className="relative z-10">
        <div className="flex items-center justify-between gap-x-8 mb-4 px-4 flex-wrap">
          <p className="py-4 f-medium text-xl">Get started</p>

          <div className="flex items-center space-x-4">
            <div className="">
              <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
                <span className="font-medium text-gray-600 dark:text-gray-300">
                  <ShortenName user={auth?.user?.organizationName} />
                </span>
              </div>
            </div>
            <div className="font-medium dark:text-white">
              <div>{workspace?.data?.workspaceName}</div>
              <div className="text-sm text-gray-500 dark:text-gray-400">
                {auth?.user?.organizationName}....
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 flex justify-between overflow-x-auto gap-8 scrollbar-hide">
          <div className="flex items-center space-x-4">
            <div>
              <div
                className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-bluerolodex rounded-md"
                style={{
                  background: orgTheme || "",
                }}
              >
                <span className="font-medium text-white">
                  {currentRoute === params[1] || currentRoute === params[2] ? (
                    <BsCheckLg />
                  ) : (
                    "1"
                  )}
                </span>
              </div>
            </div>
            <div
              className={`f-medium ${
                currentRoute === params[0]
                  ? "text-bluerolodex"
                  : "text-gray-700"
              }`}
              style={{
                color: orgTheme && currentRoute === params[0] ? orgTheme : "",
              }}
            >
              <div className="c whitespace-nowrap">Create your dataset</div>
              <div
                style={{
                  color: orgTheme && currentRoute === params[0] ? orgTheme : "",
                }}
                className={`text-sm ${
                  currentRoute === params[0]
                    ? "text-bluerolodex"
                    : "text-gray-400"
                }`}
              >
                Input and import dataset for your map
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <div>
              <div
                style={{
                  background:
                    orgTheme && currentRoute === params[1] ? orgTheme : "",
                }}
                className={`relative inline-flex items-center justify-center w-10 h-10 overflow-hidden f-medium ${
                  currentRoute === params[1]
                    ? "bg-bluerolodex text-white"
                    : "bg-gray-100 text-black"
                } rounded-md`}
              >
                <span className={`font-medium`}>
                  {currentRoute === params[2] ? <BsCheckLg /> : "2"}
                </span>
              </div>
            </div>
            <div
              style={{
                color: orgTheme && currentRoute === params[1] ? orgTheme : "",
              }}
              className={`f-medium ${
                currentRoute === params[1]
                  ? "text-bluerolodex"
                  : "text-gray-700"
              }`}
            >
              <div className="c whitespace-nowrap">Invite Organisations</div>
              <div
                style={{
                  color: orgTheme && currentRoute === params[1] ? orgTheme : "",
                }}
                className={`text-sm ${
                  currentRoute === params[1]
                    ? "text-bluerolodex"
                    : "text-gray-400"
                }`}
              >
                Invite collaborating organisations
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <div>
              <div
                style={{
                  background:
                    orgTheme && currentRoute === params[2] ? orgTheme : "",
                }}
                className={`relative inline-flex items-center justify-center w-10 h-10 overflow-hidden f-medium ${
                  currentRoute === params[2]
                    ? "bg-bluerolodex text-white"
                    : "bg-gray-100 text-black"
                } rounded-md`}
              >
                <span className="font-medium">3</span>
              </div>
            </div>
            <div
              style={{
                color: orgTheme && currentRoute === params[2] ? orgTheme : "",
              }}
              className={`f-medium ${
                currentRoute === params[2]
                  ? "text-bluerolodex"
                  : "text-gray-700"
              }`}
            >
              <div className="c whitespace-nowrap">Start Mapping</div>
              <div
                style={{
                  color: orgTheme && currentRoute === params[2] ? orgTheme : "",
                }}
                className={`text-sm ${
                  currentRoute === params[2]
                    ? "text-bluerolodex"
                    : "text-gray-400"
                }`}
              >
                Start collaborating with your team
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="py-12">
          {currentRoute === params[0] && <CreateDataset route={params[1]} />}
          {currentRoute === params[1] && (
            <InviteOrganisation route={params[2]} />
          )}
          {currentRoute === params[2] && <StartMapping route={params[2]} />}
        </div>
      </div>
    </div>
  );
};

export default Steps;

const CreateDataset = ({ route }) => {
  const navigate = useNavigate();

  let [loading, setLoading] = useState(null),
    dispatch = useDispatch(),
    { rawdata, mainSocket } = useSelector((s) => s),
    [newSample, setNewSample] = useState([]);

  let init2 = {};
  const [itemForm, setItemForm] = useState([init2]);
  const handleInputChangeForMutipleItem = (event, index, field) => {
    const { value } = event.target;
    let itemValue = value;

    setItemForm((prevRows) => {
      const newRows = [...prevRows];
      newRows[index][field] = itemValue;
      return newRows;
    });
  };

  const handleDeleteRowForMutipleItem = (index) => {
    setItemForm((prevRows) => {
      const newRows = [...prevRows];
      newRows.splice(index, 1);
      return newRows;
    });
  };

  const handleInputKeyPress = (e, index, field) => {
    setItemForm((prevRows) => {
      const newRows = [...prevRows];
      // console.log({ e: e.key });
      let emailInput = newRows[index].data;
      if (e.key === "Enter" && emailInput.trim() !== "") {
        newRows[index][field] = Array.isArray(newRows[index][field])
          ? [...newRows[index][field], emailInput.trim()]
          : [emailInput.trim()];
        newRows[index].data = "";
      }
      return newRows;
    });
  };

  const addRowForMutipleItem = () => {
    const newRow = init2;
    setItemForm([...itemForm, newRow]);
  };

  useEffect(() => {
    dispatch(getProgress(null));
  }, [dispatch]);

  useEffect(() => {
    let newArr = [],
      newHeader = [];
    let newObj = {};
    if (rawdata?.data?.docs?.length > 0) {
      for (let r = 0; r < rawdata?.data?.docs.length; r++) {
        let element = rawdata?.data?.docs[r];
        newObj[`${StringTOCamel(element?.category)}`] = element?.data
          ?.toString()
          ?.split(",")
          ?.join("\n");
        newHeader?.push(element?.category);
      }
      newArr?.push(newObj);
    } else {
      newArr = RawDataSample;
      newHeader = [
        "Expertise",
        "Organization Type",
        "Organization Geospatial Focus",
        "Sector",
      ];
    }
    let csvData = [
      newHeader,
      ...newArr?.map((it) => {
        let newA = Object?.keys(it)?.map((ic) => it?.[ic]);
        return newA;
      }),
    ];
    // console.log({ csvData });
    setNewSample(csvData);
  }, [rawdata?.data]);

  const onSubmit = async () => {
    if (itemForm?.length === 0) return toast.info("Data set required");
    if (itemForm?.length === 1)
      if (!itemForm?.[0]?.category || itemForm?.[0]?.dataArr?.length === 0)
        return toast.info("Data set required");
    setLoading(true);
    try {
      dispatch(getProgress(null));
      let res = await axios.post(`/api/v1/rawdata`, {
        rawdata: itemForm?.map((item) => {
          return {
            ...item,
            data: item?.dataArr?.map((it) => {
              return it?.trim();
            }),
          };
        }),
      });
      console.log({ resp: res?.data });
      toast.success(res?.data?.message);
      setLoading(false);
      navigate(`?step=${route}`);
      dispatch(
        manageMap("get", {
          map: "map",
        })
      );
      dispatch(manageRawData("get"));
      dispatch(getProgress(null));
    } catch (err) {
      dispatch(getProgress(null));
      if (err) console.log({ error: err.response?.data, err });
      if (err?.response?.status === 429) toast.error(err?.response?.data);
      let error = err.response?.data?.error;
      if (error && error?.length > 1) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
      } else {
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data?.error?.[0]?.message ||
            err?.message
        );
      }
    }
    setLoading(false);
  };

  let [pres, setPres] = useState([]);

  let [loading2, setLoading2] = useState(null),
    { orgTheme } = useContext(GlobalState);

  const [bulkUpload, setBulkUpload] = useState(false);
  const toggleUploadModal = () => {
    setPres([]);
    setBulkUpload(!bulkUpload);
  };

  const onSubmit2 = async () => {
    // console.log({ data });
    if (pres?.length === 0)
      return toast.info("Organizations' details required");
    setLoading2(true);
    try {
      // let newPres = [];
      // for (let p = 0; p < pres.length; p++) {
      // 	const element = pres[p];
      // 	newPres?.push(toCamel(element));
      // }
      // let newNewPres = newPres?.map(it => {
      // 	let data = it?.data?.split("\r\n")?.map(ix => ix?.trim());
      // 	return { ...it, data };
      // });

      let newItemForm = combineArray(pres),
        newerItemForm = [];
      console.log({ newItemForm });
      for (let n = 0; n < newItemForm?.length; n++) {
        let element = newItemForm?.[n];
        for (let c = 0; c < element?.length; c++) {
          let elementC = element?.[c],
            findIn = newerItemForm?.find(
              (ir) => ir?.category === elementC?.category
            );
          if (findIn) {
            // console.log({ ele: elementC?.data, fiIn: findIn?.data });
            let newD = [];
            if (findIn?.data && Array.isArray(findIn?.data))
              newD = [...newD, ...findIn?.data];
            if (elementC?.data && Array.isArray(elementC?.data))
              newD = [...newD, ...elementC?.data];
            findIn.data = newD;
            newerItemForm = newerItemForm?.map((ix) =>
              ix?.category === findIn?.category ? findIn : ix
            );
          } else {
            newerItemForm?.push(elementC);
          }
        }
      }
      // console.log({ newItemForm, newerItemForm });
      let newItem = [];
      for (let n = 0; n < newerItemForm?.length; n++) {
        const element = newerItemForm?.[n];
        newItem = [
          ...newItem,
          {
            ...element,
            category: element?.category,
            dataArr: element?.data,
            data: "",
          },
        ];
      }
      setItemForm(
        itemForm?.[0]?.category ? [...itemForm, ...newItem] : newItem
      );
      // console.log({ newPres, newNewPres });
      // let res = await axios.put(`/raw-data/raw-data`, {
      // 	companyFundationId: auth?.user?.organization?.organizationId,
      // 	roleType: auth?.user?.idType || "COMPANY",
      // 	rawData: newNewPres,
      // });
      // console.log({ resp: res?.data });
      // toast.success(res?.data?.message);
      setLoading2(false);
      // setPres(null);
      toggleUploadModal();
      // navigate(`?step=${route}`);
    } catch (err) {
      if (err) console.log({ error: err.response?.data, err });
      if (err?.response?.status === 429) toast.error(err?.response?.data);
      let error = err.response?.data?.error;
      if (error && error?.length > 1) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
      } else {
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data?.error?.[0]?.message ||
            err?.message
        );
      }
    }
    setLoading2(false);
  };

  return (
		<div className="max-w-4xl mx-auto">
			<div className="flex justify-between flex-wrap mb-8">
				<article>
					<p className="text-2xl f-bold">Create your dataset</p>
					<p className="f-light">Create dataset for your stakeholder map</p>
				</article>
				<Button
					buttonType={"secondary"}
					children={"Import Dataset"}
					icon={<PiDownloadSimpleFill />}
					onClick={toggleUploadModal}
				/>
			</div>
			<div>
				<form className="space-y-6">
					{itemForm?.map((item, index) => (
						<>
							<div className="relative grid md:grid-cols-3 gap-4">
								<div>
									<div>
										{index === 0 && (
											<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
												Dataset Category
											</label>
										)}
										<input
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
											placeholder="Category dataset"
											value={item.category}
											onChange={event =>
												handleInputChangeForMutipleItem(
													event,
													index,
													"category"
												)
											}
										/>
									</div>
								</div>
								<div className="col-span-2">
									<div>
										{index === 0 && (
											<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
												Dataset data
											</label>
										)}
										<div className="bg-gray-100">
											{item?.dataArr?.length > 0 && (
												<div className="flex items-center gap-2 overflow-x-auto pt-2 px-3">
													{item?.dataArr?.map((it, x) => (
														<span
															key={x}
															className="text-xs border border-black rounded-full px-3 py-1 flex items-center justify-between gap-3 w-auto whitespace-nowrap">
															{it}{" "}
															<FaTimes
																className="cursor-pointer"
																onClick={() => {
																	let newArr = item?.dataArr?.filter(
																		(j, k) => k !== x
																	);
																	let newItem = {
																		...item,
																		dataArr: newArr,
																	};
																	let newFinal = itemForm?.map((it, d) =>
																		d === index ? newItem : it
																	);
																	setItemForm(newFinal);
																	// setItemForm(prevRows => {
																	// 	const newRows = [...prevRows];
																	// 	newRows[index].dataArr?.splice(x, 1);
																	// 	return newRows;
																	// });
																}}
															/>
														</span>
													))}
												</div>
											)}
											<input
												className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
												placeholder="Press Enter to input a new Dataset data"
												value={item.data}
												onChange={event =>
													handleInputChangeForMutipleItem(event, index, "data")
												}
												onKeyUp={event =>
													handleInputKeyPress(event, index, "dataArr")
												}
											/>
										</div>
									</div>
								</div>
								<div
									onClick={() => handleDeleteRowForMutipleItem(index)}
									className="md:absolute self-center -right-20 cursor-pointer">
									<p className="text-sm text-red-600 flex items-center md:mt-2 gap-2">
										<span>
											<MdDelete />
										</span>
										Remove
									</p>
								</div>
							</div>
						</>
					))}
					{/* <div className="relative grid md:grid-cols-3 gap-4">
						<div>
							<div>
								<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
									Dataset name
								</label>
								<input
									className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
									placeholder="name dataset"
								/>
							</div>
						</div>
						<div className="col-span-2">
							<div>
								<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
									Dataset options
								</label>
								<SearchDropdownSelect />
							</div>
						</div>
						<div className="md:absolute self-center -right-20 cursor-pointer">
							<p className="text-sm text-red-600 flex items-center md:mt-2 gap-2">
								<span>
									<MdDelete />
								</span>
								Remove
							</p>
						</div>
					</div>
					<div className="relative grid md:grid-cols-3 gap-4">
						<div>
							<div>
								<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
									Dataset name
								</label>
								<input
									className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
									placeholder="name dataset"
								/>
							</div>
						</div>
						<div className="col-span-2">
							<div>
								<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
									Dataset options
								</label>
								<SearchDropdownSelect />
							</div>
						</div>
						<div className="md:absolute self-center -right-20 cursor-pointer">
							<p className="text-sm text-red-600 flex items-center md:mt-2 gap-2">
								<span>
									<MdDelete />
								</span>
								Remove
							</p>
						</div>
					</div> */}
					<p
						onClick={addRowForMutipleItem}
						style={{
							color: orgTheme || "",
						}}
						className="flex items-center gap-2 text-bluerolodex f-medium my-3 cursor-pointer">
						<span>
							<IoIosAdd />
						</span>
						Add dataset
					</p>
					{mainSocket?.progress && (
						<ProgressBarSocket
							progress={mainSocket?.progress?.value}
							current={mainSocket?.progress?.current}
						/>
					)}
					<div className="mt-4 flex items-center gap-3">
						<Button
							children={"Continue"}
							buttonType={"primary"}
							onClick={onSubmit}
							loading={loading}
						/>

						{rawdata?.data?.totalDocs > 0 && (
							<Button
								children={"Skip"}
								buttonType={"gray"}
								onClick={() => navigate(`?step=${route}`)}
							/>
						)}
					</div>
				</form>
			</div>
			<ModalContainer
				show={bulkUpload}
				close={toggleUploadModal}
				title={"Upload new dataset"}
				subtitle={
					"Add new dataset from an excel or csv file. Download sample file"
				}>
				<Extractor pres={pres} setPres={setPres} />
				<div className="flex items-center gap-1 justify-between">
					{/* <a
						style={{
							color: orgTheme || "",
						}}
						href={RawSample}
						download={RawSample?.name}
						target="_blank"
						rel="noreferrer"
						className="text-main flex items-center gap-1">
						<span>
							<BiDownload />
						</span>
						<p className="text-xs">Download sample file</p>
					</a> */}
					<CSVLink
						style={{
							color: orgTheme || "",
						}}
						filename="Rolodex Raw Data Sample.csv"
						className="text-main flex items-center gap-1"
						data={newSample}>
						<span>
							<BiDownload />
						</span>
						<p className="text-xs">Download sample file</p>
					</CSVLink>
					<div className="flex items-center gap-4">
						<Button
							buttonType={"secondary"}
							children={"Cancel"}
							css={"h-8"}
							onClick={() => setPres([])}
						/>
						<Button
							buttonType={"primary"}
							children={"Import"}
							css={"h-8"}
							loading={loading2}
							type="submit"
							onClick={onSubmit2}
						/>
					</div>
				</div>
			</ModalContainer>
		</div>
	);
};

export const escapeKeys = (o, escape, arr, asso) => {
    var newO,
      origKey,
      newKey,
      value,
      // rawData = [],
      associate = {};
    if (o instanceof Array) {
      return o.map(function (value) {
        if (typeof value === "object") {
          value = escapeKeys(value, escape, arr, asso);
        }
        return value;
      });
    } else {
      newO = {};
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          newKey = (
            origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
          ).toString();
          newKey = newKey?.split(" ")?.join("");
          value = o[origKey];
          if (
            value instanceof Array ||
            (value !== null && value?.constructor === Object)
          ) {
            value = escapeKeys(value, escape, arr, asso);
          }
          if (escape && escape?.includes(newKey)) {
            if (arr?.includes(newKey)) {
              newO[newKey] =
                typeof value === "string"
                  ? value
                      ?.trim()
                      ?.split("\r\n")
                      ?.map((ix) => ix?.trim())
                  : value;
            } else {
              if (newKey === "phoneNumber") {
                newO[newKey] =
                  typeof value === "string"
                    ? value?.trim()?.split("\r\n")?.toString()
                    : value;
              } else if (newKey === "yearFounded") {
                newO[newKey] = ["number", "string"]?.includes(typeof value)
                  ? moment(value)?.isValid()
                    ? moment(value).format("L")
                    : value?.trim()
                  : value;
              } else {
                newO[newKey] =
                  typeof value === "string" ? value?.trim() : value;
              }
            }
          } else if (asso && newKey?.includes(asso)) {
            if (newKey === "associatedPerson") {
              associate.name =
                typeof value === "string" ? value?.trim() : value;
            }
            if (newKey === "associatedPersonEmail") {
              associate.email =
                typeof value === "string" ? value?.trim() : value;
            }
            if (newKey === "associatedPersonRole") {
              associate.role =
                typeof value === "string" ? value?.trim() : value;
            }
          } else {
            newO[newKey] = typeof value === "string" ? value?.trim() : value;
            // rawData?.push({
            // 	category: `${newKey}`,
            // 	data:
            // 		typeof value === "string"
            // 			? value
            // 					?.trim()
            // 					?.split("\r\n")
            // 					?.map(ix => ix?.trim())
            // 			: value,
            // });
          }
        }
      }
      // newO.rawData = rawData;
      if (associate?.name || associate?.email) {
        let newNameArr = associate?.name
            ?.trim()
            ?.split("\r\n")
            ?.map((ix) => ix?.trim()),
          newEmailArr = associate?.email
            ?.trim()
            ?.split("\r\n")
            ?.map((ix) => ix?.trim()),
          newCombo = [];

        if (newNameArr)
          for (let ac = 0; ac < newNameArr?.length; ac++) {
            const element = newNameArr?.[ac];
            newCombo?.push({ name: element, email: newEmailArr?.[ac] });
          }
        newO.associatedPeople = newCombo;
      }
      // rawData = [];
      associate = {};
    }
    return newO;
  },
  omitKeys = [
    "organizationId",
    "organizationName",
    "websiteUrl",
    "companyBio",
    "headQuarters",
    "email",
    "contactPersonName",
    "contactPersonEmail",
    "phoneNumber",
    "locationsPaidStaff",
    "yearFounded",
    "officeLocations",
    "associatedPeople",
    "contactPerson",
    "logo",
    "organizationMission",
    "event",
    "useCase",
    "useCases",
    "relevantMedia",
  ];

const InviteOrganisation = ({ route }) => {
  const navigate = useNavigate();

  let [loading, setLoading] = useState(null),
    dispatch = useDispatch(),
    { mainSocket, rawdata } = useSelector((s) => s),
    [newSample, setNewSample] = useState([]);

  let init2 = {};
  const [itemForm, setItemForm] = useState([init2]);
  const handleInputChangeForMutipleItem = (event, index, field) => {
    const { value } = event.target;
    let itemValue = value;

    setItemForm((prevRows) => {
      const newRows = [...prevRows];
      newRows[index][field] = itemValue;
      return newRows;
    });
  };

  const handleDeleteRowForMutipleItem = (index) => {
    setItemForm((prevRows) => {
      const newRows = [...prevRows];
      newRows.splice(index, 1);
      return newRows;
    });
  };

  useEffect(() => {
    dispatch(getProgress(null));
  }, [dispatch]);

  useEffect(() => {
    let newArr = [],
      newHeader = [
        "Organization Name",
        "Website Url",
        "Company Bio",
        "Head Quarters",
        "Email",
        "Contact Person Name",
        "Contact Person Email",
        "Phone Number",
        "Logo",
        "Office Locations",
        "Locations Paid Staff",
        "Organization Mission",
        "Year Founded",
        "Associated Person",
        "Associated Person Email",
      ];
    let newObj = {};
    if (rawdata?.data?.docs?.length > 0) {
      for (let r = 0; r < rawdata?.data?.docs.length; r++) {
        let element = rawdata?.data?.docs[r];
        newObj[`${StringTOCamel(element?.category)}`] = element?.data
          ?.toString()
          ?.split(",")
          ?.join("\n");

        newHeader = [element?.category, ...newHeader];
      }
      newArr?.push({ ...newObj, ...OrgDataSample?.[0] });
    } else {
      newArr = OrgDataSample;
    }
    let csvData = [
      [...new Set(newHeader?.map((it) => it))],
      ...newArr?.map((it) => {
        let newA = Object?.keys(it)?.map((ic) => it?.[ic]);
        return newA;
      }),
    ];
    // console.log({ csvData, newArr });
    setNewSample(csvData);
  }, [rawdata?.data]);

  const addRowForMutipleItem = () => {
    const newRow = init2;
    setItemForm([...itemForm, newRow]);
  };
  let [pres, setPres] = useState([]);

  const onSubmit = async () => {
    console.log({ itemForm });
    if (itemForm?.length === 0)
      return toast.info("Organization details required");
    if (itemForm?.length === 1)
      if (
        !itemForm?.[0]?.email ||
        !itemForm?.[0]?.organizationName
        // || !itemForm?.[0]?.contactPersonEmail
      )
        return toast.info("Organizations' complete details required");
    setLoading(true);
    try {
      let res;
      dispatch(getProgress(null));
      if (pres && pres?.length > 0) {
        let arrayKeys = ["officeLocations", "locationsPaidStaff"],
          associate = "associated";
        let newItemForm = escapeKeys(itemForm, null, arrayKeys, associate);

        console.log({ newItemForm });
        res = await axios.post(`api/v1/organization/bulk-companies-file`, {
          nextScreen: `${window?.location?.origin}/verify`,
          privilege: "company",
          companies: newItemForm?.map((it) => {
            return {
              ...it,
              contactPerson: {
                email: it?.contactPersonEmail,
                name: it?.contactPersonName,
              },
            };
          }),
        });
      } else {
        // console.log({ itemForm, user: auth?.user });
        res = await axios.post(`api/v1/organization/invite-companies`, {
          nextScreen: `${window?.location?.origin}/verify`,
          privilege: "company",
          // roleType: auth?.user?.idType || "COMPANY",
          companies: itemForm?.map((it) => {
            return {
              ...it,
              contactPerson: {
                email: it?.contactPersonEmail,
                name: it?.contactPersonName,
              },
            };
          }),
        });
      }
      console.log({ resp: res?.data });
      toast.success(res?.data?.message);
      setLoading(false);
      navigate(`?step=${route}`);
      dispatch(
        manageMap("get", {
          map: "map",
        })
      );
      dispatch(manageRawData("get"));
      dispatch(getProgress(null));
    } catch (err) {
      dispatch(getProgress(null));
      if (err) console.log({ error: err.response?.data, err });
      if (err?.response?.status === 429) toast.error(err?.response?.data);
      let error = err.response?.data?.error;
      if (error && error?.length > 1) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
      } else {
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data?.error?.[0]?.message ||
            err?.message
        );
      }
    }
    setLoading(false);
  };

  let [loading2, setLoading2] = useState(null),
    { orgTheme } = useContext(GlobalState);

  const [bulkUpload, setBulkUpload] = useState(false);
  const toggleUploadModal = () => {
    // setPres([]);
    setBulkUpload(!bulkUpload);
  };

  const onSubmit2 = async () => {
    // console.log({ data });
    if (pres?.length === 0)
      return toast.info("Organizations' details required");
    setLoading2(true);
    try {
      let newItemForm = combineArray(pres, [
        ...omitKeys,
        "associatedPerson",
        "associatedPersonName",
        "associatedPersonEmail",
        "associatedPersonRole",
      ]);
      let newPres = [];
      for (let p = 0; p < newItemForm?.length; p++) {
        const element = newItemForm?.[p];
        newPres?.push(toCamel(element));
      }
      let newItem = [];
      for (let n = 0; n < newPres?.length; n++) {
        const element = newPres?.[n];
        newItem = [...newItem, element];
      }
      setItemForm(
        itemForm?.[0]?.companyEmail ||
          itemForm?.[0]?.companyName ||
          itemForm?.[0]?.contactPersonEmail
          ? [...itemForm, ...newItem]
          : newItem
      );
      // console.log({ newItemForm, newPres });
      // let res = await axios.post(`/organization/invite-companies`, {
      // 	organizationId: auth?.user?.organization?.organizationId,
      // 	// roleType: auth?.user?.idType || "COMPANY",
      // 	companies: newPres,
      // });
      // console.log({ resp: res?.data });
      // toast.success(res?.data?.message);
      setLoading2(false);
      // setPres(null);
      toggleUploadModal();
      // navigate(`?step=${route}`);
    } catch (err) {
      if (err) console.log({ error: err.response?.data, err });
      if (err?.response?.status === 429) toast.error(err?.response?.data);
      let error = err.response?.data?.error;
      if (error && error?.length > 1) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
      } else {
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data?.error?.[0]?.message ||
            err?.message
        );
      }
    }
    setLoading2(false);
  };

  // console.log({ itemForm });
  return (
    <div className="max-w-4xl mx-auto">
      <div className="flex justify-between flex-wrap mb-8">
        <article>
          <p className="text-2xl f-bold">Invite organisation </p>
          <p className="f-light">Invite collaborating organisations</p>
        </article>
        <Button
          buttonType={"secondary"}
          children={"Import Contacts"}
          icon={<PiDownloadSimpleFill />}
          onClick={toggleUploadModal}
        />
      </div>
      <div>
        <form className="space-y-2">
          <div className="relative grid grid-cols-3 gap-4">
            <div>
              <label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
                Organization name
              </label>
            </div>
            <div className="">
              <label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
                Email
              </label>
            </div>
            <div className="">
              <label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
                Contact Person Email
              </label>
            </div>
          </div>
          {/* <div className="relative grid grid-cols-3 gap-4">
						<div>
							<input
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
								placeholder="name dataset"
							/>
						</div>
						<div className="">
							<input
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
								placeholder="name dataset"
							/>
						</div>
						<div className="">
							<input
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
								placeholder="name dataset"
							/>
						</div>
					</div> */}
          {itemForm?.map((item, index) => (
            <>
              <div className="relative grid grid-cols-3 gap-4">
                <div className="">
                  <div>
                    {/* <label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
											Company Name
										</label> */}
                    <input
                      className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
                      placeholder="Company Name"
                      value={item.organizationName}
                      onChange={(event) =>
                        handleInputChangeForMutipleItem(
                          event,
                          index,
                          "organizationName"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="">
                  <div>
                    {/* <label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
											Company Email
										</label> */}
                    <input
                      className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
                      placeholder="Company Email"
                      value={item.email}
                      onChange={(event) =>
                        handleInputChangeForMutipleItem(event, index, "email")
                      }
                    />
                  </div>
                </div>
                <div className="">
                  <div>
                    {/* <label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
											Company Contact Person Email
										</label> */}
                    <input
                      className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
                      placeholder="Company Contact Person Email"
                      type="email"
                      value={item.contactPersonEmail}
                      onChange={(event) =>
                        handleInputChangeForMutipleItem(
                          event,
                          index,
                          "contactPersonEmail"
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  onClick={() => handleDeleteRowForMutipleItem(index)}
                  className="md:absolute self-center -right-20 cursor-pointer"
                >
                  <p className="text-sm text-red-600 flex items-center md:mt-2 gap-2">
                    <span>
                      <MdDelete />
                    </span>
                    Remove
                  </p>
                </div>
              </div>
            </>
          ))}
          <p
            style={{
              color: orgTheme || "",
            }}
            onClick={addRowForMutipleItem}
            className="flex items-center gap-2 text-bluerolodex f-medium py-3 cursor-pointer capitalize"
          >
            <span>
              <IoIosAdd />
            </span>
            Add company
          </p>
          {mainSocket?.progress && (
            <ProgressBarSocket
              progress={mainSocket?.progress?.value}
              current={mainSocket?.progress?.current}
            />
          )}
          <div className="mt-4 flex items-center gap-3">
            <Button
              children={"Continue"}
              buttonType={"primary"}
              onClick={onSubmit}
              loading={loading}
            />

            <Button
              children={"Skip"}
              buttonType={"gray"}
              onClick={() => navigate(`?step=${route}`)}
            />
          </div>
        </form>
      </div>
      <ModalContainer
        show={bulkUpload}
        close={toggleUploadModal}
        title={"Upload new organisations"}
        subtitle={
          "Add new organisation from an excel or csv file. Download sample file"
        }
      >
        <Extractor pres={pres} setPres={setPres} />
        <div className="flex items-center gap-1 justify-between">
          {/* <a
						style={{
							color: orgTheme || "",
						}}
						href={RawSample2}
						download={RawSample2?.name}
						target="_blank"
						rel="noreferrer"
						className="text-main flex items-center gap-1">
						<span>
							<BiDownload />
						</span>
						<p className="text-xs">Download sample file</p>
					</a> */}
          <CSVLink
            style={{
              color: orgTheme || "",
            }}
            filename="Rolodex Organization Company Sample.csv"
            className="text-main flex items-center gap-1"
            data={newSample}
          >
            <span>
              <BiDownload />
            </span>
            <p className="text-xs">Download sample file</p>
          </CSVLink>
          <div className="flex items-center gap-4">
            <Button
              buttonType={"secondary"}
              children={"Cancel"}
              css={"h-8"}
              onClick={() => setPres([])}
            />
            <Button
              buttonType={"primary"}
              children={"Import"}
              css={"h-8"}
              loading={loading2}
              type="submit"
              onClick={onSubmit2}
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

const StartMapping = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { orgTheme } = useContext(GlobalState);
  return (
    <section className="min-h-screen flex flex-col items-center">
      {/* <div className="fixed inset-0  bg-mainDark"></div> */}
      <div className="flex flex-col px-5 md:px-8 py-8 rounded-md bg-white w-10/12 lg:w-full max-w-lg relative z-10">
        <div>
          <img
            src={require("../../assets/success-gif.gif")}
            alt=""
            className="mx-auto"
          />
        </div>
        <h1 className="text-2xl md:text-2xl xl:text-3xl font-bold text-center">
          Awesome! Let's get started
        </h1>
        <p className="p-0.5 px-2 text-center mb-8 max-w-sm mx-auto text-gray-600">
          Your account is ready to go - you can now access your workspace.
        </p>
        <div className="space-y-2">
          <button
            style={{
              background: orgTheme || "",
            }}
            className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
            onClick={() => {
              dispatch(setStarter("started"));
              navigate("/");
            }}
          >
            Start Mapping
          </button>
          <button
            style={{
              color: orgTheme || "",
            }}
            className="w-full flex justify-center text-bluerolodex hover:brightness-100 p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
            onClick={() => {
              navigate("/invitations");
            }}
          >
            View Invitations
          </button>
        </div>
      </div>
    </section>
  );
};

export const combineArray = (o, escape) => {
  var newO,
    origKey,
    newKey,
    value,
    checkKey,
    rawData = [];
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = combineArray(value, escape);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = origKey.toString();
        // newKey = newKey?.split(" ")?.join("");
        value = o[origKey];
        checkKey = (
          origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
        ).toString();
        checkKey = checkKey
          ?.split(" ")
          ?.map((it, i) => {
            let newIt =
              i === 0
                ? (it.charAt(0).toLowerCase() + it.slice(1) || it).toString()
                : (it.charAt(0).toUpperCase() + it.slice(1) || it).toString();
            return newIt;
          })
          ?.join("");
        if (
          value instanceof Array ||
          (value !== null && value?.constructor === Object)
        ) {
          value = combineArray(value, escape);
        }
        if (escape && escape?.includes(checkKey)) {
          newO[newKey] = typeof value === "string" ? value?.trim() : value;
        } else {
          let nextD = [],
            newData =
              typeof value === "string"
                ? value
                    ?.trim()
                    ?.split("\r\n")
                    ?.map((ix) => ix?.trim())
                    ?.map((iq) =>
                      nextD?.push(...iq?.split(",")?.map((pc) => pc?.trim()))
                    )
                : null;

          rawData?.push({
            category: newKey,
            data: typeof value === "string" ? nextD : value,
          });
        }
      }
    }
    if (escape) newO.rawData = rawData;
    else newO = rawData;
    rawData = [];
  }
  return newO;
};

export const ProgressBarSocket = ({ progress, current }) => {
  let { orgTheme } = useContext(GlobalState);
  return (
    <>
      <div className="w-[96%] bg-[#F1F8FF] rounded-full min-h-1 text-center">
        <div
          className={`w-[${progress}%] bg-bluerolodex rounded-full h-2 text-center text-white`}
          style={{
            width: progress ? `${progress}%` : "",
            background: orgTheme || "",
          }}
        ></div>
      </div>
      <span className="text-black block text-center">
        {current} {progress}%
      </span>
    </>
  );
};

export const StringTOCamel = (origKey) => {
  let checkKey = (
    origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
  ).toString();
  checkKey = checkKey
    ?.split(" ")
    ?.map((it, i) => {
      let newIt =
        i === 0
          ? (it.charAt(0).toLowerCase() + it.slice(1) || it).toString()
          : (it.charAt(0).toUpperCase() + it.slice(1) || it).toString();
      return newIt;
    })
    ?.join("");

  return checkKey;
};
