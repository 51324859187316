/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect, useState } from "react";
import DefaultHeader from "../../components/default-header/default-header";
import SmallNav from "../../components/small-nav/small-nav";
// import organisations from "../../organizations";
import IconDropdown from "../../components/icon-dropdown/icon-dropdown";
import Search from "../../components/search/search";
import ModalContainer, {
	PageLoader,
} from "../../components/modal-container/modal-container";
import { BiDownload } from "react-icons/bi";
import Button, { LoadMore, MainPaginate } from "../../components/button/button";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RiDeleteBin6Line, RiSendPlaneFill } from "react-icons/ri";
import RightDrawer from "../../components/right-drawer/right-drawer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	manageOrganization,
	resetOrganizationSearch,
} from "../../data/Reducers/OrganizationReducer";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import Extractor from "../../data/useExtractor";
import { GlobalState } from "../../data/Context";
import { toCamel } from "../../App";
import RawSample from "../../assets/Rolodex Organization Company Sample.xlsx";
import {
	SearchDropdownSelectExport,
	SortBy,
} from "../../components/search-dropdown/search-dropdown";

function Organisations() {
	const [bulkUpload, setBulkUpload] = useState(false);
	const [drawer, setDrawer] = useState(false);
	const navigate = useNavigate();

	// const handleBulkUpload = () => {
	// 	toggleUploadModal();
	// };
	const toggleUploadModal = () => {
		setBulkUpload(!bulkUpload);
	};
	const toggleShowDrawer = dd => {
		setDrawer(drawer ? null : dd);
	};
	const addNewOrganization = () => {
		navigate(auth?.user?.company ? "recommendation" : "add-new-organisation");
	};
	const addNewOrganizationBulk = () => {
		navigate(auth?.user?.company ? "recommendation" : "bulk-upload");
	};

	let [data, setData] = useState(null),
		{ organization, auth, invitations } = useSelector(s => s),
		dispatch = useDispatch(),
		{ orgTheme, numberWithCommas } = useContext(GlobalState),
		[search, setSearch] = useState(""),
		[page, setPage] = useState(1),
		[sort, setSort] = useState("");

	// useEffect(() => {
	// 	if (auth?.user)
	// 		if (!["organization", "company"]?.includes(auth?.user?.privilege))
	// 			// if (!["ADMIN"]?.includes(auth?.user?.role?.title?.toUpperCase()))
	// 			navigate(-1);
	// }, [auth?.user, navigate]);

	let tabViews = [
		`Verified Organization ${
			auth?.user?.privilege === "organization"
				? organization?.companies?.totalDocs > 0
					? ` (${numberWithCommas(organization?.companies?.totalDocs)})`
					: ""
				: auth?.user?.privilege !== "organization"
				? invitations?.data?.totalDocs > 0
					? ` (${numberWithCommas(invitations?.data?.totalDocs)})`
					: ""
				: ""
		}`,
	];

	if (auth?.user?.privilege === "organization")
		tabViews = [
			...tabViews,
			`Pending Verification ${
				organization?.companiesArchived?.totalDocs > 0
					? ` (${numberWithCommas(organization?.companiesArchived?.totalDocs)})`
					: ""
			}`,
			`Recommendation ${
				organization?.companiesRecommendation?.totalDocs > 0
					? ` (${numberWithCommas(
							organization?.companiesRecommendation?.totalDocs
					  )})`
					: ""
			}`,
		];

	let [active, setActive] = useState(
		`Verified Organization ${
			auth?.user?.privilege === "organization"
				? organization?.companies?.totalDocs > 0
					? ` (${numberWithCommas(organization?.companies?.totalDocs)})`
					: ""
				: auth?.user?.privilege !== "organization"
				? invitations?.data?.totalDocs > 0
					? ` (${numberWithCommas(invitations?.data?.totalDocs)})`
					: ""
				: ""
		}`
	);

	const handleNav = item => {
		setActive(item);
	};

	useEffect(() => {
		if (organization?.isFound) setData(organization?.mainSearch);
		else if (active === tabViews[2])
			setData(organization?.companiesRecommendation);
		else if (active === tabViews[1]) setData(organization?.companiesArchived);
		else if (
			organization?.companies &&
			auth?.user?.privilege === "organization"
		)
			setData(organization?.companies);
		else if (invitations?.data && auth?.user?.privilege !== "organization")
			setData(invitations?.data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		organization?.companies,
		organization?.companiesArchived,
		active,
		organization?.companiesRecommendation,
		organization?.isFound,
		organization?.mainSearch,
		auth?.user,
		invitations?.data,
	]);

	let handleLoadMore = async () => {
		setLoading("loadmore");

		let dd = {
			companies: "companies",
			limit: data?.limit * data?.nextPage,
		};
		if (active === tabViews?.[0]) {
			if (auth?.user?.privilege === "organization") {
				dd = {
					...dd,
					active: "active",
				};
			} else {
				dd = { ...dd, statusText: "activated" };
			}
		}
		if (active === tabViews?.[1])
			dd = {
				...dd,
				statusText: "archived",
			};
		if (active === tabViews?.[2])
			dd = {
				...dd,
				statusText: "recommendation",
			};
		if (search) {
			dd = { ...dd, search };
		}
		if (sort) dd = { ...dd, sort };

		await dispatch(manageOrganization("get", dd));
		setLoading(false);
	};

	let handleSearch = async () => {
		setLoading("loadmore");

		let dd = {
			companies: "companies",
		};
		if (active === tabViews?.[0]) {
			if (auth?.user?.privilege === "organization") {
				dd = {
					...dd,
					active: "active",
				};
			} else {
				dd = { ...dd, statusText: "activated" };
			}
		}
		if (active === tabViews?.[1])
			dd = {
				...dd,
				statusText: "archived",
			};
		if (active === tabViews?.[2])
			dd = {
				...dd,
				statusText: "recommendation",
			};
		if (search) {
			dd = { ...dd, search };
		}
		if (sort) dd = { ...dd, sort };

		await dispatch(manageOrganization("get", dd));
		setLoading(false);
	};

	useEffect(() => {
		if (search) {
			document.getElementById("Search").addEventListener("search", () => {
				dispatch(resetOrganizationSearch());
			});
			let handleSubmit = async () => {
				if (!search) return;

				await handleSearch();
			};
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	useEffect(() => {
		dispatch(resetOrganizationSearch());
		dispatch(
			manageOrganization("get", {
				companies: "companies",
				active: "active",
				sort,
			})
		);
		dispatch(
			manageOrganization("get", {
				companies: "companies",
				statusText: "archived",
				sort,
			})
		);
		dispatch(
			manageOrganization("get", {
				companies: "companies",
				statusText: "recommendation",
				sort,
			})
		);
		dispatch(
			manageOrganization("get", {
				companies: "companies",
				statusText: "activated",
				sort,
			})
		);
		// dispatch(
		// 	manageOrganization("get", {
		// 		logo: "logo",
		// 	})
		// );
		// dispatch(manageOrganization("get"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sort]);

	let [pres, setPres] = useState([]);

	let [loading, setLoading] = useState(null),
		[newSample, setNewSample] = useState([]),
		[updater, setUpdater] = useState(null);

	const onSubmit = async () => {
			// console.log({ data });
			if (pres?.length === 0)
				return toast.info("Organizations' details required");
			setLoading(true);
			try {
				let newPres = [];
				for (let p = 0; p < pres.length; p++) {
					const element = pres[p];
					newPres?.push(toCamel(element));
				}
				let res = await axios.post(`/organization/invite-companies`, {
					organizationId: auth?.user?.organization?.organizationId,
					// roleType: auth?.user?.idType || "COMPANY",
					companies: newPres,
					nextScreen: `${window?.location?.origin}/verify`,
					nextScreen2: `${window?.location?.origin}/signup/verify-profile`,
					privilege: "company",
				});
				console.log({ resp: res?.data });
				toast.success(res?.data?.message);
				setLoading(false);
				setPres(null);
				toggleUploadModal();
				dispatch(
					manageOrganization("get", {
						companies: "companies",
						active: "active",
					})
				);
				dispatch(
					manageOrganization("get", {
						companies: "companies",
						statusText: "archived",
					})
				);
				dispatch(
					manageOrganization("get", {
						companies: "companies",
						statusText: "recommendation",
					})
				);
				dispatch(
					manageOrganization("get", {
						companies: "companies",
						statusText: "activated",
					})
				);
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				let error = err.response?.data?.error;
				if (error && error?.length > 1) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				} else {
					toast.error(
						err?.response?.data?.message ||
							err?.response?.data?.error?.[0]?.message ||
							err?.message
					);
				}
			}
			setLoading(false);
		},
		updateSubmit = async e => {
			e?.preventDefault();
			// console.log({ data });
			if (!updater) return toast.info("Updated invite required");
			setLoading(true);
			try {
				let res = await axios.put(`/api/v1/organization/bulk-companies-file`, {
					nextScreen: `${window?.location?.origin}/verify`,
					nextScreen2: `${window?.location?.origin}/verify-profile`,
					privilege: "company",
					invitations: updater?.invitations,
					type: updater?.type,
				});
				console.log({ resp: res?.data });
				toast.success(res?.data?.message);
				setLoading(false);
				setUpdater(null);
				dispatch(
					manageOrganization("get", {
						companies: "companies",
						statusText: "activated",
					})
				);
				dispatch(
					manageOrganization("get", {
						companies: "companies",
						statusText: "archived",
					})
				);
				dispatch(
					manageOrganization("get", {
						companies: "companies",
						statusText: "recommendation",
					})
				);
				dispatch(
					manageOrganization("get", {
						companies: "companies",
						active: "active",
					})
				);
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				let error = err.response?.data?.error;
				if (error && error?.length > 1) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				} else {
					toast.error(
						err?.response?.data?.message ||
							err?.response?.data?.error?.[0]?.message ||
							err?.message
					);
				}
			}
			setLoading(false);
		};

	useEffect(() => {
		let newArr = [],
			newHeader = [];
		let newObj = {};
		if (data?.docs?.length > 0) {
			for (let r = 0; r < data?.docs.length; r++) {
				let element = data?.docs[r];
				newObj = {
					company: element?.organizationName,
					email: element?.email,
					contactPersonName: element?.contactPerson?.name,
					website: element?.websiteUrl,
					phone: element?.phoneNumber,
					dateAdded: moment(element?.createdAt).format("L"),
				};
				console.log({ newObj, data });
				newArr?.push(newObj);
				newObj = {};
			}
		}
		newHeader = [
			"Company",
			"Email",
			"Contact Person",
			"Website Url",
			"Phone Number",
			"Date Added",
		];
		console.log({ newArr });
		let csvData = [
			newHeader,
			...newArr?.map(it => {
				let newA = Object?.keys(it)?.map(ic => it?.[ic]);
				return newA;
			}),
		];
		// console.log({ csvData });
		setNewSample(csvData);
	}, [data]);

	let [range] = useState(10);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	if (active === tabViews[0]) {
		if (auth?.user?.privilege === "organization")
			if (!data && !organization?.companies) return <PageLoader />;
			else {
				if (!data && !invitations?.data) return <PageLoader />;
			}
	}

	if (active === tabViews[1])
		if (!data && !organization?.companiesArchived) return <PageLoader />;

	if (active === tabViews[2])
		if (!data && !organization?.companiesRecommendation) return <PageLoader />;

	const currentItems = data
		? [...data?.docs]?.slice(itemOffset, endOffset)
		: [];
	const pageCount = Math.ceil(data?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % data?.docs?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	return (
		<div>
			<DefaultHeader
				handleBulkUpload={addNewOrganizationBulk}
				addNew={addNewOrganization}
				title1={auth?.user?.privilege === "organization" ? "Bulk upload" : ""}
				title2={
					auth?.user?.privilege === "company" ||
					auth?.user?.role?.title?.toUpperCase() === "ADMIN"
						? "Recommend Organization"
						: auth?.user?.privilege === "organization"
						? "Add Organization"
						: ""
				}
				button
			/>
			<div className="bg-white rounded-lg p-4">
				<SmallNav menu={tabViews} handleNav={handleNav} active={active} />
				<div className="flex items-center gap-2 justify-between my-4">
					<Search
						placeholder={"Search Organizations"}
						search={search}
						setSearch={setSearch}
					/>
					<div className="flex items-center gap-2">
						<SortBy sort={sort} setSort={setSort} />
						<SearchDropdownSelectExport
							newSample={newSample}
							fileName={"Rolodex Organization"}
						/>
						{auth?.user?.privilege === "organization" && (
							<>
								{data?.docs?.some?.(ic => ic?.isChecked) && (
									<>
										{active === tabViews[1] || active === tabViews[2] ? (
											<Button
												buttonType={"primary"}
												children={"Send Invitation"}
												type="button"
												css={"w-fit me-2 py-3"}
												onClick={() =>
													setUpdater({
														type: "send",
														invitations: data?.docs
															?.filter(iz => iz?.isChecked)
															?.map(ia => {
																return { invitationId: ia?._id };
															}),
													})
												}
											/>
										) : null}
										<Button
											buttonType={"primary"}
											children={"Delete Organization"}
											type="button"
											css={"w-fit me-2 py-3"}
											onClick={() =>
												setUpdater({
													type: "delete",
													invitations: data?.docs
														?.filter(iz => iz?.isChecked)
														?.map(ia => {
															return { invitationId: ia?._id };
														}),
												})
											}
										/>
									</>
								)}
							</>
						)}
					</div>
				</div>
				<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
					<table className="w-full text-sm text-left text-gray-500">
						<thead className="text-md text-gray-700 capitalize bg-secondary">
							<tr>
								<th scope="col" className="p-4">
									<div className="flex items-center">
										<input
											id="checkbox-all-search"
											type="checkbox"
											className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
											onChange={e => {
												let dd = data?.docs;
												dd = dd?.map(ic => {
													let dc = ic;
													return { ...dc, isChecked: e?.target?.checked };
												});
												setData({ ...data, docs: dd });
											}}
											checked={
												data?.docs?.length > 0 &&
												data?.docs?.every(it => it?.isChecked)
											}
										/>
										<label for="checkbox-all-search" className="sr-only">
											checkbox
										</label>
									</div>
								</th>

								<th scope="col" className="px-6 py-3">
									Company
								</th>
								<th scope="col" className="px-6 py-3">
									Email Address
								</th>
								<th scope="col" className="px-6 py-3">
									Contact Person
								</th>
								<th scope="col" className="px-6 py-3">
									Website
								</th>
								<th scope="col" className="px-6 py-3">
									Phone
								</th>
								<th scope="col" className="px-6 py-3">
									Date Added
								</th>
								<th scope="col" className="px-6 py-3"></th>
							</tr>
						</thead>
						<tbody>
							{currentItems?.map((organization, ind) => (
								<tr key={ind} className="bg-white border-b hover:bg-gray-50">
									<td className="w-4 p-4">
										<div className="flex items-center">
											<input
												id="checkbox-table-search-1"
												type="checkbox"
												className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
												onChange={e => {
													let dd = data?.docs;
													let current = organization;
													if (e?.target?.checked) {
														current = {
															...current,
															isChecked: true,
														};
													} else {
														current = {
															...current,
															isChecked: false,
														};
													}
													dd = dd?.map(ic =>
														ic?._id === organization?._id ? current : ic
													);
													setData({ ...data, docs: dd });
												}}
												checked={organization?.isChecked}
											/>
											<label for="checkbox-table-search-1" className="sr-only">
												checkbox
											</label>
										</div>
									</td>

									{/* <th
										scope="row"
										className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:underline cursor-pointer"
										onClick={() => toggleShowDrawer(organization)}>
										{organization?.companyName}
									</th> */}
									<td
										title={organization?.organizationName}
										className="px-6 py-4 font-medium text-gray-900 dark:text-white hover:underline cursor-pointer max-w-[200px]"
										onClick={() => toggleShowDrawer(organization)}>
										<span className="flex items-center w-full">
											<span className="fontInherit my-0 ps-0 ps-md-1 textTrunc textTrunc2 fontReduce w-full">
												{organization?.organizationName}
											</span>
										</span>
									</td>
									<td
										title={organization?.email}
										className="px-6 py-4 max-w-[250px] cursor-pointer"
										onClick={() => toggleShowDrawer(organization)}>
										<span className="flex items-center w-full">
											<span className="fontInherit my-0 ps-0 ps-md-1 textTrunc textTrunc2 fontReduce w-full">
												{organization?.email}
											</span>
										</span>
									</td>
									<td
										className="px-6 py-4 cursor-pointer"
										onClick={() => toggleShowDrawer(organization)}>
										{organization?.contactPerson?.name}
									</td>
									<td
										className="px-6 py-4 cursor-pointer"
										onClick={() => toggleShowDrawer(organization)}>
										{organization?.websiteUrl}
									</td>
									<td
										className="px-6 py-4 cursor-pointer"
										onClick={() => toggleShowDrawer(organization)}>
										{organization?.phoneNumber}
									</td>
									<td
										className="px-6 py-4 cursor-pointer"
										onClick={() => toggleShowDrawer(organization)}>
										{moment(organization?.createdAt).format("MM-DD-YYYY")}
									</td>
									<td className="flex items-center px-6 py-4 space-x-3">
										<IconDropdown>
											<ul className="space-y-1">
												<li
													className="text-xs flex items-center gap-1 cursor-pointer hover:text-gray-600"
													onClick={() => toggleShowDrawer(organization)}>
													<span>
														<AiOutlineCheckCircle />
													</span>
													<span>View Profile</span>
												</li>
												{auth?.user?.privilege === "organization" && (
													<>
														{active === tabViews[2] && (
															<>
																<li
																	onClick={() => {
																		setUpdater({
																			type: "send",
																			invitations: [
																				{
																					invitationId: organization?._id,
																				},
																			],
																		});
																	}}
																	className="text-green-400 texs-xs flex items-center gap-1 cursor-pointer hover:text-green-600">
																	<span>
																		<RiSendPlaneFill />
																	</span>
																	<span>Send Invitation</span>
																</li>
															</>
														)}
														<li
															onClick={() => {
																setUpdater({
																	type: "delete",
																	invitations: [
																		{
																			invitationId: organization?._id,
																		},
																	],
																});
															}}
															className="text-red-400 texs-xs flex items-center gap-1 cursor-pointer hover:text-red-600">
															<span>
																<RiDeleteBin6Line />
															</span>
															<span>Remove account</span>
														</li>
													</>
												)}
											</ul>
										</IconDropdown>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && data?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
				</div>
			</div>
			<ModalContainer
				show={bulkUpload}
				close={toggleUploadModal}
				title={"Upload new organisations"}
				subtitle={
					"Add new organisation from an excel or csv file. Download sample file"
				}>
				<Extractor pres={pres} setPres={setPres} />
				<div className="flex items-center gap-1 justify-between">
					<a
						style={{
							color: orgTheme || "",
						}}
						href={RawSample}
						download={RawSample?.name}
						target="_blank"
						rel="noreferrer"
						className="text-main flex items-center gap-1">
						<span>
							<BiDownload />
						</span>
						<p className="text-xs">Download sample file</p>
					</a>
					<div className="flex items-center gap-4">
						<Button
							buttonType={"secondary"}
							children={"Cancel"}
							css={"h-8"}
							onClick={toggleUploadModal}
						/>
						<Button
							buttonType={"primary"}
							children={"Import"}
							css={"h-8"}
							loading={loading}
							type="submit"
							onClick={onSubmit}
						/>
					</div>
				</div>
			</ModalContainer>
			<RightDrawer drawer={drawer} toggleShowDrawer={toggleShowDrawer} />
			<ModalContainer
				title={"Update Invitation"}
				width={"max-w-sm"}
				show={updater ? true : false}
				close={() => setUpdater(null)}>
				<div className="mx-20">
					<form className="space-y-4">
						<div className="my-auto w-100">
							<p className="text2 Lexend text-center">
								Do you want to {updater?.type} this invitation(s)
								/organization(s)?
							</p>
							<div className="pt-4 flex">
								<Button
									buttonType={"primary"}
									children={"Yes"}
									type="button"
									css={"w-fit me-2 py-3"}
									loading={loading}
									onClick={updateSubmit}
								/>
								<Button
									buttonType={"secondary"}
									children={"No"}
									type="button"
									css={"w-fit ms-2 py-3"}
									onClick={() => setUpdater(null)}
								/>
							</div>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
}

export default Organisations;
