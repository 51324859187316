import React, { useState, useEffect } from "react";
import DefaultHeader from "../../components/default-header/default-header";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import Button from "../../components/button/button";

const AddNewOrganisation = () => {
	let { auth } = useSelector(s => s),
		navigate = useNavigate();

	useEffect(() => {
		if (auth?.user)
			if (auth?.user?.privilege !== "organization")
				if (auth?.user?.role?.toUpperCase() !== "ADMIN") navigate(-1);
	}, [auth?.user, navigate]);

	return <AddNewOrganisationComponent />;
};

export default AddNewOrganisation;

export const AddNewOrganisationComponent = () => {
	const methods = useForm({
		defaultValues: {
			email: "",
			password: "",
		},
	});

	let [loading, setLoading] = useState(null),
		{ page } = useParams(),
		{ auth } = useSelector(s => s),
		navigate = useNavigate(),
		dispatch = useDispatch();

	const onSubmit = async data => {
		// console.log({ data });
		if (!data?.email || !data?.organizationName || !data?.contactPersonEmail)
			return toast.info("Organizations' complete details required");
		if (auth?.user?.company)
			if (!data?.recommenderPurpose)
				return toast.info("Purpose of Recommendation is required");
		setLoading(true);
		try {
			let res = await axios.post(`/api/v1/organization/invite-companies`, {
				nextScreen: `${window?.location?.origin}/verify`,
				privilege: "company",
				companies: [
					{ ...data, contactPerson: { email: data?.contactPersonEmail } },
				],
			});
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			setLoading(false);
			navigate(`/${page}`);
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
		setLoading(false);
	};

	return (
		<div>
			<DefaultHeader
				mainTitle={
					auth?.user?.company
						? "Recommend New Organization"
						: "Add New Organization"
				}
			/>
			<div className="bg-white rounded-lg p-4 mb-10">
				<p className="text-lg text-gray-500 mt-2">
					Fill all information to onboard new organization
				</p>
				<div className="mt-5 md:mt-7 lg:mt-10 max-w-xl">
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							{/* <div className="flex flex-col mb-6">
								<label
									className="mb-1 text-sm md:text-lg font-semibold tracking-wide text-gray-600"
									htmlFor="email">
									Company
								</label>

								<input
									className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
									type={"text"}
									// onChange={onChange}
									placeholder="Enter Company's name"
								/>
							</div>
							<div className="flex flex-col mb-6">
								<label
									className="mb-1 text-sm md:text-lg font-semibold tracking-wide text-gray-600"
									htmlFor="email">
									Email Address
								</label>

								<input
									className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
									type={"text"}
									// onChange={onChange}
									placeholder="Enter you email address"
								/>
							</div> */}
							<Controller
								name="organizationName"
								rules={{ required: "This field is required" }}
								render={({ field: { onChange, name } }) => (
									<fieldset className="flex flex-col mb-6">
										<input
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
											name={name}
											id={name}
											type={"text"}
											onChange={onChange}
											placeholder="Company Name"
										/>
										{methods.formState.errors.organizationName && (
											<span className="md:text-base font-bold text-red-600">
												This field is required
											</span>
										)}
									</fieldset>
								)}
							/>
							<Controller
								name="email"
								rules={{
									required: "This field is required",
									pattern: {
										value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/g,
										message: "You must enter a valid email address",
									},
								}}
								render={({ field: { onChange, name, value } }) => (
									<fieldset className="flex flex-col mb-6">
										<input
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
											name={name}
											id={name}
											type={"email"}
											onChange={onChange}
											value={value}
											placeholder="Enter  Company's email"
										/>
										{methods.formState.errors.email && (
											<span className="text sm md:text-base font-semibold text-red-600">
												This field is required
											</span>
										)}
									</fieldset>
								)}
							/>
							<Controller
								name="contactPersonEmail"
								rules={{
									required: "This field is required",
									pattern: {
										value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/g,
										message: "You must enter a valid email address",
									},
								}}
								render={({ field: { onChange, name, value } }) => (
									<fieldset className="flex flex-col mb-6">
										<input
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
											name={name}
											id={name}
											type={"email"}
											onChange={onChange}
											value={value}
											placeholder="Enter Contact Person's email."
										/>
										{methods.formState.errors[name] && (
											<span className="text sm md:text-base font-semibold text-red-600">
												This field is required
											</span>
										)}
									</fieldset>
								)}
							/>
							{auth?.user?.company && (
								<Controller
									name="recommenderPurpose"
									rules={{ required: "This field is required" }}
									render={({ field: { onChange, name, value } }) => (
										<fieldset className="flex flex-col mb-6">
											<textarea
												className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none placeholder:text-lg"
												placeholder="Reason(s) for recommendation"
												row={6}
												style={{
													resize: "none",
													height: "10rem",
												}}
												id={name}
												name={name}
												onChange={onChange}
												value={value}
											/>
											{methods.formState.errors[name] && (
												<span className="text sm md:text-base font-semibold text-red-600">
													This field is required
												</span>
											)}
										</fieldset>
									)}
								/>
							)}
							{/* <div className="flex flex-col mb-6">
								<label
									className="mb-1 text-sm md:text-lg font-semibold tracking-wide text-gray-600"
									htmlFor="email">
									Pnone Number
								</label>

								<input
									className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
									type={"text"}
									// onChange={onChange}
									placeholder="Please provide your phone number"
								/>
							</div>
							<div className="flex flex-col mb-6">
								<label
									className="mb-1 text-sm md:text-lg font-semibold tracking-wide text-gray-600"
									htmlFor="email">
									Contact person's name
								</label>

								<input
									className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
									type={"text"}
									// onChange={onChange}
									placeholder="Rolodex"
								/>
							</div> */}
							{/* <div className="flex flex-col mb-6">
								<label
									className="mb-1 text-sm md:text-lg font-semibold tracking-wide text-gray-600"
									htmlFor="email">
									Contact person's email
								</label>

								<input
									className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
									type={"text"}
									// onChange={onChange}
									placeholder="Rolodex"
								/>
							</div> */}
							<div className="flex items-center gap-8 mb-4">
								<button
									type="button"
									onClick={() => navigate(`/${page}`)}
									className="w-full flex justify-center bg-gray-200 hover:brightness-100  text-black p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500">
									Back
								</button>
								<Button
									children={"Next"}
									buttonType={"primary"}
									loading={loading}
									type="submit"
									// eslint-disable-next-line react/style-prop-object
									css={
										"w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
									}
								/>
								{/* <button
									type="submit"
									className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500">
									Next
								</button> */}
							</div>
						</form>
					</FormProvider>
				</div>
			</div>
		</div>
	);
};