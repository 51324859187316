import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { loadUser, login } from "../data/Reducers/UserReducer";
import { returnErrors } from "../data/Reducers/ErrorReducer";
// import { login } from "../api/config/axios-api-instance";
import { toast } from "react-toastify";
import axios from "axios";
import Button from "../components/button/button";
import OtpInput from "react18-otp-input";

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let { state } = useLocation(),
		[code, setCode] = useState("");

	let [loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		{ auth } = useSelector(s => s);

	const onSubmitOTP = async data => {
		console.log({ data });
		// dispatch(login({ token: "ROLODEX" }));
		// dispatch(loadUser());
		// navigate("/");
		if (!code) return;
		setLoading("2fa");
		try {
			let res = await axios.put(
				`/api/v1/user/is2fa-authenticate`,
				{
					token: code,
				},
				{ headers: { Authorization: state?.token } }
			);
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			dispatch(login(res?.data));
			dispatch(loadUser());
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
		setLoading(false);
		setSubmit(true);
	};

	useEffect(() => {
		if (auth?.isLoggedIn && submit) {
			navigate("/");
		}
		if (!state?.token) navigate(-1);
	}, [auth, submit, navigate, state]);

	return (
		<section className="min-h-screen flex flex-col items-center space-y-5 justify-center">
			<div className="fixed inset-0 bg-white"></div>
			<div
				className="fixed top-0 px-8 z-30 w-full mb-12"
				onClick={() => navigate("/")}>
				<img
					src={require("../assets/logo-black.png")}
					className="h-6 md:h-10"
					alt=""
				/>
			</div>
			<div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10 mt-8">
				<div className="font-medium self-center  text-gray-700 mb-2">
					<h1 className="text-center text-2xl md:text-2xl xl:text-3xl f-bold">
						Log In on <span className="text-bluerolodex">Stakeholder map</span>{" "}
					</h1>
				</div>
				<div className="self-center">
					<h2 className="text-center text-base md:text-md lg:text-md xl:text-xl f-light">
						Welcome back. It's good to have you back here
					</h2>
				</div>
				<div className="mt-5 md:mt-7 lg:mt-10">
					<Enable2FAComponent
						handleSubmit={onSubmitOTP}
						loading={loading === "2fa"}
						code={code}
						setCode={setCode}
						subtext={"Enter code generated from your Authenticator App"}
					/>
				</div>
			</div>
		</section>
	);
};

export default Login;

export const Enable2FAComponent = ({
	handleSubmit,
	code,
	setCode,
	loading,
	subtext,
}) => {
	return (
		<>
			<h3 className="text-capitalize">OTP</h3>
			<form onSubmit={handleSubmit}>
				<VerifyMail
					code={code}
					setCode={setCode}
					text="confirm Token"
					numInputs={6}
					subtext={subtext}
				/>
				<Button
					onClick={handleSubmit}
					loading={loading}
					type="button"
					children="Confirm Token"
					// eslint-disable-next-line react/style-prop-object
					css="w-full flex justify-center mt-5 bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light  shadow-lg cursor-pointer transition ease-in duration-500"
				/>
			</form>
		</>
	);
};

export const VerifyMail = ({ code, setCode, loading2, numInputs, subtext }) => {
	return (
		<>
			<small className="mb-4 d-block">
				{subtext || `Enter the OTP sent to your email`}
			</small>
			<div className="flex justify-center my-5 mx-auto">
				<OtpComponent
					stateData={code}
					textChange={data => {
						setCode(data);
					}}
					css="borderColor"
					loading={loading2}
					numInputs={numInputs}
				/>
			</div>
		</>
	);
};

export const OtpComponent = ({
	stateData,
	textChange,
	numInputs,
	separator,
	css,
	loading,
	isInputSecure,
	inputType,
}) => {
	return (
		<>
			<OtpInput
				value={stateData}
				onChange={otp => textChange(otp)}
				numInputs={numInputs ? numInputs : 6}
				separator={separator ? separator : <span>-</span>}
				inputStyle={`${css} otp-code__input`}
				isDisabled={loading}
				shouldAutoFocus={true}
				isInputNum={true}
				isInputSecure={isInputSecure}
				inputType={inputType || "tel"}
			/>
		</>
	);
};
