import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalState } from "../../data/Context";

const Success = () => {
	const navigate = useNavigate(),
		{ orgTheme } = useContext(GlobalState);

	return (
		<section className="min-h-screen flex flex-col items-center justify-between py-10">
			<div className="fixed inset-0  bg-white"></div>
			<div className="flex flex-col justify-center px-5 md:px-8 py-8 rounded-md bg-white w-10/12 lg:w-full max-w-lg relative z-10">
				<div>
					<img
						src={require("../../assets/success-gif.gif")}
						alt=""
						className="mx-auto"
					/>
				</div>
				<h1 className="text-2xl md:text-2xl xl:text-3xl font-bold text-center">
					Query Sent successfully
				</h1>
				<p className="p-0.5 px-2 text-center mb-8 max-w-sm mx-auto text-gray-600">
					Our team will reach out to you as soon as possible, please exercise
					patience.
				</p>
				<button
					type="button"
					style={{ background: orgTheme || "" }}
					className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
					onClick={() => {
						navigate("/");
					}}>
					Go back to Home
				</button>
			</div>
		</section>
	);
};

export default Success;
