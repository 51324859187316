import React, { useContext } from "react";
import { GlobalState } from "../../data/Context";

const ExpertisePill = ({ value, state, toggleState, css, bg }) => {
	let { orgTheme } = useContext(GlobalState);
	return (
		<div
			className={`px-4 border border-gray-300 rounded-full whitespace-nowrap text-sm flex items-center justify-center capitalize py-2 text-center cursor-pointer h-fit ${css} ${
				state ? "bg-main text-white" : `text-gray ${bg}`
			}`}
			style={{
				background: orgTheme && state ? orgTheme : "",
			}}
			onClick={toggleState}>
			{value}
		</div>
	);
};

export default ExpertisePill;
