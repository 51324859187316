import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import {
  // TransformWrapper,
  // TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";

import { FaRegCircleCheck } from "react-icons/fa6";
import RightDrawer from "../right-drawer/right-drawer";
import organization from "../../data1";
// import data2 from "../../data2";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// import { DrawerModal } from "../modal-container/modal-container";
const colors = [
  "#1B3C8E",
  "#D92E21",
  "#490D30",
  "#1B3C8E",
  "#CC579F",
  "#E64B13",
  "#428F8E",
  "#1B3C8E",
  "#D92E21",
  "#428F8E",
  "#CC579F",
  "#E64B13",
];

export const getUniquArray = (myObj, selection) => {
	/**
	 * this function take and object and returns an object with
	 * two types of array based onthe selection
	 * args:
	 * 	myObj: The data object to be manipulated
	 * 	selection: the selection used in manupulatio=ng the object
	 *
	 * return:
	 * 		data1: an array ob object
	 * 		dataStr: an array ofjust strings
	 */
	let collect = [];
	let data1 = [];
	let dataStr = [];
	console.log({ myObj });
	// if (selection === "organization") {
	//   myObj.forEach((fd, idx) =>
	//     data1.push({ name: fd.myObj[0], color: colors[idx] })
	//   );
	// } else {
	myObj?.forEach(item =>
		item?.[selection]?.forEach(
			data => !collect?.includes(data) && collect?.push(data)
		)
	);

	collect.forEach((fd, idx) => {
		data1.push({
			name: fd,
			color: colors?.[idx],
			isSelected: true,
			companyId:
				selection === "company"
					? myObj?.find(it => it?.[selection]?.[0] === fd)?.companyId
					: null,
		});
		dataStr.push(fd);
	});
	// }
	return { data1, dataStr };
};

const MapVisual = ({
	smallCircle,
	bigCircle,
	mainData1,
	setMainData1,
	mainData2,
	setMainData2,
}) => {
	const svgRef = useRef();
	const [selectedObject, setSelectedObject] = useState("");
	const [currentCircle, setCurrentCircle] = useState("");
	// const [drawer, setDrawer] = useState(false);
	const [showOption, setShowOption] = useState(false);
	const [drawer, setDrawer] = useState(false);

	const toggleShowDrawer = it => {
		setDrawer(drawer ? null : { ...it, _id: it?.companyId });
	};

	// let data2 = [
	// 	{
	// 		name: "EpiMap",
	// 		tools: [
	// 			"GIS & Mapping",
	// 			"Implementation and scaling",
	// 			"Data curation & integration",
	// 			"Data validation",
	// 		],
	// 	},
	// 	{
	// 		name: "Flowminder Foundation",
	// 		tools: ["Data visualization", "Data validation"],
	// 	},
	// 	{
	// 		name: "Fraym.IO",
	// 		tools: ["Data curation & integration", "Microplanning"],
	// 	},
	// 	{
	// 		name: "Humanitarian Open Street Map",
	// 		tools: ["Microplanning", "Epidemiology"],
	// 	},
	// 	{ name: "Kenya Space Agency", tools: ["Epidemiology", "Microplanning"] },
	// 	{
	// 		name: "Geo-Referenced Infrastr...",
	// 		tools: ["Population Estimates", "Immunization M&E"],
	// 	},
	// 	{
	// 		name: "Kaduna Bureau of Statistics",
	// 		tools: ["Immunization M&E", "Capacity Building"],
	// 	},
	// 	{
	// 		name: "Avyantra Health Tech...",
	// 		tools: ["Data validation", "Capacity Building"],
	// 	},
	// 	{
	// 		name: "Agence Nationale du Genie...",
	// 		tools: ["Capacity Building", "GIS & Mapping", "Data management"],
	// 	},
	// 	{
	// 		name: "African Regional Institute ...",
	// 		tools: ["Data management", "GIS & Mapping", "Implementation and scaling"],
	// 	},
	// 	{
	// 		name: "Research Institute",
	// 		tools: ["Implementation and scaling", "Data management"],
	// 	},
	// 	{
	// 		name: "Company DUI",
	// 		tools: [
	// 			"Implementation and scaling",
	// 			"Data curation & integration",
	// 			"Microplanning",
	// 		],
	// 	},
	// 	{ name: "Company dux..", tools: ["Microplanning", "GIS & Mapping"] },
	// 	{
	// 		name: "Digital Earth Africa",
	// 		tools: ["Data visualization", "GIS & Mapping", "Surveillance"],
	// 	},
	// 	{
	// 		name: "eHealth Africa",
	// 		tools: ["Immunization M&E", "Population Estimates"],
	// 	},
	// 	{ name: "Cizoti Nigeria", tools: ["Data management", "Surveillance"] },
	// 	{
	// 		name: "Data Scientist Network",
	// 		tools: ["Surveillance", "Population Estimates"],
	// 	},
	// 	{ name: "Akros", tools: ["Population Estimates", "Capacity Building"] },
	// 	{ name: "AFRIGIST", tools: ["Population Estimates", "GIS & Mapping"] },
	// ];

	const [position, setPosition] = useState({
		xAxis: 0,
		yAxis: 0,
	});
	const toggleShow = e => {
		let pos = getMouseLocation(e);
		setPosition({ xAxis: pos.x, yAxis: pos.y });
		setShowOption(!showOption);
	};
	// const toggleShowDrawer = () => {
	//   setDrawer(!drawer);
	// };

	const getMouseLocation = evt => {
		console.log(evt);

		let x = evt?.clientX - 100;
		let y =
			evt?.clientY + 150 > evt?.view?.innerHeight
				? evt?.clientY - 200
				: evt?.clientY;
		return { x, y };
	};

	let { map } = useSelector(s => s),
		newOrg =
			process.env.NODE_ENV === "production"
				? map?.data?.relations || []
				: map?.data?.relations || organization;

	console.log({ map });
	console.log({ newOrg });

	console.log({ mainData1, mainData2 });
	
	useEffect(() => {
		// Your D3 code here

		const handleObjectClick = (e, d) => {
			if (selectedObject) {
				setSelectedObject("");
			} else {
				console.log({ e, d }, "selected");
				toggleShow(e);
				setCurrentCircle(d);
				console.log(selectedObject);
			}
			// setSelectedObject(d);
		};

		const calculateRadius = (circleRadius, data) => {
			// let totalCir = 2 * Math.PI * circleRadius;
			return circleRadius * data?.length;
		};

		// Adjust the offset to change the starting point
		let offset = Math.PI / 24; // Example offset, change as needed

		const svg = d3.select(svgRef?.current);
		// Clear existing elements from the SVG
		svg.selectAll("*").remove();

		// Get the dimensions of the SVG container
		const svgWidth = svgRef?.current?.clientWidth;
		const svgHeight = svgRef?.current?.clientHeight;

		// Calculate the center of the SVG
		const center = { x: svgWidth / 2, y: svgHeight / 2 };
		let radius1 =
			mainData1?.filter(it => it?.isSelected)?.length < 5
				? 100
				: calculateRadius(
						16,
						mainData1?.filter(it => it?.isSelected)
				  );
		console.log({ radius1 });
		// let radius2 = mainData2?.length < 25 ? 300 : calculateRadius(25, mainData1);
		let radius2 = 	mainData1?.filter(it => it?.isSelected)?.length < 10 ? 500 : radius1 + 200;
		const padding = 30;

		const numCircles1 = mainData1?.filter(it => it?.isSelected)?.length;
		const angleIncrement1 = (2 * Math.PI) / numCircles1;

		// Calculate positions for circles in a circular path for data2
		const numCircles2 = mainData2.filter(it => it?.isSelected)?.length;
		const angleIncrement2 = (2 * Math.PI) / numCircles2;

		// create a group
		const group = svg.append("g").attr("transform", `translate(${0},${0})`);

		// Get the dimensions of the SVG container
		const groupWidth = group.clientWidth;
		const groupHeight = group.clientHeight;

		console.log({ group });
		console.log({ x: center.x });
		console.log({ y: center.y });
		console.log({ groupHeight });
		console.log({ groupWidth });

		// Draw lines connecting items in data2 with matching tools in data1
		const line = smallCircle
			? group
					.selectAll("line")
					.data(mainData2.filter(it => it?.isSelected))
					.enter()
					.selectAll("line")
					.data(d => d.tools.map(tool => ({ tool, company: d?.name })))
					.enter()
					.append("line")
					.attr(
						"x1",
						d =>
							center.x +
							radius2 *
								Math.cos(
									offset +
										mainData2
											?.filter(it => it?.isSelected)
											?.findIndex(item => item?.name === d?.company) *
											angleIncrement2
								)
					)
					.attr(
						"y1",
						d =>
							center.y +
							radius2 *
								Math.sin(
									offset +
										mainData2
											?.filter(it => it?.isSelected)
											?.findIndex(item => item?.name === d?.company) *
											angleIncrement2
								)
					)
					.attr(
						"x2",
						d =>
							center.x +
							radius1 *
								Math.cos(
									angleIncrement1 *
										mainData1
											?.filter(it => it?.isSelected)
											?.findIndex(item => item?.name === d?.tool)
								)
					)
					.attr(
						"y2",
						d =>
							center.y +
							radius1 *
								Math.sin(
									angleIncrement1 *
										mainData1
											?.filter(it => it?.isSelected)
											?.findIndex(item => item?.name === d?.tool)
								)
					)
					.attr(
						"stroke",
						d =>
							mainData1
								?.filter(it => it?.isSelected)
								?.find(item => item?.name === d?.tool)?.color || "#000000"
					)
					.attr("stroke-width", 2)
					.attr("opacity", d => {
						if (selectedObject) console.log({ selectedObject });
						return selectedObject
							? selectedObject.name === d?.company &&
							  selectedObject?.tools?.includes(d?.tool)
								? 1
								: selectedObject?.name === d?.tool
								? // &&
								  //   data2.forEach((data) => data.tools.includes(d.tool))
								  1
								: 0.01
							: 1;
					})
			: group.selectAll("line").remove();

		// Embed HTML content at circle positions
		const foreignObject1 =
			bigCircle || smallCircle
				? group
						.selectAll("foreignObject1")
						.data(mainData1?.filter(it => it?.isSelected))
						.enter()
						.append("foreignObject")
						.attr(
							"x",
							(d, i) => center.x + radius1 * Math.cos(i * angleIncrement1) - 40
						) // Adjust x and y based on circle radius
						.attr(
							"y",
							(d, i) => center.y + radius1 * Math.sin(i * angleIncrement1) - 40
						)
						.attr("width", 80)
						.attr("height", 80)
						.html(d => {
							return `<div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; background: white; color: black; border-radius: 50%; padding: 4px; font-size: 10px; text-align: center; border: 1.5px solid overflow-hidden ${
								smallCircle ? d?.color : "#A4DCF9"
							}; cursor: pointer; opacity: ${
								selectedObject
									? selectedObject?.tools?.includes(d?.name)
										? "100%"
										: selectedObject?.name === d?.name
										? "100%"
										: "15%"
									: "100%"
							};"><p>${d?.name.slice(0, 20)}</p></div>`;
						})
						.on("click", (e, d) => {
							selectedObject ? setSelectedObject("") : setSelectedObject(d);
						})
				: group.selectAll("foreignObject1").remove();

		const foreignObject2 = smallCircle
			? group
					.selectAll("foreignObject2")
					.data(mainData2.filter(it => it?.isSelected))
					.enter()
					.append("foreignObject")
					.attr(
						"x",
						(d, i) =>
							center.x + radius2 * Math.cos(offset + i * angleIncrement2) - 30
					) // Adjust x and y based on circle radius
					.attr(
						"y",
						(d, i) =>
							center.y + radius2 * Math.sin(offset + i * angleIncrement2) - 30
					)
					.attr("width", 60)
					.attr("height", 60)
					.html(
						d =>
							`<div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; background: white; color: black; border-radius: 50%; padding: 4px; font-size: 8px; text-align: center; cursor: pointer; position: relative; opacity: ${
								selectedObject
									? selectedObject?.name === d?.name
										? "100%"
										: d.tools?.includes(selectedObject?.name)
										? "100%"
										: "20%"
									: "100%"
							};">
              <p>${d?.name.slice(0, 20)}${d?.name.length > 20 ? "..." : ""}</p>
              </div>`
					)
					// <span>i</span>
					.on("click", (e, d) => {
						handleObjectClick(e, d);
						// console.log(e);
					})
			: group.selectAll("foreignObject2").remove();

		const initialTransform = d3.zoomIdentity
			.scale(0.3)
			.translate(center.x * 2.5, center.y * 2.5);

		// Apply initial transformation to the group
		handleZoom({ transform: initialTransform });

		// Handling zoom events
		function handleZoom(event) {
			group.attr("transform", event.transform);
		}

		// Creating a zoom behavior
		const zoomIdentity = d3
			.zoom(group)
			.scaleExtent([0.2, 3]) // Set the minimum and maximum scale
			.on("zoom", handleZoom);

		// Applying zoom behavior to the group
		svg.call(zoomIdentity);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [smallCircle, bigCircle, selectedObject, mainData1, mainData2]);

	console.log({ currentCircle });

	return (
		<>
		<SortMainData bigCircle={bigCircle} smallCircle={smallCircle} setMainData1={setMainData1} setMainData2={setMainData2} />
		<div className="flex items-center justify-center pt-24">
			<div className="fixed inset-0 flex items-center justify-center flex-col">
				<svg
					ref={svgRef}
					width={"100%"}
					height={"100%"}
					style={{ margin: "auto" }}
				/>
			</div>
			{showOption && (
				<div className="fixed inset-0">
					<div className="fixed inset-0 bg-black opacity-10"></div>
					<div
						className="bg-white p-4 rounded-md space-y-4 font-light whitespace-nowrap w-fit absolute cursor-pointer"
						style={{ left: position.xAxis, top: position.yAxis, zIndex: 100 }}>
						<div
							className="flex items-center gap-2 capitalize"
							onClick={() => {
								setSelectedObject(currentCircle);
								setShowOption(false);
							}}>
							<span>
								<FaRegCircleCheck />
							</span>
							<p>View links</p>
						</div>
						{currentCircle?.companyId && (
							<div
								className="flex items-center gap-2 capitalize"
								onClick={() => {
									console.log("hi");
									toggleShowDrawer(currentCircle);
									setShowOption(false);
								}}>
								<span>
									<FaRegCircleCheck />
								</span>
								<p>View profile</p>
							</div>
						)}
					</div>
				</div>
			)}
			<RightDrawer drawer={drawer} toggleShowDrawer={toggleShowDrawer} />
		</div>
		</>
	);
};

export default MapVisual;

export const Controls = () => {
  const { zoomIn, zoomOut, resetTransform } = useControls();
  return (
    <>
      <button onClick={() => zoomIn()}>Zoom In</button>
      <button onClick={() => zoomOut()}>Zoom Out</button>
      <button onClick={() => resetTransform()}>Reset</button>
    </>
  );
};

export const MapVisual2 = () => {
  const svgRef2 = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef2.current);
    // Circle data
    const numberOfCircles = 10;
    // Get the dimensions of the SVG container
    // const svgWidth = svgRef.current.clientWidth;
    // const svgHeight = svgRef.current.clientHeight;
    const svgWidth = 500; // Replace with the actual width of your SVG container
    const svgHeight = 500; // Replace with the actual height of your SVG container

    // Calculate the maximum radius that fits within the SVG
    const maxRadius = Math.min(svgWidth, svgHeight) / (2 * numberOfCircles);

    // Adjust the padding between circles
    const padding = 5;

    // Calculate the radius dynamically based on the available space
    const radius = Math.min((maxRadius - padding) / numberOfCircles, maxRadius);

    const circleData = Array.from({ length: numberOfCircles }).map((_, i) => ({
      cx:
        svgWidth / 2 +
        (radius + padding) * Math.cos((2 * Math.PI * i) / numberOfCircles),
      cy:
        svgHeight / 2 +
        (radius + padding) * Math.sin((2 * Math.PI * i) / numberOfCircles),
      r: radius, // Use the calculated radius
      fill: "blue", // fill color
    }));

    // Append circles to the SVG container
    svg
      .selectAll("circle")
      .data(circleData)
      .enter()
      .append("circle")
      .attr("cx", (d) => d.cx)
      .attr("cy", (d) => d.cy)
      .attr("r", (d) => d.r)
      .attr("fill", (d) => d.fill);
  }, []);
  return (
    <svg
      ref={svgRef2}
      width={500}
      height={500}
      style={{ margin: "auto" }}
      scale={0.2}
    />
  );
};


export const SortMainData = ({bigCircle, smallCircle, setMainData1, setMainData2})=> {
	const location = useLocation();

	let { map } = useSelector(s => s),
		newOrg =
			process.env.NODE_ENV === "production"
				? map?.data?.relations || []
				: map?.data?.relations || organization;

	const currentItems = newOrg;

	useEffect(() => {
		setMainData1(
			getUniquArray(currentItems, bigCircle).data1?.sort((a, b) => {
				let nameA = a?.name,
					nameB = b?.name;
				var textA = nameA?.toUpperCase();
				var textB = nameB?.toUpperCase();
				return textA < textB ? -1 : textA > textB ? 1 : 0;
			})
		);
		if (smallCircle) {
			let smallCircleArray = [];
			let allSmallCircleData = getUniquArray(
				currentItems,
				smallCircle
			)?.dataStr;
			allSmallCircleData?.forEach(dat => {
				let arr = [];
				currentItems?.forEach(
					it =>
						it?.[smallCircle]?.includes(dat) &&
						it?.[bigCircle]?.forEach(
							el => !smallCircleArray?.includes(el) && arr?.push(el)
						)
				);
				smallCircleArray.push({
					name: dat,
					tools: [...arr],
					isSelected: true,
					companyId:
						smallCircle === "company"
							? currentItems?.find(it => it?.[smallCircle]?.[0] === dat)
									?.companyId
							: null,
				});
			});
			console.log({ smallCircleArray });
			setMainData2(
				smallCircleArray?.sort((a, b) => {
					let nameA = a?.name,
						nameB = b?.name;
					var textA = nameA?.toUpperCase();
					var textB = nameB?.toUpperCase();
					return textA < textB ? -1 : textA > textB ? 1 : 0;
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [map?.data, bigCircle, smallCircle, location?.search]);

	return <></>
}