import { createSlice } from "@reduxjs/toolkit";
import { DeleteData, EditData } from "./UserReducer";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	isAdded: false,
	isDeleted: null,
	search: "",
	mainSearch: null,
	isFound: null,
	isUpdated: null,
	companies: null,
	mapFilters:null
};

export const companySlice = createSlice({
	name: "company",
	initialState,
	reducers: {
		getSearchCompany: (state, { payload }) => {
			state.mainSearch = payload?.data || payload || state?.mainSearch;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetCompanySearch: state => {
			state.search = "";
			state.mainSearch = null;
			state.isFound = false;
		},
		getCompany: (state, { payload }) => {
			state.data = payload?.company || payload;
		},
		getCompanyCompany: (state, { payload }) => {
			state.companies = payload?.companies || payload;
		},
		getCompanyMap: (state, { payload }) => {
			state.mapFilters = payload?.filters || payload;
		},
		addCompany: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isAdded = true;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
				totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
			};
		},
		deleteCompany: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isDeleted = true;
			state.data = {
				...state?.data,
				docs: DeleteData(state?.data?.docs, data),
				totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
			};
		},
		updateCompany: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isUpdated = true;
			state.data = { ...state?.data, docs: EditData(state?.data?.docs, data) };
		},
		companyFail: state => {
			state.isDeleted = false;
			state.isAdded = false;
			state.isFound = false;
		},
		logoutCompany: state => {
			state.data = null;
			state.companies = null;
			state.isAdded = false;
			state.isDeleted = null;
			state.search = "";
			state.mainSearch = null;
			state.isFound = null;
			state.isUpdated = null;
			state.all = null;
		},
	},
});
export const {
	companyFail,
	getOrg,
	getCompany,
	deleteCompany,
	addCompany,
	getSearchCompany,
	getSearch,
	resetCompanySearch,
	updateCompany,
	logoutCompany,
	getCompanyCompany,
	getCompanyMap
} = companySlice.actions;

export const manageCompany = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			if (data?.map) {
				res = await axios.get(
					`/map/filters/${data?.id || ""}${
						data?.limit ? `&limit=${data?.limit}` : ""
					}`
				);
				dispatch(getCompanyMap(res?.data));
			} else {
				res = await axios.get(
					`/companies/${data?.id || ""}${
						data?.limit ? `&limit=${data?.limit}` : ""
					}`
				);
				dispatch(getCompany(res?.data));
			}
		}
		if (type === "post") {
			res = await axios.post(`/companies`, { ...data });
			dispatch(addCompany(res?.data));
		}
		if (type === "put") {
			res = await axios.put(`/companies/${data?._id}`, { ...data });
			dispatch(updateCompany(res?.data));
		}
		if (type === "delete") {
			res = await axios.delete(`/companies/${data?._id}`);
			dispatch(deleteCompany(data));
		}
		if (type !== "get") toast.success(res?.data?.message);
	} catch (err) {
		if (err?.response?.status === 429 || err?.response?.status === 405)
			toast.error(err?.response?.data ? err?.response?.data : err?.message);
		console.log({ err });
		dispatch(companyFail());
		if (type && type !== "get") {
			let error =
				Array.isArray(err.response?.data?.error) ||
				Array.isArray(err?.response?.data?.message);
			if (error) {
				dispatch(
					returnErrors({
						error: Array.isArray(err.response?.data?.message)
							? err.response?.data?.message
							: err.response?.data?.error,
						status: err?.response?.status,
					})
				);
			} else {
				toast.error(err?.response?.data?.message || err?.response?.data?.error);
			}
		}
	}
};
