import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import logoFav from "../../assets/Geo-Logo.png";

const Brand = ({ height, white }) => {
	let { workspace } = useSelector(s => s);

	useEffect(() => {
		if (workspace?.data?.file?.url) {
			let link = document.querySelector("link[rel~='icon']");
			let link2 = document.querySelector("link[rel~='apple-touch-icon']");
			// let title = document.querySelector("title");
			if (!link) {
				link = document.createElement("link");
				link.rel = "icon";
				document.getElementsByTagName("head")[0].appendChild(link);
			}
			if (!link2) {
				link2 = document.createElement("link");
				link2.rel = "apple-touch-icon";
				document.getElementsByTagName("head")[0].appendChild(link2);
			}
			link.href = workspace?.data?.workspaceSubdomain?.includes("geo-rolodex")
				? logoFav
				: workspace?.data?.file?.url;
			link2.href = workspace?.data?.workspaceSubdomain?.includes("geo-rolodex")
				? logoFav
				: workspace?.data?.file?.url;
		}
	}, [workspace?.data]);

	return (
		<Link to={"/"}>
			{white ? (
				<div className="border-black border-2 p-2 mt-4 rounded-lg">
					<img
						src={workspace?.data?.file?.url || require("../../assets/logo.png")}
						alt="logo"
					/>
				</div>
			) : (
				<div className="border-black border-2 p-2 mt-4 rounded-lg">
					<img
						src={workspace?.data?.file?.url || require("../../assets/logo.png")}
						alt="logo"
					/>
				</div>
			)}
		</Link>
	);
};

export default Brand;
