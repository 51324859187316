import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const faqs = [
  {
    question: "Is Geo-Rolodex open access?",
    answer:
      "Access to Geo-Rolodex is invitation-based, ensuring secure and managed entry for participating organizations.",
  },
  {
    question: "Who can use Geo-Rolodex?",
    answer:
      " Geo-Rolodex is accessible to a wide range of organizations, including development partners, private companies, government agencies, and other geospatial-based entities operating in low- and middle-income countries (LMICs).",
  },
  {
    question: "Is Geo-Rolodex a paid service?",
    answer:
      "Geo-Rolodex is currently offered as a free service for invited organizations.",
  },
  {
    question:
      "Who is responsible for identifying and updating stakeholders on the platform?",
    answer:
      "Administrators oversee the addition and identification of new stakeholders, with users also able to recommend potential contacts within their networks.",
  },
  {
    question: "How secure is Geo-Rolodex?",
    answer:
      "Geo-Rolodex prioritizes data security with robust measures in place to protect user information and ensure compliance with privacy regulations.",
  },
];

const FAQItem = ({ faq, isOpen, onToggle }) => (
  <div
    data-aos="fade-right"
    className="border px-4 rounded-lg mb-4 bg-white lg:w-[1050px] md:w-full w-full relative"
  >
    <div className="flex justify-between items-center py-4 cursor-pointer">
      <h4 className="font-medium">{faq.question}</h4>
      <span onClick={onToggle} className=" absolute right-2">
        {isOpen ? "-" : "+"}
      </span>
    </div>
    {isOpen && <p className="pb-4 text-gray-600">{faq.answer}</p>}
  </div>
);

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  }, []);

  return (
    <div className="">
      <div data-aos="fade-right" className=" justify-center items-center grid">
        {" "}
        <h2 className=" lg:text-4xl md:text-3xl text-xl font-bold mb-4 font-Inter">
          Frequently Asked Questions
        </h2>
      </div>
      <div
        data-aos="fade-left"
        className=" justify-center items-center grid font-Inter"
      >
        {" "}
        <p className="text-gray-600 lg:text-xl md:text-base text-sm mb-8">
          Everything you need to know about Geo-Rolodex.
        </p>
      </div>

      {faqs.map((faq, index) => (
        <FAQItem
          key={index}
          faq={faq}
          isOpen={openIndex === index}
          onToggle={() => handleToggle(index)}
        />
      ))}
    </div>
  );
};

export default FAQ;
