/* eslint-disable react/prop-types */
import { createContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
// import { TOKEN } from "./Reducers/UserReducer";

export const GlobalState = createContext();

const DataProvider = ({ children }) => {
	const [nav, setNav] = useState(false);
	let handleCapitalize = word => {
			let splitter = word.trim().split(" ");
			let firstCap = splitter[0].split("");
			let remaining = splitter.slice(1, splitter.length).join(" ");

			let firstCapOne = firstCap[0].toUpperCase();
			let firstCapTwo = firstCap.slice(1, firstCap.length).join("");

			let joinFirst = `${firstCapOne}${firstCapTwo}`;

			return `${joinFirst} ${remaining}`;
		},
		[subdomain, setSubdomain] = useState("");

	let numberWithCommas = x => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const toggleNav = () => {
		setNav(!nav);
	};

	let { auth, workspace } = useSelector(state => state),
		[orgTheme, setOrgTheme] = useState(null);

	useEffect(() => {
		if (workspace?.data) {
			setOrgTheme(workspace?.data?.color);
			// if (workspace?.data?.workspaceSubdomain) {
			// 	let hostname = window.location.hostname,
			// 		newUrl = "";
			// 	if (hostname?.startsWith("www"))
			// 		hostname = hostname?.replace("www.", "");

			// 	if (!hostname?.includes(".vercel.app")) {
			// 		// Split the hostname by '.' to get subdomain
			// 		const parts = hostname.split(".");

			// 		// Check if there's a subdomain (more than 2 parts in the hostname)
			// 		console.log({ parts });
			// 		if (parts.length === (hostname?.includes("localhost") ? 1 : 2)) {
			// 			// Subdomain is the first part of the hostname

			// 			newUrl = `${window?.location?.protocol}//${
			// 				workspace?.data?.workspaceSubdomain
			// 					? `${workspace?.data?.workspaceSubdomain}.`
			// 					: ""
			// 			}${
			// 				window.location?.hostname?.startsWith("www")
			// 					? window.location?.hostname?.replace("www.", "")
			// 					: window.location?.hostname
			// 			}${window.location.port ? `:${window.location.port}` : ""}`;
			// 			let thisToken = auth?.token;
			// 			window.location.replace(newUrl);
			// 			localStorage.setItem(TOKEN, thisToken);
			// 		}
			// 	}
			// }
		}
	}, [workspace?.data, auth]);

	const [unboardingScreen, setUnboardingScreen] = useState(false);
	const [unboarding, setUnboarding] = useState(false);

	let sidebarList = [
		{
			name: "Map",
			url: "/map",
			icon: (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
					fill="currentColor">
					<path
						opacity="0.3"
						d="M13 9V6C9.04564 7.48815 6.78867 8.9935 5 13H8C8.74285 12.2 9.98856 10 13 9Z"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M18 10.5C19.6569 10.5 21 9.15685 21 7.5C21 5.84315 19.6569 4.5 18 4.5C16.3431 4.5 15 5.84315 15 7.5C15 9.15685 16.3431 10.5 18 10.5Z"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M6 21C7.65685 21 9 19.6569 9 18C9 16.3431 7.65685 15 6 15C4.34315 15 3 16.3431 3 18C3 19.6569 4.34315 21 6 21Z"
					/>
				</svg>
			),
			permission: ["admin", "user", "organisation", "superadmin"],
			show: true,
		},
		{
			name: "Organisations",
			url: "/organisations",
			icon: (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M7 2C5.89543 2 5 2.89543 5 4V21H10.5V18C10.5 17.4477 10.9477 17 11.5 17H12.5C13.0523 17 13.5 17.4477 13.5 18V21H19V4C19 2.89543 18.1046 2 17 2H7ZM9 4C8.44772 4 8 4.44772 8 5V6C8 6.55228 8.44772 7 9 7H10C10.5523 7 11 6.55228 11 6V5C11 4.44772 10.5523 4 10 4H9ZM13 5C13 4.44772 13.4477 4 14 4H15C15.5523 4 16 4.44772 16 5V6C16 6.55228 15.5523 7 15 7H14C13.4477 7 13 6.55228 13 6V5ZM9 8C8.44772 8 8 8.44772 8 9V10C8 10.5523 8.44772 11 9 11H10C10.5523 11 11 10.5523 11 10V9C11 8.44772 10.5523 8 10 8H9ZM8 13C8 12.4477 8.44772 12 9 12H10C10.5523 12 11 12.4477 11 13V14C11 14.5523 10.5523 15 10 15H9C8.44772 15 8 14.5523 8 14V13ZM14 8C13.4477 8 13 8.44772 13 9V10C13 10.5523 13.4477 11 14 11H15C15.5523 11 16 10.5523 16 10V9C16 8.44772 15.5523 8 15 8H14ZM13 13C13 12.4477 13.4477 12 14 12H15C15.5523 12 16 12.4477 16 13V14C16 14.5523 15.5523 15 15 15H14C13.4477 15 13 14.5523 13 14V13Z"
					/>
					<path
						opacity="0.3"
						d="M3 22.9881C3 21.8901 3.89009 21 4.98807 21H19.0119C20.1099 21 21 21.8901 21 22.9881C21 22.9947 20.9947 23 20.9881 23H3.01193C3.00534 23 3 22.9947 3 22.9881Z"
					/>
				</svg>
			),
			permission: ["admin", "user", "organisation", "superadmin"],
			show: true,
			// show: ["organization", "company"]?.includes(auth?.user?.privilege),
			// // ||["ADMIN"]?.includes(auth?.user?.role?.title?.toUpperCase())
		},
		{
			name: "Invitations",
			url: "/invitations",
			icon: (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M8 5.77206C8 5.40923 8.37431 5.1672 8.70518 5.3161L22.5456 11.5443C22.9386 11.7212 22.9386 12.2793 22.5456 12.4562L8.70518 18.6843C8.37431 18.8332 8 18.5912 8 18.2284V13.1671L20.447 12.0002L8 10.8333V5.77206Z"
					/>
					<path
						opacity="0.3"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8H5C5.55228 8 6 7.55228 6 7C6 6.44772 5.55228 6 5 6H4ZM0 12C0 11.4477 0.447715 11 1 11H5C5.55228 11 6 11.4477 6 12C6 12.5523 5.55228 13 5 13H1C0.447715 13 0 12.5523 0 12ZM3 17C3 16.4477 3.44772 16 4 16H5C5.55228 16 6 16.4477 6 17C6 17.5523 5.55228 18 5 18H4C3.44772 18 3 17.5523 3 17Z"
					/>
				</svg>
			),
			permission: ["admin", "user", "organisation", "superadmin"],
			show: ["organization"]?.includes(auth?.user?.privilege),
		},
		{
			name: "Data",
			url: "/data",
			icon: (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg">
					<path
						opacity="0.3"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M2 19.5C2 20.3284 2.67157 21 3.5 21H20.5C21.3284 21 22 20.3284 22 19.5V8.5C22 7.67157 21.3284 7 20.5 7H10L7.43934 4.43934C7.15804 4.15804 6.7765 4 6.37868 4H3.5C2.67157 4 2 4.67157 2 5.5V19.5Z"
					/>
					<rect
						opacity="0.3"
						x="6.76172"
						y="14.127"
						width="3.14286"
						height="3.14286"
						rx="0.75"
						transform="rotate(-45 6.76172 14.127)"
					/>
					<rect
						opacity="0.3"
						x="13.0469"
						y="14.127"
						width="3.14286"
						height="3.14286"
						rx="0.75"
						transform="rotate(-45 13.0469 14.127)"
					/>
					<rect
						x="9.90625"
						y="17.27"
						width="3.14286"
						height="3.14286"
						rx="0.75"
						transform="rotate(-45 9.90625 17.27)"
					/>
					<rect
						x="9.90625"
						y="10.9844"
						width="3.14286"
						height="3.14286"
						rx="0.75"
						transform="rotate(-45 9.90625 10.9844)"
					/>
				</svg>
			),
			permission: ["admin", "user", "organisation", "superadmin"],
			show: ["organization"]?.includes(auth?.user?.privilege),
		},
		{
			name: "Use Cases",
			url: "/use-cases",
			icon: (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg">
					<path
						opacity="0.3"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M2 19.5C2 20.3284 2.67157 21 3.5 21H20.5C21.3284 21 22 20.3284 22 19.5V8.5C22 7.67157 21.3284 7 20.5 7H10L7.43934 4.43934C7.15804 4.15804 6.7765 4 6.37868 4H3.5C2.67157 4 2 4.67157 2 5.5V19.5Z"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M10.875 16.75C10.6354 16.75 10.3958 16.6542 10.2042 16.4625L8.2875 14.5458C7.90417 14.1625 7.90417 13.5875 8.2875 13.2042C8.67083 12.8208 9.29375 12.8208 9.62917 13.2042L10.875 14.45L14.0375 11.2875C14.4208 10.9042 14.9958 10.9042 15.3792 11.2875C15.7625 11.6708 15.7625 12.2458 15.3792 12.6292L11.5458 16.4625C11.3542 16.6542 11.1146 16.75 10.875 16.75Z"
					/>
				</svg>
			),
			permission: ["admin", "user", "organisation", "superadmin"],
			show: true,
		},
		{
			name: "Setup",
			url: "/setup",
			icon: (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg">
					<path
						opacity="0.3"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M2 19.5C2 20.3284 2.67157 21 3.5 21H20.5C21.3284 21 22 20.3284 22 19.5V8.5C22 7.67157 21.3284 7 20.5 7H10L7.43934 4.43934C7.15804 4.15804 6.7765 4 6.37868 4H3.5C2.67157 4 2 4.67157 2 5.5V19.5Z"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13Z"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M7.00036 18.4995C7.2157 15.5166 9.36773 14 11.9907 14C14.6507 14 16.836 15.4332 16.9988 18.5C17.0053 18.6222 16.9988 19 16.5815 19C14.5228 19 11.4637 19 7.40417 19C7.26484 19 6.98863 18.662 7.00036 18.4995Z"
					/>
				</svg>
			),
			permission: ["admin", "user", "organisation", "superadmin"],
			show: true,
		},
		{
			name: "Get Started",
			url: "/get-started",
			icon: (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M15 19.5229C15 20.265 15.9624 20.5564 16.374 19.9389L22.2227 11.166C22.5549 10.6676 22.1976 10 21.5986 10H17V4.47708C17 3.73503 16.0376 3.44363 15.626 4.06106L9.77735 12.834C9.44507 13.3324 9.80237 14 10.4014 14H15V19.5229Z"
						fill="currentColor"
					/>
					<path
						opacity="0.3"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M3 6.5C3 5.67157 3.67157 5 4.5 5H9.5C10.3284 5 11 5.67157 11 6.5C11 7.32843 10.3284 8 9.5 8H4.5C3.67157 8 3 7.32843 3 6.5ZM3 18.5C3 17.6716 3.67157 17 4.5 17H9.5C10.3284 17 11 17.6716 11 18.5C11 19.3284 10.3284 20 9.5 20H4.5C3.67157 20 3 19.3284 3 18.5ZM2.5 11C1.67157 11 1 11.6716 1 12.5C1 13.3284 1.67157 14 2.5 14H6.5C7.32843 14 8 13.3284 8 12.5C8 11.6716 7.32843 11 6.5 11H2.5Z"
						fill="currentColor"
					/>
				</svg>
			),
			permission: ["admin", "user", "organisation", "superadmin"],
			show: ["organization"]?.includes(auth?.user?.privilege),
		},
	];

	const state = {
		handleCapitalize,

		numberWithCommas,

		sidebarList,
		auth,
		nav,
		toggleNav,
		orgTheme,
		subdomain,
		setSubdomain,
		unboardingScreen,
		setUnboardingScreen,
		unboarding,
		setUnboarding,
	};

	return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
