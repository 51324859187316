import Email from "../../assets/email.svg";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import Button from "../../components/button/button";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const ResendVerifyEmail = () => {
  let [loading, setLoading] = useState(null),
    dispatch = useDispatch(),
    navigate = useNavigate()

  const methods = useForm({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    console.log({ data });
    try {
      if (!data?.email) toast.info("Email is required");
      let res = await axios.post(
        `/api/v1/user/resend-verify?email=${data?.email}`,
        {
          nextScreen: `${window?.location?.origin}/verified-email`,
        }
      );
      console.log({ resp: res?.data });
      toast.success(res?.data?.message);
    } catch (err) {
      if (err) console.log({ error: err.response?.data, err });
      if (err?.response?.status === 429) toast.error(err?.response?.data);
      let error = err.response?.data?.error;
      if (error && error?.length > 1) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
      } else {
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data?.error?.[0]?.message ||
            err?.message
        );
      }
    }
    setLoading(false);
  };

  return (
		<section className="min-h-screen flex flex-col items-center space-y-5 justify-center">
			<div className="fixed inset-0 bg-white"></div>
			<div
				className="fixed top-0 px-8 z-30 w-full mb-12"
				onClick={() => {
					if (
						window.location?.origin?.includes("geo-rolodex.com") ||
						window.location?.origin?.includes("map.policyvault.africa")
					) {
						navigate("/");
					} else {
						navigate("/login");
					}
				}}>
				<img
					src={require("../../assets/logo-black.png")}
					className="h-6 md:h-10"
					alt=""
				/>
			</div>
			<div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10">
				<div className="font-medium self-center  text-gray-800 mb-2">
					<div className="flex flex-col items-center mb-5">
						<img src={Email} alt="Email" />
					</div>
					<h1 className="text-center text-2xl md:text-2xl xl:text-3xl font-bold">
						Verify your <span className="text-bluerolodex ">email address</span>
					</h1>
				</div>
				<div className="self-center">
					<h2 className="text-center text-base md:text-md max-w-sm mx-auto lg:text-md xl:text-lg f-light">
						We will send a verification link to{" "}
						<span className="font-bold"> {methods?.watch("email")} </span>,
						please visit your mail to continue
					</h2>
				</div>
				<div className="mt-5 md:mt-7 lg:mt-10">
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							{/* Email */}
							<Controller
								name="email"
								rules={{ required: "This field is required" }}
								render={({ field: { onChange, name } }) => (
									<fieldset className="flex flex-col mb-6">
										<label
											className="mb-1 text-sm md:text-lg f-bold tracking-wide"
											htmlFor="email">
											Email address
										</label>

										<input
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
											name={name}
											id={name}
											type={name}
											onChange={onChange}
											placeholder="Enter email"
										/>
										{methods.formState.errors.email && (
											<span className="text-xs text-red-600">
												This field is required
											</span>
										)}
									</fieldset>
								)}
							/>
							<Button
								type="submit"
								loading={loading}
								children="Send verification link again"
								// eslint-disable-next-line react/style-prop-object
								css="w-full flex justify-center bg-lightblue hover:brightness-100  text-bluerolodex p-4  rounded-lg tracking-wide font-semibold cursor-pointer transition ease-in duration-500"
								loadCss="text-bluerolodex"
							/>
						</form>
					</FormProvider>
				</div>
			</div>
		</section>
	);
};

export default ResendVerifyEmail;
