import React, { useEffect, useRef, useState } from "react";
import { GrAdd } from "react-icons/gr";

const ColorSelector = ({ state, setState }) => {
	const colorInputRef = useRef(null);
	let [col, setCol] = useState("");
	// console.log({ col });

	useEffect(() => {
		if (col) setState(col);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [col]);
	useEffect(() => {
		if (state) setCol(state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	return (
		<div>
			<div className="mt-1 mb-4 flex items-center gap-4">
				<div
					className="w-16 h-16 rounded-full bg-red-400 cursor-pointer"
					onClick={() => setState("#f87171")}></div>
				<div
					className="w-16 h-16 rounded-full bg-main cursor-pointer"
					onClick={() => setState("#1B3C8E")}></div>
				<div
					className="w-16 h-16 rounded-full bg-green-500 cursor-pointer"
					onClick={() => setState("#22c55e")}></div>
				<div
					className="w-16 h-16 rounded-full bg-pink-600 cursor-pointer"
					onClick={() => setState("#db2777")}></div>
				<div
					className="w-16 h-16 rounded-full bg-orange-500 cursor-pointer"
					onClick={() => setState("#f97316")}></div>
				{/* {col && (
					<div
						className={`w-16 h-16 rounded-full bg-[${col}]`}
						onClick={() => setState("#f97316")}></div>
				)} */}
				<div className="relative flex items-center justify-center">
					<div
						className={`w-16 h-16 rounded-full ${
							col ? `bg-[${col}]` : "bg-gray-400"
						} flex items-center justify-center`}
						style={{
							background: col || "",
						}}
						onClick={() => colorInputRef.current.click()}>
						<GrAdd color={col ? "#fff" : "#000"} />
					</div>
					<input
						ref={colorInputRef}
						type="color"
						style={{ width: "0" }}
						className="absolute -z-10"
						value={col}
						onChange={e => setCol(e.target.value)}
					/>
				</div>
			</div>
		</div>
	);
};

export default ColorSelector;
