import { IconEye, IconEyeClosed } from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { loadUser, login, login2FA } from "../../data/Reducers/UserReducer";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
// import { login } from "../api/config/axios-api-instance";
import { toast } from "react-toastify";
import axios from "axios";
import Button from "../../components/button/button";
import MicrosoftLogin from "react-microsoft-login";
import { ClipLoader } from "react-spinners";
import { GlobalState } from "../../data/Context";

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showPassword, setShowPassword] = useState(false);
	let location = useLocation(),
		{ subdomain } = useContext(GlobalState);
	// const [invalidCredentials, setInvalidCredentials] = useState(false);
	const methods = useForm({
		defaultValues: {
			email: location?.state?.email || "",
			password: "",
		},
	});

	let [loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		{ auth } = useSelector(s => s);

	const onSubmit = async data => {
		console.log({ data });
		// dispatch(login({ token: "ROLODEX" }));
		// dispatch(loadUser());
		// navigate("/");
		if (!data?.email || !data?.password) return;
		setLoading("login");
		try {
			let res = await axios.post(`/api/v1/user/login`, { ...data });
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			if (res?.data?.is2FAEnabled !== "enable") {
				dispatch(login(res?.data));
				dispatch(loadUser());
			} else {
				navigate("/authenticator", { state: res?.data });
				dispatch(login2FA(res?.data));
			}
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
		setLoading(false);
		setSubmit(true);
	};

	const authHandler = async (err, data) => {
		console.log({ err }, { data });
		if (err) {
			return toast.info(
				err?.errorMessage?.split(". ")?.slice(0, 2)?.join(". ")
			);
		}
		if (data && data?.idToken) {
			setLoading("microsoft");
			try {
				let res = await axios.post(`/api/v1/user/login-microsoft`, {
					...data,
				});
				console.log({ resp: res?.data });
				toast.success(res?.data?.message);
				if (res?.data?.is2FAEnabled !== "enable") {
					dispatch(login(res?.data));
					dispatch(loadUser());
				} else {
					navigate("/authenticator", { state: res?.data });
					dispatch(login2FA(res?.data));
				}
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				let error = err.response?.data?.error;
				if (error && error?.length > 1) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				} else {
					toast.error(
						err?.response?.data?.message ||
							err?.response?.data?.error?.[0]?.message ||
							err?.message
					);
				}
			}
			setLoading(false);
			setSubmit(true);
		}
	};

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const redirect = url => {
		navigate(url);
	};

	useEffect(() => {
		if (auth?.isLoggedIn && submit) {
			navigate("/");
		}
	}, [auth, submit, navigate]);

	return (
		<section className="min-h-screen flex flex-col items-center space-y-5 justify-center">
			<div className="fixed inset-0 bg-white"></div>
			<div
				className="fixed top-0 px-8 z-30 w-full mb-12"
				onClick={() => {
					if (window.location?.origin?.includes("geo-rolodex.com")) {
						window.location.replace("https://www.geo-rolodex.com/landing");
					} else if (
						window.location?.origin?.includes("map.policyvault.africa")
					) {
						window.location.replace("https://www.geo-rolodex.com/landing");
					} else {
						navigate("/");
					}
				}}>
				<img
					src={require("../../assets/logo-black.png")}
					className="h-6 md:h-10"
					alt=""
				/>
			</div>
			<div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10 mt-8">
				<div className="font-medium self-center  text-gray-700 mb-2">
					<h1 className="text-center text-2xl md:text-2xl xl:text-3xl f-bold">
						Log in to{" "}
						<span className="text-bluerolodex capitalize">
							{subdomain?.workspaceName || "Stakeholder Map"}
						</span>{" "}
					</h1>
				</div>
				<div className="self-center">
					<h2 className="text-center text-base md:text-md lg:text-md xl:text-xl f-light">
						Welcome back! We are pleased to see you again.
					</h2>
				</div>
				<div className="mt-5 md:mt-7 lg:mt-10">
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							{/* Email */}
							<Controller
								name="email"
								control={methods.control}
								rules={{
									required: "This field is required",
									pattern: {
										value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/g,
										message: "You must enter a valid email address",
									},
								}}
								render={({ field: { onChange, name } }) => (
									<fieldset className="flex flex-col mb-6">
										<label
											className="mb-1 text-sm md:text-lg font-semibold tracking-wide"
											htmlFor="email">
											Email Address
										</label>
										<input
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
											name={name}
											id={name}
											onChange={onChange}
											type="text"
											placeholder="Enter email"
										/>
										{methods.formState.errors.email && (
											<span className="text-xs text-red-600">
												{methods.formState.errors.email.message}
											</span>
										)}
									</fieldset>
								)}
							/>

							{/* Password*/}
							<Controller
								name="password"
								control={methods.control}
								rules={{
									required: "This field is required",
									pattern: {
										value:
											/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
										message:
											"Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
									},
								}}
								render={({ field: { onChange, name } }) => (
									<fieldset className="flex flex-col mb-6">
										<div className="flex items-center justify-between">
											<label
												className="mb-1 text-sm md:text-lg font-semibold tracking-wide"
												htmlFor="password">
												Password
											</label>
											<span
												className="custom-link"
												onClick={() => redirect("reset-password")}>
												{" "}
												Forgot Password{" "}
											</span>
										</div>
										<div className="relative">
											<input
												className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
												name={name}
												id={name}
												onChange={onChange}
												type={showPassword ? "text" : "password"}
												placeholder="Password"
											/>
											<div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
												{showPassword ? (
													<IconEye onClick={handleShowPassword} />
												) : (
													<IconEyeClosed onClick={handleShowPassword} />
												)}
											</div>
										</div>
										{methods.formState.errors.password && (
											<span className="text-xs text-red-600">
												{methods.formState.errors.password.message}
											</span>
										)}
									</fieldset>
								)}
							/>
							{/* {invalidCredentials && (
								<span className="text sm md:text-base font-bold text-red-600">
									Invalid credentials
								</span>
							)} */}
							{/* <button
								className="w-full flex justify-center mt-5 bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light  shadow-lg cursor-pointer transition ease-in duration-500">
								Log In
							</button> */}
							<Button
								type="submit"
								loading={loading === "login"}
								children="Log In"
								// eslint-disable-next-line react/style-prop-object
								css="w-full flex justify-center mt-5 bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light  shadow-lg cursor-pointer transition ease-in duration-500"
							/>
						</form>
					</FormProvider>
				</div>
				<div className="mt-5 md:mt-7 lg:mt-10 flex justify-center items-center">
					{loading && loading === "microsoft" ? (
						<div>
							<ClipLoader className="text-bluerolodex" size={30} />
						</div>
					) : (
						<MicrosoftLogin
							clientId={process.env.REACT_APP_MICROSOFT_CLIENTID}
							authCallback={authHandler}
							redirectUri={window.location.href}
							// tenantUrl={`https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_TENANT_ID}`}
						/>
					)}
				</div>
				<p className="text-center f-light self-center mt-6 text-sm md:text-lg text-gray-700 hidden">
					I do not have a workspace yet?{" "}
					<span
						className="custom-link f-medium"
						onClick={() => redirect("/signup/setup-organization")}>
						Sign up here
					</span>
				</p>

				<p className="text-center f-light self-center px-1 text-sm md:text-base text-gray-600 mt-16 lg:mt-16">
					By creating workspace, you agree to our <br />
					<span className="px-4">
						<span
							onClick={() =>
								window.open(
									"https://policyvault.africa/terms-of-service/",
									"_blank"
								)
							}
							className="mb-1 f-medium text-bluerolodex text-cursor">
							Terms of use
						</span>{" "}
						and{" "}
						<span
							onClick={() =>
								window.open(
									"https://policyvault.africa/privacy-policy/",
									"_blank"
								)
							}
							className="mb-1 f-medium text-bluerolodex text-cursor">
							Privacy Policy
						</span>
					</span>
				</p>
			</div>
		</section>
	);
};

export default Login;
