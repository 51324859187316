import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import { errorSlice } from "./ErrorReducer";
import { rawdataSlice } from "./RawDataReducer";
import { organizationSlice } from "./OrganizationReducer";
import { invitationsSlice } from "./InvitationReducer";
import { usecasesSlice } from "./UsecasesReducer";
import { workspaceSlice } from "./WorkspaceReducer";
import { companySlice } from "./CompanyReducer";
import { mapSlice } from "./MapReducer";
import { socketSlice } from "./SocketReducer";

const rootReducer = combineReducers({
	auth: UserReducer,
	error: errorSlice.reducer,
	rawdata: rawdataSlice.reducer,
	organization: organizationSlice.reducer,
	invitations: invitationsSlice.reducer,
	usecases: usecasesSlice.reducer,
	workspace: workspaceSlice.reducer,
	company: companySlice.reducer,
	map: mapSlice.reducer,
	mainSocket: socketSlice.reducer,
});

export default rootReducer;
