import { createSlice } from "@reduxjs/toolkit";
import { DeleteData, EditData } from "./UserReducer";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	isAdded: false,
	isDeleted: null,
	search: "",
	mainSearch: null,
	isFound: null,
	isUpdated: null,
};

export const workspaceSlice = createSlice({
	name: "workspace",
	initialState,
	reducers: {
		getSearchWorkspace: (state, { payload }) => {
			state.mainSearch = payload?.data || payload || state?.mainSearch;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetWorkspaceSearch: state => {
			state.search = "";
			state.mainSearch = null;
			state.isFound = false;
		},
		getWorkspace: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		addWorkspace: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isAdded = true;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
				totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
			};
		},
		deleteWorkspace: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isDeleted = true;
			state.data = {
				...state?.data,
				docs: DeleteData(state?.data?.docs, data),
				totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
			};
		},
		updateWorkspace: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isUpdated = true;
			state.data = { ...state?.data, docs: EditData(state?.data?.docs, data) };
		},
		workspaceFail: state => {
			state.isDeleted = false;
			state.isAdded = false;
			state.isFound = false;
		},
		logoutWorkspace: state => {
			state.data = null;
			state.isAdded = false;
			state.isDeleted = null;
			state.search = "";
			state.mainSearch = null;
			state.isFound = null;
			state.isUpdated = null;
			state.all = null;
		},
	},
});
export const {
	workspaceFail,
	getOrg,
	getWorkspace,
	deleteWorkspace,
	addWorkspace,
	getSearchWorkspace,
	getSearch,
	resetWorkspaceSearch,
	updateWorkspace,
	logoutWorkspace,
} = workspaceSlice.actions;

export const manageWorkspace = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			res = await axios.get(
				`/api/v1/organization/workspace?organisation=organisation`
			);
			// console.log({ workspace: res?.data }, "organization");
			dispatch(getWorkspace(res?.data));
		}
		if (type === "post") {
			res = await axios.post(`/workspace`, { ...data });
			dispatch(addWorkspace(res?.data));
		}
		if (type === "put") {
			res = await axios.put(`/workspace/${data?._id}`, { ...data });
			dispatch(updateWorkspace(res?.data));
		}
		if (type === "delete") {
			res = await axios.delete(`/workspace/${data?._id}`);
			dispatch(deleteWorkspace(data));
		}
		if (type !== "get") toast.success(res?.data?.message);
	} catch (err) {
		if (err?.response?.status === 302) {
			if (type === "get") {
				dispatch(getWorkspace(err?.response?.data));
			}
		} else {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			dispatch(workspaceFail());
			if (type && type !== "get") {
				let error =
					Array.isArray(err.response?.data?.error) ||
					Array.isArray(err?.response?.data?.message);
				if (error) {
					dispatch(
						returnErrors({
							error: Array.isArray(err.response?.data?.message)
								? err.response?.data?.message
								: err.response?.data?.error,
							status: err?.response?.status,
						})
					);
				} else {
					toast.error(
						err?.response?.data?.message || err?.response?.data?.error
					);
				}
			}
		}
	}
};
