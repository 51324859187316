export const OrgDataSample = [
	{
		organizationName: "American University of Nigeria",
		websiteUrl: "https://www.aun.edu.ng/",
		companyBio: `AUN is a private university in Nigeria committed to providing the skills and leadership essentials to all students using high level training in information technology, entrepreneurship and in arts and sciences, all of which are the key to future sustainable development for advancing the continent's pressing social and economic challenges.`,
		headQuarters: `Yola, Nigeria`,
		email: `bi.matech4ever@gmail.com`,
		contactPersonName: `Dr. Audu Liman`,
		contactPersonEmail: `audu.liman@aun.edu.ng`,
		phoneNumber: `+234 805-200-2962\n+2348086661659`,
		logo: `https://images.app.goo.gl/Ji1ePGLNzp2qZ5SK7 `,
		officeLocations: `Nigeria`,
		locationsPaidStaff: `Nigeria`,
		organizationMission: `The goal of AUN is to train the future leaders of Africa and to serve as both a stimulus and agent of economic development throughout the region.`,
		yearFounded: "2003",
		associatedPerson: `Mohammed Muktar\nSalisu Mohammed\nGarba Bakori Isa\nFatima Bashir`,
		associatedPersonEmail: `muktar.mohammed@aun.edu.ng\ngarba.isa@aun.edu.ng\nsalisu.mohammed@aun.edu.ng\nfatima.bashir@aun.edu.ng`,
	},
];

export const RawDataSample = [
	{
		expertise: "Agriculture",
		organizationType: "NGO - Local",
		organizationGeospatialFocus: "Generators",
		regionalGeography: "Carribean",
		sector: "Aerospace",
	},
];

export const InvitationSample = [
	{
		company: "bi.matech4ever@gmail.com",
		email: "bi.matech.4ever@gmail.com",
		role: "ADMIN",
	},
];
