import youtube from "../../../src/assets/youtube.png";
import linkedin from "../../../src/assets/linkedin.png";
import twitter from "../../../src/assets/twitter.png";
import logo from "../../assets/landing/Geo-Rolodex2.png";
import { Link } from "react-scroll";

const Footer = () => {
  return (
		<div className=" pt-20">
			<div className=" grid justify-center items-center pb-32">
				<div className=" grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 justify-between gap-10 text-[#fff] lg:px-16 md:px-10 px-5">
					<div>
						<div className="flex items-center gap-3 mb-1">
							{/* <img className=" w-36 h-8" src={LandingLogo} alt="" /> */}
							{/* <img src={logo} alt="" className="h-6 md:h-10  relative z-10" /> */}
							<img
								src={logo}
								alt=""
								className="h-6 md:h-7 lg:w-52 md:w-52 w-52 mx-auto lg:right-6 relative z-10"
							/>
							{/* <p className="text-white">Geo-Rolodex</p> */}
						</div>
						<p className=" font-Inter text-sm text-[#DDDDDE] py-3">
							Efficiently map and analyze stakeholders in minutes.
						</p>
						<div className=" flex items-center gap-2">
							<img className=" w-10 h-10" src={youtube} alt="" />
							<img className=" w-10 h-10" src={linkedin} alt="" />
							<img className=" w-10 h-10" src={twitter} alt="" />
						</div>
					</div>
					<div className=" space-y-2">
						<p className=" font-bold text-[#fff] font-Inter mb-4">Support</p>
						<p className=" text-[#DDDDDE] text-sm font-Inter ">
							<Link
								to="faq"
								smooth={true}
								duration={500}
								className="font-Inter text-base cursor-pointer">
								FAQs
							</Link>
						</p>
						<p className=" text-[#DDDDDE] font-Inter text-sm ">
							<Link
								to="contact"
								smooth={true}
								duration={500}
								className="font-Inter text-base cursor-pointer">
								Expert Support
							</Link>
						</p>
					</div>
					<div className=" space-y-2">
						<p className=" font-bold text-[#fff] font-Inter mb-4">Resources</p>
						<p className=" text-[#DDDDDE] text-sm font-Inter ">
							<Link
								to="#"
								onClick={() =>
									window.open(
										"https://stakeholder-map.nyc3.cdn.digitaloceanspaces.com/Geo-Rolodex%20User%20Guide.pdf",
										"_blank"
									)
								}
								smooth={true}
								duration={500}
								className="font-Inter text-base cursor-pointer">
								User Guide
							</Link>{" "}
						</p>
					</div>
					<div className=" space-y-2">
						<p className=" font-bold text-[#fff] font-Inter mb-4">Legal</p>
						<p
							className=" text-[#DDDDDE] text-sm font-Inter cursor-pointer"
							onClick={() =>
								window.open(
									"https://policyvault.africa/terms-of-service/",
									"_blank"
								)
							}>
							Terms of Service
						</p>
						<p
							className=" text-[#DDDDDE] font-Inter text-sm cursor-pointer"
							onClick={() =>
								window.open(
									"https://policyvault.africa/privacy-policy/",
									"_blank"
								)
							}>
							Privacy Policy / GDPR
						</p>
					</div>
				</div>
			</div>
			<hr className=" text-white" />
			<div className=" grid justify-center items-center py-5">
				<p className=" text-center font-Inter text-[13px] text-[#DDDDDE]">
					© Geo-Rolodex {new Date().getFullYear()}. All rights reserved
				</p>
			</div>
		</div>
	);
};

export default Footer;
