import { useLocation, useNavigate } from "react-router-dom";
import Email from "../../assets/email.svg";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import Button from "../../components/button/button";

const VerifyEmail = () => {
	let navigate = useNavigate(),
		{ state } = useLocation();

	let [loading, setLoading] = useState(null),
		dispatch = useDispatch();

	const onSubmit = async () => {
		setLoading(true);
		try {
			let res = await axios.post(
				`/api/v1/user/resend-verify?email=${state?.email}`,
				{
					nextScreen: `${window?.location?.origin}/verified-email`,
				}
			);
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
		setLoading(false);
	};

	if (!state?.email) return navigate(-1);

	return (
		<section className="min-h-screen flex flex-col items-center space-y-5 justify-center">
			<div className="fixed inset-0 bg-white"></div>
			<div
				className="fixed top-0 px-8 z-30 w-full mb-12"
				onClick={() => navigate("/login")}>
				<img
					src={require("../../assets/logo-black.png")}
					className="h-6 md:h-10"
					alt=""
				/>
			</div>
			<div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10">
				<div className="font-medium self-center  text-gray-800 mb-2">
					<div className="flex flex-col items-center mb-5">
						<img src={Email} alt="Email" />
					</div>
					<h1 className="text-center text-2xl md:text-2xl xl:text-3xl font-bold">
						Verify your <span className="text-bluerolodex ">email address</span>
					</h1>
				</div>
				<div className="self-center">
					<h2 className="text-center text-base md:text-md max-w-sm mx-auto lg:text-md xl:text-lg f-light">
						We have sent a verification link to{" "}
						<span className="font-bold"> {state?.email} </span>, please visit
						your mail to continue
					</h2>
				</div>
				<div className="mt-5 md:mt-7 lg:mt-10">
					{/* <button
						type="submit"
						onClick={() => {
							console.debug("resend link");
							navigate("/signup/setup-workspace");
						}}
						className="w-full flex justify-center bg-lightblue hover:brightness-100  text-bluerolodex p-4  rounded-lg tracking-wide font-semibold cursor-pointer transition ease-in duration-500">
						Send verification link again
					</button> */}
					<Button
						type="submit"
						loading={loading}
						children="Send verification link again"
						// eslint-disable-next-line react/style-prop-object
						css="w-full flex justify-center bg-lightblue hover:brightness-100  text-bluerolodex p-4  rounded-lg tracking-wide font-semibold cursor-pointer transition ease-in duration-500"
						loadCss="text-bluerolodex"
						onClick={onSubmit}
					/>
				</div>
			</div>
		</section>
	);
};

export default VerifyEmail;
