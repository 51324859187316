const organisations = [
  {
    company: "Akros",
    email: "akros@gmail.com",
    contact: "Anna Winters",
    website: "akros.com",
    role: "Super Admin",
    phone: "07055561754",
    date: "11/09/22",
  },
  {
    company: "Cizoti Nigeria Ltd",
    email: "citozinigeria@gmail.com",
    contact: "Edidiong Amos",
    website: "akros.com",
    role: "Super Admin",
    phone: "07055561754",
    date: "11/09/22",
  },
  {
    company: "eHealth Africa",
    email: "Edidiong",
    contact: "Busayo Fashoto",
    website: "akros.com",
    role: "Super Admin",
    phone: "07055561754",
    date: "11/09/22",
  },
  {
    company: "Fraym.IO",
    email: "akros@gmail.com",
    contact: "Melissa Persaud",
    website: "akros.com",
    role: "Super Admin",
    phone: "07055561754",
    date: "11/09/22",
  },
  {
    company: "Digital Earth Africa",
    email: "akros@gmail.com",
    contact: "Zviko Mudimu",
    website: "akros.com",
    role: "Super Admin",
    phone: "07055561754",
    date: "11/09/22",
  },
  {
    company: "Epimap",
    email: "akros@gmail.com",
    contact: "Erin Stearns",
    website: "akros.com",
    role: "Super Admin",
    phone: "07055561754",
    date: "11/09/22",
  },
  {
    company: "Kenya Space",
    email: "akros@gmail.com",
    contact: "mark Ndonga",
    website: "akros.com",
    role: "Super Admin",
    phone: "07055561754",
    date: "11/09/22",
  },
];

export default organisations;
