import React, { useState, useEffect, useContext } from "react";
import SmallNav from "../../components/small-nav/small-nav";
// import organisations from "../../organizations";
import IconDropdown from "../../components/icon-dropdown/icon-dropdown";
import Search from "../../components/search/search";
import { AiOutlineCheckCircle } from "react-icons/ai";

// import usecases from "../../use-cases";
import {
	SearchDropdownSelectExport,
	SortBy,
	SortByCompany,
} from "../../components/search-dropdown/search-dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
	manageUsecases,
	resetUsecasesSearch,
} from "../../data/Reducers/UsecasesReducer";
import { PageLoader } from "../../components/modal-container/modal-container";
import DefaultHeader from "../../components/default-header/default-header";
import moment from "moment";
import { LoadMore, MainPaginate } from "../../components/button/button";
import { GlobalState } from "../../data/Context";
function Invitations() {
	// const [bulkUpload, setBulkUpload] = useState(false);
	// const [drawer, setDrawer] = useState(false);

	// const toggleUploadModal = () => {
	//   setBulkUpload(!bulkUpload);
	// };

	let [data, setData] = useState(null),
		{ usecases, organization } = useSelector(s => s),
		dispatch = useDispatch(),
		{ numberWithCommas } = useContext(GlobalState),
		[search, setSearch] = useState(""),
		[loading, setLoading] = useState(false),
		[newSample, setNewSample] = useState([]),
		[sort, setSort] = useState(""),
		[company, setCompany] = useState("");

	useEffect(() => {
		if (usecases?.isFound) setData(usecases?.mainSearch);
		else setData(usecases?.data);
	}, [usecases?.data, usecases?.isFound, usecases?.mainSearch]);

	let tabViews = [
		`All${
			data?.totalDocs > 0 ? ` (${numberWithCommas(data?.totalDocs)})` : ""
		}`,
		// "Newly Uploaded",
	];

	useEffect(() => {
		dispatch(manageUsecases("get", { sort, company }));
		dispatch(resetUsecasesSearch());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sort, company]);

	useEffect(() => {
		let newArr = [],
			newHeader = [];
		let newObj = {};
		if (data?.docs?.length > 0) {
			for (let r = 0; r < data?.docs.length; r++) {
				let element = data?.docs[r];
				newObj = {
					company: element?.company?.organizationName,
					usecase: element?.url,
					completionDate: moment(element?.completionDate).format("L"),
				};
				newArr?.push(newObj);
				newObj = {};
			}
		}
		newHeader = ["Company", "UseCase", "Completion Date"];
		let csvData = [
			newHeader,
			...newArr?.map(it => {
				let newA = Object?.keys(it)?.map(ic => it?.[ic]);
				return newA;
			}),
		];
		// console.log({ csvData });
		setNewSample(csvData);
	}, [data]);

	let handleLoadMore = async () => {
		setLoading("loadmore");

		let dd = {};
		if (search) {
			dd = { ...dd, search };
		}
		if (sort) dd = { ...dd, sort };
		if (company) dd = { ...dd, company };

		await dispatch(manageUsecases("get", dd));
		setLoading(false);
	};

	let handleSearch = async () => {
		setLoading("loadmore");

		let dd = {};
		if (search) {
			dd = { ...dd, search };
		}
		if (sort) dd = { ...dd, sort };
		if (company) dd = { ...dd, company };

		await dispatch(manageUsecases("get", dd));
		setLoading(false);
	};

	useEffect(() => {
		if (search) {
			document.getElementById("Search").addEventListener("search", () => {
				dispatch(resetUsecasesSearch());
			});
			let handleSubmit = async () => {
				if (!search) return;

				await handleSearch();
			};
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	let [range] = useState(10),
		[page, setPage] = useState(1);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	if (!data && !usecases?.data) return <PageLoader />;

	const currentItems = data
		? [...data?.docs]?.slice(itemOffset, endOffset)
		: [];
	const pageCount = Math.ceil(data?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % data?.docs?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	return (
		<div>
			<DefaultHeader mainTitle={"Use Cases"} />
			<div className="bg-white rounded-lg p-4">
				<SmallNav menu={tabViews} />
				<div className="flex items-center gap-2 justify-between my-4">
					<div className="hidden">
						<Search
							placeholder={"Search Usecases"}
							search={search}
							setSearch={setSearch}
						/>
					</div>
					<SortByCompany
						data={company}
						setData={setCompany}
						options={organization?.companies?.docs
							?.concat(organization?.companiesArchived?.docs)
							?.sort((a, b) => {
								let nameA = a?.organizationName,
									nameB = b?.organizationName;
								var textA = nameA?.toUpperCase();
								var textB = nameB?.toUpperCase();
								return textA < textB ? -1 : textA > textB ? 1 : 0;
							})}
						title="Filter By Organization"
					/>
					<div className="flex items-center gap-2">
						<SortBy sort={sort} setSort={setSort} />

						<SearchDropdownSelectExport
							newSample={newSample}
							fileName={"Rolodex Use Case"}
						/>
					</div>
				</div>
				<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
					<table className="w-full text-sm text-left text-gray-500">
						<thead className="text-md text-gray-700 capitalize bg-secondary">
							<tr>
								<th scope="col" className="p-4">
									<div className="flex items-center">
										<input
											id="checkbox-all-search"
											type="checkbox"
											className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
										/>
										<label for="checkbox-all-search" className="sr-only">
											checkbox
										</label>
									</div>
								</th>
								<th scope="col" className="px-6 py-3">
									Company
								</th>
								<th scope="col" className="px-6 py-3">
									Use Cases
								</th>
								<th scope="col" className="px-6 py-3">
									Completion Date
								</th>
								<th scope="col" className="px-6 py-3">
									Date Added
								</th>
								<th scope="col" className="px-6 py-3"></th>
							</tr>
						</thead>
						<tbody>
							{currentItems?.map((usecase, i) => (
								<tr key={i} className="bg-white border-b hover:bg-gray-50">
									<td className="w-4 p-4">
										<div className="flex items-center">
											<input
												id="checkbox-table-search-1"
												type="checkbox"
												className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
											/>
											<label for="checkbox-table-search-1" className="sr-only">
												checkbox
											</label>
										</div>
									</td>
									<th
										scope="row"
										className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:underline cursor-pointer">
										{usecase?.company?.organizationName}
									</th>
									<td
										className="px-6 py-4 cursor-pointer"
										onClick={
											usecase?.url
												? () => window.open(usecase?.url, "_blank")
												: Array.isArray(usecase?.file)
												? null
												: usecase?.file?.url
												? () => window.open(usecase?.file?.url, "_blank")
												: null
										}>
										{usecase?.url ? (
											usecase?.fileName || usecase?.url
										) : Array.isArray(usecase?.file) ? (
											<>
												{usecase?.file?.map((ic, u) => (
													<span
														key={u}
														className="block py-1"
														onClick={
															ic?.url
																? () => window.open(ic?.url, "_blank")
																: null
														}>
														{usecase?.fileName ? (
															<>
																{usecase?.fileName} {u + 1}
															</>
														) : (
															ic?.url
														)}
													</span>
												))}
											</>
										) : (
											usecase?.fileName || usecase?.file?.url
										)}
									</td>
									<td className="px-6 py-4">
										{moment(usecase?.completionDate).format("L")}
									</td>
									<td className="px-6 py-4">
										{moment(usecase?.createdAt).format("L")}
									</td>
									<td className="flex items-center px-6 py-4 space-x-3">
										<IconDropdown>
											<ul className="space-y-1">
												<li
													className="text-xs flex items-center gap-1 cursor-pointer hover:text-gray-600"
													onClick={
														usecase?.url
															? () => window.open(usecase?.url, "_blank")
															: Array.isArray(usecase?.file)
															? () =>
																	window.open(usecase?.file?.[0]?.url, "_blank")
															: usecase?.file?.url
															? () => window.open(usecase?.file?.url, "_blank")
															: null
													}>
													<span>
														<AiOutlineCheckCircle />
													</span>
													<span>Open link in new tab</span>
												</li>
											</ul>
										</IconDropdown>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && data?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default Invitations;
