/* eslint-disable react/style-prop-object */
import { IconEye, IconEyeClosed } from "@tabler/icons-react";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../../components/button/button";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";

const Signup = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch(),
    { state } = useLocation();
  const methods = useForm({
    defaultValues: {
      // email: state?.email || state?.org?.email,
      password: "",
    },
  });

  let [loading, setLoading] = useState(null);

  const onSubmit = async (data) => {
    if (!data) return;
    console.log(data);
    setLoading(true);
    try {
      let res = await axios.post(
        `/api/v1/user/signup`,
        { ...data, nextScreen: `${window?.location?.origin}/verified-email` },
        { headers: { Authorization: state?.token } }
      );
      console.log({ resp: res?.data });
      toast.success(res?.data?.message);
      navigate("/signup/setup-workspace", { state });
    } catch (err) {
      if (err) console.log({ error: err.response?.data, err });
      if (err?.response?.status === 429) toast.error(err?.response?.data);
      let error = err.response?.data?.error;
      if (error && error?.length > 1) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
      } else {
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data?.error?.[0]?.message ||
            err?.message
        );
      }
    }
    setLoading(false);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const redirect = (url) => {
    navigate(url);
  };

  if (!state?.email && !state?.token) navigate(-1);

  return (
		<section className="min-h-screen flex flex-col items-center space-y-5 justify-center relative">
			<div className="fixed inset-0 bg-white"></div>
			<div
				className="fixed top-0 px-8 z-30 w-full mb-12"
				onClick={() => {
					if (
						window.location?.origin?.includes("geo-rolodex.com") ||
						window.location?.origin?.includes("map.policyvault.africa")
					) {
						navigate("/");
					} else {
						navigate("/login");
					}
				}}>
				<img
					src={require("../../assets/logo-black.png")}
					className="h-6 md:h-10"
					alt=""
				/>
			</div>
			<div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10">
				<div className="font-medium self-center  text-gray-800 mb-2">
					<h1 className="text-center text-2xl md:text-2xl xl:text-3xl f-bold">
						Get Started on{" "}
						<span className="text-bluerolodex">Stakeholder map</span>{" "}
					</h1>
				</div>
				<div className="self-center">
					<h2 className="text-center text-base md:text-lg lg:text-lg xl:text-xl f-light">
						Let's get you started right away. We suggest using your work email
						address.
					</h2>
				</div>
				<div className="mt-4 md:mt-4 lg:mt-8">
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							{/* Email */}
							{/* <Controller
								name="email"
								rules={{ required: "This field is required" }}
								render={({ field: { onChange, name } }) => (
									<fieldset className="flex flex-col mb-6">
										<label
											className="mb-1 text-sm md:text-lg f-bold tracking-wide"
											htmlFor="email">
											Email address
										</label>

										<input
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
											name={name}
											id={name}
											type={name}
											onChange={onChange}
											placeholder="Enter email"
										/>
										{methods.formState.errors.email && (
											<span className="text sm md:text-base font-semibold text-red-600">
												This field is required
											</span>
										)}
									</fieldset>
								)}
							/> */}

							{/* Password*/}
							<Controller
								name="password"
								control={methods.control}
								rules={{
									required: "This field is required",
									pattern: {
										value:
											/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
										message:
											"Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
									},
								}}
								render={({ field: { onChange, name } }) => (
									<fieldset className="flex flex-col mb-6">
										<div className="flex items-center justify-between">
											<label
												className="mb-1 text-sm md:text-lg f-bold tracking-wide"
												htmlFor="password">
												Password
											</label>
										</div>
										<div className="relative">
											<input
												className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
												name={name}
												id={name}
												onChange={onChange}
												placeholder="Password"
												type={showPassword ? "text" : "password"}
											/>
											<div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
												{showPassword ? (
													<IconEye onClick={handleShowPassword} />
												) : (
													<IconEyeClosed onClick={handleShowPassword} />
												)}
											</div>
										</div>
										{methods.formState.errors.password && (
											<span className="text-xs text-red-600">
												{methods.formState.errors.password.message}
											</span>
										)}
									</fieldset>
								)}
							/>

							{/* <button
								type="submit"
								className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light  shadow-lg cursor-pointer transition ease-in duration-500">
								Create Workspace
							</button> */}
							<Button
								type="submit"
								loading={loading}
								children="Create Workspace"
								css="w-full flex justify-center mt-5 bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light  shadow-lg cursor-pointer transition ease-in duration-500"
							/>
						</form>
					</FormProvider>
				</div>
				<p className="text-center f-light self-center mt-6 text-sm md:text-lg">
					Have a workspace already?{" "}
					<span className="custom-link f-medium" onClick={() => redirect("/")}>
						Login here
					</span>
				</p>
				<p className="text-center f-light self-center mt-6 text-sm md:text-lg">
					Not yet verified?{" "}
					<span
						className="custom-link f-medium"
						onClick={() => redirect("/signup/resend-verify-email")}>
						Resend Verification Link Here
					</span>
				</p>

				<p className="text-center f-light self-center px-1 text-gray-600 text-sm md:text-base mt-10 lg:mt-17">
					By creating workspace, you agree to our <br />
					<span className="px-4">
						<span
							onClick={() =>
								window.open(
									"https://policyvault.africa/terms-of-service/",
									"_blank"
								)
							}
							className="mb-1 f-medium text-bluerolodex text-cursor">
							Terms of use
						</span>{" "}
						and{" "}
						<span
							onClick={() =>
								window.open(
									"https://policyvault.africa/privacy-policy/",
									"_blank"
								)
							}
							className="mb-1 f-medium text-bluerolodex text-cursor">
							Privacy Policy
						</span>
					</span>
				</p>
			</div>
		</section>
	);
};

export default Signup;
