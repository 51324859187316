import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import imgone from "../../assets/slidesone.png";
// import imgtwo from "../../assets/slidestwo.png";
// import imgthree from "../../assets/slidesthree.png";
import imgfour from "../../assets/slidesfour.png";
import imgfive from "../../assets/slidesfive.png";

const Slides = () => {
	const images = [
		imgone,
		// imgtwo, imgthree,
		imgfour,
		imgfive,
	];

	return (
		<Swiper
			spaceBetween={30}
			centeredSlides={true}
			loop={true}
			autoplay={{
				delay: 2500,
				disableOnInteraction: false,
			}}
			modules={[Autoplay]} // Ensure the modules are included here
		>
			{images.map((image, index) => (
				<SwiperSlide key={index} className="bg-transparent rounded-2xl h-full">
					<img
						src={image}
						alt={`Slide ${index}`}
						className="rounded-lg h-full"
					/>
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export default Slides;
