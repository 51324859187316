import { useState } from "react"
import NewPasswordSuccess from "./new-password/new-password-success"
import NewPasswordForm from "./new-password/new-password-form"


const CreateNewPassword = () => {
    const [success, setSuccess] = useState(false)

    return (
			<section className="min-h-screen flex flex-col items-center space-y-5 justify-center">
				{!success ? (
					<NewPasswordForm setSuccess={setSuccess} />
				) : (
					<NewPasswordSuccess success={success} />
				)}
			</section>
		);
}

export default CreateNewPassword