import { createSlice } from "@reduxjs/toolkit";
import { DeleteData, EditData } from "./UserReducer";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	isAdded: false,
	isDeleted: null,
	search: "",
	mainSearch: null,
	isFound: null,
	isUpdated: null,
};

export const usecasesSlice = createSlice({
	name: "usecases",
	initialState,
	reducers: {
		getSearchUsecases: (state, { payload }) => {
			if (state?.search === payload?.search) {
				state.mainSearch = payload?.data || payload || state?.mainSearch;
				state.isFound = true;
			}
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetUsecasesSearch: state => {
			state.search = "";
			state.mainSearch = null;
			state.isFound = false;
		},
		getUsecases: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		addUsecases: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isAdded = true;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
				totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
			};
		},
		deleteUsecases: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isDeleted = true;
			state.data = {
				...state?.data,
				docs: DeleteData(state?.data?.docs, data),
				totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
			};
		},
		updateUsecases: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isUpdated = true;
			state.data = { ...state?.data, docs: EditData(state?.data?.docs, data) };
		},
		usecasesFail: state => {
			state.isDeleted = false;
			state.isAdded = false;
			state.isFound = false;
		},
		logoutUsecases: state => {
			state.data = null;
			state.isAdded = false;
			state.isDeleted = null;
			state.search = "";
			state.mainSearch = null;
			state.isFound = null;
			state.isUpdated = null;
			state.all = null;
		},
	},
});
export const {
	usecasesFail,
	getOrg,
	getUsecases,
	deleteUsecases,
	addUsecases,
	getSearchUsecases,
	getSearch,
	resetUsecasesSearch,
	updateUsecases,
	logoutUsecases,
} = usecasesSlice.actions;

export const manageUsecases = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			if (data?.search) dispatch(getSearch({ search: data?.search }));

			res = await axios.get(
				`/api/v1/usecases?all=all${data?.limit ? `&limit=${data?.limit}` : ""}${
					data?.search ? `&search=${data?.search}` : ""
				}${data?.sort ? `&sort=${data?.sort}` : ""}${
					data?.company ? `&company=${data?.company}` : ""
				}`
			);
			dispatch(
				data?.search ? getSearchUsecases(res?.data) : getUsecases(res?.data)
			);
		}
		if (type === "post") {
			res = await axios.post(`/use-cases`, { ...data });
			dispatch(addUsecases(res?.data));
		}
		if (type === "put") {
			res = await axios.put(`/use-cases/${data?._id}`, { ...data });
			dispatch(updateUsecases(res?.data));
		}
		if (type === "delete") {
			res = await axios.delete(`/use-cases/${data?._id}`);
			dispatch(deleteUsecases(data));
		}
		if (type !== "get") toast.success(res?.data?.message);
	} catch (err) {
		if (err?.response?.status === 429 || err?.response?.status === 405)
			toast.error(err?.response?.data ? err?.response?.data : err?.message);
		console.log({ err });
		dispatch(usecasesFail());
		if (type && type !== "get") {
			let error =
				Array.isArray(err.response?.data?.error) ||
				Array.isArray(err?.response?.data?.message);
			if (error) {
				dispatch(
					returnErrors({
						error: Array.isArray(err.response?.data?.message)
							? err.response?.data?.message
							: err.response?.data?.error,
						status: err?.response?.status,
					})
				);
			} else {
				toast.error(err?.response?.data?.message || err?.response?.data?.error);
			}
		}
	}
};
