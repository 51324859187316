import { createSlice } from "@reduxjs/toolkit";
import { DeleteData, EditData } from "./UserReducer";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	pending: null,
	isAdded: false,
	isDeleted: null,
	search: "",
	mainSearch: null,
	isFound: null,
	isUpdated: null,
};

export const invitationsSlice = createSlice({
	name: "invitations",
	initialState,
	reducers: {
		getSearchInvitations: (state, { payload }) => {
			if (state?.search === payload?.search) {
				state.mainSearch = payload?.data || payload || state?.mainSearch;
				state.isFound = true;
			}
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetInvitationsSearch: state => {
			state.search = "";
			state.mainSearch = null;
			state.isFound = false;
		},
		getInvitations: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		getPendingInvitations: (state, { payload }) => {
			state.pending = payload?.data || payload;
		},
		addInvitations: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isAdded = true;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
				totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
			};
		},
		deleteInvitations: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isDeleted = true;
			state.data = {
				...state?.data,
				docs: DeleteData(state?.data?.docs, data),
				totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
			};
		},
		updateInvitations: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isUpdated = true;
			state.data = { ...state?.data, docs: EditData(state?.data?.docs, data) };
		},
		invitationsFail: state => {
			state.isDeleted = false;
			state.isAdded = false;
			state.isFound = false;
		},
		logoutInvitations: state => {
			state.data = null;
			state.pending = null;
			state.isAdded = false;
			state.isDeleted = null;
			state.search = "";
			state.mainSearch = null;
			state.isFound = null;
			state.isUpdated = null;
			state.all = null;
		},
	},
});
export const {
	invitationsFail,
	getOrg,
	getInvitations,
	deleteInvitations,
	addInvitations,
	getSearchInvitations,
	getSearch,
	resetInvitationsSearch,
	updateInvitations,
	logoutInvitations,
	getPendingInvitations,
} = invitationsSlice.actions;

export const manageInvitations = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			if (data?.search) dispatch(getSearch({ search: data?.search }));

			res = await axios.get(
				`/invitations/${data?.type || "all-users"}${
					data?.id ? `?organizationId=${data?.id}` : ""
				}${data?.limit ? `&limit=${data?.limit}` : ""}${
					data?.search ? `&search=${data?.search}` : ""
				}${data?.sort ? `&sort=${data?.sort}` : ""}
				`
			);
			// const response = await fetch(
			// 	`/invitations/${data?.type || "all-users"}${
			// 		data?.limit ? `&_limit=${data?.limit}` : ""
			// 	}`,
			// 	{
			// 		method: "get",
			// 		body: JSON.stringify({
			// 			organizationId: data?.id,
			// 		}),
			// 		headers: {
			// 			"Content-Type": "application/json",
			// 			"Authorization": `Bearer ${localStorage.getItem(TOKEN)}`,
			// 			"Accept": "application/json",
			// 		},
			// 	}
			// );
			// const responseData = await response.json();
			// res = {
			// 	data: responseData,
			// };
			dispatch(
				data?.search
					? getSearchInvitations(res?.data)
					: data?.type === "pending-invites"
					? getPendingInvitations(res?.data)
					: getInvitations(res?.data)
			);
		}
		if (type === "post") {
			res = await axios.post(`/invitations`, { ...data });
			dispatch(addInvitations(res?.data));
		}
		if (type === "put") {
			res = await axios.put(`/invitations/${data?._id}`, { ...data });
			dispatch(updateInvitations(res?.data));
		}
		if (type === "delete") {
			res = await axios.delete(`/invitations/${data?._id}`);
			dispatch(deleteInvitations(data));
		}
		if (type !== "get") toast.success(res?.data?.message);
	} catch (err) {
		if (err?.response?.status === 429 || err?.response?.status === 405)
			toast.error(err?.response?.data ? err?.response?.data : err?.message);
		console.log({ err });
		dispatch(invitationsFail());
		if (type && type !== "get") {
			let error =
				Array.isArray(err.response?.data?.error) ||
				Array.isArray(err?.response?.data?.message);
			if (error) {
				dispatch(
					returnErrors({
						error: Array.isArray(err.response?.data?.message)
							? err.response?.data?.message
							: err.response?.data?.error,
						status: err?.response?.status,
					})
				);
			} else {
				toast.error(err?.response?.data?.message || err?.response?.data?.error);
			}
		}
	}
};
