import { useContext } from "react";
import "./loader.css";
import { GlobalState } from "../../data/Context";

const ModalContainer = ({ show, title, close, children, width, subtitle }) => {
  return (
    <div
      className={`fixed ${
        show ? "flex" : "hidden"
      } flex items-center top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] my-auto max-h-full`}
    >
      <div className="fixed inset-0 bg-black opacity-30"></div>
      <div
        className={`relative w-full ${
          width ? width : "max-w-2xl"
        } mx-auto max-h-full overflow-auto`}
      >
        {/* <!-- Modal content --> */}
        <div className="relative bg-white rounded-sm shadow">
          {/* <!-- Modal header --> */}
          <div className="flex items-center justify-between gap-4 p-4 py-2 rounded-t">
            <div>
              <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
              <p className="text-xs text-gray-400 mt-1 text">{subtitle}</p>
            </div>
            <button
              type="button"
              className="bg-gray-400 text-white rounded-lg text-sm p-1.5 inline-flex items-center"
              onClick={close}
            >
              <span className="sr-only">Close modal</span>
              <svg
                aria-hidden="true"
                className="w-3 h-3"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <hr className="m-0 border-1 border-primary" />
          {/* <!-- Modal body --> */}
          <div className="p-6 space-y-6">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalContainer;

export const SuccessModal = ({ show, close, children, width }) => {
  return (
    <div
      className={`fixed ${
        show ? "flex" : "hidden"
      } flex items-center top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full scrollbar-hide`}
    >
      <div className="w-2/5"></div>
      <div className="w-3/5 flex justify-center">
        <div
          className="fixed inset-0 bg-black opacity-30"
          onClick={close}
        ></div>
        <div
          className={`relative w-full ${
            width ? width : "max-w-2xl"
          } mx-auto max-h-full`}
        >
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg shadow">
            <div className="p-6 space-y-6">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DrawerModal = ({ show, close, children }) => {
  return (
    <div className="">
      {show && (
        <div
          className="fixed inset-0 bg-black opacity-20"
          onClick={close}
        ></div>
      )}
      {/* <!-- drawer component --> */}
      <div
        className={`fixed top-0 right-0 z-[100] h-screen transition-transform scrollbar-hide overflow-scroll bg-grey ${
          !show && "translate-x-full"
        } max-w-md`}
      >
        <button
          type="button"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center"
          onClick={close}
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close menu</span>
        </button>
        {children}
      </div>
    </div>
  );
};

export const PageLoader = () => {
  let { orgTheme } = useContext(GlobalState);
  return (
    <div
      className="ring"
      style={{
        color: orgTheme || "",
        borderColor: orgTheme || "",
      }}
    >
      Loading
      <span className="spanClass"></span>
    </div>
  );
};
