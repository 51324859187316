/* eslint-disable no-undef */
import { createElement, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clearErrors } from "./data/Reducers/ErrorReducer";
import { useDispatch, useSelector } from "react-redux";
import { PageLoader } from "./components/modal-container/modal-container";

const GeneratePage = (pageName, folder) => {
	const component = () => require(`./${folder}/${pageName}`).default;
	let navigate = useNavigate();
	try {
		return createElement(component());
	} catch (e) {
		console.log(e);
		navigate("/");
	}
};

const PageRender = () => {
	const { auth, error } = useSelector(s => s);
	const { page, id, step } = useParams();
	const escape2 = [
			"reset-password",
			"verify-email",
			"verify-profile",
			"resend-verify-email",
			"setup-workspace",
			"signup-success",
			"add-new-organisation",
			"add-new-user",
			"success",
			"steps",
			"setup-organization",
			"recommendation",
			"bulk-upload",
		],
		navigate = useNavigate(),
		dispatch = useDispatch();

	useEffect(() => {
		if (!auth?.isAuth) {
			if (!error?.errorText) {
				if (
					![
						"login",
						"register",
						"signup",
						"verify-profile",
						"verified-email",
						"create-new-password",
						"verify",
						"new-password",
						"landing",
						"home",
					]?.includes(page)
				) {
					navigate("/");
					dispatch(clearErrors());
				}
			}
		}
		if (auth?.isAuth) {
			if (["register", "login", "create-account"]?.includes(page)) {
				navigate("/");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, auth?.isAuth, navigate, error]);

	if (
		![
			"login",
			"register",
			"landing",
			"signup",
			"verified-email",
			"create-new-password",
			"verify",
			"new-password",
			"verify-profile",
		]?.includes(page)
	)
		if (auth.loading) return <PageLoader />;
	// if (general?.isLoading && users.isLoading) return <Loader />;

	let pageName = "";
	if (step) {
		pageName = `${page}/${id}/${"[id]"}`;
	} else if (id) {
		if (
			(page === "login" && escape2.includes(id)) ||
			(page === "signup" && escape2.includes(id)) ||
			(page === "organisations" && escape2.includes(id)) ||
			(page === "invitations" && escape2.includes(id)) ||
			(page === "verify" && escape2.includes(id)) ||
			(page === "get-started" && escape2.includes(id)) ||
			(page === "data" && ["update"].includes(id)) ||
			(page === "help" && escape2.includes(id))
		) {
			pageName = `${page}/${id}`;
		} else {
			pageName = `${page}/${"[id]"}`;
		}
	} else {
		pageName = `${page}`;
	}
	return GeneratePage(pageName, auth?.isAuth ? "pages" : "screens");
};

export default PageRender;
