import React, { useContext, useEffect, useState } from "react";
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import { GlobalState } from "../../data/Context";
import { useDispatch, useSelector } from "react-redux";
import { ShortenName } from "../../components/contact-card/contact-card";
import { BsCheckLg } from "react-icons/bs";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import { FaTimes } from "react-icons/fa";
import Button from "../../components/button/button";

const UpdateRawdata = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const params = ["update your dataset"];
	// const navigate = useNavigate();
	const currentRoute = searchParams.get("step");

	useEffect(() => {
		if (!currentRoute) {
			setSearchParams({ step: "update your dataset" });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let { orgTheme } = useContext(GlobalState),
		{ auth, workspace } = useSelector(s => s),
		navigate = useNavigate();

	useEffect(() => {
		if (auth?.user) if (auth?.user?.privilege !== "organization") navigate(-1);
	}, [auth?.user, navigate]);

	return (
		<div>
			<div className="inset-0 bg-white fixed"></div>
			<div className="relative z-10">
				<div className="flex items-center justify-between gap-x-8 mb-4 px-4 flex-wrap">
					<p className="py-4 f-medium text-xl">Raw data</p>

					<div className="flex items-center space-x-4">
						<div className="">
							<div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
								<span className="font-medium text-gray-600 dark:text-gray-300">
									<ShortenName user={auth?.user?.organizationName} />
								</span>
							</div>
						</div>
						<div className="font-medium dark:text-white">
							<div>{workspace?.data?.workspaceName}</div>
							<div className="text-sm text-gray-500 dark:text-gray-400">
								{auth?.user?.organizationName}....
							</div>
						</div>
					</div>
				</div>
				<div className="p-4 flex justify-between overflow-x-auto gap-8 scrollbar-hide">
					<div className="flex items-center space-x-4">
						<div>
							<div
								className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-bluerolodex rounded-md"
								style={{
									background: orgTheme || "",
								}}>
								<span className="font-medium text-white">
									<BsCheckLg />
								</span>
							</div>
						</div>
						<div
							className={`f-medium ${
								currentRoute === params[0]
									? "text-bluerolodex"
									: "text-gray-700"
							}`}
							style={{
								color: orgTheme && currentRoute === params[0] ? orgTheme : "",
							}}>
							<div className="c whitespace-nowrap">Update your dataset</div>
							<div
								style={{
									color: orgTheme && currentRoute === params[0] ? orgTheme : "",
								}}
								className={`text-sm ${
									currentRoute === params[0]
										? "text-bluerolodex"
										: "text-gray-400"
								}`}>
								Modify dataset for your map
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div className="py-12">
					{currentRoute === params[0] && <CreateDataset route={params[1]} />}
				</div>
			</div>
		</div>
	);
};

const CreateDataset = ({ route }) => {
	const navigate = useNavigate();

	let [loading, setLoading] = useState(null),
		dispatch = useDispatch(),
		{ page } = useParams(),
		{ state } = useLocation();

	let init2 = {};
	const [itemForm, setItemForm] = useState([init2]);

	useEffect(() => {
		if (!state) navigate(-1);
		if (state) {
			setItemForm([
				...state?.map(it => {
					return {
						...it,
						dataArr: it?.data,
						data: null,
					};
				}),
			]);
		}
	}, [state, navigate]);
	console.log({ state });
	const handleInputChangeForMutipleItem = (event, index, field) => {
		const { value } = event.target;
		let itemValue = value;

		setItemForm(prevRows => {
			const newRows = [...prevRows];
			newRows[index][field] = itemValue;
			return newRows;
		});
	};

	const handleInputKeyPress = (e, index, field) => {
		setItemForm(prevRows => {
			const newRows = [...prevRows];
			// console.log({ e: e.key });
			let emailInput = newRows[index].data;
			if (e.key === "Enter" && emailInput.trim() !== "") {
				newRows[index][field] = Array.isArray(newRows[index][field])
					? [...newRows[index][field], emailInput.trim()]
					: [emailInput.trim()];
				newRows[index].data = "";
			}
			return newRows;
		});
	};

	const onSubmit = async () => {
		if (itemForm?.length === 0) return toast.info("Data set required");
		if (itemForm?.length === 1)
			if (!itemForm?.[0]?.category || itemForm?.[0]?.dataArr?.length === 0)
				return toast.info("Data set required");
		setLoading(true);
		try {
			let res = await axios.put(`/api/v1/rawdata`, {
				rawdata: itemForm?.map(item => {
					return {
						...item,
						data: item?.dataArr?.map(it => {
							return it?.trim();
						}),
					};
				}),
			});
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			setLoading(false);
			navigate(`/${page}`);
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
		setLoading(false);
	};

	return (
		<div className="max-w-4xl mx-auto">
			<div className="flex justify-between flex-wrap mb-8">
				<article>
					<p className="text-2xl f-bold">Update your dataset</p>
					<p className="f-light">Update dataset for your stakeholder map</p>
				</article>
			</div>
			<div>
				<form className="space-y-6">
					{itemForm?.map((item, index) => (
						<>
							<div className="relative grid md:grid-cols-3 gap-4">
								<div>
									<div>
										{index === 0 && (
											<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
												Dataset Category
											</label>
										)}
										<input
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
											placeholder="Category dataset"
											value={item?.category}
											onChange={event =>
												handleInputChangeForMutipleItem(
													event,
													index,
													"category"
												)
											}
										/>
									</div>
								</div>
								<div className="col-span-2">
									<div>
										{index === 0 && (
											<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
												Dataset data
											</label>
										)}
										<div className="bg-gray-100">
											{item?.dataArr?.length > 0 && (
												<div className="flex items-center gap-2 overflow-x-auto noScroll pt-2 px-3">
													{item?.dataArr?.map((it, x) => (
														<span
															key={x}
															className="text-xs border border-black rounded-full px-3 py-1 flex items-center justify-between gap-3 w-auto whitespace-nowrap">
															{it}{" "}
															<FaTimes
																className="cursor-pointer"
																onClick={() => {
																	let newArr = item?.dataArr?.filter(
																		(j, k) => k !== x
																	);
																	let newItem = {
																		...item,
																		dataArr: newArr,
																	};
																	let newFinal = itemForm?.map((it, d) =>
																		d === index ? newItem : it
																	);
																	setItemForm(newFinal);
																	// setItemForm(prevRows => {
																	// 	const newRows = [...prevRows];
																	// 	newRows[index].dataArr?.splice(x, 1);
																	// 	return newRows;
																	// });
																}}
															/>
														</span>
													))}
												</div>
											)}
											<input
												className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
												placeholder="Press Enter to input a new Dataset data"
												value={item.data}
												onChange={event =>
													handleInputChangeForMutipleItem(event, index, "data")
												}
												onKeyUp={event =>
													handleInputKeyPress(event, index, "dataArr")
												}
											/>
										</div>
									</div>
								</div>
							</div>
						</>
					))}

					<div className="mt-4 flex items-center gap-3">
						<Button
							children={"Update"}
							buttonType={"primary"}
							onClick={onSubmit}
							loading={loading}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default UpdateRawdata;
