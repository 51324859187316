import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddNewOrganisationComponent } from "../organisations/add-new-organisation";

export const Recommendation = () => {
	let { auth } = useSelector(s => s),
		navigate = useNavigate();

	useEffect(() => {
		if (auth?.user) if (auth?.user?.privilege === "organization") navigate(-1);
	}, [auth?.user, navigate]);

	return <AddNewOrganisationComponent />;
};

const MainRecommend = () => <Recommendation />;

export default MainRecommend;
