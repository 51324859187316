import React, { useState } from "react";
import { ImLinkedin } from "react-icons/im";
import { BsChevronDown } from "react-icons/bs";

const ContactCard = ({ user }) => {
	const [show, setShow] = useState(false);
	return (
		<div>
			<div className="flex items-center space-x-2">
				<div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-200 rounded-full">
					<span className="font-medium text-gray-600">
						<ShortenName user={user?.organizationName || user?.name} />
					</span>
				</div>

				<div className="">
					{(user?.organizationName || user?.name) && (
						<div className="font-semibold flex items-center gap-2">
							<span className="text-sm">
								{user?.organizationName || user?.name}
							</span>
							<span
								onClick={
									user?.linkedInUrl
										? () => window.open(user?.linkedInUrl, "_blank")
										: null
								}
								className={`c text-blue-700 ${
									user?.linkedInUrl ? "cursor-pointer" : ""
								}`}>
								<ImLinkedin />
							</span>
						</div>
					)}
					<div className="text-xs text-gray-500 font-normal flex items-center gap-2">
						<span>{user?.email}</span>
						<span className="text-gray-100">|</span>
						<span>{user?.phoneNumber}</span>
					</div>
				</div>
			</div>
			<div className="w-80 mx-auto">
				{show && (user?.companyBio || user?.bio) && (
					<div className="rounded-md p-4 text-xs my-2 bg-grey">
						<p>{user?.bio || user?.companyBio}</p>
					</div>
				)}
				<div>
					{show ? (
						<p
							className="flex items-center gap-1 text-xs justify-center text-blue-400 cursor-pointer"
							onClick={() => setShow(false)}>
							Hide Bio
							<span>
								<BsChevronDown size={10} />
							</span>
						</p>
					) : (
						<p
							className="flex items-center gap-1 text-xs px-10 mt-1 text-blue-400 cursor-pointer"
							onClick={() => setShow(true)}>
							Show Bio
							<span>
								<BsChevronDown size={10} />
							</span>
						</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default ContactCard;

export const ShortenName = ({ user }) => {
	return (
		<>
			{user?.split(" ")?.[0]?.charAt(0)}
			{user?.split(" ")?.[1]?.charAt(0)}
		</>
	);
};