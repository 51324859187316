import { useSelector } from "react-redux";
import { MainVerify } from "../../screens/verify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Verify = () => {
	let { auth } = useSelector(s => s),
		navigate = useNavigate();

	useEffect(() => {
		if (auth?.user?.privilege !== "company")
			if (auth?.user?.role?.title?.toUpperCase() !== "ADMIN") navigate(-1);
	}, [auth?.user, navigate]);

	return <MainVerify />;
};

export default Verify;
