import { useContext } from "react";
import { ClipLoader } from "react-spinners";
import { GlobalState } from "../../data/Context";
import ReactPaginate from "react-paginate";

const BUTTON_TYPES = {
  primary: "bg-main text-white",
  gray: "bg-gray-300 text-black",
  secondary: "border border-main text-main",
  white: "bg-white text-main",
};

export const MainPaginate = ({ handlePageClick, pageCount, css }) => {
  return (
    <div className={`flex justify-center items-center py-10 ${css || ""}`}>
      <ReactPaginate
        breakLabel="..."
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        pageClassName="h-10 w-10 flex items-center justify-center rounded-full text-xl font-medium manrope border border-main mx-2 cursor-pointer"
        className="flex items-center justify-center"
        previousClassName="hidden"
        nextClassName="hidden"
        activeClassName="bg-[#1B3C8e] text-[#fff]"
      />
    </div>
  );
};

const Button = ({ buttonType, children, css, icon, ...restProps }) => {
  let { orgTheme } = useContext(GlobalState);
  return (
    <button
      type={restProps?.type || "button"}
      disabled={restProps?.loading || restProps?.disabled}
      className={`px-6 rounded-md font-light text-sm whitespace-nowrap flex items-center ${
        icon || restProps?.loading ? "gap-2" : ""
      } ${css || "h-10"} ${BUTTON_TYPES[buttonType]}`}
      style={{
        background: orgTheme && buttonType === "primary" ? orgTheme : "",
        borderColor: orgTheme && buttonType === "secondary" ? orgTheme : "",
        color:
          orgTheme && ["white", "secondary"]?.includes(buttonType)
            ? orgTheme
            : "",
        ...restProps?.style,
      }}
      {...restProps}
    >
      <span>{icon}</span>
      {children}
      {restProps?.loading && (
        <ClipLoader color={restProps?.loadCss || "white"} size={16} />
      )}
    </button>
  );
};

export const LandingBtn = ({ css, bg, text, onClick, ...restProps }) => {
	return (
		<>
			<button
				onClick={onClick}
				type={restProps?.type || "button"}
				className={`${bg} ${css} font-Inter`}
				disabled={restProps?.loading || restProps?.disabled}>
				{text}
				{restProps?.loading && (
					<ClipLoader color={restProps?.loadCss || "white"} size={16} />
				)}
			</button>
		</>
	);
};

export const LoadMore = ({ handleLoadMore, next, loading }) => {
  return (
    <>
      {!next ? (
        ""
      ) : (
        <Button
          onClick={handleLoadMore}
          text={loading ? "Loading..." : "Load More"}
          loading={loading}
          css="mx-auto"
          type={"button"}
          buttonType={"primary"}
          width={"w"}
        />
      )}
    </>
  );
};

export default Button;
