import React, { useState, useEffect } from "react";
import DefaultHeader from "../../components/default-header/default-header";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import Button from "../../components/button/button";
import { manageOrganization } from "../../data/Reducers/OrganizationReducer";
import { manageRole } from "../../data/Reducers/UserReducer";

const AddNewUser = () => {
	const methods = useForm({
		defaultValues: {
			email: "",
		},
	});

	let { auth, organization } = useSelector(s => s);

	let [loading, setLoading] = useState(null),
		{ page } = useParams(),
		navigate = useNavigate(),
		dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			manageOrganization("get", {
				companies: "companies",
				active: "active",
			})
		);
		dispatch(
			manageOrganization("get", {
				companies: "companies",
				statusText: "activated",
			})
		);
		dispatch(
			manageOrganization("get", {
				companies: "companies",
				statusText: "archived",
			})
		);
		dispatch(manageRole());
	}, [auth?.user, dispatch]);

	useEffect(() => {
		if (auth?.user) if (auth?.user?.privilege !== "organization") navigate(-1);
	}, [auth?.user, navigate]);

	const onSubmit = async data => {
		// console.log({ data });
		if (!data?.email || !data?.company || !data?.role)
			return toast.info("User' complete details required");
		setLoading(true);
		try {
			let res = await axios.post(`/api/v1/organization/invite-user`, {
				nextScreen: `${window?.location?.origin}/signup/verify-profile`,
				privilege: "staff",
				staffs: [
					{
						...data,
					},
				],
			});
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			setLoading(false);
			navigate(`/${page}`);
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
		setLoading(false);
	};
	return (
		<div>
			<DefaultHeader mainTitle={"Add New User"} />
			<div className="bg-white rounded-lg p-4 mb-10">
				<p className="text-lg text-gray-500 mt-2">
					Fill all information to onboard new organization
				</p>
				<div className="mt-5 md:mt-7 lg:mt-10 max-w-xl">
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Controller
								name="organizationName"
								rules={{ required: "This field is required" }}
								render={({ field: { onChange, name } }) => (
									<fieldset className="flex flex-col mb-6">
										<input
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
											name={name}
											id={name}
											type={"text"}
											onChange={onChange}
											placeholder="User's Name"
										/>
										{methods.formState.errors.organizationName && (
											<span className="md:text-base font-bold text-red-600">
												This field is required
											</span>
										)}
									</fieldset>
								)}
							/>
							<Controller
								name="email"
								rules={{
									required: "This field is required",
									pattern: {
										value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/g,
										message: "You must enter a valid email address",
									},
								}}
								render={({ field: { onChange, name } }) => (
									<fieldset className="flex flex-col mb-6">
										<input
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
											name={name}
											id={name}
											type={"email"}
											onChange={onChange}
											placeholder="Enter User's email address"
										/>
										{methods.formState.errors.email && (
											<span className="text sm md:text-base font-semibold text-red-600">
												This field is required
											</span>
										)}
									</fieldset>
								)}
							/>
							<Controller
								name="company"
								rules={{ required: "This field is required" }}
								render={({ field: { onChange, name } }) => (
									<fieldset className="flex flex-col mb-6">
										<label
											className="mb-1 text-sm md:text-lg font-semibold tracking-wide text-gray-600"
											htmlFor={name}>
											Company
										</label>
										<select
											name={name}
											id={name}
											onChange={onChange}
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none">
											<option value="">Select Company</option>
											{organization?.companies?.docs
												?.concat(organization?.companiesArchived?.docs)
												?.map((it, i) => (
													<option
														key={i}
														value={it?.company?._id || it?.company}>
														{it?.organizationName} ({it?.email})
													</option>
												))}
										</select>
										{methods.formState.errors.company && (
											<span className="text sm md:text-base font-semibold text-red-600">
												This field is required
											</span>
										)}
									</fieldset>
								)}
							/>
							<Controller
								name="role"
								rules={{ required: "This field is required" }}
								render={({ field: { onChange, name } }) => (
									<fieldset className="flex flex-col mb-6">
										<label
											className="mb-1 text-sm md:text-lg font-semibold tracking-wide text-gray-600"
											htmlFor="email">
											Role
										</label>
										<select
											name={name}
											id={name}
											onChange={onChange}
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none">
											<option value="">Select Role</option>
											{auth?.role?.docs?.map((it, i) => (
												<option key={i} value={it?._id}>
													{it?.title}
												</option>
											))}
										</select>
										{methods.formState.errors.role && (
											<span className="text sm md:text-base font-semibold text-red-600">
												This field is required
											</span>
										)}
									</fieldset>
								)}
							/>
							<div className="flex items-center gap-8 mb-4">
								<button
									type="button"
									onClick={() => navigate(`/${page}`)}
									className="w-full flex justify-center bg-gray-200 hover:brightness-100  text-black p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500">
									Back
								</button>
								<Button
									children={"Next"}
									buttonType={"primary"}
									loading={loading}
									type="submit"
									// eslint-disable-next-line react/style-prop-object
									css={
										"w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
									}
								/>
							</div>
						</form>
					</FormProvider>
				</div>
			</div>
		</div>
	);
};

export default AddNewUser;
