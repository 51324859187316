const usecases = [
  {
    company: "Akros",
    useCases: "Students Loan (Access to Higher Education)",
    completionDate: "11/09/22",
    dateAdded: "11/09/22",
  },
  {
    company: "Fraym.IO",
    useCases: "Taking the great step to discovering medical cure",
    completionDate: "11/09/22",
    dateAdded: "11/09/22",
  },
  {
    company: "Digital Earth Africa",
    useCases: "Taking the great step to discovering medical cure",
    completionDate: "11/09/22",
    dateAdded: "11/09/22",
  },
  {
    company: "Kenya Space Agency ",
    useCases: "Students Loan (Access to Higher Education)",
    completionDate: "11/09/22",
    dateAdded: "11/09/22",
  },
  {
    company: "Digital Earth Africa",
    useCases: "Taking the great step to discovering medical cure",
    completionDate: "11/09/22",
    dateAdded: "11/09/22",
  },
  {
    company: "Kenya Space Agency ",
    useCases: "Students Loan (Access to Higher Education)",
    completionDate: "11/09/22",
    dateAdded: "11/09/22",
  },
  {
    company: "Digital Earth Africa",
    useCases: "akros@gmail.com",
    completionDate: "11/09/22",
    dateAdded: "11/09/22",
  },
];

export default usecases;
