import { useState } from "react";
// import LandingLogo from "../../../src/assets/LandingLogo.png";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { LandingBtn } from "../button/button";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "../../assets/landing/Geo-Rolodex2.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();

  return (
		<div>
			<div className=" flex py-4 bg-dark items-center justify-between lg:px-16 md:px-10 px-5">
				<div className="flex items-center gap-3">
					{/* <img className=" w-36 h-8" src={LandingLogo} alt="" /> */}
					<img
						src={logo}
						alt=""
						className="h-6 md:h-7 lg:w-52 md:w-52 w-52 mx-auto relative z-10"
					/>
					{/* <p className="text-white">Geo-Rolodex</p> */}
				</div>
				<ul className=" lg:flex md:flex hidden items-center gap-10 text-[#fff]">
					<Link
						to="about-us"
						smooth={true}
						duration={500}
						className="font-Inter text-base cursor-pointer">
						About Us
					</Link>
					<Link
						to="#"
						onClick={() =>
							window.open(
								"https://stakeholder-map.nyc3.cdn.digitaloceanspaces.com/Geo-Rolodex%20User%20Guide.pdf",
								"_blank"
							)
						}
						smooth={true}
						duration={500}
						className="font-Inter text-base cursor-pointer">
						User Guide
					</Link>
					<div className=" flex items-center gap-2">
						<Link
							to="faq"
							smooth={true}
							duration={500}
							className="font-Inter text-base cursor-pointer">
							FAQs
						</Link>
						<MdOutlineKeyboardArrowDown />
					</div>
				</ul>
				<div className=" lg:flex md:hidden hidden items-center gap-4">
					{/* <LandingBtn
            text="Request Demo"
            css={"text-[#fff] font-Inter text-[16px] "}
          /> */}
					<LandingBtn
						text="Login"
						css={
							"border-[#fff] border rounded-md text-[#fff] w-[140px] h-[44px] "
						}
						onClick={() => {
							if (window.location?.origin?.includes("geo-rolodex.com")) {
								window.location.replace(
									"https://geo-rolodex.map.policyvault.africa/login"
								);
							} else if (
								window.location?.origin?.includes("map.policyvault.africa")
							) {
								window.location.replace(
									"https://geo-rolodex.map.policyvault.africa/login"
								);
							} else {
								navigate("/login");
							}
						}}
					/>
				</div>

				<div className=" text-white lg:hidden  md:block block">
					<GiHamburgerMenu
						size={24}
						className=" cursor-pointer"
						onClick={() => setToggle(!toggle)}
					/>
				</div>

				{toggle && (
					<div className=" absolute bg-dark right-0 top-14 p-5">
						<ul className=" grid gap-4 items-center text-[#fff]">
							<li>
								<Link
									to="about-us"
									smooth={true}
									duration={500}
									className="font-Inter text-base cursor-pointer">
									About Us
								</Link>
							</li>
							<li>
								<Link
									to="#"
									onClick={() =>
										window.open(
											"https://stakeholder-map.nyc3.cdn.digitaloceanspaces.com/Geo-Rolodex%20User%20Guide.pdf",
											"_blank"
										)
									}
									smooth={true}
									duration={500}
									className="font-Inter text-base cursor-pointer">
									User Guide
								</Link>
							</li>
							<div className=" flex items-center gap-2">
								<Link
									to="faq"
									smooth={true}
									duration={500}
									className="font-Inter text-base cursor-pointer">
									FAQs
								</Link>
								<MdOutlineKeyboardArrowDown />
							</div>
						</ul>
						<div className=" flex items-center gap-4 mt-5">
							{/* <LandingBtn
								text="Request Demo"
								css={"text-[#fff] font-Inter text-[16px] "}
							/> */}
							<LandingBtn
								text="Login"
								css={
									"border-[#fff] font-Inter border rounded-md text-[#fff] w-[140px] h-[44px] "
								}
								onClick={() => {
									if (window.location?.origin?.includes("geo-rolodex.com")) {
										window.location.replace(
											"https://geo-rolodex.map.policyvault.africa/login"
										);
									} else if (
										window.location?.origin?.includes("map.policyvault.africa")
									) {
										window.location.replace(
											"https://geo-rolodex.map.policyvault.africa/login"
										);
									} else {
										navigate("/login");
									}
								}}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Navbar;
