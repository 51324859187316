import axios from "axios";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import Button from "../../components/button/button";

const ResetPassword = () => {
  const navigate = useNavigate();
  const methods = useForm();

  let [loading, setLoading] = useState(null),
    dispatch = useDispatch();
  const onSubmit = async (data) => {
    setLoading(true);
    console.log({ data });
    try {
      if (!data?.email) toast.info("Email is required");
      let res = await axios.post(`/api/v1/user/setup-password`, {
        email: data?.email,
        nextScreen: `${window?.location?.origin}/create-new-password`,
      });
      console.log({ resp: res?.data });
      toast.success(
        res?.data?.message || "Password reset sent. Please check your mail"
      );
    } catch (err) {
      if (err) console.log({ error: err.response?.data, err });
      if (err?.response?.status === 429) toast.error(err?.response?.data);
      let error = err.response?.data?.error;
      if (error && error?.length > 1) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
      } else {
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data?.error?.[0]?.message ||
            err?.message
        );
      }
    }
    setLoading(false);
  };

  const redirect = (url) => {
    navigate(url);
  };

  return (
    <section className="min-h-screen flex flex-col items-center space-y-5 justify-center">
      <div className="fixed inset-0 bg-white"></div>
      <div className="fixed top-0 px-8 z-30 w-full mb-12">
        <img
          src={require("../../assets/logo-black.png")}
          className="h-6 md:h-10"
          alt=""
        />
      </div>
      <div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10">
        <div className="f-bold self-center  text-gray-800 mb-2">
          <h1 className="text-center text-2xl md:text-2xl xl:text-3xl">
            Reset Password
          </h1>
        </div>
        <div className="self-center">
          <h2 className="text-center text-base md:text-md xl:text-lg f-light">
            Forgot your password? Enter your email address to get a password
            reset link.
          </h2>
        </div>
        <div className="mt-5 md:mt-7 lg:mt-10">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {/* Email */}
              <Controller
                name="email"
                control={methods.control}
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/g,
                    message: "You must enter a valid email address",
                  },
                }}
                render={({ field: { onChange, name } }) => (
                  <fieldset className="flex flex-col mb-6">
                    <label
                      className="mb-1 text-sm md:text-lg f-bold tracking-wide"
                      htmlFor="email"
                    >
                      Email address
                    </label>

                    <input
                      className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
                      name={name}
                      id={name}
                      type="text"
                      onChange={onChange}
                      placeholder="Enter email"
                    />
                    {methods.formState.errors.email && (
                      <span className="text-xs text-red-600">
                        {methods.formState.errors.email.message}
                      </span>
                    )}
                  </fieldset>
                )}
              />

              {/* <button
								type="submit"
								className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light  shadow-lg cursor-pointer transition ease-in duration-500">
								Send my password reset link
							</button> */}
              <Button
                type="submit"
                loading={loading}
                children="Send my password reset link"
                // eslint-disable-next-line react/style-prop-object
                css="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light  shadow-lg cursor-pointer transition ease-in duration-500"
                // loadCss="text-bluerolodex"
              />
              <button
                type="button"
                onClick={() => redirect("/")}
                className="w-full flex justify-center hover:brightness-100  text-bluerolodex p-4  tracking-wide font-semibold cursor-pointer transition ease-in duration-500 mt-6"
              >
                Go back to Login
              </button>
            </form>
          </FormProvider>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
