import React, { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  SearchDropdownSelect,
  SearchDropdownSelectCountry,
} from "../../components/search-dropdown/search-dropdown";
import { TbUpload } from "react-icons/tb";
import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";
import {
	PageLoader,
	SuccessModal,
} from "../../components/modal-container/modal-container";
import Button, { LoadMore, MainPaginate } from "../../components/button/button";
import SmallNav from "../../components/small-nav/small-nav";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import { useDispatch } from "react-redux";
import moment from "moment";
import { FaTimes } from "react-icons/fa";
import { IconEye, IconEyeClosed } from "@tabler/icons-react";
import DOMPurify from "dompurify";
import { IoIosAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { AnswerTypeArr, OtherQuestionArr } from "../../pages/setup";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { IdleLogout } from "../../components/sidebar/sidebar";
import logo from "../../assets/landing/Geo-Rolodex2.png";
import logoFav from "../../assets/Geo-Logo.png";
import IconDropdown from "../../components/icon-dropdown/icon-dropdown";

export const createMarkup = html => {
	return {
		__html: DOMPurify.sanitize(html),
	};
};

export const MainVerify = () => {
	const [searchParams, setSearchParams] = useSearchParams(),
		initParams = [
			"company information",
			"contact information",
			"expertise and niche information",
			"media upload",
			"use cases",
			"experience",
		];
	let [params, setParams] = useState(["password", ...initParams]);
	const currentRoute = searchParams.get("type"),
		[rawdata, setRawdata] = useState(null),
		[countries, setCountries] = useState(null),
		[rawdata2, setRawdata2] = useState(null),
		[firstStep, setFirstStep] = useState(null),
		[secondStep, setSecondStep] = useState(null),
		[experience, setExperience] = useState(null),
		[usecases, setUseCases] = useState(null),
		[media, setMedia] = useState(null),
		[workspace, setWorkspace] = useState(null),
		[actualUser, setActualUser] = useState(null);

	// modal
	let tabViews = ["Upload file", "Add link"],
		navigate = useNavigate();
	const [modal] = useState({
		title: "Add media file",
		subtitle:
			"import contacts from an excel or csv file. Use sample file as guide",
	});
	const [active, setActive] = useState(tabViews[0]);
	const [isOpen, setIsOpen] = useState(false);
	const handleSmallNav = nav => {
		setActive(nav);
	};

	useEffect(() => {
		if (workspace?.workspaceName) document.title = workspace?.workspaceName;
		if (workspace?.file?.url) {
			let link = document.querySelector("link[rel~='icon']");
			let link2 = document.querySelector("link[rel~='apple-touch-icon']");
			// let title = document.querySelector("title");
			if (!link) {
				link = document.createElement("link");
				link.rel = "icon";
				document.getElementsByTagName("head")[0].appendChild(link);
			}
			if (!link2) {
				link2 = document.createElement("link");
				link2.rel = "apple-touch-icon";
				document.getElementsByTagName("head")[0].appendChild(link2);
			}
			link.href = workspace?.workspaceSubdomain?.includes("geo-rolodex")
				? logoFav
				: workspace?.file?.url;
			link2.href = workspace?.workspaceSubdomain?.includes("geo-rolodex")
				? logoFav
				: workspace?.file?.url;
		}
	}, [workspace]);

	const toggleIsOpen = () => {
		setIsOpen(!isOpen);
	};

	let getRawData = async () => {
		try {
			let res = await axios.get(`/api/v1/rawdata?organisation=organisation`, {
				headers: {
					Authorization: searchParams?.get("token"),
				},
			});
			console.log({ rd: res }, "raw-data");
			setRawdata(res?.data?.data || res?.data);
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
		}
	};

	let getCountries = async () => {
		try {
			let res = await axios.get(
				`https://restcountries.com/v3.1/all?fields=name,flags,region,capital,timezones`,
				{
					headers: {
						Authorization: null,
					},
					baseURL: null,
				}
			);
			console.log({ rd: res }, "countries");
			setCountries(res?.data?.data || res?.data);
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
		}
	};

	let getMedia = async () => {
		try {
			let res = await axios.get(`/api/v1/media`, {
				headers: {
					Authorization: searchParams?.get("token"),
				},
			});
			console.log({ media: res }, "media");

			setMedia(res?.data?.data || res?.data);
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
		}
	};

	let getUseCases = async () => {
		try {
			let res = await axios.get(`/api/v1/usecases`, {
				headers: {
					Authorization: searchParams?.get("token"),
				},
			});
			console.log({ usecases: res }, "usecases");

			setUseCases(res?.data?.data || res?.data);
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
		}
	};

	let getExperience = async () => {
		try {
			let res = await axios.get(`/api/v1/experience`, {
				headers: {
					Authorization: searchParams?.get("token"),
				},
			});
			console.log({ exp: res }, "experience");

			setExperience(res?.data?.data || res?.data);
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
		}
	};

	let getRawData2 = async () => {
		try {
			let res = await axios.get(`/api/v1/rawdata`, {
				headers: {
					Authorization: searchParams?.get("token"),
				},
			});
			console.log({ rd2: res }, "raw-data-2");

			setRawdata2(res?.data?.data || res?.data);
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
		}
	};

	let getFirstStep = async () => {
		try {
			let res = await axios.get(`/api/v1/user?onboarding=true`, {
				headers: {
					Authorization: searchParams?.get("token"),
				},
			});
			console.log({ detail: res?.data }, "user-detail");
			setFirstStep(res?.data?.data || res?.data);
			setSecondStep(res?.data?.data || res?.data);
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
		}
	};

	let getActualUser = async () => {
		try {
			let res = await axios.get(`/api/v1/user`, {
				headers: {
					Authorization: searchParams?.get("token"),
				},
			});
			console.log({ actual: res?.data }, "actual-user-detail");
			setActualUser(res?.data?.data || res?.data);
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
		}
	};

	let getWorkspace = async () => {
		try {
			let res = await axios.get(
				`/api/v1/organization/workspace?organisation=organisation`,
				{
					headers: {
						Authorization: searchParams?.get("token"),
					},
				}
			);
			console.log({ detail: res?.data }, "workspace");

			setWorkspace(res?.data?.data || res?.data);
		} catch (err) {
			navigate("/");
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
		}
	};

	useEffect(() => {
		if (!currentRoute && actualUser) {
			setSearchParams(searchParams => {
				searchParams.set(
					"type",
					actualUser?.privilege === "company" ? params?.[0] : params?.[1]
				);
				return searchParams;
			});

			// searchParams?.append("type", params?.[0]));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentRoute, actualUser]);

	useEffect(() => {
		if (actualUser) {
			if (actualUser?.privilege !== "company") setParams(["", ...initParams]);
			// searchParams?.append("type", params?.[0]));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [actualUser]);

	useEffect(() => {
		getWorkspace();
		getActualUser();
		getFirstStep();
		getRawData();
		getRawData2();
		getExperience();
		getCountries();
		getUseCases();
		getMedia();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentRoute]);

	useEffect(() => {
		if (!searchParams?.get("token") || !searchParams?.get("email"))
			navigate("/");
	}, [searchParams, navigate]);

	if (!workspace || !actualUser) return <PageLoader />;

	return (
		<div>
			<div className="flex">
				<div
					className="bg-[#070D27] h-screen min-w-fi p-4 md:px-16 w-2/5"
					style={{ background: workspace?.color || "" }}>
					<img
						src={
							workspace?.workspaceSubdomain?.includes("geo-rolodex")
								? logo || require("../../assets/brand-white.png")
								: workspace?.file?.url ||
								  logo ||
								  require("../../assets/brand-white.png")
						}
						alt=""
						className="h-auto w-52"
					/>
					<div className="text-white mt-8">
						<p className="f-semibold text-lg md:text-2xl">Welcome Aboard!</p>
						<p className="f-light text-gray-100 text-xs md:text-lg">
							You have been invited to join the {workspace?.workspaceName}{" "}
							Stakeholder Map. Please follow the steps below to get started.
						</p>
					</div>
					<div className="border-l border-gray-300 mt-8">
						<ul className="space-y-2">
							{params
								?.filter(ic => ic)
								.map(url => (
									<li
										className={`text-white border-l-4 px-2 py-2 md:px-4 capitalize cursor-pointer ${
											url === currentRoute
												? "border-gray-200 f-bold"
												: "border-transparent f-light"
										}`}
										onClick={() => {
											setSearchParams(searchParams => {
												searchParams.set("type", url);
												return searchParams;
											});

											// navigate(`?type=${url}`)
										}}>
										{url}
									</li>
								))}
						</ul>
					</div>
				</div>
				<div
					className={`md:w-3/5 bg-white h-screen overflow-y-auto scrollbar-hide`}>
					{currentRoute === params[1] && (
						<CompanyInformation
							route={params[2]}
							setSearchParams={setSearchParams}
							firstStep={firstStep}
							workspace={workspace}
							countries={countries}
						/>
					)}
					{currentRoute === params[2] && (
						<ContactInformation
							route={params[3]}
							back={params[1]}
							setSearchParams={setSearchParams}
							secondStep={secondStep}
							workspace={workspace}
							countries={countries}
						/>
					)}
					{currentRoute === params[3] && (
						<ExpertiseAndNicheInformation
							route={params[4]}
							back={params[2]}
							setSearchParams={setSearchParams}
							rawdata={rawdata}
							rawdata2={rawdata2}
							workspace={workspace}
						/>
					)}
					{currentRoute === params[4] && (
						<MediaUpload
							route={params[5]}
							back={params[3]}
							toggleIsOpen={toggleIsOpen}
							setSearchParams={setSearchParams}
							modal={modal}
							tabViews={tabViews}
							active={active}
							handleSmallNav={handleSmallNav}
							workspace={workspace}
							media={media}
							setMedia={setMedia}
						/>
					)}
					{currentRoute === params[5] && (
						<UseCases
							back={params[4]}
							route={params[6]}
							toggleIsOpen={toggleIsOpen}
							active={active}
							handleSmallNav={handleSmallNav}
							modal={modal}
							tabViews={tabViews}
							setSearchParams={setSearchParams}
							workspace={workspace}
							usecases={usecases}
							setUseCases={setUseCases}
						/>
					)}
					{currentRoute === params[6] && (
						<Experience
							back={params[5]}
							// route={params[7]}
							toggleIsOpen={toggleIsOpen}
							active={active}
							handleSmallNav={handleSmallNav}
							modal={modal}
							tabViews={tabViews}
							setSearchParams={setSearchParams}
							workspace={workspace}
							experience={experience}
							route={"success"}
						/>
					)}
					{currentRoute === params[0] && (
						<Password
							firstStep={firstStep}
							route={params[1]}
							// back={params[5]}
							setSearchParams={setSearchParams}
							toggleIsOpen={toggleIsOpen}
							active={active}
							handleSmallNav={handleSmallNav}
							modal={modal}
							tabViews={tabViews}
							workspace={workspace}
						/>
					)}
					{currentRoute === "success" && <SuccessPage workspace={workspace} />}
				</div>
			</div>
			{/* modals */}
			<SuccessModal show={isOpen} close={toggleIsOpen}>
				<div className="">
					<div className="flex items-center justify-between">
						<article className="w-full">
							<p className="f-bold text-2xl">{modal.title}</p>
							<p className="text-gray-700 f-light text-lg">{modal.subtitle}</p>
						</article>
						<span>
							<AiOutlineClose />
						</span>
					</div>
					<SmallNav
						menu={tabViews}
						active={active}
						handleNav={handleSmallNav}
						full
					/>
					<div className="my-4">
						{active === tabViews[0] && (
							<UploadFile toggleModal={toggleIsOpen} />
						)}
						{active === tabViews[1] && (
							<AddFileLink toggleModal={toggleIsOpen} />
						)}
					</div>
				</div>
			</SuccessModal>
		</div>
	);
};

const Verify = () => {
	return (
		<>
			<IdleLogout />
			<MainVerify />;
		</>
	);
};

export default Verify;

export const CompanyInformation = ({
	route,
	setSearchParams,
	firstStep,
	workspace,
}) => {
	let [logo, setLogo] = useState(null),
		fileRef = useRef(),
		[loading, setLoading] = useState(null),
		[getSearch] = useSearchParams(),
		[data, setData] = useState({
			linkedInUrl: "https://",
			websiteUrl: "https://",
		}),
		textChange = e => {
			let { name, value } = e?.target;
			setData({ ...data, [name]: value });
		},
		dispatch = useDispatch(),
		onSubmit = async e => {
			e?.preventDefault();
			try {
				if (!data?.organizationName) {
					return toast.info("Company name is required");
				}
				if (!logo && !data?.image?.url) return toast.info("Logo is required");
				setLoading(true);

				let body = {
					...data,
				};
				if (data?.websiteUrl)
					body = {
						...body,
						websiteUrl: data?.websiteUrl?.startsWith("https://")
							? data?.websiteUrl
							: `https://${data?.websiteUrl}`,
					};
				if (data?.linkedInUrl)
					body = {
						...body,
						linkedInUrl: data?.linkedInUrl?.startsWith("https://")
							? data?.linkedInUrl
							: `https://${data?.linkedInUrl}`,
					};

				if (logo) {
					// try {
					// 	let post = new FormData();
					// 	post.append(`file`, logo);

					// 	let resImg = await axios.post(`/api/v1/file`, post, {
					// 		headers: {
					// 			"Content-Type": "multipart/form-data",
					// 			"Authorization": getSearch?.get("token"),
					// 		},
					// 	});

					// 	body.file = resImg?.data?.data?.[0];
					// } catch (err) {
					// 	if (err) console.log({ error: err.response?.data, err });
					// 	if (err?.response?.status === 429) toast.error(err?.response?.data);
					// 	let error = err.response?.data?.error;
					// 	if (error && error?.length > 1) {
					// 		dispatch(returnErrors({ error, status: err?.response?.status }));
					// 	} else {
					// 		toast.error(
					// 			err?.response?.data?.message ||
					// 				err?.response?.data?.error?.[0]?.message ||
					// 				err?.message
					// 		);
					// 	}
					// }
					body.intendedFileName = "image";
					body.intendedFile = logo;
				}

				let res = await axios.put(`/api/v1/user?step=first-step`, body, {
					headers: {
						"Authorization": getSearch?.get("token"),
						"Content-Type": "multipart/form-data",
					},
				});
				console.log({ resp: res?.data });
				let msg = typeof res?.data === "string" ? res?.data : "";
				toast.success(res?.data?.message || msg);
				setSearchParams(searchParams => {
					searchParams.set("type", route);
					return searchParams;
				});
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				let error = err.response?.data?.error;
				if (error && error?.length > 1) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				} else {
					toast.error(
						err?.response?.data?.message ||
							err?.response?.data?.error?.[0]?.message ||
							err?.message
					);
				}
			}
			setLoading(false);
		};

	useEffect(() => {
		if (firstStep) {
			setData({
				...data,
				...firstStep,
				linkedInUrl: firstStep?.linkedInUrl || "https://",
				websiteUrl: firstStep?.websiteUrl || "https://",
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstStep]);
	// console.log({ firstStep, data });
	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes("image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			// setLogo(file);

			setLogo(file);
		}
	};
	return (
		<section className="items-center space-y-5 justify-center">
			{/* <div className="fixed inset-0 bg-white"></div> */}
			<div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10 mx-auto">
				<div className="font-medium  text-gray-800 mb-2">
					<h1 className="text-2xl md:text-2xl xl:text-3xl f-bold">
						Company Information
					</h1>
					<p className="text-base md:text-lg lg:text-lg xl:text-xl f-light mt-2">
						Tell us about your organization
					</p>
				</div>

				<div className="mt-8 md:mt-4 lg:mt-8">
					<form className="space-y-6">
						<fieldset className="flex flex-col">
							<label
								className="mb-1 text-sm md:text-lg f-bold tracking-wide"
								htmlFor="email">
								Organization Name
							</label>

							<input
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
								type={"text"}
								placeholder="Your company name"
								id={"organizationName"}
								name="organizationName"
								onChange={textChange}
								value={data?.organizationName}
							/>
						</fieldset>
						<div>
							<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
								Logo
							</label>
							<div
								onClick={() => fileRef?.current?.click()}
								className="text-center rounded-lg border border-dashed border-gray-400 bg-[#cbd5e052] p-4 space-y-2 cursor-pointer">
								{logo ? (
									<>
										<img
											src={URL.createObjectURL(logo)}
											alt=""
											className="mx-auto mb-2"
										/>
									</>
								) : (
									<div>
										<img
											src={
												data?.image?.url ||
												require("../../assets/file-icon2.png")
											}
											alt=""
											className="mx-auto mb-2"
										/>
										<p className="f-bold">
											Select a PNG or JPG file to upload. File size should not
											be more than 2mb
										</p>
										<p className="c text-gray-500">or drag and drop it here</p>
									</div>
								)}
								<input
									ref={fileRef}
									type="file"
									style={{ width: "0" }}
									className="absolute -z-10"
									onChange={handleChangeImage}
								/>
							</div>
							<input
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none hidden"
								placeholder="Enter year founded"
							/>
						</div>
						<fieldset className="flex flex-col">
							<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
								Website URL
							</label>
							<input
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
								type={"text"}
								placeholder="Your website link"
								id={"websiteUrl"}
								name="websiteUrl"
								onChange={textChange}
								value={data?.websiteUrl}
							/>
							{/* <div className="flex gap-2">
								<div className="p-4 border border-gray-300 rounded-md">
									https://
								</div>
							</div> */}
						</fieldset>
						<fieldset className="flex flex-col">
							<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
								LinkedIn URL
							</label>
							<input
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
								type={"url"}
								placeholder="Your linkedIn link"
								id={"linkedInUrl"}
								name="linkedInUrl"
								onChange={textChange}
								value={data?.linkedInUrl}
							/>
							{/* <div className="flex gap-2">
								<div className="p-4 border border-gray-300 rounded-md">
									https://
								</div>
							</div> */}
						</fieldset>
						<fieldset className="flex flex-col">
							<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
								Year Founded
							</label>

							<input
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
								type={"date"}
								placeholder="Enter year founded"
								max={moment().format("YYYY-MM-DD")}
								id={"yearFounded"}
								name="yearFounded"
								onChange={textChange}
								value={moment(data?.yearFounded).format("YYYY-MM-DD")}
							/>
						</fieldset>
						<fieldset className="flex flex-col">
							<label className="text-sm md:text-lg f-bold tracking-wide">
								Company Bio
							</label>
							<br />
							<small className="b text-gray-400">
								Tell us about your company
							</small>
							<textarea
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none placeholder:text-lg"
								placeholder="Write a short bio here"
								row={6}
								style={{
									resize: "none",
									height: "10rem",
								}}
								id={"companyBio"}
								name="companyBio"
								onChange={textChange}
								value={data?.companyBio}
							/>
						</fieldset>
						{/* <button
								type="submit"
								className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light  shadow-lg cursor-pointer transition ease-in duration-500"
								onClick={() =>
									setSearchParams(searchParams => {
										searchParams.set("type", route);
										return searchParams;
									})
								}>
								Next
							</button> */}
						<Button
							type="button"
							loading={loading}
							children="Next"
							// eslint-disable-next-line react/style-prop-object
							css="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light  shadow-lg cursor-pointer transition ease-in duration-500"
							// loadCss="text-bluerolodex"
							onClick={onSubmit}
							style={{ background: workspace?.color || "" }}
						/>
					</form>
				</div>
			</div>
		</section>
	);
};

export const ContactInformation = ({
	route,
	back,
	setSearchParams,
	secondStep,
	workspace,
	countries,
}) => {
	const [property, setProperty] = useState(null),
		// [proper, setProp] = useState(null),
		[data, setData] = useState({ linkedInUrl: "https://" }),
		textChange = e => {
			let { name, value } = e?.target;
			setData({ ...data, [name]: value });
		};

	useEffect(() => {
		if (secondStep) {
			setData({
				...data,
				...secondStep,
				bio: secondStep?.contactPerson?.bio || data?.bio,
				name: secondStep?.contactPerson?.name || data?.name,
				email: secondStep?.contactPerson?.email || data?.email,
				linkedInUrl:
					secondStep?.contactPerson?.linkedInUrl || data?.linkedInUrl,
			});
			let newD = property;
			if (secondStep?.locationsPaidStaff) {
				newD = { ...newD, locationsPaidStaff: secondStep?.locationsPaidStaff };
			}
			if (secondStep?.officeLocations) {
				newD = { ...newD, officeLocations: secondStep?.officeLocations };
			}
			setProperty(newD);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [secondStep]);

	// const handleInputKeyPress = (e, field) => {
	// 	let emailInput = proper[field];
	// 	if (e.key === "Enter" && emailInput.trim() !== "") {
	// 		setProperty(prevRows => {
	// 			const newRows = { ...prevRows };
	// 			// console.log({ e: e.key });
	// 			newRows[field] = Array.isArray(newRows[field])
	// 				? [...newRows[field], emailInput.trim()]
	// 				: [emailInput.trim()];
	// 			return newRows;
	// 		});
	// 		setProp({
	// 			...proper,
	// 			[field]: "",
	// 		});
	// 	}
	// };
	let [loading, setLoading] = useState(null),
		[getSearch] = useSearchParams(),
		dispatch = useDispatch(),
		onSubmit = async e => {
			e?.preventDefault();
			try {
				if (!data?.phoneNumber) {
					return toast.info("Company phone is required");
				}
				setLoading(true);
				let body = {
					...data,
					contactPerson: {
						name: data?.name,
						email: data?.email,
						bio: data?.bio,
					},
					...property,
				};

				if (data?.linkedInUrl)
					body = {
						...body,
						contactPerson: {
							...body?.contactPerson,
							linkedInUrl: data?.linkedInUrl?.startsWith("https://")
								? data?.linkedInUrl
								: `https://${data?.linkedInUrl}`,
						},
					};
				console.log({ body });
				let res = await axios.put(`/api/v1/user?step=second-step`, body, {
					headers: {
						Authorization: getSearch?.get("token"),
					},
				});
				console.log({ resp: res?.data });
				let msg = typeof res?.data === "string" ? res?.data : "";
				toast.success(res?.data?.message || msg);
				setSearchParams(searchParams => {
					searchParams.set("type", route);
					return searchParams;
				});
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				let error = err.response?.data?.error;
				if (error && error?.length > 1) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				} else {
					toast.error(
						err?.response?.data?.message ||
							err?.response?.data?.error?.[0]?.message ||
							err?.message
					);
				}
			}
			setLoading(false);
		};
	return (
		<section className="items-center space-y-5 justify-center">
			{/* <div className="fixed inset-0 bg-white"></div> */}
			<div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10 mx-auto">
				<div className="font-medium  text-gray-800 mb-2">
					<h1 className="text-2xl md:text-2xl xl:text-3xl f-bold">
						Contact Information
					</h1>
					<p className="text-base md:text-lg lg:text-lg xl:text-xl f-light mt-2">
						How can we get in touch?
					</p>
				</div>

				<div className="mt-8 md:mt-4 lg:mt-8">
					<form className="space-y-6">
						<fieldset className="flex flex-col">
							<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
								Phone Number
							</label>

							{/* <input
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
								type={"tel"}
								placeholder="Your company phone"
								id={"phoneNumber"}
								name="phoneNumber"
								onChange={textChange}
								value={data?.phoneNumber}
							/> */}
							<PhoneInput
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
								placeholder="Enter company phone number"
								value={data?.phoneNumber}
								onChange={e => setData({ ...data, phoneNumber: e })}
								defaultCountry={
									window?.navigator?.language?.includes("-")
										? window?.navigator?.language?.split("-")[1]
										: window?.navigator?.language
								}
								limitMaxLength
							/>
						</fieldset>
						<fieldset className="flex flex-col">
							<label className="mb-1 text-sm md:text-lg f-bold tracking-wide capitalize">
								Head Office Location
							</label>{" "}
							<input
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
								type={"text"}
								placeholder="City, Country"
								id={"headQuarters"}
								name="headQuarters"
								onChange={textChange}
								value={data?.headQuarters}
							/>
						</fieldset>
						<div>
							{/* <label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
								Office locations
							</label> */}
							{/* <SearchDropdownSelect /> */}
							{/* <div className="bg-gray-100">
								{property?.officeLocations?.length > 0 && (
									<div className="flex items-center gap-2 overflow-x-auto noScroll pt-2 px-3">
										{property?.officeLocations?.map((it, x) => (
											<span
												key={x}
												className="text-xs border border-black rounded-full px-3 py-1 flex items-center justify-between gap-3 w-auto whitespace-nowrap">
												{it}{" "}
												<FaTimes
													className="cursor-pointer"
													onClick={() => {
														let newArr = property?.officeLocations?.filter(
															(j, k) => k !== x
														);
														let newItem = {
															...property,
															officeLocations: newArr,
														};
														setProperty(newItem);
														// setItemForm(prevRows => {
														// 	const newRows = [...prevRows];
														// 	newRows[index].dataArr?.splice(x, 1);
														// 	return newRows;
														// });
													}}
												/>
											</span>
										))}
									</div>
								)}
								<input
									className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
									placeholder="Press Enter to input a new Office Location"
									value={proper?.officeLocations}
									onChange={e =>
										setProp({ ...proper, officeLocations: e.target.value })
									}
									onKeyUp={event =>
										handleInputKeyPress(event, "officeLocations")
									}
								/>
							</div> */}
							<div>
								<label className="mb-1 text-sm md:text-lg f-bold tracking-wide capitalize">
									Other Office Locations
								</label>
								<SearchDropdownSelectCountry
									data={property}
									setRaw={setProperty}
									rawdata={countries?.sort((a, b) => {
										let nameA = a?.name?.common,
											nameB = b?.name?.common;
										var textA = nameA?.toUpperCase();
										var textB = nameB?.toUpperCase();
										return textA < textB ? -1 : textA > textB ? 1 : 0;
									})}
									index={"officeLocations"}
								/>
							</div>
						</div>
						<div>
							<div>
								<label className="mb-1 text-sm md:text-lg f-bold tracking-wide capitalize">
									Locations with paid staff
								</label>
								<SearchDropdownSelectCountry
									data={property}
									setRaw={setProperty}
									rawdata={countries?.sort((a, b) => {
										let nameA = a?.name?.common,
											nameB = b?.name?.common;
										var textA = nameA?.toUpperCase();
										var textB = nameB?.toUpperCase();
										return textA < textB ? -1 : textA > textB ? 1 : 0;
									})}
									index={"locationsPaidStaff"}
								/>
							</div>
						</div>
						{/* <div>
							<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
								Locations with paid staff
							</label>
							<div className="bg-gray-100">
								{property?.locationsPaidStaff?.length > 0 && (
									<div className="flex items-center gap-2 overflow-x-auto noScroll pt-2 px-3">
										{property?.locationsPaidStaff?.map((it, x) => (
											<span
												key={x}
												className="text-xs border border-black rounded-full px-3 py-1 flex items-center justify-between gap-3  w-auto whitespace-nowrap">
												{it}{" "}
												<FaTimes
													className="cursor-pointer"
													onClick={() => {
														let newArr = property?.locationsPaidStaff?.filter(
															(j, k) => k !== x
														);
														let newItem = {
															...property,
															locationsPaidStaff: newArr,
														};
														setProperty(newItem);
													}}
												/>
											</span>
										))}
									</div>
								)}
								<input
									className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
									placeholder="Press Enter to add to the list of paid staff locations"
									value={proper?.locationsPaidStaff}
									onChange={e =>
										setProp({ ...proper, locationsPaidStaff: e.target.value })
									}
									onKeyUp={event =>
										handleInputKeyPress(event, "locationsPaidStaff")
									}
								/>
							</div>
						</div> */}
						<fieldset className="flex flex-col">
							<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
								Contact Person's Name
							</label>{" "}
							<input
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
								type={"text"}
								placeholder="First name and Last name"
								id={"name"}
								name="name"
								onChange={textChange}
								value={data?.name}
							/>
						</fieldset>
						<fieldset className="flex flex-col">
							<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
								Contact Person's Email Address
							</label>{" "}
							<input
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
								type={"email"}
								placeholder="johndoe@mail.com"
								id={"email"}
								name="email"
								onChange={textChange}
								value={data?.email}
							/>
						</fieldset>
						<fieldset className="flex flex-col">
							<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
								Contact Person's LinkedIn URL
							</label>
							<div className="flex gap-2">
								<input
									className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
									type={"url"}
									placeholder="Contact linkedIn link"
									id={"linkedInUrl"}
									name="linkedInUrl"
									onChange={textChange}
									value={data?.linkedInUrl}
								/>
								{/* <div className="p-4 border border-gray-300 rounded-md">
									https://
								</div> */}
							</div>
						</fieldset>
						<fieldset className="flex flex-col">
							<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
								Contact Person's Bio
							</label>{" "}
							<br />
							<small className="b text-gray-400">
								Tell us about your yourself!
							</small>
							<textarea
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
								placeholder="Bio"
								style={{
									resize: "none",
									height: "10rem",
								}}
								id={"bio"}
								name="bio"
								onChange={textChange}
								value={data?.bio}
							/>
						</fieldset>
						<div className="flex gap-8">
							<button
								type="button"
								className="w-full flex justify-center bg-gray-100 hover:brightness-100  text-gray-700 p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
								onClick={() =>
									setSearchParams(searchParams => {
										searchParams.set("type", back);
										return searchParams;
									})
								}>
								Back
							</button>
							{/* <button
									type="submit"
									className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
									onClick={() =>
										setSearchParams(searchParams => {
											searchParams.set("type", route);
											return searchParams;
										})
									}>
									Next
								</button> */}
							<Button
								type="button"
								loading={loading}
								children="Next"
								// eslint-disable-next-line react/style-prop-object
								css="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
								// loadCss="text-bluerolodex"
								onClick={onSubmit}
								style={{ background: workspace?.color || "" }}
							/>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};

export const ExpertiseAndNicheInformation = ({
	route,
	back,
	setSearchParams,
	rawdata,
	rawdata2,
	workspace,
}) => {
	const methods = useForm({
		defaultValues: {
			email: "",
			password: "",
		},
	});
	let [getSearch] = useSearchParams(),
		dispatch = useDispatch(),
		onSubmit = async () => {
			try {
				let newRef = thisRaw?.docs
					?.map(it => {
						if (it?.selection && it?.selection?.length > 0) {
							return {
								referencedRawDataId: it?._id,
								category: it?.category,
								data: it?.selection,
							};
						} else return null;
					})
					?.filter(it => it);

				if (newRef?.length === 0)
					return toast.info("Please select referenced data");

				setLoading(true);
				let body = {
					rawdata: newRef,
				};
				console.log({ body, thisRaw });

				let res = await axios.post(`/api/v1/rawdata`, body, {
					headers: {
						Authorization: getSearch?.get("token"),
					},
				});

				console.log({ resp: res?.data });
				let msg = typeof res?.data === "string" ? res?.data : "";
				toast.success(res?.data?.message || msg);
				setSearchParams(searchParams => {
					searchParams.set("type", route);
					return searchParams;
				});
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				let error = err.response?.data?.error;
				if (error && error?.length > 1) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				} else {
					toast.error(
						err?.response?.data?.message ||
							err?.response?.data?.error?.[0]?.message ||
							err?.message
					);
				}
			}
			setLoading(false);
		};
	let [thisRaw, setRaw] = useState(rawdata),
		[loading, setLoading] = useState(false);

	useEffect(() => {
		if (rawdata || rawdata2) {
			let newRaw = [];
			for (let r = 0; r < rawdata?.docs.length; r++) {
				let element = rawdata?.docs?.[r],
					findRaw = rawdata2?.docs?.find(
						it => it?.referencedRawDataId === element?._id
					);
				if (findRaw) element = { ...element, selection: findRaw?.data };
				newRaw?.push(element);
			}
			setRaw({ ...rawdata, docs: newRaw });
		}
	}, [rawdata, rawdata2]);

	// console.log({ rawdata, thisRaw });

	return (
		<section className="items-center space-y-5 justify-center">
			{/* <div className="fixed inset-0 bg-white"></div> */}
			<div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10 mx-auto">
				<div className="font-medium  text-gray-800 mb-2">
					<h1 className="text-2xl md:text-2xl xl:text-3xl f-bold">
						Expertise & Niche Information
					</h1>
					<p className="text-base md:text-lg lg:text-lg xl:text-xl f-light mt-2">
						You can select up to 10 of the most relevant options in these
						categories:
					</p>
				</div>

				<div className="mt-8 md:mt-4 lg:mt-8">
					<FormProvider {...methods}>
						<form
							onSubmit={methods.handleSubmit(onSubmit)}
							className="space-y-6">
							{thisRaw?.docs?.map((it, i) => (
								<div key={i}>
									<label className="mb-1 text-sm md:text-lg f-bold tracking-wide capitalize">
										{it?.category}
									</label>
									<SearchDropdownSelect
										data={it?.data}
										setRaw={setRaw}
										rawdata={thisRaw}
										index={i}
									/>
								</div>
							))}
							{/* <div>
								<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
									Expertise
								</label>
								<SearchDropdownSelect />
							</div>
							<div>
								<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
									Geography
								</label>
								<SearchDropdownSelect />
							</div>
							<div>
								<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
									Sector
								</label>
								<SearchDropdownSelect />
							</div>
							<div>
								<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
									Target Population
								</label>
								<SearchDropdownSelect />
							</div> */}
							<div className="flex gap-8">
								<button
									type="submit"
									className="w-full flex justify-center bg-gray-100 hover:brightness-100  text-gray-700 p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
									onClick={() =>
										setSearchParams(searchParams => {
											searchParams.set("type", back);
											return searchParams;
										})
									}>
									Back
								</button>
								{/* <button
									type="submit"
									className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
									onClick={() =>
										setSearchParams(searchParams => {
											searchParams.set("type", route);
											return searchParams;
										})
									}>
									Next
								</button> */}
								<Button
									type="submit"
									loading={loading}
									children="Next"
									// eslint-disable-next-line react/style-prop-object
									css="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
									// loadCss="text-bluerolodex"
									// onClick={onSubmit}
									style={{ background: workspace?.color || "" }}
								/>
							</div>
						</form>
					</FormProvider>
				</div>
			</div>
		</section>
	);
};

export const MediaUpload = ({
	route,
	back,
	setSearchParams,
	tabViews,
	modal,
	active,
	handleSmallNav,
	workspace,
	media,
	setMedia,
}) => {
	let [logo, setLogo] = useState(null),
		fileRef = useRef(),
		[loading, setLoading] = useState(null),
		[getSearch] = useSearchParams(),
		dispatch = useDispatch(),
		[data, setData] = useState({}),
		textChange = e => {
			let { name, value } = e?.target;
			setData({ ...data, [name]: value });
		},
		onSubmit = async () => {
			try {
				if (!data?.fileName) {
					return toast.info("Media File name is required");
				}
				if (logo && !data?.description) {
					return toast.info("Media description is required");
				}
				// if (!data?.url) {
				// 	return toast.info("Media url is required");
				// }
				if (!logo && !data?.url)
					return toast.info("File/Media URL is required");
				setLoading(true);

				let body = {
					...data,
					file: logo,
					companyId: getSearch?.get("companyId"),
				};

				if (logo) {
					try {
						// let post = new FormData();
						// post.append(`file`, logo);
						console.log("hi here");
						let resImg = await axios.post(
							`/api/v1/file`,
							{ intendedFile: logo },
							{
								headers: {
									"Content-Type": "multipart/form-data",
									"Authorization": getSearch?.get("token"),
								},
							}
						);

						console.log({ res: resImg?.data });

						body.file = resImg?.data?.data?.[0];
					} catch (err) {
						if (err) console.log({ error: err.response?.data, err });
						if (err?.response?.status === 429) toast.error(err?.response?.data);
						let error = err.response?.data?.error;
						if (error && error?.length > 1) {
							setLoading(false);
							return dispatch(
								returnErrors({ error, status: err?.response?.status })
							);
						} else {
							setLoading(false);
							return toast.error(
								err?.response?.data?.message ||
									err?.response?.data?.error?.[0]?.message ||
									err?.message
							);
						}
					}
					// body.intendedFileName = "file";
					// body.intendedFile = logo;
				}
				body = {
					media: [body],
				};
				let res = await axios.post(`/api/v1/media`, body, {
					headers: {
						Authorization: getSearch?.get("token"),
						// "Content-Type": "multipart/form-data",
					},
				});
				console.log({ resp: res?.data });
				let msg = typeof res?.data === "string" ? res?.data : "";
				toast.success(res?.data?.message || msg);
				setSearchParams(searchParams => {
					searchParams.set("type", route);
					return searchParams;
				});
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				let error = err.response?.data?.error;
				if (error && error?.length > 1) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				} else {
					toast.error(
						err?.response?.data?.message ||
							err?.response?.data?.error?.[0]?.message ||
							err?.message
					);
				}
			}
			setLoading(false);
		},
		[isOpen, setIsOpen] = useState(false),
		toggleIsOpen = () => {
			setIsOpen(!isOpen);
		};

	return (
		<section className="items-center space-y-5 justify-center">
			{/* <div className="fixed inset-0 bg-white"></div> */}
			<div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10 mx-auto">
				<div className="font-medium  text-gray-800 mb-2">
					<h1 className="text-2xl md:text-2xl xl:text-3xl f-bold">
						Media Files
					</h1>
					<p className="text-base md:text-lg lg:text-lg xl:text-xl f-light mt-2">
						Upload relevant media files such as news and other media related to
						your organizations
					</p>
					<p className="f-light mt-2 italic">
						File size should not be more than 2MB
					</p>
				</div>

				<div className="mt-8 md:mt-4 lg:mt-8">
					<form className="space-y-6">
						<div>
							<div
								style={{ color: workspace?.color || "" }}
								className="flex items-center justify-center gap-4 text-bluerolodex bg-gray-100 py-4 rounded-lg cursor-pointer"
								onClick={toggleIsOpen}>
								<span>
									<TbUpload />
								</span>
								<p>Add Media Files</p>
							</div>
						</div>
						<div className="flex gap-8">
							<button
								type="button"
								className="w-full flex justify-center bg-gray-100 hover:brightness-100  text-gray-700 p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
								onClick={() =>
									setSearchParams(searchParams => {
										searchParams.set("type", back);
										return searchParams;
									})
								}>
								Back
							</button>
							<button
								type="button"
								className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
								style={{ background: workspace?.color || "" }}
								onClick={() =>
									setSearchParams(searchParams => {
										searchParams.set("type", route);
										return searchParams;
									})
								}>
								Next
							</button>
						</div>
					</form>
				</div>
			</div>
			<TableMediaUseCase datum={media} setDatum={setMedia} type="media" />
			<SuccessModal show={isOpen} close={toggleIsOpen}>
				<div className="py-6">
					<div className="flex items-center justify-between">
						<article className="w-full">
							<p className="f-bold text-2xl">{modal.title}</p>
							{/* <p className="text-gray-700 f-light text-lg">{modal.subtitle}</p> */}
						</article>
						<span>
							<AiOutlineClose />
						</span>
					</div>
					<SmallNav
						menu={tabViews}
						active={active}
						handleNav={handleSmallNav}
						full
						theme={workspace?.color}
					/>
					<div className="my-4">
						{active === tabViews[0] && (
							<UploadFile
								toggleModal={toggleIsOpen}
								logo={logo}
								fileRef={fileRef}
								setLogo={setLogo}
								textChange={textChange}
								data={data}
								type="media"
								style={{ background: workspace?.color || "" }}
							/>
						)}
						{active === tabViews[1] && (
							<AddFileLink
								toggleModal={toggleIsOpen}
								textChange={textChange}
								data={data}
								type="media"
								style={{ background: workspace?.color || "" }}
							/>
						)}
						<div className="flex gap-8 mt-6">
							<button
								type="submit"
								className="w-full flex justify-center border border-bluerolodex hover:brightness-100  text-gray-700 p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
								style={{ borderColor: workspace?.color || "" }}
								onClick={toggleIsOpen}>
								Cancel
							</button>
							{/* <button
								type="submit"
								className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
								onClick={() => {}}>
								Save
							</button> */}
							<Button
								type="submit"
								loading={loading}
								children="Save"
								// eslint-disable-next-line react/style-prop-object
								css="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
								// loadCss="text-bluerolodex"
								onClick={onSubmit}
								style={{ background: workspace?.color || "" }}
							/>
						</div>
					</div>
				</div>
			</SuccessModal>
		</section>
	);
};

export const UseCases = ({
	route,
	back,
	setSearchParams,
	tabViews,
	active,
	handleSmallNav,
	workspace,
	usecases,
	setUseCases,
}) => {
	const [property, setProperty] = useState(null),
		[proper, setProp] = useState(null);

	const handleInputKeyPress = (e, field) => {
			let emailInput = proper[field];
			if (e.key === "Enter" && emailInput.trim() !== "") {
				setProperty(prevRows => {
					const newRows = { ...prevRows };
					// console.log({ e: e.key });
					newRows[field] = Array.isArray(newRows[field])
						? [...newRows[field], emailInput.trim()]
						: [emailInput.trim()];
					return newRows;
				});
				setProp({
					...proper,
					[field]: "",
				});
			}
		},
		[modal] = useState({
			title: "Add usecase file",
			subtitle:
				"import contacts from an excel or csv file. Use sample file as guide",
		});

	let [logo, setLogo] = useState(null),
		fileRef = useRef(),
		[loading, setLoading] = useState(null),
		[getSearch] = useSearchParams(),
		dispatch = useDispatch(),
		[data, setData] = useState({}),
		textChange = e => {
			let { name, value } = e?.target;
			setData({ ...data, [name]: value });
		},
		onSubmit = async () => {
			try {
				// if (!data?.fileName) {
				// 	return toast.info("Usecase File name is required");
				// }
				if (!data?.completionDate) {
					return toast.info("Usecase completion date is required");
				}
				// if (!data?.url) {
				// 	return toast.info("Usecase url is required");
				// }
				if (!property?.tags) {
					return toast.info("Usecase tags is required");
				}
				if (!logo && !data?.url)
					return toast.info("File/Usecase url is required");
				setLoading(true);

				let body = {
					...data,
					file: logo,
					companyId: getSearch?.get("companyId"),
					tags: property?.tags,
					completionDate: moment(data?.completionDate).format("YYYY-MM-DD"),
				};

				if (logo) {
					try {
						// let post = new FormData();
						// post.append(`file`, logo);

						let resImg = await axios.post(
							`/api/v1/file`,
							{ intendedFile: logo },
							{
								headers: {
									"Content-Type": "multipart/form-data",
									"Authorization": getSearch?.get("token"),
								},
							}
						);
						console.log({ resImg: resImg?.data });
						body.file = resImg?.data?.data;
					} catch (err) {
						if (err) console.log({ error: err.response?.data, err });
						if (err?.response?.status === 429) toast.error(err?.response?.data);
						let error = err.response?.data?.error;
						if (error && error?.length > 1) {
							setLoading(false);
							return dispatch(
								returnErrors({ error, status: err?.response?.status })
							);
						} else {
							setLoading(false);
							return toast.error(
								err?.response?.data?.message ||
									err?.response?.data?.error?.[0]?.message ||
									err?.message
							);
						}
					}
					// body.intendedFileName = "file";
					// body.intendedFile = logo;
				}

				body = {
					usecases: [body],
				};
				let res = await axios.post(`/api/v1/usecases`, body, {
					headers: {
						Authorization: getSearch?.get("token"),
						// "Content-Type": "multipart/form-data",
					},
				});
				console.log({ resp: res?.data });
				let msg = typeof res?.data === "string" ? res?.data : "";
				toast.success(res?.data?.message || msg);
				setSearchParams(searchParams => {
					searchParams.set("type", route);
					return searchParams;
				});
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				let error = err.response?.data?.error;
				if (error && error?.length > 1) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				} else {
					toast.error(
						err?.response?.data?.message ||
							err?.response?.data?.error?.[0]?.message ||
							err?.message
					);
				}
			}
			setLoading(false);
		},
		[isOpen, setIsOpen] = useState(false),
		toggleIsOpen = () => {
			setIsOpen(!isOpen);
		};

	return (
		<section className="items-center space-y-5 justify-center">
			{/* <div className="fixed inset-0 bg-white"></div> */}
			<div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10 mx-auto">
				<div className="font-medium  text-gray-800 mb-2">
					<h1 className="text-2xl md:text-2xl xl:text-3xl f-bold">Use Cases</h1>
					<p className="text-base md:text-lg lg:text-lg xl:text-xl f-light mt-2">
						Upload use cases related to your organization.
					</p>
				</div>

				<div className="mt-8 md:mt-4 lg:mt-8">
					<form className="space-y-6">
						<div>
							<div
								style={{ color: workspace?.color || "" }}
								className="flex items-center justify-center gap-4 text-bluerolodex bg-gray-100 py-4 rounded-lg cursor-pointer"
								onClick={toggleIsOpen}>
								<span>
									<TbUpload />
								</span>
								<p>Add Use Cases</p>
							</div>
						</div>
						<div className="flex gap-8">
							<button
								type="button"
								className="w-full flex justify-center bg-gray-100 hover:brightness-100  text-gray-700 p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
								onClick={() =>
									setSearchParams(searchParams => {
										searchParams.set("type", back);
										return searchParams;
									})
								}>
								Back
							</button>
							<button
								type="button"
								style={{ background: workspace?.color || "" }}
								className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
								onClick={() =>
									setSearchParams(searchParams => {
										searchParams.set("type", route);
										return searchParams;
									})
								}>
								Next
							</button>
						</div>
					</form>
				</div>
			</div>
			<TableMediaUseCase
				datum={usecases}
				setDatum={setUseCases}
				type="usecases"
			/>
			<SuccessModal show={isOpen} close={toggleIsOpen}>
				<div className="py-6">
					<div className="flex items-center justify-between">
						<article className="w-full">
							<p className="f-bold text-2xl">{modal.title}</p>
							{/* <p className="text-gray-700 f-light text-lg">{modal.subtitle}</p> */}
						</article>
						<span>
							<AiOutlineClose />
						</span>
					</div>
					<SmallNav
						menu={tabViews}
						active={active}
						handleNav={handleSmallNav}
						full
						theme={workspace?.color}
					/>
					<div className="my-4">
						{active === tabViews[0] && (
							<UploadFile
								toggleModal={toggleIsOpen}
								logo={logo}
								fileRef={fileRef}
								setLogo={setLogo}
								textChange={textChange}
								data={data}
								type={"usecase"}
								style={{ background: workspace?.color || "" }}
								handleInputKeyPress={handleInputKeyPress}
								proper={proper}
								property={property}
								setProp={setProp}
								setProperty={setProperty}
								textTitle="Select a PDF file to upload. File size should not be more than 2mb"
							/>
						)}
						{active === tabViews[1] && (
							<AddFileLink
								type={"usecase"}
								toggleModal={toggleIsOpen}
								textChange={textChange}
								data={data}
								handleInputKeyPress={handleInputKeyPress}
								proper={proper}
								property={property}
								setProp={setProp}
								setProperty={setProperty}
								style={{ background: workspace?.color || "" }}
							/>
						)}
						<div className="flex gap-8 mt-6">
							<button
								type="submit"
								className="w-full flex justify-center border border-bluerolodex hover:brightness-100  text-gray-700 p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
								style={{ borderColor: workspace?.color || "" }}
								onClick={toggleIsOpen}>
								Cancel
							</button>
							{/* <button
								type="submit"
								className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
								onClick={() => {}}>
								Save
							</button> */}
							<Button
								type="submit"
								loading={loading}
								children="Save"
								// eslint-disable-next-line react/style-prop-object
								css="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light cursor-pointer transition ease-in duration-500"
								// loadCss="text-bluerolodex"
								style={{ background: workspace?.color || "" }}
								onClick={onSubmit}
							/>
						</div>
					</div>
				</div>
			</SuccessModal>
		</section>
	);
};

export const UploadFile = ({
	logo,
	data,
	textChange,
	setLogo,
	type,
	proper,
	property,
	handleInputKeyPress,
	setProperty,
	setProp,
	textTitle = "",
}) => {
	let fileRef = useRef();

	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!textTitle)
			if (!file.type.includes("image"))
				return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			// setLogo(file);

			setLogo(type === "usecase" && textTitle ? e?.target?.files : file);
		}
	};
	console.log({ logo });
	return (
		<div>
			<div>
				<div
					onClick={() => fileRef?.current?.click()}
					className="text-center rounded-lg border border-dashed border-gray-200 bg-[#9dc8f50a] p-8 space-y-2 cursor-pointer">
					{logo ? (
						textTitle ? (
							<div>
								<img
									src={require("../../assets/file-icon.png")}
									alt=""
									className="mx-auto mb-2"
								/>
								<p className="f-bold">
									{Array.from(logo)?.map?.((ic, a) => (
										<span key={a}>{ic?.name},</span>
									)) ||
										textTitle ||
										`Select a PNG or JPG file to upload. File size should not be more than 2mb
`}
								</p>
							</div>
						) : (
							<>
								<img
									src={URL.createObjectURL(logo)}
									alt=""
									className="mx-auto mb-2 max-h-40 object-contain"
								/>
							</>
						)
					) : (
						<div>
							<img
								src={require("../../assets/file-icon.png")}
								alt=""
								className="mx-auto mb-2"
							/>
							<p className="f-bold">
								{textTitle || `Select a PNG or JPG file to upload`}
							</p>
							<p className="c text-gray-500">or drag and drop it here</p>
						</div>
					)}
					<input
						ref={fileRef}
						type="file"
						style={{ width: "0" }}
						className="absolute -z-10"
						onChange={handleChangeImage}
						multiple={type === "usecase" && textTitle}
					/>
				</div>
				<input
					className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none hidden"
					placeholder="Enter year founded"
				/>
			</div>
			{type === "media" && (
				<div className="mt-6">
					<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
						Description
					</label>
					<textarea
						className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
						placeholder="Description"
						value={data?.description}
						onChange={textChange}
						name="description"
						style={{
							resize: "none",
							height: "10rem",
						}}
					/>
				</div>
			)}
			{/* {type === "usecase" && (
				<>
					<div className="mt-6">
						<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
							File url
						</label>
						<input
							className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
							placeholder="Enter link here"
							value={data?.url}
							onChange={textChange}
							name="url"
							type="url"
						/>
					</div>
				</>
			)} */}
			<div className="mt-6">
				<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
					Save file as
				</label>
				<input
					className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
					placeholder="What do you want this file to be saved as"
					value={data?.fileName}
					onChange={textChange}
					name="fileName"
				/>
			</div>
			<UsecaseUpload
				type={type}
				data={data}
				textChange={textChange}
				proper={proper}
				property={property}
				setProp={setProp}
				setProperty={setProperty}
				handleInputKeyPress={handleInputKeyPress}
			/>
		</div>
	);
};

export const AddFileLink = ({
	data,
	textChange,
	type,
	proper,
	property,
	handleInputKeyPress,
	setProperty,
	setProp,
}) => {
	return (
		<div>
			<div className="mt-6">
				<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
					File url
				</label>
				<input
					className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
					placeholder="Enter link here"
					value={data?.url}
					onChange={textChange}
					name="url"
					type="url"
				/>
			</div>
			<div className="mt-6">
				<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
					Save file as
				</label>
				<input
					className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
					placeholder="What do you want this file to be saved as"
					value={data?.fileName}
					onChange={textChange}
					name="fileName"
				/>
			</div>
			<UsecaseUpload
				type={type}
				data={data}
				textChange={textChange}
				proper={proper}
				property={property}
				setProp={setProp}
				setProperty={setProperty}
				handleInputKeyPress={handleInputKeyPress}
			/>
		</div>
	);
};

export const UsecaseUpload = ({
	type,
	data,
	textChange,
	proper,
	property,
	setProp,
	setProperty,
	handleInputKeyPress,
}) => {
	return (
		<>
			{type === "usecase" && (
				<>
					<div className="mt-6">
						<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
							Completion Date
						</label>
						<input
							className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
							placeholder="What do you want this file to be saved as"
							value={data?.completionDate}
							onChange={textChange}
							name="completionDate"
							type="date"
							// min={moment().format("YYYY-MM-DD")}
						/>
					</div>
					<div>
						<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
							Add Tags
						</label>
						{/* <SearchDropdownSelect /> */}
						<div className="bg-gray-100">
							{property?.tags?.length > 0 && (
								<div className="flex items-center gap-2 overflow-x-auto noScroll pt-2 px-3">
									{property?.tags?.map((it, x) => (
										<span
											key={x}
											className="text-xs border border-black rounded-full px-3 py-1 flex items-center justify-between gap-3  w-auto whitespace-nowrap">
											{it}{" "}
											<FaTimes
												className="cursor-pointer"
												onClick={() => {
													let newArr = property?.tags?.filter(
														(j, k) => k !== x
													);
													let newItem = {
														...property,
														tags: newArr,
													};
													setProperty(newItem);
													// setItemForm(prevRows => {
													// 	const newRows = [...prevRows];
													// 	newRows[index].dataArr?.splice(x, 1);
													// 	return newRows;
													// });
												}}
											/>
										</span>
									))}
								</div>
							)}
							<input
								className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
								placeholder="Press Enter after each tag"
								value={proper?.tags}
								onChange={e => setProp({ ...proper, tags: e.target.value })}
								onKeyUp={event => handleInputKeyPress(event, "tags")}
							/>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export const SuccessPage = ({ workspace }) => {
	let navigate = useNavigate();
	return (
		<section className="min-h-screen flex flex-col items-center space-y-5 justify-center">
			<div
				className="fixed inset-0  bg-mainDark"
				style={{ background: workspace?.color || "" }}></div>

			<div className="flex flex-col px-5 md:px-8 py-8 rounded-md bg-white w-10/12 lg:w-full max-w-lg relative z-10">
				<div>
					<img
						src={require("../../assets/success-gif.gif")}
						alt=""
						className="mx-auto"
					/>
				</div>
				<h1 className="text-2xl md:text-2xl xl:text-3xl font-bold text-center">
					Awesome!
				</h1>
				<p className="p-0.5 px-2 text-center mb-8 max-w-sm mx-auto text-gray-600">
					Your account is now active, and you can access your workspace.
				</p>
				<button
					onClick={() => {
						navigate("/verify/success", { state: workspace });
					}}
					className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
					style={{ background: workspace?.color || "" }}>
					Get Started
				</button>
			</div>
		</section>
	);
};

export const Password = ({
	route,
	back,
	setSearchParams,
	workspace,
	firstStep,
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const dispatch = useDispatch(),
		[getSearch] = useSearchParams();
	const methods = useForm({
			defaultValues: {
				email: "",
				password: "",
			},
		}),
		tabViews = ["Privacy Policy", "Password"],
		[active, setActive] = useState(tabViews[0]);

	let [loading, setLoading] = useState(null);

	useEffect(() => {
		if (workspace) if (!workspace?.privacyPolicy) setActive(tabViews[1]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [workspace]);

	const onSubmit = async data => {
		console.log(data);
		setLoading(true);
		try {
			let res = await axios.put(
				`/api/v1/user/setup-password`,
				{
					...data,
					email: getSearch?.get("email"),
				},
				{
					headers: {
						Authorization: getSearch?.get("token"),
					},
				}
			);
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			setSearchParams(searchParams => {
				searchParams.set("type", route);
				return searchParams;
			});
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
		setLoading(false);
	};

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<section className="items-center space-y-5 justify-center">
			{/* <div className="fixed inset-0 bg-white"></div> */}
			<div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10 mx-auto h-screen">
				<div className="mt-4 md:mt-4 lg:mt-8 h-full">
					{active === "Password" && (
						<FormProvider {...methods}>
							<form onSubmit={methods.handleSubmit(onSubmit)}>
								{/* Password*/}
								<Controller
									name="password"
									control={methods.control}
									rules={{
										required: "This field is required",
										pattern: {
											value:
												/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
											message:
												"Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
										},
									}}
									render={({ field: { onChange, name } }) => (
										<fieldset className="flex flex-col mb-6">
											<div className="flex items-center justify-between">
												<label
													className="mb-1 text-sm md:text-lg f-bold tracking-wide"
													htmlFor="password">
													Password
												</label>
											</div>
											<div className="relative">
												<input
													className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
													name={name}
													id={name}
													onChange={onChange}
													placeholder="Password"
													type={showPassword ? "text" : "password"}
												/>
												<div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
													{showPassword ? (
														<IconEye onClick={handleShowPassword} />
													) : (
														<IconEyeClosed onClick={handleShowPassword} />
													)}
												</div>
											</div>
											{methods.formState.errors.password && (
												<span className="text-xs text-red-600">
													{methods.formState.errors.password.message}
												</span>
											)}
										</fieldset>
									)}
								/>

								{/* <button
								type="submit"
								className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light  shadow-lg cursor-pointer transition ease-in duration-500">
								Create Profile
							</button> */}
								<Button
									type="submit"
									loading={loading}
									children={
										firstStep?.password ? "Update Profile" : "Create Profile"
									}
									// eslint-disable-next-line react/style-prop-object
									css="w-full flex justify-center mt-5 bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light  shadow-lg cursor-pointer transition ease-in duration-500"
									style={{ background: workspace?.color || "" }}
								/>
							</form>
						</FormProvider>
					)}
					{active === "Privacy Policy" && (
						<div className="h-full">
							<p className="font-semibold text-lg md:text-2xl capitalize">
								{workspace?.workspaceName || ""} Privacy Policy
							</p>
							<p className="f-light text-xs md:text-lg mb-2">
								Kindly accept the terms and condition to continue
							</p>
							<div className="tw-space-y-4 h-4/5 overflow-y-auto p-2">
								<div
									className=" tw-text-main manrope lg:tw-text-xl tw-text-base tw-font-normal tw-leading-8 text-justify"
									dangerouslySetInnerHTML={createMarkup(
										workspace?.privacyPolicy
									)}
								/>
							</div>
							<Button
								type="button"
								onClick={() => setActive(tabViews?.[1])}
								children="I Agree"
								// eslint-disable-next-line react/style-prop-object
								css="w-full flex justify-center mt-5 bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light  shadow-lg cursor-pointer transition ease-in duration-500"
								style={{ background: workspace?.color || "" }}
							/>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export const Experience = ({
	route,
	back,
	setSearchParams,
	workspace,
	experience,
}) => {
	const dispatch = useDispatch(),
		[getSearch] = useSearchParams(),
		tabViews = ["Question", "Donor List", "Workspace Question"],
		[data, setData] = useState({}),
		[active, setActive] = useState(tabViews[0]);

	let [loading, setLoading] = useState(null),
		[formInfo, setFormInfo] = useState(null),
		navigate = useNavigate();

	let init2 = {};
	const [itemForm, setItemForm] = useState([init2]);
	const handleInputChangeForMutipleItem = (event, index, field) => {
			const { value } = event.target;
			let itemValue = value;

			setItemForm(prevRows => {
				const newRows = [...prevRows];
				newRows[index][field] = itemValue;
				return newRows;
			});
		},
		textChange = e => {
			let { value, name } = e?.target;
			setData(prevRows => {
				const newRows = { ...prevRows };
				newRows[name] = value;
				return newRows;
			});
		};
	console.log({ experience });
	const handleDeleteRowForMutipleItem = index => {
		setItemForm(prevRows => {
			const newRows = [...prevRows];
			newRows.splice(index, 1);
			return newRows;
		});
	};
	const addRowForMutipleItem = () => {
		const newRow = init2;
		setItemForm([...itemForm, newRow]);
	};

	useEffect(() => {
		if (experience && experience?.docs?.length > 0) {
			if (experience?.docs?.[0]?.type === "Yes") setActive(tabViews[1]);
			setData(experience?.docs?.[0]);
			if (experience?.docs?.[0]?.donorList)
				setItemForm(experience?.docs?.[0]?.donorList);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [experience]);

	useEffect(() => {
		if (experience || workspace) {
			let findRef = experience?.docs?.[0];
			setFormInfo(workspace?.questions);
			if (workspace) {
				let mappedSection = [];

				for (let s = 0; s < workspace?.questions.length; s++) {
					const element = workspace?.questions[s];
					let findOther = findRef?.questions?.find(
						it => it?.queId === element?.queId
					);
					if (findOther) {
						let newSubTitle = [];
						for (let ts = 0; ts < element?.subTitle.length; ts++) {
							const elementSub = element?.subTitle[ts];
							let findOtherSub = findOther?.subTitle?.find(
								it => it?.kipId === elementSub?.kipId
							);
							if (findOtherSub) {
								newSubTitle?.push({
									...findOtherSub,
									kpiname: elementSub?.kpiname,
								});
							} else {
								newSubTitle?.push(elementSub);
							}
						}
						mappedSection?.push({
							...findOther,
							subTitle: newSubTitle,
							question: element?.question,
						});
					} else {
						mappedSection?.push(element);
					}
				}
				// console.log({
				// 	mappedSection,
				// 	main: findDept?.sections,
				// 	review: findRef?.sections,
				// });
				setFormInfo(mappedSection);
			}
		}
	}, [workspace, experience]);

	const onSubmit = async () => {
		console.log(data);
		if (!data?.type) return;
		if (data?.type === "Yes") {
			if (itemForm?.length === 0) return toast.info("Donor(s) required");
			if (itemForm?.length === 1)
				if (!itemForm?.[0]?.donorName || !itemForm?.[0]?.noOfYears)
					return toast.info("Donor(s) required");
		}
		if (active === "Workspace Question") {
			let errArr = [];

			if (formInfo?.length === 0) return toast.info("Questions required");

			for (let e = 0; e < formInfo.length; e++) {
				let element = formInfo?.[e];
				if (element?.required)
					if (!element?.quesAnswer)
						errArr.push({
							message: `Question ${element?.question} answer is required`,
							path: "quesAnswer",
						});
			}

			// console.log({ returnedData });
			if (errArr?.length > 0) return dispatch(returnErrors({ error: errArr }));
		}
		setLoading(data?.type);
		try {
			let newD = data;
			if (data?.type === "Yes") {
				newD.donorList = itemForm;
				for (let q = 0; q < OtherQuestionArr.length; q++) {
					const element = OtherQuestionArr[q];
					if (data?.[element?.name])
						newD[element?.name] = data?.[element?.name];
				}
			}
			if (formInfo && Array.isArray(formInfo) && formInfo?.length > 0)
				newD.questions = formInfo;

			let res = await axios.post(
				`/api/v1/experience`,
				{
					...newD,
				},
				{
					headers: {
						Authorization: getSearch?.get("token"),
					},
				}
			);
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			if (active === "Workspace Question") {
				// setSearchParams(searchParams => {
				// 	searchParams.set("type", route);
				// 	return searchParams;
				// });
				navigate("/verify/success", { state: workspace });
			} else if (data?.type !== "Yes" && workspace?.questions?.length > 0) {
				setActive("Workspace Question");
			} else if (data?.type === "Yes" && workspace?.questions?.length > 0) {
				setActive(active === "Question" ? "Donor List" : "Workspace Question");
			} else if (active === "Donor List" && workspace?.questions?.length > 0) {
				setActive("Workspace Question");
			} else {
				navigate("/verify/success", { state: workspace });
				// setSearchParams(searchParams => {
				// 	searchParams.set("type", route);
				// 	return searchParams;
				// });
			}
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
		setLoading(false);
	};

	// const handleKpi = (event, formIndex, kpiIndex) => {
	// 	const newInputs = [...formInfo];
	// 	let { name, value } = event.target;
	// 	// newInputs[formIndex].subTitle[kpiIndex][name] = value;
	// 	let clonedObject = { ...newInputs[formIndex].subTitle[kpiIndex] };
	// 	clonedObject = { ...clonedObject, [name]: value };

	// 	let arr1 = [
	// 		...newInputs[formIndex].subTitle?.map((it, i) =>
	// 			i === kpiIndex ? clonedObject : it
	// 		),
	// 	];

	// 	let arr2 = [
	// 		...newInputs?.map((it, i) =>
	// 			i === formIndex
	// 				? {
	// 						...it,
	// 						subTitle: arr1,
	// 				  }
	// 				: it
	// 		),
	// 	];
	// 	setFormInfo(arr2);
	// };

	const handleFormTitle = (event, formIndex) => {
		const newFormInput = [...formInfo];
		let { name, value, type, checked, title } = event.target;
		if (["checkbox", "radio"]?.includes(type)) {
			if (["checkbox"]?.includes(type)) {
				let thisArr = newFormInput[formIndex][name] || [];
				if (checked) {
					thisArr = [...new Set([...thisArr, title])];
				} else {
					thisArr = thisArr?.filter(ic => ic !== title);
				}

				newFormInput[formIndex][name] = thisArr;
			} else newFormInput[formIndex][name] = title;
		}
		if (!["checkbox", "radio"]?.includes(type))
			newFormInput[formIndex][name] = value;
		setFormInfo(newFormInput);
	};

	console.log({ formInfo });

	return (
		<section className="items-center space-y-5 justify-center">
			{/* <div className="fixed inset-0 bg-white"></div> */}
			<div className="flex flex-col px-5 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10 mx-auto h-screen">
				<p className="font-semibold md:text-md italic text-gray-600">
					The information you are about to provide is only visible to the system
					owner (The Geospatial Insight Support Team) of the Foundation.
				</p>
				<p className="font-semibold md:text-md italic text-gray-600">
					All information are optional and confidential.
				</p>
				<p className="font-semibold md:text-md italic pt-3 text-gray-600">
					These questions help the Foundation to verify the projects or
					capabilities of your organization.
				</p>
				<div className="mt-4 md:mt-4 lg:mt-8 h-full">
					{active === "Donor List" && (
						<>
							<p className="font-semibold md:text-md italic pt-3">
								Add donor organizations you have worked with in the past.
							</p>
							<form className="space-y-6 pb-5 md:pb-10">
								<div className="relative grid md:grid-cols-4 gap-4">
									<label className="mb-1 text-sm md:text-md f-bold tracking-wide capitalize">
										donor organization name
									</label>
									<label className="mb-1 text-sm md:text-md f-bold tracking-wide">
										Number of Years
									</label>
									<label className="mb-1 text-sm md:text-md f-bold tracking-wide">
										Project Description
									</label>
								</div>
								{itemForm?.map((item, index) => (
									<>
										<div className="relative grid md:grid-cols-4 gap-4">
											<div>
												<input
													className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none placeholder:text-sm placeholder:italic"
													placeholder="Donor Name"
													value={item?.donorName}
													onChange={event =>
														handleInputChangeForMutipleItem(
															event,
															index,
															"donorName"
														)
													}
												/>
											</div>
											<div>
												<input
													className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none placeholder:text-sm placeholder:italic"
													placeholder="Number of Years"
													value={item?.noOfYears}
													onChange={event =>
														handleInputChangeForMutipleItem(
															event,
															index,
															"noOfYears"
														)
													}
													type="number"
												/>
											</div>
											<div className="col-span-2">
												<textarea
													className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none placeholder:text-sm placeholder:italic"
													placeholder="Project Description"
													value={item?.projectDescription}
													onChange={event =>
														handleInputChangeForMutipleItem(
															event,
															index,
															"projectDescription"
														)
													}
													style={{
														height: "10rem",
														resize: "none",
													}}
												/>
											</div>
											<div
												onClick={() => handleDeleteRowForMutipleItem(index)}
												className="md:absolute self-center -right-20 cursor-pointer">
												<p className="text-sm text-red-600 flex items-center md:mt-2 gap-2">
													<span>
														<MdDelete />
													</span>
													Remove
												</p>
											</div>
										</div>
									</>
								))}
								<p
									onClick={addRowForMutipleItem}
									style={{
										color: workspace?.color || "",
									}}
									className="flex items-center gap-2 text-bluerolodex f-medium my-3 cursor-pointer">
									<span>
										<IoIosAdd />
									</span>
									Add donor
								</p>
								{OtherQuestionArr?.map((ite, i) => (
									<fieldset key={i} className="flex flex-col">
										<label className="text-sm md:text-lg f-bold tracking-wide">
											{ite?.question}
										</label>
										{ite?.subquestion && (
											<>
												<small className="b text-gray-400 block">
													{ite?.subquestion}
												</small>
											</>
										)}
										<textarea
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none placeholder:text-sm placeholder:italic"
											placeholder={ite?.placeholder || ite?.question}
											row={6}
											style={{
												resize: "none",
												height: "10rem",
											}}
											id={ite?.name}
											name={ite?.name}
											onChange={textChange}
											value={data?.[ite?.name]}
										/>
									</fieldset>
								))}
								<div className="mt-4 flex items-center gap-3">
									<Button
										children={"Continue"}
										buttonType={"primary"}
										onClick={onSubmit}
										loading={loading === "Yes"}
										style={{ background: workspace?.color || "" }}
									/>
									{workspace?.questions?.length > 0 && (
										<Button
											children={"Skip"}
											buttonType={"gray"}
											onClick={() => setActive("Workspace Question")}
										/>
									)}
								</div>
							</form>
						</>
					)}
					{active === "Question" && (
						<div className="h-full">
							<p className="font-semibold text-lg md:text-2xl">
								Have you worked with a donor?
							</p>
							<div className="flex gap-8">
								<Button
									type="button"
									onClick={() => {
										setData({ ...data, type: "Yes" });
										setActive(tabViews?.[1]);
									}}
									children="Yes"
									// eslint-disable-next-line react/style-prop-object
									css="w-full flex justify-center mt-5 bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light  shadow-lg cursor-pointer transition ease-in duration-500"
									style={{ background: workspace?.color || "" }}
									loading={loading === "Yes"}
								/>
								<Button
									type="button"
									loading={loading === "Not Applicable"}
									children="No"
									// eslint-disable-next-line react/style-prop-object
									css="bg-gray-100 text-gray-700 w-full flex justify-center mt-5 hover:brightness-100  p-4  rounded-lg tracking-wide f-light  shadow-lg cursor-pointer transition ease-in duration-500"
									// loadCss="text-bluerolodex"
									// onClick={onSubmit}
									// style={{ background: workspace?.color || "" }}
									onClick={e => {
										e?.preventDefault();
										setData({ ...data, type: "Not Applicable" });
										setTimeout(() => {
											onSubmit();
										}, 500);
									}}
									loadCss="black"
								/>
							</div>
						</div>
					)}
					{active === "Workspace Question" && (
						<div className="pb-5 md:pb-10">
							<QuestionaireComponent
								formInfo={formInfo}
								handleFormTitle={handleFormTitle}
							/>
							<div className="mt-4 flex items-center gap-3">
								<Button
									children={"Continue"}
									buttonType={"primary"}
									onClick={onSubmit}
									loading={loading === "Yes"}
									style={{ background: workspace?.color || "" }}
								/>
								{workspace?.questions?.length > 0 && (
									<Button
										children={"Back"}
										buttonType={"gray"}
										onClick={() => setActive("Donor List")}
									/>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export const QuestionaireComponent = ({
	formInfo,
	handleFormTitle,
	readOnly,
}) => {
	return (
		<>
			{formInfo?.map((each, formIndex) => (
				<div key={formIndex} className="mt-8 shadow-md pl-4 pb-4">
					<div className=" flex justify-between">
						<span>
							Question
							<span className="text-red-900">{each?.required ? "*" : ""}</span>
						</span>
					</div>
					<div className="flex justify-between flex-col">
						<div className="w-full">
							<div className="flex px-3 py-1 bg-[#F9FFFF] w-full font-bold items-center">
								<p className="mr-1">{formIndex + 1}.</p>
								<input
									type="text"
									name="question"
									placeholder={`Question ${formIndex + 1}`}
									className="border-none outline-none w-full placeholder:text-sm placeholder:italic"
									readOnly
									value={each?.question}
								/>
							</div>
						</div>
					</div>
					{AnswerTypeArr?.find(ix => ix?.type === each?.answerType)
						?.writeableAdmin ? (
						<div className="pl-5">
							<>
								<div className="w-full my-1 pe-5">
									{AnswerTypeArr?.find(ix => ix?.type === each?.answerType)
										?.type === "radio" ? (
										<>
											{each.subTitle?.map((item, kpiIndex) => (
												<>
													<div className="flex gap-5 items-center ">
														<input
															name="quesAnswer"
															type="radio"
															className="mt-1 border-[#CCCCCC] text-center placeholder:text-sm placeholder:italic"
															onChange={event =>
																handleFormTitle(event, formIndex)
															}
															checked={item?.kipId === each?.quesAnswer}
															id={each?.queId}
															title={item?.kipId}
															readOnly={readOnly}
														/>
														<label htmlFor={each?.queId}>{item?.kpiname}</label>
													</div>
												</>
											))}
										</>
									) : AnswerTypeArr?.find(ix => ix?.type === each?.answerType)
											?.type === "checkbox" ? (
										<>
											{each.subTitle?.map((item, kpiIndex) => (
												<>
													<div className="flex gap-5 items-center ">
														<input
															name="quesAnswer"
															type="checkbox"
															className="mt-1 border-[#CCCCCC] text-center placeholder:text-sm placeholder:italic"
															onChange={event =>
																handleFormTitle(event, formIndex)
															}
															checked={each?.quesAnswer?.includes(item?.kipId)}
															id={each?.queId}
															title={item?.kipId}
															readOnly={readOnly}
														/>
														<label htmlFor={each?.queId}>{item?.kpiname}</label>
													</div>
												</>
											))}
										</>
									) : (
										<>
											<select
												className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none placeholder:text-sm placeholder:italic"
												id={"quesAnswer"}
												readOnly={readOnly}
												name="quesAnswer"
												onChange={event => handleFormTitle(event, formIndex)}>
												{each.subTitle?.map((item, kpiIndex) => (
													<option value={item?.kipId} key={kpiIndex}>
														{item?.kpiname}
													</option>
												))}
											</select>
										</>
									)}
								</div>
							</>
						</div>
					) : (
						<div className="pl-5">
							<div className="w-full my-1 pe-5">
								{AnswerTypeArr?.find(ix => ix?.type === each?.answerType)
									?.type === "text" ? (
									<>
										<fieldset className="flex flex-col">
											<input
												className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none placeholder:text-sm placeholder:italic"
												type={"text"}
												placeholder="John Doe"
												id={"quesAnswer"}
												name="quesAnswer"
												onChange={event => handleFormTitle(event, formIndex)}
												value={each?.quesAnswer}
												readOnly={readOnly}
											/>
										</fieldset>
									</>
								) : AnswerTypeArr?.find(ix => ix?.type === each?.answerType)
										?.type === "textarea" ? (
									<>
										<fieldset className="flex flex-col">
											<textarea
												className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none placeholder:text-sm placeholder:italic"
												placeholder=""
												style={{
													resize: "none",
													height: "10rem",
												}}
												id={"quesAnswer"}
												name="quesAnswer"
												onChange={event => handleFormTitle(event, formIndex)}
												value={each?.quesAnswer}
												readOnly={readOnly}
											/>
										</fieldset>
									</>
								) : AnswerTypeArr?.find(ix => ix?.type === each?.answerType)
										?.type === "date" ? (
									<>
										<fieldset className="flex flex-col">
											<input
												className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none placeholder:text-sm placeholder:italic"
												type={"date"}
												placeholder="Enter date"
												id={"quesAnswer"}
												name="quesAnswer"
												onChange={event => handleFormTitle(event, formIndex)}
												value={moment(each?.quesAnswer).format("YYYY-MM-DD")}
												readOnly={readOnly}
											/>
										</fieldset>
									</>
								) : AnswerTypeArr?.find(ix => ix?.type === each?.answerType)
										?.type === "time" ? (
									<>
										<fieldset className="flex flex-col">
											<input
												className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none placeholder:text-sm placeholder:italic"
												type="time"
												placeholder="Enter date"
												id={"quesAnswer"}
												name="quesAnswer"
												onChange={event => handleFormTitle(event, formIndex)}
												value={moment(each?.quesAnswer, "h:mm:ss A").format(
													"HH:mm:ss"
												)}
												readOnly={readOnly}
											/>
										</fieldset>
									</>
								) : (
									<></>
								)}
							</div>
						</div>
					)}
				</div>
			))}
		</>
	);
};

export const TableMediaUseCase = ({ datum, type, setDatum }) => {
	let [data, setData] = useState(null),
		[loading, setLoading] = useState(false),
		[searchParams] = useSearchParams();

	useEffect(() => {
		setData(datum);
	}, [datum]);

	let handleLoadMore = async () => {
		setLoading("loadmore");
		try {
			let res = await axios.get(
				`/api/v1/${type}?limit=${data?.limit * data?.nextPage}`,
				{
					headers: {
						Authorization: searchParams?.get("token"),
					},
				}
			);

			console.log({ type: res }, type);
			setDatum(res?.data?.data || res?.data);
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
		}
		setLoading(false);
	};

	let [range] = useState(10),
		[page, setPage] = useState(1);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	if (!data) return <></>;

	const currentItems = data
		? [...data?.docs]?.slice(itemOffset, endOffset)
		: [];
	const pageCount = Math.ceil(data?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % data?.docs?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	return (
		<>
			<div className="bg-white rounded-lg p-4">
				<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
					<table className="w-full text-sm text-left text-gray-500">
						<thead className="text-md text-gray-700 capitalize bg-secondary">
							<tr>
								<th scope="col" className="p-4">
									<div className="flex items-center">
										<input
											id="checkbox-all-search"
											type="checkbox"
											className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
										/>
										<label for="checkbox-all-search" className="sr-only">
											checkbox
										</label>
									</div>
								</th>
								<th scope="col" className="px-6 py-3">
									File Name
								</th>
								<th scope="col" className="px-6 py-3">
									{type === "media" ? "Description" : `Completion Date`}
								</th>
								<th scope="col" className="px-6 py-3">
									Date Added
								</th>
								<th scope="col" className="px-6 py-3"></th>
							</tr>
						</thead>
						<tbody>
							{currentItems?.map((itx, i) => (
								<tr key={i} className="bg-white border-b hover:bg-gray-50">
									<td className="w-4 p-4">
										<div className="flex items-center">
											<input
												id="checkbox-table-search-1"
												type="checkbox"
												className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
											/>
											<label for="checkbox-table-search-1" className="sr-only">
												checkbox
											</label>
										</div>
									</td>
									<td
										className="px-6 py-4 cursor-pointer"
										onClick={
											itx?.url
												? () => window.open(itx?.url, "_blank")
												: Array.isArray(itx?.file)
												? null
												: itx?.file?.url
												? () => window.open(itx?.file?.url, "_blank")
												: null
										}>
										{itx?.url ? (
											itx?.fileName || itx?.url
										) : Array.isArray(itx?.file) ? (
											<>
												{itx?.file?.map((ic, u) => (
													<span
														key={u}
														className="block py-1"
														onClick={
															ic?.url
																? () => window.open(ic?.url, "_blank")
																: null
														}>
														{itx?.fileName ? (
															<>
																{itx?.fileName} {u + 1}
															</>
														) : (
															ic?.url
														)}
													</span>
												))}
											</>
										) : (
											itx?.fileName || itx?.file?.url
										)}
									</td>
									<td className="px-6 py-4">
										{type === "usecases" && itx?.completionDate
											? moment(itx?.completionDate).format("L")
											: null}
										{type === "media" ? itx?.description : ""}
									</td>
									<td className="px-6 py-4">
										{moment(itx?.createdAt).format("L")}
									</td>
									<td className="flex items-center px-6 py-4 space-x-3">
										<IconDropdown>
											<ul className="space-y-1">
												<li
													className="text-xs flex items-center gap-1 cursor-pointer hover:text-gray-600"
													onClick={
														itx?.url
															? () => window.open(itx?.url, "_blank")
															: Array.isArray(itx?.file)
															? () => window.open(itx?.file?.[0]?.url, "_blank")
															: itx?.file?.url
															? () => window.open(itx?.file?.url, "_blank")
															: null
													}>
													<span>
														<AiOutlineCheckCircle />
													</span>
													<span>Open link in new tab</span>
												</li>
											</ul>
										</IconDropdown>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && data?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
				</div>
			</div>
		</>
	);
};