import React, { useContext, useEffect, useState } from "react";
import DefaultHeader from "../../components/default-header/default-header";
import SmallNav from "../../components/small-nav/small-nav";
// import rawData from "../../raw-data";
import Search from "../../components/search/search";
import ModalContainer, {
	DrawerModal,
	PageLoader,
} from "../../components/modal-container/modal-container";
import { BiDownload } from "react-icons/bi";
import Button, { LoadMore, MainPaginate } from "../../components/button/button";
// import RightDrawer from "../../components/right-drawer/right-drawer";
import ExpertisePill from "../../components/expertise-pill/expertise-pill";
import { useDispatch, useSelector } from "react-redux";
import {
	manageRawData,
	resetRawDataSearch,
} from "../../data/Reducers/RawDataReducer";
import { GlobalState } from "../../data/Context";
import { BsCheckLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import axios from "axios";
import { StringTOCamel } from "../get-started/steps";
import {
	SearchDropdownSelectExport,
	SortBy,
} from "../../components/search-dropdown/search-dropdown";

function Data() {
	const [bulkUpload, setBulkUpload] = useState(false);
	const [drawer, setDrawer] = useState(false);

	// const handleBulkUpload = () => {
	//   toggleUploadModal();
	// };
	const toggleUploadModal = () => {
		setBulkUpload(!bulkUpload);
	};
	const toggleShowDrawer = it => {
		setDrawer(drawer ? null : it);
	};

	let [data, setData] = useState(null),
		[search, setSearch] = useState(""),
		{ rawdata, auth } = useSelector(s => s),
		dispatch = useDispatch(),
		[loading, setLoading] = useState(false),
		[updater, setUpdater] = useState(null),
		navigate = useNavigate(),
		{ numberWithCommas, orgTheme } = useContext(GlobalState),
		[priority, setPriority] = useState("unselect"),
		[newSample, setNewSample] = useState([]),
		[sort, setSort] = useState("");

		useEffect(() => {
			if (auth?.user)
				if (!["organization"]?.includes(auth?.user?.privilege)) navigate(-1);
		}, [auth?.user, navigate]);

		useEffect(() => {
			if (rawdata?.isFound) setData(rawdata?.mainSearch);
			else setData(rawdata?.data);
		}, [rawdata?.data, rawdata?.mainSearch, rawdata?.isFound]);

		useEffect(() => {
			let newArr = [],
				newHeader = [];
			let newObj = {};
			if (data?.docs?.length > 0) {
				for (let r = 0; r < data?.docs.length; r++) {
					let element = data?.docs[r];
					newObj[`${StringTOCamel(element?.category)}`] = element?.data
						?.toString()
						?.split(",")
						?.join("\n");
					newHeader?.push(element?.category);
				}
				newArr?.push(newObj);
			}
			let csvData = [
				newHeader,
				...newArr?.map(it => {
					let newA = Object?.keys(it)?.map(ic => it?.[ic]);
					return newA;
				}),
			];
			// console.log({ csvData });
			setNewSample(csvData);
		}, [data]);

		let tabViews = [
				`All${
					data?.totalDocs > 0 ? ` (${numberWithCommas(data?.totalDocs)})` : ""
				}`,
				// "Newly Uploaded",
			],
			updateSubmit = async e => {
				e?.preventDefault();
				// console.log({ data });
				if (!updater) return toast.info("Updated data required");
				setLoading(true);
				try {
					let res;
					if (updater?.type === "delete") {
						res = await axios.delete(`/api/v1/rawdata`, {
							data: { rawdata: updater?.rawdata },
						});
					} else {
						res = await axios.put(`/api/v1/rawdata/map`, {
							rawdata: updater?.rawdata,
							priority,
						});
					}
					console.log({ resp: res?.data });
					toast.success(res?.data?.message);
					setLoading(false);
					setUpdater(null);
					dispatch(manageRawData("get"));
				} catch (err) {
					if (err) console.log({ error: err.response?.data, err });
					if (err?.response?.status === 429) toast.error(err?.response?.data);
					let error = err.response?.data?.error;
					if (error && error?.length > 1) {
						dispatch(returnErrors({ error, status: err?.response?.status }));
					} else {
						toast.error(
							err?.response?.data?.message ||
								err?.response?.data?.error?.[0]?.message ||
								err?.message
						);
					}
				}
				setLoading(false);
			};

		useEffect(() => {
			dispatch(manageRawData("get", { sort }));
			dispatch(resetRawDataSearch());
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [sort]);

		let handleLoadMore = async () => {
			setLoading("loadmore");

			let dd = {};
			if (search) {
				dd = { ...dd, search };
			}
			if (sort) dd = { ...dd, sort };

			await dispatch(manageRawData("get", dd));
			setLoading(false);
		};

		let handleSearch = async () => {
			setLoading("loadmore");

			let dd = {};
			if (search) {
				dd = { ...dd, search };
			}
			if (sort) dd = { ...dd, sort };

			await dispatch(manageRawData("get", dd));
			setLoading(false);
		};

		useEffect(() => {
			if (search) {
				document.getElementById("Search").addEventListener("search", () => {
					dispatch(resetRawDataSearch());
				});
				let handleSubmit = async () => {
					if (!search) return;

					await handleSearch();
				};
				handleSubmit();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [search]);

		let [range] = useState(10),
			[page, setPage] = useState(1);

		const [itemOffset, setItemOffset] = useState(0);
		const endOffset = itemOffset + range;
		if (!data && !rawdata?.data) return <PageLoader />;

		const currentItems = data
			? [...data?.docs]?.slice(itemOffset, endOffset)
			: [];
		const pageCount = Math.ceil(data?.docs?.length / range);

		const handlePageClick = event => {
			const newOffset = (event.selected * range) % data?.docs?.length;
			setItemOffset(newOffset);
			setPage(1 + event?.selected);
		};

		return (
			<div>
				<DefaultHeader mainTitle={"Raw Data"} />
				<div className="bg-white rounded-lg p-4">
					<SmallNav menu={tabViews} />
					<div className="flex items-center gap-2 justify-between my-4">
						<Search
							placeholder={"Search Categories"}
							search={search}
							setSearch={setSearch}
						/>
						<div className="flex items-center gap-2">
							<SortBy sort={sort} setSort={setSort} />
							<SearchDropdownSelectExport
								newSample={newSample}
								fileName={"Rolodex Raw Data"}
							/>
							{data?.docs?.some?.(ic => ic?.isChecked) && (
								<>
									<Button
										buttonType={"primary"}
										children={"Select as Map Data"}
										type="button"
										css={"w-fit me-2 py-3"}
										onClick={() =>
											setUpdater({
												type: "select as map selection",
												rawdata: data?.docs
													?.filter(iz => iz?.isChecked)
													?.map(ia => {
														return { referencedRawDataId: ia?._id };
													}),
											})
										}
									/>
									<Button
										buttonType={"primary"}
										children={"Delete Data"}
										type="button"
										css={"w-fit mx-2 py-3"}
										onClick={() =>
											setUpdater({
												type: "delete",
												rawdata: data?.docs
													?.filter(iz => iz?.isChecked)
													?.map(ia => {
														return { referencedRawDataId: ia?._id };
													}),
											})
										}
									/>
									<Button
										buttonType={"primary"}
										children={"Update Data"}
										type="button"
										css={"w-fit ms-2 py-3"}
										onClick={() => {
											console.log({
												state: data?.docs?.filter(iz => iz?.isChecked),
											});
											navigate(`/data/update?step=update your dataset`, {
												state: data?.docs?.filter(iz => iz?.isChecked),
											});
										}}
									/>
								</>
							)}
						</div>
					</div>
					<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
						<table className="w-full text-sm text-left text-gray-500">
							<thead className="text-md text-gray-700 capitalize bg-secondary">
								<tr className="">
									{auth?.user?.privilege === "organization" && (
										<>
											<th scope="col" className="p-4">
												<div className="flex items-center">
													<input
														id="checkbox-all-search"
														type="checkbox"
														className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
														onChange={e => {
															let dd = data?.docs;
															dd = dd?.map(ic => {
																let dc = ic;
																return { ...dc, isChecked: e?.target?.checked };
															});
															setData({ ...data, docs: dd });
														}}
														checked={
															data?.docs?.length > 0 &&
															data?.docs?.every(it => it?.isChecked)
														}
													/>
													<label for="checkbox-all-search" className="sr-only">
														checkbox
													</label>
												</div>
											</th>
											<th scope="col" className="px-6 py-3">
												Map Selection
											</th>
										</>
									)}
									<th className="px-6 py-3 max-w-fit">Category</th>
									<th scope="col" className="px-6 py-3 w-full">
										<span className="border-l-2 border-gray-300 px-2">
											Data
										</span>
									</th>
									<th scope="col" className="px-6 py-3 w-fit"></th>
								</tr>
							</thead>
							<tbody>
								{currentItems?.map((da, i) => (
									<tr className="bg-white border-b" key={i}>
										{auth?.user?.privilege === "organization" && (
											<>
												<td className="w-4 p-4">
													<div className="flex items-center">
														<input
															id="checkbox-table-search-1"
															type="checkbox"
															className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
															onChange={e => {
																let dd = data?.docs;
																let current = da;
																if (e?.target?.checked) {
																	current = {
																		...current,
																		isChecked: true,
																	};
																} else {
																	current = {
																		...current,
																		isChecked: false,
																	};
																}
																dd = dd?.map(ic =>
																	ic?._id === da?._id ? current : ic
																);
																setData({ ...data, docs: dd });
															}}
															checked={da?.isChecked}
														/>
														<label
															for="checkbox-table-search-1"
															className="sr-only">
															checkbox
														</label>
													</div>
												</td>
												<th
													scope="row"
													className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:underline cursor-pointer">
													{da?.isMapFilter ? (
														<span
															className="font-medium text-white"
															style={{ color: orgTheme }}>
															<BsCheckLg size={20} />
														</span>
													) : null}
												</th>
											</>
										)}
										<th
											scope="row"
											className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:underline cursor-pointer">
											{da?.category}
										</th>
										<td className="px-6 py-4">
											<div className="flex gap-1 max-w-xl overflow-x-auto pb-2">
												{da?.data?.map((item, x) => (
													<ExpertisePill value={item} key={x} />
												))}
											</div>
										</td>
										<td className="flex items-center px-6 py-4 space-x-3">
											<div
												class="font-medium text-blue-600 underline whitespace-nowrap cursor-pointer"
												onClick={() => toggleShowDrawer(da)}>
												view All
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<div className="tw-mt-4 tw-flex tw-justify-center">
							<LoadMore
								next={page === pageCount && data?.hasNextPage}
								loading={loading === "loadmore"}
								handleLoadMore={handleLoadMore}
							/>
						</div>
						{pageCount > 1 && (
							<MainPaginate
								pageCount={pageCount}
								handlePageClick={handlePageClick}
							/>
						)}
					</div>
				</div>
				<ModalContainer
					show={bulkUpload}
					close={toggleUploadModal}
					title={"Upload new organisations"}
					subtitle={
						"Add new organisation from an excel or csv file. Download sample file"
					}>
					<div className="py-20 border border-dashed border-gray-300 bg-grey rounded-lg">
						<img
							src={require("../../assets/file-icon.png")}
							alt=""
							className="mx-auto mb-2"
						/>
						<p className="font-bold text-sm text-center">
							Select a CSV file to upload
						</p>
						<p className="text-xs text-center text-gray-400">
							or drag and drop it here
						</p>
					</div>
					<div className="flex items-center gap-1 justify-between">
						<div className="text-main flex items-center gap-1">
							<span>
								<BiDownload />
							</span>
							<p className="text-xs">Download sample file</p>
						</div>
						<div className="flex items-center gap-4">
							<Button
								buttonType={"secondary"}
								children={"Cancel"}
								// eslint-disable-next-line react/style-prop-object
								css={"h-8"}
								onClick={toggleUploadModal}
							/>
							<Button
								buttonType={"primary"}
								children={"Import"}
								// eslint-disable-next-line react/style-prop-object
								css={"h-8"}
							/>
						</div>
					</div>
				</ModalContainer>
				<DrawerModal show={drawer} close={toggleShowDrawer}>
					<div className="scrollbar-hid flex items-center gap-1 p-4 flex-wrap">
						{drawer?.data?.map((item, x) => (
							<ExpertisePill value={item} key={x} />
						))}
					</div>
				</DrawerModal>
				<ModalContainer
					title={"Update Invitation"}
					width={"max-w-sm"}
					show={updater ? true : false}
					close={() => setUpdater(null)}>
					<div className="mx-20">
						<form className="space-y-4">
							<div className="my-auto w-100">
								<p className="text2 Lexend text-center">
									Do you want to {updater?.type} this data?
								</p>
								{updater?.type === "select as map selection" ? (
									<>
										<p>What would you like for your previous selection</p>
										<nav className="flex items-center gap-2 px-1 overflow-x-auto  scrollbar-hide">
											<div
												onClick={() => setPriority("select")}
												className={`py-2 font-body capitalize w-fit whitespace-nowrap text-sm pr-2 cursor-pointer border-b-2 ${
													priority === "select"
														? "border-main f-bold"
														: "border-transparent text-gray-600"
												}`}
												style={{
													borderColor:
														orgTheme && priority === "select" ? orgTheme : "",
												}}>
												Keep
											</div>
											<div
												onClick={() => setPriority("unselect")}
												className={`py-2 font-body capitalize w-fit whitespace-nowrap text-sm pr-2 cursor-pointer border-b-2 ${
													priority === "unselect"
														? "border-main f-bold"
														: "border-transparent text-gray-600"
												}`}
												style={{
													borderColor:
														orgTheme && priority === "unselect" ? orgTheme : "",
												}}>
												Unselect
											</div>
										</nav>
									</>
								) : null}
								<div className="pt-4 flex">
									<Button
										buttonType={"primary"}
										children={"Yes"}
										type="button"
										css={"w-fit me-2 py-3"}
										loading={loading}
										onClick={updateSubmit}
									/>
									<Button
										buttonType={"secondary"}
										children={"No"}
										type="button"
										css={"w-fit ms-2 py-3"}
										onClick={() => setUpdater(null)}
									/>
								</div>
							</div>
						</form>
					</div>
				</ModalContainer>
			</div>
		);
}

export default Data;
