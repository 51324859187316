import { IconEye, IconEyeClosed } from "@tabler/icons-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import { useDispatch } from "react-redux";
import Button from "../../components/button/button";

const NewPasswordForm = (props) => {
  const { setSuccess } = props;
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      password: "",
      confirmedPassword: "",
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [notEqualPass, setNotEqualPass] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const redirect = (url) => {
    navigate(url);
  };

  let [loading, setLoading] = useState(null),
    [getSearch] = useSearchParams(),
    dispatch = useDispatch();

  useEffect(() => {
    if (!getSearch?.get("token")) redirect("/login/reset-password");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSearch]);

  const onSubmit = async (e) => {
    try {
      if (e?.confirmedPassword !== e?.password) {
        setNotEqualPass(true);
        return;
      }
      console.log({ e });
      setNotEqualPass(false);
      setLoading(true);
      let res,
        body = {
          password: e?.password,
        };

      res = await axios.put(
        `/api/v1/user/setup-password`,
        { ...body },
        {
          headers: {
            Authorization: getSearch?.get("token"),
          },
        }
      );

      console.log({ resp: res?.data });
      toast.success(res?.data?.message);
      console.log({ resp: res?.data });
      let hostname = window.location.hostname,
        newUrl = "";
      if (hostname?.startsWith("www")) hostname = hostname?.replace("www.", "");

      if (!hostname?.includes(".vercel.app")) {
        // Split the hostname by '.' to get subdomain
        const parts = hostname.split(".");

        // Check if there's a subdomain (more than 2 parts in the hostname)
        console.log({ parts });
        if (parts.length > (hostname?.includes("localhost") ? 1 : 2)) {
					// Subdomain is the first part of the hostname

					newUrl = `${window?.location?.protocol}//${
						res?.data?.data &&
						parts?.[0]?.toLowerCase() !== res?.data?.data?.toLowerCase()
							? `${res?.data?.data}.`
							: ""
					}${
						window.location?.hostname?.startsWith("www")
							? window.location?.hostname?.replace("www.", "")
							: window.location?.hostname
					}${window.location.port ? `:${window.location.port}` : ""}`;
				}
      }
      setSuccess(newUrl || true);
      setLoading(false);
    } catch (err) {
      if (err) console.log({ error: err.response?.data, err });
      if (err?.response?.status === 429) toast.error(err?.response?.data);
      let error = err.response?.data?.error;
      if (error && error?.length > 1) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
      } else {
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data?.error?.[0]?.message ||
            err?.message
        );
      }
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg">
      <div className="fix ed inset-0 bg-white"></div>
      <div className="relative px-8 z-30 w-full mb-12">
        <img
          src={require("../../assets/logo-black.png")}
          className="h-6 md:h-10 mt-4"
          alt=""
        />
      </div>
      <div className=" relative z-10">
        <div className="font-medium self-center  text-gray-800 mb-2">
          <h1 className="text-center text-2xl md:text-2xl xl:text-3xl">
            Create New Password{" "}
          </h1>
        </div>
        <div className="self-center text-gray-800">
          <h2 className="text-center text-base md:text-lg lg:text-xl xl:text-2xl font-light">
            Enter new password a minimum of 8 characters with a combination of
            letters, numbers and symbols
          </h2>
        </div>
        <div className="mt-5 md:mt-7 lg:mt-10">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {/* Password*/}
              <Controller
                name="password"
                control={methods.control}
                rules={{
                  required: "This field is required",
                  pattern: {
                    value:
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
                    message:
                      "Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
                  },
                }}
                render={({ field: { onChange, name } }) => (
                  <fieldset className="flex flex-col mb-6">
                    <div className="flex items-center justify-between">
                      <label
                        className="mb-1 text-sm md:text-lg font-bold tracking-wide text-gray-600"
                        htmlFor="password"
                      >
                        New Password *
                      </label>
                    </div>
                    <div className="relative">
                      <input
                        className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-2 focus:outline-none focus:border-blue-400"
                        name={name}
                        id={name}
                        onChange={onChange}
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                        {showPassword ? (
                          <IconEye onClick={handleShowPassword} />
                        ) : (
                          <IconEyeClosed onClick={handleShowPassword} />
                        )}
                      </div>
                    </div>
                    {methods.formState.errors.password && (
                      <span className="text-xs text-red-600">
                        {methods.formState.errors.password.message}
                      </span>
                    )}
                  </fieldset>
                )}
              />

              {/* Confirmed Password */}
              {/* Password*/}
              <Controller
                name="confirmedPassword"
                control={methods.control}
                rules={{
                  required: "This field is required",
                  pattern: {
                    value:
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
                    message:
                      "Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
                  },
                }}
                render={({ field: { onChange, name } }) => (
                  <fieldset className="flex flex-col mb-6">
                    <div className="flex items-center justify-between">
                      <label
                        className="mb-1 text-sm md:text-lg font-bold tracking-wide text-gray-600"
                        htmlFor="password"
                      >
                        New Password Again *
                      </label>
                    </div>
                    <div className="relative">
                      <input
                        className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-2 focus:outline-none focus:border-blue-400"
                        name={name}
                        id={name}
                        onChange={onChange}
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                        {showPassword ? (
                          <IconEye onClick={handleShowPassword} />
                        ) : (
                          <IconEyeClosed onClick={handleShowPassword} />
                        )}
                      </div>
                    </div>
                    {methods.formState.errors.confirmedPassword && (
                      <span className="text-xs text-red-600">
                        {methods.formState.errors.confirmedPassword.message}
                      </span>
                    )}
                  </fieldset>
                )}
              />
              {notEqualPass && (
                <p className="mb-5 text-sm md:text-base font-bold text-red-600">
                  Both passwords must be equal
                </p>
              )}
              {/* <button
								type="submit"
								className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500">
								Reset password
							</button> */}
              <Button
                type="submit"
                loading={loading}
                children="Reset Password"
                // eslint-disable-next-line react/style-prop-object
                css="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
                // loadCss="text-bluerolodex"
              />
              <button
                type="button"
                onClick={() => redirect("/")}
                className="w-full flex justify-center hover:brightness-100  text-bluerolodex p-4  tracking-wide font-semibold cursor-pointer transition ease-in duration-500"
              >
                Go back to Login
              </button>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default NewPasswordForm;
