import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import Email from "../../assets/email.svg";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import Button from "../../components/button/button";
import { IconEye, IconEyeClosed } from "@tabler/icons-react";

const SetupOrganisation = () => {
	let navigate = useNavigate(),
		[getSearch] = useSearchParams();

	useEffect(() => {
		if (getSearch)
			if (!getSearch?.get?.("token") || !getSearch?.get?.("email")) {
				console.log({ getSearch });
				navigate(-1);
			}
	}, [getSearch, navigate]);

	return (
		<SetupOrganisationMain
			email={getSearch?.get?.("email")}
			token={getSearch?.get?.("token")}
		/>
	);
};

export default SetupOrganisation;

export const SetupOrganisationMain = ({ email, token = null }) => {
	let navigate = useNavigate(),
		[firstStep, setFirstStep] = useState(null),
		{ state } = useLocation();

	const methods = useForm({
		defaultValues: {
			organizationName: firstStep?.organizationName || "",
			password: "",
			linkedInUrl: firstStep?.linkedInUrl || "",
		},
	});

	const [showPassword, setShowPassword] = useState(false);
	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	let [loading, setLoading] = useState(null),
		dispatch = useDispatch(),
		{ auth } = useSelector(s => s);

	let getFirstStep = async () => {
		try {
			let res = await axios.get(`/api/v1/user`, {
				headers: {
					Authorization: token,
				},
			});
			console.log({ detail: res?.data }, "user-detail");
			setFirstStep(res?.data?.data || res?.data);
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
		}
	};

	useEffect(() => {
		getFirstStep();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// console.log(methods);

	const onSubmit = async data => {
		if (!data) return;
		setLoading(true);
		try {
			let res = await axios.put(
				`/api/v1/user/setup-password`,
				{
					...data,
				},
				{
					headers: {
						Authorization: token,
					},
				}
			);
			console.log({ resp: res?.data }, "profile");
			toast.success(res?.data?.message);
			navigate(
				auth?.isAuth
					? "/"
					: // : firstStep?.role?.title === "ADMIN"
					  // ? `/verify?type=company information${
					  // 		token ? `&token=${token}` : ""
					  //   }${email ? `&email=${email}` : ""}`
					  "/signup/signup-success",
				{
					state: {
						...state,
						org: res?.data?.data,
						email: email || data?.email,
					},
				}
			);
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
		setLoading(false);
	};

	return (
		<section className="min-h-screen flex flex-col items-center space-y-5 justify-center">
			<div className="fixed inset-0 bg-white"></div>
			<div
				className="fixed top-0 px-8 z-30 w-full mb-12 cursor-pointer"
				onClick={() => {
					if (
						window.location?.origin?.includes("geo-rolodex.com") ||
						window.location?.origin?.includes("map.policyvault.africa")
					) {
						navigate("/");
					} else {
						navigate("/login");
					}
				}}>
				<img
					src={require("../../assets/logo-black.png")}
					className="h-6 md:h-10"
					alt=""
				/>
			</div>
			<div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10">
				<div className="flex items-center justify-between text-gray-800 mb-2">
					<h1 className="text-2xl md:text-2xl xl:text-3xl font-bold">
						<span>Setup Profile</span>
					</h1>
					{/* <span className="text-xs bg-gray-300 rounded-full p-0.5 px-2">
						{step} of 2
					</span> */}
				</div>
				<div></div>
				<div className="mt-8 mb-4">
					<p className="font-semibold text-lg">{"Profile name"}</p>
					<p className="text-sm text-gray-400">{"Give your profile a name"}</p>
				</div>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Controller
							name="organizationName"
							rules={{
								required: firstStep?.organizationName
									? null
									: "This field is required",
							}}
							render={({ field: { onChange, name, value } }) => (
								<fieldset className="flex flex-col mb-6">
									<input
										className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
										name={name}
										id={name}
										type={"text"}
										onChange={onChange}
										placeholder="Profile name"
										value={value || firstStep?.organizationName}
									/>
									{methods.formState.errors.organizationName && (
										<span className="text-xs text-red-600">
											This field is required
										</span>
									)}
								</fieldset>
							)}
						/>
						<Controller
							name="linkedInUrl"
							rules={{
								required: firstStep?.linkedInUrl
									? null
									: "This field is required",
							}}
							render={({ field: { onChange, name, value } }) => (
								<fieldset className="flex flex-col">
									<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
										LinkedIn URL
									</label>
									<input
										className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
										type={"url"}
										placeholder="Your linkedIn link"
										name={name}
										id={name}
										onChange={onChange}
										value={value || firstStep?.linkedInUrl || "https://"}
									/>
									{methods.formState.errors[name] && (
										<span className="text-xs text-red-600">
											This field is required
										</span>
									)}
								</fieldset>
							)}
						/>
						<Controller
							name="companyBio"
							rules={{
								required: firstStep?.companyBio
									? null
									: "This field is required",
							}}
							render={({ field: { onChange, name, value } }) => (
								<fieldset className="flex flex-col my-4">
									<label className="text-sm md:text-lg f-bold tracking-wide">
										Bio
									</label>
									<small className="b text-gray-400">Tell us about you</small>
									<div className="flex gap-2">
										<textarea
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none placeholder:text-lg"
											name={name}
											id={name}
											onChange={onChange}
											placeholder="Write a short bio here"
											row={6}
											style={{
												resize: "none",
												height: "10rem",
											}}
											value={value || firstStep?.companyBio}
										/>
									</div>
									{methods.formState.errors[name] && (
										<span className="text-xs text-red-600">
											This field is required
										</span>
									)}
								</fieldset>
							)}
						/>
						{/* Password*/}
						<Controller
							name="password"
							control={methods.control}
							rules={{
								required: "This field is required",
								pattern: {
									value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
									message:
										"Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
								},
							}}
							render={({ field: { onChange, name, value } }) => (
								<fieldset className="flex flex-col mb-6">
									<div className="flex items-center justify-between">
										<label
											className="mb-1 text-sm md:text-lg f-bold tracking-wide"
											htmlFor="password">
											Password
										</label>
									</div>
									<div className="relative">
										<input
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
											name={name}
											id={name}
											onChange={onChange}
											placeholder="Password"
											type={showPassword ? "text" : "password"}
											value={value}
										/>
										<div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
											{showPassword ? (
												<IconEye onClick={handleShowPassword} />
											) : (
												<IconEyeClosed onClick={handleShowPassword} />
											)}
										</div>
									</div>
									{methods.formState.errors.password && (
										<span className="text-xs text-red-600">
											{methods.formState.errors.password.message}
										</span>
									)}
								</fieldset>
							)}
						/>

						<Button
							type="submit"
							loading={loading}
							children="Create Profile"
							// eslint-disable-next-line react/style-prop-object
							css="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
							// loadCss="text-bluerolodex"
							// onClick={onSubmit}
						/>
					</form>
				</FormProvider>
			</div>
		</section>
	);
};
