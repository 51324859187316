import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import Email from "../../assets/email.svg";
import { Controller, FormProvider, useForm } from "react-hook-form";
import ColorSelector from "../../components/color-selector/color-selector";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import Button from "../../components/button/button";

const SetupWorkspace = () => {
  let navigate = useNavigate(),
    { state } = useLocation();

  if (!state?.email || !state?.org?.id_org_fundation) navigate(-1);

  return <SetupWorkspaceMain organizationId={state?.org?.id_org_fundation} />;
};

export default SetupWorkspace;

export const SetupWorkspaceMain = ({ organizationId }) => {
  const [step, setStep] = useState(1);

  const methods = useForm({
    defaultValues: {
      name: "",
    },
  });

  let navigate = useNavigate(),
    { state } = useLocation(),
    [property, setProperty] = useState({
      logo: "",
      color: "",
    }),
    logoInputRef = useRef();

  let [loading, setLoading] = useState(null),
    dispatch = useDispatch(),
    [subdomain, setSubdomain] = useState(""),
    { auth } = useSelector((s) => s);

  // console.log(methods);
  const onSubmit = async (data) => {
    if (step === 1) {
      try {
        if (!data?.name) {
          setStep(1);
          return toast.info("Workspace name is required");
        }
        setLoading(true);
        let res,
          body = {
            workspaceName: data?.name,
          };

        res = await axios.put(
          `api/v1/organization/workspace`,
          { ...body },
          {
            headers: {
              Authorization: state?.token,
            },
          }
        );
        console.log({ resp: res?.data });
        toast.success(res?.data?.message);
        setSubdomain(res?.data?.data);
        setLoading(false);
        setStep(2);
      } catch (err) {
        setLoading(false);
        if (err) console.log({ error: err.response?.data, err });
        if (err?.response?.status === 429) toast.error(err?.response?.data);
        let error = err.response?.data?.error;
        if (error && error?.length > 1) {
          dispatch(returnErrors({ error, status: err?.response?.status }));
        } else {
          toast.error(
            err?.response?.data?.message ||
              err?.response?.data?.error?.[0]?.message ||
              err?.message
          );
        }
      }
    } else {
      onSubmitMain(data);
    }
  };

  let handleChangeImage = (e) => {
    const file = e.target.files[0];
    let err = "";

    if (!file) return (err = `File, ${file?.name} does not exist`);
    if (!file.type.includes("image"))
      return (err = `File, ${file?.name} format not supported`);

    if (err) {
      return toast.error(err);
    } else {
      // setLogo(file);

      setProperty((prevRows) => {
        let newRows = { ...prevRows };
        newRows.logo = file;
        return newRows;
      });
    }
  };

  const onSubmitMain = async (data) => {
    if (!data) return;
    try {
      if (!data?.name) {
        setStep(1);
        return toast.info("Workspace name is required");
      }
      if (!property?.logo || !property?.color)
        return toast.info("Logo/Color is required");
      setLoading(true);

      let res,
        body = {
          workspaceName: data?.name,
        };

      if (property?.color) body.color = property?.color;
      // if (property?.logo) {
      // 	try {
      // 		let post = new FormData();
      // 		post.append(`file`, property?.logo);

      // 		let resImg = await axios.post(`/api/v1/file`, post, {
      // 			headers: {
      // 				"Content-Type": "multipart/form-data",
      // 			},
      // 		});

      // 		body.file = resImg?.data?.data?.[0];
      // 	} catch (err) {
      // 		if (err) console.log({ error: err.response?.data, err });
      // 		if (err?.response?.status === 429) toast.error(err?.response?.data);
      // 		let error = err.response?.data?.error;
      // 		if (error && error?.length > 1) {
      // 			dispatch(returnErrors({ error, status: err?.response?.status }));
      // 		} else {
      // 			toast.error(
      // 				err?.response?.data?.message ||
      // 					err?.response?.data?.error?.[0]?.message ||
      // 					err?.message
      // 			);
      // 		}
      // 		setLoading(false);
      // 		return;
      // 	}
      // }

      if (property?.logo) {
        body.intendedFileName = "file";
        body.intendedFile = property.logo;
      }

      res = await axios.post(
        `api/v1/organization/workspace`,
        { ...body },
        {
          headers: {
            Authorization: state?.token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log({ resp: res?.data });
      toast.success(res?.data?.message);
      console.log({ subdomain });
      navigate(auth?.isAuth ? "/" : "/signup/verify-email", {
        state: { ...state, workspace: res?.data?.data },
      });
    } catch (err) {
      if (err) console.log({ error: err.response?.data, err });
      if (err?.response?.status === 429) toast.error(err?.response?.data);
      let error = err.response?.data?.error;
      if (error && error?.length > 1) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
      } else {
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data?.error?.[0]?.message ||
            err?.message
        );
      }
    }
    setLoading(false);
  };

  if (!state?.email && !state?.token) navigate(-1);

  return (
		<section className="min-h-screen flex flex-col items-center space-y-5 justify-center">
			<div className="fixed inset-0 bg-white"></div>
			<div
				className="fixed top-0 px-8 z-30 w-full mb-12 cursor-pointer"
				onClick={() => {
					if (
						window.location?.origin?.includes("geo-rolodex.com") ||
						window.location?.origin?.includes("map.policyvault.africa")
					) {
						navigate("/");
					} else {
						navigate("/login");
					}
				}}>
				<img
					src={require("../../assets/logo-black.png")}
					className="h-6 md:h-10"
					alt=""
				/>
			</div>
			<div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10">
				<div className="flex items-center justify-between text-gray-800 mb-2">
					<h1 className="text-2xl md:text-2xl xl:text-3xl font-bold">
						<span>Setup Workspace</span>
					</h1>
					<span className="text-xs bg-gray-300 rounded-full p-0.5 px-2">
						{step} of 2
					</span>
				</div>
				<div>
					<p className="text-gray-600 f-light text-xl">
						{step === 1
							? "Name + Custom URL"
							: "Set theme color and add corporate logo"}
					</p>
					<div className="flex items-center gap-4 mt-4">
						<div className={`h-1 bg-bluerolodex w-full rounded-md`}></div>
						<div
							className={`h-1 ${
								step === 2 ? "bg-bluerolodex" : "bg-gray-400"
							} w-full rounded-md`}></div>
					</div>
				</div>
				<div className="mt-8 mb-4">
					<p className="font-semibold text-lg">
						{step === 1 ? "Workspace name" : "Theme color"}
					</p>
					<p className="text-sm text-gray-400">
						{step === 1 ? "Give your workspace a name" : "Choose a theme"}
					</p>
				</div>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						{/* Email */}
						{step === 1 && (
							<Controller
								name="name"
								rules={{ required: "This field is required" }}
								render={({ field: { onChange, name } }) => (
									<fieldset className="flex flex-col mb-6">
										<input
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
											name={name}
											id={name}
											type={name}
											onChange={onChange}
											placeholder="Workspacename"
										/>
										<div
											className={
												window.location?.hostname?.includes(".vercel.app")
													? "hidden"
													: ""
											}>
											<p className="text-main flex items-center gap-1 mt-1">
												<span>
													<img src={require("../../assets/globe.png")} alt="" />
												</span>
												<span className="text-gray-400 text-lg lowercase">
													Your url -{" "}
												</span>
												{`${window.location?.protocol}//${methods.watch(
													name?.toLowerCase(),
													"rolodex"
												)}.${
													window.location?.hostname
														? window.location?.hostname?.startsWith("www")
															? window.location?.hostname?.replace("www.", "")
															: window.location?.hostname
														: "" || "devproject.africa"
												}${
													window.location.port ? `:${window.location.port}` : ""
												}`}
											</p>
										</div>

										{methods.formState.errors.name && (
											<span className="text-xs text-red-600">
												This field is required
											</span>
										)}
									</fieldset>
								)}
							/>
						)}

						{step === 2 && (
							<div>
								<ColorSelector
									state={property?.color}
									setState={col => setProperty({ ...property, color: col })}
								/>
								<div className="mb-8">
									<p className="font-semibold text-lg">Logo</p>
									<div className="h-24 w-24 rounded-full flex justify-center items-center bg-gray-400">
										<img
											src={
												property?.logo
													? URL.createObjectURL(property?.logo)
													: require("../../assets/profile.png")
											}
											alt=""
											className="rounded-full h-24 w-24"
											onClick={() => logoInputRef?.current?.click()}
										/>
										<input
											ref={logoInputRef}
											type="file"
											style={{ width: "0" }}
											className="absolute -z-10"
											onChange={handleChangeImage}
										/>
									</div>
								</div>
							</div>
						)}

						{/* <button
							type="submit"
							className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500">
							Continue
						</button> */}
						<Button
							type="submit"
							loading={loading}
							children="Continue"
							// eslint-disable-next-line react/style-prop-object
							css="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
							// loadCss="text-bluerolodex"
							// onClick={onSubmit}
						/>
					</form>
				</FormProvider>
			</div>
		</section>
	);
};
