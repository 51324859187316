const Search = ({ placeholder, search, setSearch }) => {
	return (
		<div>
			<label
				htmlFor="Search"
				className="mb-2 text-sm font-medium text-gray-900 sr-only">
				Search
			</label>
			<div className="relative">
				<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
					<svg
						className="w-4 h-4 text-gray-500"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 20 20">
						<path
							stroke="currentColor"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
						/>
					</svg>
				</div>
				<input
					className="block w-full px-4 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-200 max-w-sm outline-none focus:outline-none"
					placeholder={placeholder || "Search Mockups"}
					required
					type="search"
					name="search"
					id="Search"
					value={search}
					onChange={e => setSearch(e.target.value)}
				/>
			</div>
		</div>
	);
};

export default Search;
