import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import "swiper/css/pagination";
import "swiper/css/scrollbar";
import testim from "../../assets/testim.png";
import {
	// Navigation,
	Pagination,
} from "swiper/modules";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const TestimonialSlider = () => {
  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  }, []);

  const testimonials = [
    {
      text: "I like being in control of my payroll, without the back and forth of using an accountant. Onfolk is clear, clean, and makes payroll really easy to do. Other software products were confusing to use.",
      name: "Rebecca De Beukelaer",
      position: "Marketing & Operations Manager, SearchLand",
      image: testim,
    },
    // {
    //   text: "Using Onfolk has streamlined our payroll process and reduced errors. It's an essential tool for our HR department.",
    //   name: "John Doe",
    //   position: "HR Manager, CompanyX",
    //   image: testim,
    // },
  ];
  return (
    <Swiper
      modules={[Pagination]}
      pagination={{ clickable: true }}
      spaceBetween={50}
      slidesPerView={1}
    >
      {testimonials.map((testimonial, index) => (
        <SwiperSlide key={index}>
          <div className="flex flex-col bg-[#F9FAFC] lg:flex-row justify-center items-center space-x-8 p-4">
            <div className="w-full lg:w-1/2">
              <p
                data-aos="fade-right"
                className="lg:text-2xl md:text-2xl text-base font-Georgian  font-serif"
              >{`"${testimonial.text}"`}</p>
              <p
                data-aos="fade-left"
                className="mt-4 text-lg font-bold font-Inter"
              >
                {testimonial.name}
              </p>
              <p data-aos="fade-left" className="text-sm font-Inter">
                {testimonial.position}
              </p>
            </div>
            <div className="w-full lg:w-1/2 lg:mt-0 md:mt-5 mt-5">
              <img
                data-aos="fade-up"
                src={testimonial.image}
                alt={testimonial.name}
                className="rounded-lg"
              />
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TestimonialSlider;
