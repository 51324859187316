import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { GlobalState } from "../../data/Context";
import { useDispatch, useSelector } from "react-redux";
import { ShortenName } from "../../components/contact-card/contact-card";
import { BsCheckLg } from "react-icons/bs";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import Button from "../../components/button/button";
import { ProgressBarSocket } from "../get-started/steps";
import { IoIosAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { getProgress } from "../../data/Reducers/SocketReducer";

const BulkOrgUpload = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const params = ["invite organisations"];
	// const navigate = useNavigate();
	const currentRoute = searchParams.get("step");

	useEffect(() => {
		if (!currentRoute) {
			setSearchParams({ step: "invite organisations" });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let { orgTheme } = useContext(GlobalState),
		{ auth, workspace } = useSelector(s => s),
		navigate = useNavigate();

	useEffect(() => {
		if (auth?.user) if (auth?.user?.privilege !== "organization") navigate(-1);
	}, [auth?.user, navigate]);

	return (
		<div>
			<div className="inset-0 bg-white fixed"></div>
			<div className="relative z-10">
				<div className="flex items-center justify-between gap-x-8 mb-4 px-4 flex-wrap">
					<p className="py-4 f-medium text-xl">Invite Organization</p>

					<div className="flex items-center space-x-4">
						<div className="">
							<div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
								<span className="font-medium text-gray-600 dark:text-gray-300">
									<ShortenName user={auth?.user?.organizationName} />
								</span>
							</div>
						</div>
						<div className="font-medium dark:text-white">
							<div>{workspace?.data?.workspaceName}</div>
							<div className="text-sm text-gray-500 dark:text-gray-400">
								{auth?.user?.organizationName}....
							</div>
						</div>
					</div>
				</div>
				<div className="p-4 flex justify-between overflow-x-auto gap-8 scrollbar-hide">
					<div className="flex items-center space-x-4">
						<div>
							<div
								className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-bluerolodex rounded-md"
								style={{
									background: orgTheme || "",
								}}>
								<span className="font-medium text-white">
									<BsCheckLg />
								</span>
							</div>
						</div>
						<div
							style={{
								color: orgTheme && currentRoute === params[0] ? orgTheme : "",
							}}
							className={`f-medium ${
								currentRoute === params[0]
									? "text-bluerolodex"
									: "text-gray-700"
							}`}>
							<div className="c whitespace-nowrap">Invite Organisations</div>
							<div
								style={{
									color: orgTheme && currentRoute === params[1] ? orgTheme : "",
								}}
								className={`text-sm ${
									currentRoute === params[0]
										? "text-bluerolodex"
										: "text-gray-400"
								}`}>
								Invite collaborating organisations
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div className="py-12">
					{currentRoute === params[0] && (
						<InviteOrganisation route={params[1]} />
					)}
				</div>
			</div>
		</div>
	);
};

const InviteOrganisation = ({ route }) => {
	const navigate = useNavigate(),
		{ page } = useParams();

	let [loading, setLoading] = useState(null),
		dispatch = useDispatch(),
		{ mainSocket } = useSelector(s => s);

	let init2 = {};
	const [itemForm, setItemForm] = useState([init2]);
	const handleInputChangeForMutipleItem = (event, index, field) => {
		const { value } = event.target;
		let itemValue = value;

		setItemForm(prevRows => {
			const newRows = [...prevRows];
			newRows[index][field] = itemValue;
			return newRows;
		});
	};

	const handleDeleteRowForMutipleItem = index => {
		setItemForm(prevRows => {
			const newRows = [...prevRows];
			newRows.splice(index, 1);
			return newRows;
		});
	};

	useEffect(() => {
		dispatch(getProgress(null));
	}, [dispatch]);

	const addRowForMutipleItem = () => {
		const newRow = init2;
		setItemForm([...itemForm, newRow]);
	};

	const onSubmit = async () => {
		console.log({ itemForm });
		if (itemForm?.length === 0)
			return toast.info("Organization details required");
		if (itemForm?.length === 1)
			if (
				!itemForm?.[0]?.email ||
				!itemForm?.[0]?.organizationName
				// || !itemForm?.[0]?.contactPersonEmail
			)
				return toast.info("Organizations' complete details required");
		setLoading(true);
		try {
			let res = await axios.post(`api/v1/organization/invite-companies`, {
				nextScreen: `${window?.location?.origin}/verify`,
				privilege: "company",
				// roleType: auth?.user?.idType || "COMPANY",
				companies: itemForm?.map(it => {
					return {
						...it,
						contactPerson: {
							email: it?.contactPersonEmail,
							name: it?.contactPersonName,
						},
					};
				}),
			});
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			setLoading(false);
			navigate(`/${page}`);
			dispatch(getProgress(null));
		} catch (err) {
			dispatch(getProgress(null));
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
		setLoading(false);
	};

	let { orgTheme } = useContext(GlobalState);

	return (
		<div className="max-w-4xl mx-auto">
			<div className="flex justify-between flex-wrap mb-8">
				<article>
					<p className="text-2xl f-bold">Invite organisation </p>
					<p className="f-light">Invite collaborating organisations</p>
				</article>
			</div>
			<div>
				<form className="space-y-2">
					<div className="relative grid grid-cols-3 gap-4">
						<div>
							<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
								Organization name
							</label>
						</div>
						<div className="">
							<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
								Email
							</label>
						</div>
						<div className="">
							<label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
								Contact Person Email
							</label>
						</div>
					</div>
					{itemForm?.map((item, index) => (
						<>
							<div className="relative grid grid-cols-3 gap-4">
								<div className="">
									<div>
										{/* <label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
											Company Name
										</label> */}
										<input
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
											placeholder="Company Name"
											value={item.organizationName}
											onChange={event =>
												handleInputChangeForMutipleItem(
													event,
													index,
													"organizationName"
												)
											}
										/>
									</div>
								</div>
								<div className="">
									<div>
										{/* <label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
											Company Email
										</label> */}
										<input
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
											placeholder="Company Email"
											value={item.email}
											onChange={event =>
												handleInputChangeForMutipleItem(event, index, "email")
											}
										/>
									</div>
								</div>
								<div className="">
									<div>
										{/* <label className="mb-1 text-sm md:text-lg f-bold tracking-wide">
											Company Contact Person Email
										</label> */}
										<input
											className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
											placeholder="Company Contact Person Email"
											type="email"
											value={item.contactPersonEmail}
											onChange={event =>
												handleInputChangeForMutipleItem(
													event,
													index,
													"contactPersonEmail"
												)
											}
										/>
									</div>
								</div>
								<div
									onClick={() => handleDeleteRowForMutipleItem(index)}
									className="md:absolute self-center -right-20 cursor-pointer">
									<p className="text-sm text-red-600 flex items-center md:mt-2 gap-2">
										<span>
											<MdDelete />
										</span>
										Remove
									</p>
								</div>
							</div>
						</>
					))}
					<p
						style={{
							color: orgTheme || "",
						}}
						onClick={addRowForMutipleItem}
						className="flex items-center gap-2 text-bluerolodex f-medium py-3 cursor-pointer capitalize">
						<span>
							<IoIosAdd />
						</span>
						Add company
					</p>
					{mainSocket?.progress && (
						<ProgressBarSocket
							progress={mainSocket?.progress?.value}
							current={mainSocket?.progress?.current}
						/>
					)}
					<div className="mt-4 flex items-center gap-3">
						<Button
							children={"Continue"}
							buttonType={"primary"}
							onClick={onSubmit}
							loading={loading}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default BulkOrgUpload;
