import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useURL } from "./data/Config";
import { useDispatch, useSelector } from "react-redux";
import { getProgress, getSocket } from "./data/Reducers/SocketReducer";

const SocketClient = () => {
	const { auth, mainSocket } = useSelector(s => s),
		dispatch = useDispatch();

	let [socketNew, setSocket] = useState(null);

	const setUpSocket = async () => {
		var newSocket = io(useURL, {
			query: {
				userId: auth?.user?._id,
			},
			transports: ["websocket"],
		});
		newSocket.on("disconnect", () => {
			setSocket(null);
			setTimeout(setUpSocket, 3000);
		});
		setSocket(newSocket);
		// console.log({ ss: newSocket });
	};

	useEffect(() => {
		setUpSocket();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// console.log({ s: socketNew });
	useEffect(() => {
		if (socketNew) {
			dispatch(getSocket(socketNew));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socketNew]);

	useEffect(() => {
		mainSocket?.socket?.on("progress", progress => {
			console.log({ progress });
			dispatch(getProgress(progress));
		});
		// console.log({ socketNew });
		return () => {
			mainSocket?.socket?.off("progress");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainSocket?.socket]);

	// console.log({ socket });
	// JoinUser
	useEffect(() => {
		socketNew?.emit("checkUserOnlineAdmin");
	}, [socketNew, auth?.user]);

	return <></>;
};

export default SocketClient;
