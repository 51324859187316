import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PageRender from "./PageRender";
import Home from "./screens/default";
// import Footer from "./components/footer/footer";
// import Nav from "./components/nav/nav";
import Index from "./pages/index";
import Sidebar from "./components/sidebar/sidebar";
// import DefaultHeader from "./components/default-header/default-header";
// import ModalComponents from "./components/ModalComponents";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "./data/Reducers/ErrorReducer";
import ModalContainer from "./components/modal-container/modal-container";
import SocketClient from "./SocketClient";
import { useContext, useEffect } from "react";
import { GlobalState } from "./data/Context";
import axios from "axios";
// import { clearErrors } from "./data/Reducers/ErrorReducer";

const Routers = () => {
	const { error, auth } = useSelector(state => state),
		dispatch = useDispatch(),
		location = useLocation(),
		{ setSubdomain } = useContext(GlobalState);
	// console.log(location);

	useEffect(() => {
		// Get the hostname from the window location
		const hostname = window.location.hostname;

		if (!hostname?.includes(".vercel.app")) {
			// Split the hostname by '.' to get subdomain
			const parts = hostname.split(".");

			// Check if there's a subdomain (more than 2 parts in the hostname)
			if (parts.length > (hostname?.includes("localhost") ? 1 : 2)) {
				// Subdomain is the first part of the hostname
				const subdomain = parts[0];
				console.log({ subdomain });

				let retrieveSubdomain = async () => {
					try {
						let res,
							body = {
								workspaceSubdomain: subdomain,
							};

						res = await axios.post(`api/v1/organization/workspace-subdomain`, {
							...body,
						});
						console.log({ resp: res?.data });
						setSubdomain(res?.data?.data);
						axios.defaults.headers.common["organization-source"] =
							res?.data?.data?.organisation;
					} catch (err) {
						delete axios.defaults.headers.common["organization-source"];
						let newUrl = window.location.href;
						newUrl = newUrl?.replace(`${subdomain}.`, "");
						window.location.replace(newUrl);
						setSubdomain("");
						if (err) console.log({ error: err.response?.data, err });
						let error = err.response?.data?.error;
						console.log({ error }, "subdomain");
					}
				};
				if (subdomain && !["www", "map"]?.includes(subdomain))
					retrieveSubdomain();
				// setSubdomain(subdomain);
				console.log({ location, hostname: window.location });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<>
			<ToastContainer position="bottom-center" />
			{auth?.isAuth ? <SocketClient /> : null}
			<Sidebar auth={auth}>
				{/* {navControl() ? <Nav /> : null} */}
				<Routes>
					<Route path="/" element={auth?.isAuth ? <Index /> : <Home />} />
					<Route path="/:page" element={<PageRender />} />
					<Route path="/:page/:id" element={<PageRender />} />
					<Route path="/:page/:id/:step" element={<PageRender />} />
				</Routes>
			</Sidebar>
			<ModalContainer
				show={error?.error?.length > 0 || error?.accountNotCreated?.length > 0}
				title="Error"
				width={"max-w-md"}
				close={() => dispatch(clearErrors())}>
				<div className="downH2 flex flex-col">
					{/* <div className="mx-auto mb-3">
						<img src={gif2} alt="Gif" className="img-fluid" />
					</div> */}
					{error?.accountNotCreated?.map((item, i) => (
						<p key={i} className="fw-bold Lexend text-center w-100">
							<span className="fontInherit me-2">
								{error?.accountNotCreated?.length !== 1 && <>{i + 1}.</>}
							</span>{" "}
							{item?.company?.companyName}: {item?.error?.message}
						</p>
					))}
					{error?.error?.map((item, i) => (
						<p key={i} className="fw-bold Lexend text-center w-100">
							<span className="fontInherit me-2">
								{error?.error?.length !== 1 && <>{i + 1}.</>}
							</span>{" "}
							{item?.message || item || null}{" "}
							{item?.path?.includes("[") ? (
								<>
									(
									{Number(
										item?.path
											?.substring(item?.path.indexOf("[") + 1)
											?.split("]")?.[0]
									) + 1}
									)
								</>
							) : null}
						</p>
					))}
					<button
						onClick={() => dispatch(clearErrors())}
						className="inline-block w-52 mx-auto rounded bg-bluerolodex px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-bluerolodex hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-bluerolodex focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-bluerolodex active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] mt-5">
						close
					</button>
				</div>
			</ModalContainer>
		</>
	);
};

export default Routers;
