import {
  FloatingFocusManager,
  FloatingPortal,
  autoPlacement,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// const IconDropdown = ({ title, children, pressed }) => {
//   const [show, setShow] = useState(false);
//   const [position, setPosition] = useState({
//     xAxis: 0,
//     yAxis: 0,
//   });
//   const toggleShow = (e) => {
//     let pos = getMouseLocation(e);
//     setPosition({ xAxis: pos.x, yAxis: pos.y });
//     setShow(!show);
//   };
//   const getMouseLocation = (evt) => {
//     console.log(evt);

//     let x = evt?.clientX + 100 > evt?.pageX ? evt?.clientX - 200 : evt?.clientX;
//     let y =
//       evt?.clientY + 150 > evt?.view?.innerHeight
//         ? evt?.clientY - 200
//         : evt?.clientY;
//     return { x, y };
//   };

//   useEffect(() => {
//     if (pressed) {
//       setShow(false);
//     }
//   }, [pressed]);
//   return (
//     <div className="static">
//       <button
//         className="items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
//         type="button"
//         onClick={toggleShow}
//       >
//         <svg
//           className="w-5 h-5"
//           aria-hidden="true"
//           xmlns="http://www.w3.org/2000/svg"
//           fill="currentColor"
//           viewBox="0 0 4 15"
//         >
//           <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
//         </svg>
//       </button>

//       {/* Dropdown */}
//       {show && (
//         <div>
//           <div className="fixed inset-0" onClick={toggleShow}></div>
//           <div
//             className="fixed z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-fit p-4"
//             style={{
//               left: position.xAxis,
//               top: position.yAxis,
//               zIndex: 100,
//             }}
//           >
//             <div onClick={toggleShow}>{children}</div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

const IconDropdown = ({ title, children, pressed }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom",
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
      shift(),
      autoPlacement({
        alignment: 'left',
        allowedPlacements: ['bottom', 'left-end', 'right'],
      }),
    ],
  });

  // const listRef = useRef([]);
  // const listContentRef = useRef(options);
  const isTypingRef = useRef(false);

  const click = useClick(context, { event: "mousedown" });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "listbox" });
  // const listNav = useListNavigation(context, {
  //   listRef,
  //   activeIndex,
  //   selectedIndex,
  //   onNavigate: setActiveIndex,
  //   // This is a large list, allow looping.
  //   loop: true,
  // });
  // const typeahead = useTypeahead(context, {
  //   listRef: listContentRef,
  //   activeIndex,
  //   selectedIndex,
  //   onMatch: isOpen ? setActiveIndex : setSelectedIndex,
  //   onTypingChange(isTyping) {
  //     isTypingRef.current = isTyping;
  //   },
  // });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [dismiss, role, click]
  );

  const handleSelect = (index) => {
    setSelectedIndex(index);
    setIsOpen(false);
  };

  useEffect(() => {
    if (pressed) {
      setIsOpen(false);
    }
  }, [pressed]);

  return (
    <>
      <button
        className="items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
        ref={refs.setReference}
        aria-labelledby="select-label"
        aria-autocomplete="none"
        style={{ lineHeight: 2, margin: "auto" }}
        {...getReferenceProps()}
        type="button"
        onClick={setIsOpen}
      >
        <svg
          className="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 4 15"
        >
          <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
        </svg>
      </button>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
                // minWidth: 100,
                outline: 0,
                zIndex: 50,
              }}
              {...getFloatingProps()}
              // className="grid grid-cols-2 bg-white rounded-2xl p-2"
            >
              <div>
                {/* <div className="fixed inset-0" onClick={setIsOpen}></div> */}
                <div className="bg-white divide-y divide-gray-100 rounded-lg shadow w-fit p-4 whitespace-nowrap">
                  <div onClick={() => setIsOpen(false)}>{children}</div>
                </div>
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};

export default IconDropdown;
