/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect, useState } from "react";
import DefaultHeader from "../../components/default-header/default-header";
import SmallNav from "../../components/small-nav/small-nav";
// import organisations from "../../organizations";
import IconDropdown from "../../components/icon-dropdown/icon-dropdown";
import Search from "../../components/search/search";
import ModalContainer, {
	PageLoader,
} from "../../components/modal-container/modal-container";
import { BiDownload } from "react-icons/bi";
import Button, { LoadMore, MainPaginate } from "../../components/button/button";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RiDeleteBin6Line, RiSendPlaneFill } from "react-icons/ri";
import RightDrawer from "../../components/right-drawer/right-drawer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import Extractor from "../../data/useExtractor";
import { GlobalState } from "../../data/Context";
import { toCamel } from "../../App";
import moment from "moment";
import RawSample from "../../assets/Rolodex Invitation Sample.xlsx";
import {
	manageOrganization,
	resetOrganizationSearch,
} from "../../data/Reducers/OrganizationReducer";
import {
	SearchDropdownSelectExport,
	SortBy,
} from "../../components/search-dropdown/search-dropdown";

function Invitations() {
	const handleNav = item => {
		setActive(item);
	};
	const navigate = useNavigate();
	const [bulkUpload, setBulkUpload] = useState(false);
	const [drawer, setDrawer] = useState(false);

	const handleBulkUpload = () => {
		toggleUploadModal();
	};
	const toggleUploadModal = () => {
		setBulkUpload(!bulkUpload);
	};
	const toggleShowDrawer = it => {
		setDrawer(drawer ? null : it);
	};

	let { auth, invitations, organization } = useSelector(s => s),
		{ orgTheme, numberWithCommas } = useContext(GlobalState),
		[sort, setSort] = useState("");

	const addNewUser = () => {
		navigate(auth?.user?.company ? "recommendation" : "add-new-user");
	};

	useEffect(() => {
		if (auth?.user)
			if (!["organization"]?.includes(auth?.user?.privilege)) navigate(-1);
	}, [auth?.user, navigate]);

	let [loading, setLoading] = useState(null),
		[pres, setPres] = useState(null),
		dispatch = useDispatch(),
		[updater, setUpdater] = useState(null);

	const onSubmit = async () => {
			// console.log({ data });
			if (pres?.length === 0)
				return toast.info("Organizations' details required");
			setLoading(true);
			try {
				let newPres = [];
				for (let p = 0; p < pres.length; p++) {
					const element = pres[p];
					newPres?.push(toCamel(element));
				}
				let res = await axios.post(`/api/v1/organization/invite-user`, {
					nextScreen: `${window?.location?.origin}/signup/verify-profile`,
					privilege: "staff",
					staffs: newPres,
				});
				console.log({ resp: res?.data });
				toast.success(res?.data?.message);
				setLoading(false);
				setPres(null);
				toggleUploadModal();
				dispatch(
					manageOrganization("get", {
						statusText: "activated",
						companies: "companies",
					})
				);
				dispatch(
					manageOrganization("get", {
						companies: "companies",
						statusText: "pending",
					})
				);
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				let error = err.response?.data?.error;
				if (error && error?.length > 1) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				} else {
					toast.error(
						err?.response?.data?.message ||
							err?.response?.data?.error?.[0]?.message ||
							err?.message
					);
				}
			}
			setLoading(false);
		},
		updateSubmit = async e => {
			e?.preventDefault();
			// console.log({ data });
			if (!updater) return toast.info("Updated invite/organization required");
			setLoading(true);
			try {
				let res = await axios.put(`/api/v1/organization/bulk-companies-file`, {
					invitations: updater?.invitations,
					type: updater?.type,
					nextScreen: `${window?.location?.origin}/verify`,
					nextScreen2: `${window?.location?.origin}/signup/verify-profile`,
					privilege: "company",
				});
				console.log({ resp: res?.data });
				toast.success(res?.data?.message);
				setLoading(false);
				setUpdater(null);
				dispatch(
					manageOrganization("get", {
						companies: "companies",
						statusText: "activated",
					})
				);
				dispatch(
					manageOrganization("get", {
						companies: "companies",
						statusText: "pending",
					})
				);
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				let error = err.response?.data?.error;
				if (error && error?.length > 1) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				} else {
					toast.error(
						err?.response?.data?.message ||
							err?.response?.data?.error?.[0]?.message ||
							err?.message
					);
				}
			}
			setLoading(false);
		};

	let tabViews = [
		`All Users ${
			invitations?.data?.totalDocs > 0
				? ` (${numberWithCommas(invitations?.data?.totalDocs)})`
				: ""
		}`,
		`Pending Invitations ${
			invitations?.pending?.totalDocs > 0
				? ` (${numberWithCommas(invitations?.pending?.totalDocs)})`
				: ""
		}`,
		auth?.user?.company
			? `Pending Recommendation ${
					organization?.companiesRecommendation?.totalDocs > 0
						? ` (${numberWithCommas(
								organization?.companiesRecommendation?.totalDocs
						  )})`
						: ""
			  }`
			: null,
	];
	let [active, setActive] = useState(
		`All Users ${
			invitations?.data?.totalDocs > 0
				? ` (${numberWithCommas(invitations?.data?.totalDocs)})`
				: ""
		}`
	);

	useEffect(() => {
		dispatch(resetOrganizationSearch());
		dispatch(
			manageOrganization("get", {
				companies: "companies",
				statusText: "activated",
				type: auth?.user?.privilege === "organization" ? "company" : "staff",
				sort,
			})
		);
		dispatch(
			manageOrganization("get", {
				companies: "companies",
				statusText: "pending",
				type: auth?.user?.privilege === "organization" ? "company" : "staff",
				sort,
			})
		);
		if (auth?.user?.company)
			dispatch(
				manageOrganization("get", {
					companies: "companies",
					statusText: "recommendation",
					type: auth?.user?.privilege === "organization" ? "company" : "staff",
					sort,
				})
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sort]);

	let [data, setData] = useState(null),
		[newSample, setNewSample] = useState([]),
		[search, setSearch] = useState("");

	useEffect(() => {
		if (organization?.isFound) setData(organization?.mainSearch);
		else if (active === tabViews[2])
			setData(organization?.companiesRecommendation);
		else if (active === tabViews[1]) setData(invitations?.pending);
		else setData(invitations?.data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		invitations?.data,
		active,
		invitations?.pending,
		organization?.companiesRecommendation,
		organization?.isFound,
		organization?.mainSearch,
	]);

	useEffect(() => {
		let newArr = [],
			newHeader = [];
		let newObj = {};
		if (data?.docs?.length > 0) {
			for (let r = 0; r < data?.docs.length; r++) {
				let element = data?.docs[r];
				newObj = {
					company:
						auth?.user?.privilege === "organization"
							? element.company?.organizationName || element?.organizationName
							: element?.organizationName || element.company?.organizationName,
					email: element?.email,
					role: element?.privilege,
					dateAdded: moment(element?.createdAt).format("L"),
				};
				newArr?.push(newObj);
				newObj = {};
			}
		}
		newHeader = ["Company", "Email", "Role", "Date Added"];
		let csvData = [
			newHeader,
			...newArr?.map(it => {
				let newA = Object?.keys(it)?.map(ic => it?.[ic]);
				return newA;
			}),
		];
		// console.log({ csvData });
		setNewSample(csvData);
	}, [data, auth?.user]);

	let handleLoadMore = async () => {
		setLoading("loadmore");

		let dd = {
			companies: "companies",
			limit: data?.limit * data?.nextPage,
			type: auth?.user?.privilege === "organization" ? "company" : "staff",
		};
		if (active === tabViews?.[0])
			dd = {
				...dd,
				statusText: "activated",
			};
		if (active === tabViews?.[1])
			dd = {
				...dd,
				statusText: "pending",
			};
		if (active === tabViews?.[2])
			dd = {
				...dd,
				statusText: "recommendation",
			};
		if (search) {
			dd = { ...dd, search };
		}
		if (sort) dd = { ...dd, sort };

		await dispatch(manageOrganization("get", dd));
		setLoading(false);
	};

	let handleSearch = async () => {
		setLoading("loadmore");

		let dd = {
			companies: "companies",
			type: auth?.user?.privilege === "organization" ? "company" : "staff",
		};
		if (active === tabViews?.[0])
			dd = {
				...dd,
				statusText: "activated",
			};
		if (active === tabViews?.[1])
			dd = {
				...dd,
				statusText: "pending",
			};
		if (active === tabViews?.[2])
			dd = {
				...dd,
				statusText: "recommendation",
			};
		if (search) {
			dd = { ...dd, search };
		}
		if (sort) dd = { ...dd, sort };

		await dispatch(manageOrganization("get", dd));
		setLoading(false);
	};

	useEffect(() => {
		if (search) {
			document.getElementById("Search").addEventListener("search", () => {
				dispatch(resetOrganizationSearch());
			});
			let handleSubmit = async () => {
				if (!search) return;

				await handleSearch();
			};
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	let [range] = useState(10),
		[page, setPage] = useState(1);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	if (active === tabViews[0])
		if (!data && !invitations?.data) return <PageLoader />;

	if (active === tabViews[1])
		if (!data && !invitations?.pending) return <PageLoader />;

	if (active === tabViews[2])
		if (!data && !organization?.companiesRecommendation) return <PageLoader />;

	const currentItems = data
		? [...data?.docs]?.slice(itemOffset, endOffset)
		: [];
	const pageCount = Math.ceil(data?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % data?.docs?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	return (
		<div>
			<DefaultHeader
				mainTitle={"Invitations"}
				title1={auth?.user?.privilege === "organization" ? "Bulk upload" : ""}
				title2={
					auth?.user?.privilege === "organization"
						? "Add New User"
						: "Recommend Organization"
				}
				handleBulkUpload={handleBulkUpload}
				addNew={addNewUser}
				button
			/>
			<div className="bg-white rounded-lg p-4">
				<SmallNav
					menu={tabViews?.filter(e => e)}
					handleNav={handleNav}
					active={active}
				/>
				<div className="flex items-center gap-2 justify-between my-4">
					<Search
						placeholder={"Search Invitations"}
						search={search}
						setSearch={setSearch}
					/>
					<div className="flex items-center gap-2">
						<SortBy sort={sort} setSort={setSort} />
						<SearchDropdownSelectExport
							newSample={newSample}
							fileName={"Rolodex Invitation"}
						/>
						{data?.docs?.some?.(ic => ic?.isChecked) && (
							<Button
								buttonType={"primary"}
								children={"Cancel Invitation"}
								type="button"
								css={"w-fit me-2 py-3"}
								onClick={() =>
									setUpdater({
										type: "cancel",
										invitations: data?.docs
											?.filter(iz => iz?.isChecked)
											?.map(ia => {
												return { invitationId: ia?._id };
											}),
									})
								}
							/>
						)}
					</div>
				</div>
				<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
					<table className="w-full text-sm text-left text-gray-500">
						<thead className="text-md text-gray-700 capitalize bg-secondary">
							<tr>
								{active === tabViews[1] && !auth?.user?.company && (
									<th scope="col" className="p-4">
										<div className="flex items-center">
											<input
												id="checkbox-all-search"
												type="checkbox"
												className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
												onChange={e => {
													let dd = data?.docs;
													dd = dd?.map(ic => {
														let dc = ic;
														return { ...dc, isChecked: e?.target?.checked };
													});
													setData({ ...data, docs: dd });
												}}
												checked={
													data?.docs?.length > 0 &&
													data?.docs?.every(it => it?.isChecked)
												}
											/>
											<label for="checkbox-all-search" className="sr-only">
												checkbox
											</label>
										</div>
									</th>
								)}
								<th scope="col" className="px-6 py-3">
									{auth?.user?.privilege === "organization"
										? "Company"
										: "Name"}
								</th>
								<th scope="col" className="px-6 py-3">
									Email Address
								</th>
								{/* <th scope="col" className="px-6 py-3">
									Name
								</th> */}
								<th scope="col" className="px-6 py-3">
									Role
								</th>
								<th scope="col" className="px-6 py-3">
									Date Added
								</th>
								{auth?.user?.privilege === "organization" && (
									<th scope="col" className="px-6 py-3"></th>
								)}
							</tr>
						</thead>
						<tbody>
							{currentItems?.map((organization, i) => (
								<tr key={i} className="bg-white border-b hover:bg-gray-50">
									{active === tabViews[1] && !auth?.user?.company && (
										<td className="w-4 p-4">
											<div className="flex items-center">
												<input
													id="checkbox-table-search-1"
													type="checkbox"
													className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
													onChange={e => {
														let dd = data?.docs;
														let current = organization;
														if (e?.target?.checked) {
															current = {
																...current,
																isChecked: true,
															};
														} else {
															current = {
																...current,
																isChecked: false,
															};
														}
														dd = dd?.map(ic =>
															ic?._id === organization?._id ? current : ic
														);
														setData({ ...data, docs: dd });
													}}
													checked={organization?.isChecked}
												/>
												<label
													for="checkbox-table-search-1"
													className="sr-only">
													checkbox
												</label>
											</div>
										</td>
									)}
									<th
										scope="row"
										className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:underline cursor-pointer"
										onClick={
											auth?.user?.privilege === "organization"
												? () => toggleShowDrawer(organization)
												: null
										}>
										{auth?.user?.privilege === "organization" ? (
											<>
												{organization.company?.organizationName ||
													organization?.organizationName}
											</>
										) : (
											<>
												{organization?.organizationName ||
													organization.company?.organizationName}
											</>
										)}
									</th>
									<td className="px-6 py-4">{organization.email}</td>
									{/* <td className="px-6 py-4">{organization.contactName}</td> */}
									<td className="px-6 py-4 uppercase">
										{organization?.privilege}
										{organization.role?.title ? (
											<>, ({organization?.role?.title})</>
										) : null}
									</td>
									<td className="px-6 py-4">
										{moment(organization?.createdAt).format("MM-DD-YYYY")}
									</td>
									{auth?.user?.privilege === "organization" && (
										<td className="flex items-center px-6 py-4 space-x-3">
											<IconDropdown>
												<ul className="space-y-1">
													<li
														className="text-xs flex items-center gap-1 cursor-pointer hover:text-gray-600"
														onClick={() => toggleShowDrawer(organization)}>
														<span>
															<AiOutlineCheckCircle />
														</span>
														<span>View Profile</span>
													</li>
													{active === tabViews[1] && (
														<>
															<li
																onClick={() => {
																	setUpdater({
																		type: "send",
																		invitations: [
																			{
																				invitationId: organization?._id,
																			},
																		],
																	});
																}}
																className="text-green-400 texs-xs flex items-center gap-1 cursor-pointer hover:text-green-600">
																<span>
																	<RiSendPlaneFill />
																</span>
																<span>Resend Invitation</span>
															</li>
															<li
																onClick={() => {
																	setUpdater({
																		type: "cancel",
																		invitations: [
																			{
																				invitationId: organization?._id,
																			},
																		],
																	});
																}}
																className="text-red-400 texs-xs flex items-center gap-1 cursor-pointer hover:text-red-600">
																<span>
																	<RiDeleteBin6Line />
																</span>
																<span>Cancel Invitation</span>
															</li>
														</>
													)}
												</ul>
											</IconDropdown>
										</td>
									)}
								</tr>
							))}
						</tbody>
					</table>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && data?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
				</div>
			</div>
			<ModalContainer
				show={bulkUpload}
				close={toggleUploadModal}
				title={"Upload new organisations"}
				subtitle={
					"Add new organisation from an excel or csv file. Download sample file"
				}>
				<Extractor pres={pres} setPres={setPres} />
				<div className="flex items-center gap-1 justify-between">
					<a
						style={{
							color: orgTheme || "",
						}}
						href={RawSample}
						download={RawSample?.name}
						target="_blank"
						rel="noreferrer"
						className="text-main flex items-center gap-1">
						<span>
							<BiDownload />
						</span>
						<p className="text-xs">Download sample file</p>
					</a>
					<div className="flex items-center gap-4">
						<Button
							buttonType={"secondary"}
							children={"Cancel"}
							css={"h-8"}
							onClick={toggleUploadModal}
						/>
						<Button
							buttonType={"primary"}
							children={"Import"}
							css={"h-8"}
							loading={loading}
							type="submit"
							onClick={onSubmit}
						/>
					</div>
				</div>
			</ModalContainer>
			<RightDrawer drawer={drawer} toggleShowDrawer={toggleShowDrawer} />
			<ModalContainer
				title={"Update Invitation"}
				width={"max-w-sm"}
				show={updater ? true : false}
				close={() => setUpdater(null)}>
				<div className="mx-20">
					<form className="space-y-4">
						<div className="my-auto w-100">
							<p className="text2 Lexend text-center">
								Do you want to {updater?.type} this invitation
								{updater?.type === "send" ? "s" : ""}?
							</p>
							<div className="pt-4 flex">
								<Button
									buttonType={"primary"}
									children={"Yes"}
									type="button"
									css={"w-fit me-2 py-3"}
									loading={loading}
									onClick={updateSubmit}
								/>
								<Button
									buttonType={"secondary"}
									children={"No"}
									type="button"
									css={"w-fit ms-2 py-3"}
									onClick={() => setUpdater(null)}
								/>
							</div>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
}

export default Invitations;
