// Your D3 code here
let organization = [
  {
    organization: ["3 x 4 Genetics"],
    expertise: ["Agriculture", "Artificial Inteligence"],
    geography: ["Central Africa", "Carebean"],
    sector: ["Aerospace", "Education"]
  },
  {
    organization: ["54 Gene"],
    expertise: ["Agriculture", "Capacity Building"],
    geography: ["Central Africa", "Central Asia"],
    sector: ["Aerospace", "Education"]
  },
  {
    organization: ["AccessMod, University of Geneva"],
    expertise: ["Artificial Inteligence", "Data Generation", "Agriculture"],
    geography: ["Central America", "East Africa"],
    sector: ["Digital Services", "Education"]
  },
  {
    organization: ["Academic City University"],
    expertise: ["Capacity Building", "Data Management"],
    geography: ["Central Asia", "East Asia"],
    sector: ["Education", "Energy"]
  },
  {
    organization: ["Acasus"],
    expertise: ["Data Generation", "Data Science"],
    geography: ["East Africa", "East Europe"],
    sector: ["Education", "Financial Services"]
  },
  {
    organization: ["Adanialabs/AI Center of Excellence (AICE)"],
    expertise: ["Data Management", "Data Science"],
    geography: ["East Africa", "East Europe"],
    sector: ["Energy", "Financial Services"]
  },
];

export default organization;
