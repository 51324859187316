let data2 = [
  {
    name: "EpiMap",
    tools: [
      "GIS & Mapping",
      "Implementation and scaling",
      "Data curation & integration",
      "Data validation",
    ],
  },
  {
    name: "Flowminder Foundation",
    tools: ["Data visualization", "Data validation"],
  },
  {
    name: "Fraym.IO",
    tools: ["Data curation & integration", "Microplanning"],
  },
  {
    name: "Humanitarian Open Street Map",
    tools: ["Microplanning", "Epidemiology"],
  },
  { name: "Kenya Space Agency", tools: ["Epidemiology", "Microplanning"] },
  {
    name: "Geo-Referenced Infrastr...",
    tools: ["Population Estimates", "Immunization M&E"],
  },
  {
    name: "Kaduna Bureau of Statistics",
    tools: ["Immunization M&E", "Capacity Building"],
  },
  {
    name: "Avyantra Health Tech...",
    tools: ["Data validation", "Capacity Building"],
  },
  {
    name: "Agence Nationale du Genie...",
    tools: ["Capacity Building", "GIS & Mapping", "Data management"],
  },
  {
    name: "African Regional Institute ...",
    tools: ["Data management", "GIS & Mapping", "Implementation and scaling"],
  },
  {
    name: "Research Institute",
    tools: ["Implementation and scaling", "Data management"],
  },
  {
    name: "Company DUI",
    tools: [
      "Implementation and scaling",
      "Data curation & integration",
      "Microplanning",
    ],
  },
  { name: "Company dux..", tools: ["Microplanning", "GIS & Mapping"] },
  {
    name: "Digital Earth Africa",
    tools: ["Data visualization", "GIS & Mapping", "Surveillance"],
  },
  {
    name: "eHealth Africa",
    tools: ["Immunization M&E", "Population Estimates"],
  },
  { name: "Cizoti Nigeria", tools: ["Data management", "Surveillance"] },
  {
    name: "Data Scientist Network",
    tools: ["Surveillance", "Population Estimates"],
  },
  { name: "Akros", tools: ["Population Estimates", "Capacity Building"] },
  { name: "AFRIGIST", tools: ["Population Estimates", "GIS & Mapping"] },
  {
    name: "EpiMap",
    tools: [
      "GIS & Mapping",
      "Implementation and scaling",
      "Data curation & integration",
      "Data validation",
    ],
  },
  {
    name: "Flowminder Foundation",
    tools: ["Data visualization", "Data validation"],
  },
  {
    name: "Fraym.IO",
    tools: ["Data curation & integration", "Microplanning"],
  },
  {
    name: "Humanitarian Open Street Map",
    tools: ["Microplanning", "Epidemiology"],
  },
  { name: "Kenya Space Agency", tools: ["Epidemiology", "Microplanning"] },
  {
    name: "Geo-Referenced Infrastr...",
    tools: ["Population Estimates", "Immunization M&E"],
  },
  {
    name: "Kaduna Bureau of Statistics",
    tools: ["Immunization M&E", "Capacity Building"],
  },
  {
    name: "Avyantra Health Tech...",
    tools: ["Data validation", "Capacity Building"],
  },
  {
    name: "Agence Nationale du Genie...",
    tools: ["Capacity Building", "GIS & Mapping", "Data management"],
  },
  {
    name: "African Regional Institute ...",
    tools: ["Data management", "GIS & Mapping", "Implementation and scaling"],
  },
  {
    name: "Research Institute",
    tools: ["Implementation and scaling", "Data management"],
  },
  {
    name: "Company DUI",
    tools: [
      "Implementation and scaling",
      "Data curation & integration",
      "Microplanning",
    ],
  },
  { name: "Company dux..", tools: ["Microplanning", "GIS & Mapping"] },
  {
    name: "Digital Earth Africa",
    tools: ["Data visualization", "GIS & Mapping", "Surveillance"],
  },
  {
    name: "eHealth Africa",
    tools: ["Immunization M&E", "Population Estimates"],
  },
  { name: "Cizoti Nigeria", tools: ["Data management", "Surveillance"] },
  {
    name: "Data Scientist Network",
    tools: ["Surveillance", "Population Estimates"],
  },
  { name: "Akros", tools: ["Population Estimates", "Capacity Building"] },
  { name: "AFRIGIST", tools: ["Population Estimates", "GIS & Mapping"] },
  {
    name: "EpiMap",
    tools: [
      "GIS & Mapping",
      "Implementation and scaling",
      "Data curation & integration",
      "Data validation",
    ],
  },
  {
    name: "Flowminder Foundation",
    tools: ["Data visualization", "Data validation"],
  },
  {
    name: "Fraym.IO",
    tools: ["Data curation & integration", "Microplanning"],
  },
  {
    name: "Humanitarian Open Street Map",
    tools: ["Microplanning", "Epidemiology"],
  },
  { name: "Kenya Space Agency", tools: ["Epidemiology", "Microplanning"] },
  {
    name: "Geo-Referenced Infrastr...",
    tools: ["Population Estimates", "Immunization M&E"],
  },
  {
    name: "Kaduna Bureau of Statistics",
    tools: ["Immunization M&E", "Capacity Building"],
  },
  {
    name: "Avyantra Health Tech...",
    tools: ["Data validation", "Capacity Building"],
  },
  {
    name: "Agence Nationale du Genie...",
    tools: ["Capacity Building", "GIS & Mapping", "Data management"],
  },
  {
    name: "African Regional Institute ...",
    tools: ["Data management", "GIS & Mapping", "Implementation and scaling"],
  },
  {
    name: "Research Institute",
    tools: ["Implementation and scaling", "Data management"],
  },
  {
    name: "Company DUI",
    tools: [
      "Implementation and scaling",
      "Data curation & integration",
      "Microplanning",
    ],
  },
  { name: "Company dux..", tools: ["Microplanning", "GIS & Mapping"] },
  {
    name: "Digital Earth Africa",
    tools: ["Data visualization", "GIS & Mapping", "Surveillance"],
  },
  {
    name: "eHealth Africa",
    tools: ["Immunization M&E", "Population Estimates"],
  },
  { name: "Cizoti Nigeria", tools: ["Data management", "Surveillance"] },
  {
    name: "Data Scientist Network",
    tools: ["Surveillance", "Population Estimates"],
  },
  { name: "Akros", tools: ["Population Estimates", "Capacity Building"] },
  { name: "AFRIGIST", tools: ["Population Estimates", "GIS & Mapping"] },
];

export default data2;
