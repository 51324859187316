import CircleCheck from "../../assets/circle-check.svg";
import { useNavigate } from "react-router-dom";

const NewPasswordSuccess = ({ success }) => {
	const navigate = useNavigate();

	const goBackLogin = () => {
		if (typeof success === "string") window.location.replace(success);
		else navigate("/");
	};

	return (
		<div className="flex flex-col bg-white px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg">
			<div className="relative px-8 z-30 w-full mb-12">
				<img
					src={require("../../assets/logo-black.png")}
					className="h-6 md:h-10 mt-4"
					alt=""
				/>
			</div>
			<div className="font-medium self-center  text-gray-800 mb-2">
				<div className="flex flex-col items-center mb-5">
					<img src={CircleCheck} alt="CircleCheck" />
				</div>
				<h1 className="text-center text-2xl md:text-2xl xl:text-3xl">
					Password reset successful
				</h1>
			</div>
			<div className="self-center text-gray-800">
				<h2 className="text-center text-base md:text-lg lg:text-xl xl:text-2xl font-light">
					Your new password is ready to go - you can now access your workspace.
				</h2>
			</div>
			<div className="mt-5 md:mt-7 lg:mt-10">
				<button
					type="button"
					className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
					onClick={goBackLogin}>
					Go back to login
				</button>
			</div>
		</div>
	);
};

export default NewPasswordSuccess;
