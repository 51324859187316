import React, { useContext, useEffect } from "react";
import { MdOutlineNavigateNext } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { GlobalState } from "../../data/Context";
import { useDispatch, useSelector } from "react-redux";
import { manageRawData } from "../../data/Reducers/RawDataReducer";

const GetStarted = () => {
	const navigate = useNavigate();

	let { orgTheme } = useContext(GlobalState),
		{ auth } = useSelector(s => s),
		dispatch = useDispatch()

		useEffect(() => {
			if (auth?.user)
				if (auth?.user?.privilege !== "organization") navigate(-1);
		}, [auth?.user, navigate]);

		useEffect(() => {
			dispatch(manageRawData("get"));
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

	return (
		<div>
			<div className="inset-0 bg-white fixed"></div>
			<div className="relative z-10">
				<p className="py-4 f-medium text-xl">Get started</p>
				<hr />
				<div className="my-8">
					<img
						src={require("../../assets/space-ship.png")}
						alt=""
						className="h-32 mx-auto mb-8"
					/>
					<div className="my-4">
						<p className="text-2xl f-bold text-center">
							Welcome,{" "}
							<span
								className="t text-bluerolodex"
								style={{
									color: orgTheme || "",
								}}>
								{auth?.user?.organizationName}
							</span>
						</p>
						<p className="f-light text-gray-500 text-center">
							Complete these simple steps to get your stakeholder map up and
							running
						</p>
					</div>
					<div className="space-y-4">
						<div
							className="max-w-xl mx-auto border-2 border-lightblue p-4 rounded-xl flex items-center justify-between cursor-pointer"
							onClick={() => navigate("steps")}>
							<div className="w-full">
								<p
									style={{
										color: orgTheme || "",
									}}
									className="f-bold text-lg text-bluerolodex">
									Create your dataset
								</p>
								<p className="f-light text-sm">
									Design a custom structure for your content. The structure is
									like a schema.
								</p>
							</div>
							<span>
								<MdOutlineNavigateNext size={24} />
							</span>
						</div>
						<div className="max-w-xl mx-auto p-4 rounded-xl flex items-center justify-between cursor-default text-gray-300">
							<div className="w-full">
								<p className="f-semibold text-lg">Create your dataset</p>
							</div>
							<span>
								<MdOutlineNavigateNext size={24} />
							</span>
						</div>
						<div className="max-w-xl mx-auto p-4 rounded-xl flex items-center justify-between cursor-default text-gray-300">
							<div className="w-full">
								<p className="f-semibold text-lg">Create your dataset</p>
							</div>
							<span>
								<MdOutlineNavigateNext size={24} />
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GetStarted;
