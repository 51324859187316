import { useLocation, useNavigate } from "react-router-dom";

const SignupSuccess = () => {
	const navigate = useNavigate();
	let { state } = useLocation();

	const onSubmit = () => {
		state?.url
			? window.location.replace(state?.url)
			: navigate("/login", { state });
	};

	if (!state?.email) navigate(-1);

	return (
		<section className="min-h-screen flex flex-col items-center space-y-5 justify-center">
			<div className="fixed inset-0 bg-white"></div>
			<div
				className="fixed top-0 px-8 z-30 w-full mb-12 cursor-pointer"
				onClick={() => navigate("/")}>
				<img
					src={require("../../assets/logo-black.png")}
					className="h-6 md:h-10"
					alt=""
				/>
			</div>
			<div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10">
				<div>
					<img
						src={require("../../assets/success-gif.gif")}
						alt=""
						className="mx-auto"
					/>
				</div>
				<h1 className="text-2xl md:text-2xl xl:text-3xl font-bold text-center">
					Awesome! Let's get started
				</h1>
				<p className="p-0.5 px-2 text-center mb-8 max-w-sm mx-auto">
					Your account is ready to go - you can now access your workspace.
				</p>
				<button
					className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
					onClick={onSubmit}>
					Begin
				</button>
			</div>
		</section>
	);
};

export default SignupSuccess;
