import React, { Fragment } from "react";
import { RiShareBoxFill } from "react-icons/ri";
import { PiCopyFill } from "react-icons/pi";
import { toast } from "react-toastify";

export const Article = ({ title, text, style }) => {
	return (
		<article className={`p-2 rounded-xl border border-gray-300 ${style}`}>
			<p className="text-sm font-semibold mb-2">{title}</p>
			<p className="text-xs text-gray-400">{text}</p>
		</article>
	);
};
export default Article;

export const MediaCard = ({ style, title, text, date }) => {
	return (
		<article className={`p-2 rounded border border-gray-300 ${style}`}>
			<div className="h-32 overflow-hidden">
				<img
					src={text || require("../../assets/media-1.png")}
					alt=""
					className="w-full"
				/>
			</div>
			<p className="text-gray-400 font-medium uppercase text-xs mt-2">{date}</p>
			<p className="text-sm font-semibold mb-2">{title}</p>
			<p className="text-xs text-gray-400 flex items-center whitespace-nowrap gap-4">
				<span className="w-4/5 overflow-hidden">{text}</span>{" "}
				<span className="flex items-center gap-1">
					<span className="cursor-pointer">
						<RiShareBoxFill
							onClick={text ? () => window.open(text, "_blank") : null}
							className="cursor-pointer"
						/>
					</span>
					<span className="cursor-pointer">
						<PiCopyFill
							onClick={
								text
									? () => {
											navigator.clipboard.writeText(text).then(
												() => {
													toast.info("Copied", { autoClose: 2000 });
												},
												err => {
													toast.warn(`Could not copy: ${err}`, {
														autoClose: 2000,
													});
												}
											);
									  }
									: null
							}
							className="cursor-pointer"
						/>
					</span>
				</span>
			</p>
		</article>
	);
};

export const UseCaseCard = ({ style, title, text, date, item }) => {
	// console.log({ item });
	return (
		<article
			className={`flex gap-2 p-2 rounded border border-gray-300 ${style}`}>
			<div className=" w-16 h-16 overflow-hidden">
				<img
					src={
						text && !text?.endsWith(".pdf")
							? text
							: require("../../assets/media-1.png")
					}
					alt=""
					className="h-full w-full object-cover object-center"
				/>
			</div>
			<div className="w-80">
				<p className="text-sm font-semibold mb-1">{title}</p>
				<p className="text-xs text-gray-400 flex items-center whitespace-nowrap gap-4">
					{/* <span
						className="w-4/5 overflow-hidden cursor-pointer"
						onClick={text ? () => window.open(text, "_blank") : null}>
						{text}
					</span>{" "} */}
					<span className="w-4/5 overflow-hidden"></span>{" "}
					<span className="flex items-center gap-1">
						<span className="cursor-pointer">
							<RiShareBoxFill
								onClick={text ? () => window.open(text, "_blank") : null}
								className="cursor-pointer"
							/>
						</span>
						<span className="cursor-pointer">
							<PiCopyFill
								onClick={
									text
										? () => {
												navigator.clipboard.writeText(text).then(
													() => {
														toast.info("Copied", { autoClose: 2000 });
													},
													err => {
														toast.warn(`Could not copy: ${err}`, {
															autoClose: 2000,
														});
													}
												);
										  }
										: null
								}
								className="cursor-pointer"
							/>
						</span>
					</span>
				</p>
				<p className="text-gray-400 font-medium uppercase text-xs">{date}</p>
			</div>
		</article>
	);
};

export const ExperienceCard = ({ style, title, item }) => {
	return (
		<article
			className={`flex gap-2 p-2 rounded border border-gray-300 ${style}`}>
			<div className="w-full">
				<p className="text-sm font-semibold mb-1">
					Have you worked with donor: {title}
				</p>
				{title === "Yes" && item?.donorList?.length > 0 && (
					<>
						<p className="text-sm font-semibold mb-1">
							What donor have you worked with:
						</p>
						<p className="text-gray-400 font-medium uppercase text-xs flex justify-between my-1">
							<span>Donor Name</span>
							<span>Number of Years</span>
						</p>
					</>
				)}
				{item?.donorList?.map((ic, x) => (
					<Fragment key={x}>
						<p className="text-gray-400 font-medium uppercase text-xs flex justify-between my-1">
							<>
								{x + 1}. {ic?.donorName}
							</>
							<span>{ic?.noOfYears}</span>
						</p>
						{ic?.projectDescription && (
							<p className="text-gray-400 font-medium text-xs my-1 ps-5">
								{ic?.projectDescription}
							</p>
						)}
					</Fragment>
				))}
			</div>
		</article>
	);
};
