import React, { useContext } from "react";
import { GlobalState } from "../../data/Context";

export const SmallNav = ({ menu, handleNav, active, full, theme }) => {
	// let [active, setActive] = useState(menu?.[0]);

	let { orgTheme } = useContext(GlobalState);
	return (
		<div className="">
			<nav className="flex items-center border-b gap-2 px-1 overflow-x-auto  scrollbar-hide">
				{menu?.map((item, i) => (
					<div
						key={i}
						onClick={handleNav ? () => handleNav(item) : null}
						className={`py-2 font-body capitalize ${
							full ? "w-full" : "w-fit"
						} whitespace-nowrap text-sm pr-2 cursor-pointer border-b-2 ${
							active === item
								? "border-main f-bold"
								: "border-transparent text-gray-600"
						}`}
						style={{
							borderColor:
								(orgTheme || theme) && active === item ? orgTheme || theme : "",
						}}>
						{item?.replace("-", " ")}
					</div>
				))}
			</nav>
		</div>
	);
};

export default SmallNav;
