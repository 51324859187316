import { createSlice } from "@reduxjs/toolkit";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	mapFilter: null,
	isMappable: null,
};

export const mapSlice = createSlice({
	name: "map",
	initialState,
	reducers: {
		getMap: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		getMapFilter: (state, { payload }) => {
			state.mapFilter = payload?.data || payload;
			state.isMappable = payload?.isMappable || null;
		},
		resetMap: state => {
			state.data = null;
		},
		logoutMap: state => {
			state.data = null;
			state.mapFilter = null;
		},
	},
});
export const { getMap, logoutMap, getMapFilter, resetMap } = mapSlice.actions;

export const manageMap = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			if (data?.map) {
				res = await axios.get(`/api/v1/rawdata/map-filter`);
				dispatch(getMapFilter(res?.data));
			} else {
				res = await axios.get(
					`/api/v1/rawdata/map${
						data?.firstFilter ? `?firstFilter=${data?.firstFilter}` : ""
					}${data?.secondFilter ? `&secondFilter=${data?.secondFilter}` : ""}${
						data?.organisation ? `&organisation=${data?.organisation}` : ""
					}`
				);
				dispatch(getMap(res?.data));
			}
		}

		if (type !== "get") toast.success(res?.data?.message);
	} catch (err) {
		if (err?.response?.status === 429 || err?.response?.status === 405)
			toast.error(err?.response?.data ? err?.response?.data : err?.message);
		console.log({ err });
		// dispatch(logoutMap());
		if (type && type !== "get") {
			let error =
				Array.isArray(err.response?.data?.error) ||
				Array.isArray(err?.response?.data?.message);
			if (error) {
				dispatch(
					returnErrors({
						error: Array.isArray(err.response?.data?.message)
							? err.response?.data?.message
							: err.response?.data?.error,
						status: err?.response?.status,
					})
				);
			} else {
				toast.error(err?.response?.data?.message || err?.response?.data?.error);
			}
		}
	}
};
