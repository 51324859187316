import React, { useContext, useState } from "react";
import { IoIosClose } from "react-icons/io";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useRole,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useHover,
  useFocus,
} from "@floating-ui/react";
import { GlobalState } from "../../data/Context";
import { useLocation, useNavigate } from "react-router-dom";

import "./style.tooltip.css";

const Tooltip = ({ refElement, place, close, isOpen }) => {
  const { unboarding, setUnboarding } = useContext(GlobalState);
  const navigate = useNavigate();
  const location = useLocation();

  const unboardNext = () => {
    console.log("unboardNext");
    // get unboarding list
    const unboardinglist = JSON.parse(localStorage.getItem("starterList"));
    // get index of location
    const itemIndex = unboardinglist.findIndex(
      (item) => item === location.pathname
    );
    const newList = unboardinglist.filter((item) => item !== location.pathname);
    console.log({ newList });
    if (newList.length === 0) {
      setUnboarding(false);
      localStorage.removeItem("Starter");
      localStorage.removeItem("starterList");
      return navigate("/map");
    }
    localStorage.setItem("starterList", JSON.stringify(newList));
    setUnboarding(false);
    if (!newList[itemIndex]) {
      navigate(newList[0]);
    } else {
      navigate(newList[itemIndex]);
    }
  };
  const { refs, floatingStyles, context } = useFloating({
    placement: place,
    strategy: "fixed",
    open: isOpen,
    onOpenChange: setUnboarding,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    // If your reference element has its own label (text).
    // role: "tooltip",
    // If your reference element does not have its own label,
    // e.g. an icon.
    role: "label",
  });
  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    role,
    dismiss,
  ]);

  return (
    <>
      {isOpen && <div className="fixed inset-0 bg-[#1018283f] z-[90]"></div>}
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        className={`z-[100] w-full text-left relative p-2 ${
          isOpen && "bg-white rounded-md"
        }`}
      >
        {refElement}
      </button>
      {isOpen && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          className="bg-[#101828] text-white p-4 rounded-lg max-w-xs space-y-2 z-[100]"
        >
          <div className="flex items-center justify-between f-medium">
            <h3 className="text-sm">This is a tooltip</h3>
            <button className="cursor-pointer" onClick={close}>
              <IoIosClose />
            </button>
          </div>
          <p className="text-xs font-thin">
            This is a tooltip Tooltips are used to describe or identify an
            element. In most scenarios, tooltips help the user understand the
            meaning, function or alt-text of an element.
          </p>
          <div className="text-xs text-white flex justify-between">
            <p>Guide 1/10</p>
            <div className="s space-x-2 f-light">
              <button>Previous</button>
              <button
                className="b bg-main rounded-md px-4 py-1 text-white"
                onClick={unboardNext}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Tooltip;

export const BasicTooltip = ({ children, tooltip }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "right",
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: "start",
      }),
      shift(),
    ],
  });

  // Event listeners to change the open state
  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, { role: "tooltip" });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return (
    <>
      <button ref={refs.setReference} {...getReferenceProps()}>
        {children || "hover me"}
      </button>
      <FloatingPortal>
        {isOpen && (
          <div
            className="Tooltip ml-2 z-50"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {tooltip}
          </div>
        )}
      </FloatingPortal>
    </>
  );
};
