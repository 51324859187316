const rawData = [
  {
    category: "Expertise",
    data: [
      "Surveillance",
      "Population Estimates",
      "GIS and Mapping",
      "Data curation & integration",
      "Data Visualization",
      "Epidemiology",
      "immunization M&E",
      "Capacity Building",
      "Data Management",
      "implementation and scaling",
      "Microplaning",
    ],
  },
  {
    category: "Skills",
    data: [
      "Analytics",
      "Location Implementatiors",
      "Capacity Building",
      "Data visualization",
      "Microplanning",
      "Data Curation & Integration",
    ],
  },
  {
    category: "Sector",
    data: [
      "Education",
      "Digital Health",
      "Surveillance",
      "Population Estimates",
      "Digital health",
      "Capacity Building",
    ],
  },
  {
    category: "Geography",
    data: [
      "Niger",
      "Nigeria",
      "Mauritius",
      "Madagascar",
      "Zambia",
      "Guaeng. South Africa",
      "Morocco",
    ],
  },
  {
    category: "Services",
    data: [
      "Microplanning",
      "Data management",
      "Data Validation",
      "Epidemiology",
      "Training",
      "Implementation and Scaling",
    ],
  },
];

export default rawData;
