import { createSlice } from "@reduxjs/toolkit";

let initialState = {
	progress: null,
	socket: null,
};

export const socketSlice = createSlice({
	name: "socket",
	initialState,
	reducers: {
		getSocket: (state, { payload }) => {
			state.socket = payload;
		},
		getProgress: (state, { payload }) => {
			state.progress = payload;
		},
		logoutSocket: state => {
			state.socket = null;
			state.progress = null;
		},
	},
});
export const { getProgress, getSocket, logoutSocket } = socketSlice.actions;
