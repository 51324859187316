import { useLocation, useNavigate } from "react-router-dom";
// import Email from "../../assets/email.svg";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import Button from "../../components/button/button";

const SetupOrganisation = () => {
	// let navigate = useNavigate(),
	let { state } = useLocation();

	// if (!state?.token || !state?.email) navigate(-1);

	return <SetupOrganisationMain email={state?.email} />;
};

export default SetupOrganisation;

export const SetupOrganisationMain = ({ email }) => {
  const methods = useForm({
    defaultValues: {
      organizationName: "",
      email: "",
    },
  });

  let navigate = useNavigate(),
    { state } = useLocation();

  let [loading, setLoading] = useState(null),
    dispatch = useDispatch(),
    { auth } = useSelector((s) => s);

  // console.log(methods);

  const onSubmit = async (data) => {
    if (!data) return;
    setLoading(true);
    try {
      let res = await axios.post(`/api/v1/user/setup-organization`, {
        ...data,
      });
      console.log({ resp: res?.data }, "organization");
      toast.success(res?.data?.message);
      navigate(auth?.isAuth ? "/setup-workspace" : "/signup", {
        state: {
          ...state,
          org: res?.data?.data,
          email: email || data?.email,
          token: res?.data?.token,
        },
      });
    } catch (err) {
      if (err) console.log({ error: err.response?.data, err });
      if (err?.response?.status === 429) toast.error(err?.response?.data);
      let error = err.response?.data?.error;
      if (error && error?.length > 1) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
      } else {
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data?.error?.[0]?.message ||
            err?.message
        );
      }
    }
    setLoading(false);
  };

  return (
		<section className="min-h-screen flex flex-col items-center">
			<div className="fixed inset-0 bg-white"></div>
			<div
				className="fixed top-0 px-8 z-30 w-full mb-8 cursor-pointer"
				onClick={() => {
					if (window.location?.origin?.includes("geo-rolodex.com")) {
						window.location.replace("https://www.geo-rolodex.com/landing");
					} else if (
						window.location?.origin?.includes("map.policyvault.africa")
					) {
						window.location.replace("https://www.geo-rolodex.com/landing");
					} else {
						navigate("/");
					}
				}}>
				<img
					src={require("../../assets/logo-black.png")}
					className="h-6 md:h-10"
					alt=""
				/>
			</div>
			<div className="flex flex-col px-5 md:px-8 lg:px-1 mt-32 rounded-md w-10/12 lg:w-full max-w-lg relative z-10">
				<div className="flex items-center justify-between text-gray-800 mb-2">
					<h1 className="text-2xl md:text-2xl xl:text-3xl font-bold">
						<span>Setup Organization</span>
					</h1>
					{/* <span className="text-xs bg-gray-300 rounded-full p-0.5 px-2">
						{step} of 2
					</span> */}
				</div>
				<div></div>
				<div className="mt-4 mb-4">
					<p className="font-semibold text-lg">{"Organization name"}</p>
					<p className="text-sm text-gray-400">
						{"Give your organization a name"}
					</p>
				</div>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Controller
							name="organizationName"
							rules={{ required: "This field is required" }}
							render={({ field: { onChange, name } }) => (
								<fieldset className="flex flex-col mb-6">
									<input
										className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
										name={name}
										id={name}
										type={"text"}
										onChange={onChange}
										placeholder="Organization name"
									/>
									{methods.formState.errors.organizationName && (
										<span className="text-xs text-red-600">
											This field is required
										</span>
									)}
								</fieldset>
							)}
						/>
						<Controller
							name="email"
							rules={{ required: "This field is required" }}
							render={({ field: { onChange, name } }) => (
								<fieldset className="flex flex-col mb-6">
									<input
										className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
										name={name}
										id={name}
										type={name}
										onChange={onChange}
										placeholder="Enter email"
									/>
									{methods.formState.errors.email && (
										<span className="text-xs text-red-600">
											This field is required
										</span>
									)}
								</fieldset>
							)}
						/>

						<Button
							type="submit"
							loading={loading}
							children="Create Organization"
							// eslint-disable-next-line react/style-prop-object
							css="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
							// loadCss="text-bluerolodex"
							// onClick={onSubmit}
						/>
					</form>
				</FormProvider>
			</div>
		</section>
	);
};
