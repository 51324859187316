import { createSlice } from "@reduxjs/toolkit";
import { SetAuthToken } from "../Config";
import { clearErrors, getErrorText } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { logoutRawData, manageRawData } from "./RawDataReducer";
import { logoutOrganization, manageOrganization } from "./OrganizationReducer";
import { logoutInvitations } from "./InvitationReducer";
import { logoutUsecases, manageUsecases } from "./UsecasesReducer";
import { logoutWorkspace, manageWorkspace } from "./WorkspaceReducer";
import { logoutCompany } from "./CompanyReducer";
import { manageMap } from "./MapReducer";
// import { manageWorkspace } from "./WorkspaceReducer";
// import { clearErrors, getErrorText } from "./ErrorReducer";
// import { SetAuthToken } from "../Config";
// import axios from "axios";
// import { toast } from "react-toastify";

export const TOKEN = "ROLODEX_LOGIN";
export const TOKEN_2FA = "ROLODEX_LOGIN_2FA";
export const TOKEN_USER = "ROLODEX_USER";

let initialState = {
  user: null,
  token: localStorage.getItem(TOKEN),
  isAuth: false,
  loading: false,
  isRegistered: false,
  isLoggedIn: false,
  isUpdated: false,
  isPassword: null,
  role: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, { payload }) => {
      // console.log({ payload });
      localStorage.setItem(TOKEN, payload?.token);
      // localStorage.setItem(TOKEN_USER, JSON.stringify(payload));
      state.isLoggedIn = true;
      state.token = payload?.token;
      state.user = payload?.data || payload;
      state.is2FA = false;
    },
    login2FA: (state, { payload }) => {
      // console.log({ payload });
      localStorage.setItem(TOKEN_2FA, payload?.token);
      // localStorage.setItem(TOKEN_USER, JSON.stringify(payload));
      state.is2FA = true;
    },
    register: (state) => {
      state.isRegistered = true;
    },
    setUser: (state, { payload }) => {
      state.isUpdated = true;
      state.user = payload?.data || payload;
    },
    getUser: (state, { payload }) => {
      if (payload?.token) {
        localStorage.setItem(TOKEN, payload?.token);
      }
      state.user = payload?.data || payload || null;
      state.isAuth = payload?.data || payload ? true : false;
      state.loading = false;
    },
    getUserFail: (state) => {
      state.isAuth = false;
      state.loading = false;
    },
    getRole: (state, { payload }) => {
      state.role = payload?.data || payload;
    },
    getUserLoading: (state) => {
      state.loading = true;
    },
    setPassword: (state) => {
      state.isPassword = true;
    },
    setUserFail: (state) => {
      state.isUpdated = false;
      state.isLoggedIn = false;
      state.isRegistered = false;
      state.isPassword = false;
    },
    logout: (state) => {
      localStorage.removeItem(TOKEN);
      localStorage.removeItem(TOKEN_2FA);
      // localStorage.removeItem(TOKEN_USER);
      state.isAuth = false;
      state.user = null;
      state.token = null;
      state.role = null;
    },
    setStage: (state, { payload }) => {
      localStorage.setItem("Stage", payload);
      state.stage = payload;
    },
    setStarter: (state, { payload }) => {
      localStorage.setItem("Starter", payload);
      localStorage.setItem(
        "starterList",
        JSON.stringify([
          "/map",
          "/organisations",
          "/invitations",
          "/data",
          "/use-cases",
        ])
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  logout,
  getUser,
  setPassword,
  setUser,
  setUserFail,
  getUserFail,
  getUserLoading,
  register,
  getRole,
  login2FA,
  setStage,
  setStarter,
} = userSlice.actions;

export default userSlice.reducer;

// GET USER INFO
export const loadUser = () => async (dispatch) => {
  let token = localStorage.getItem(TOKEN);
  if (token) {
		SetAuthToken(token);
		dispatch(getUserLoading());
		dispatch(clearErrors());
		try {
			let res = await axios.get(`/api/v1/user`);
			// console.log({ auth: res?.data });

			// let user_token = localStorage.getItem(TOKEN_USER);
			if (res?.data) {
				let userToken = res?.data?.data || res?.data;
				dispatch(getUser(res?.data));
				dispatch(manageWorkspace("get"));
				dispatch(
					manageMap("get", {
						map: "map",
					})
				);
				if (userToken?.privilege === "organization") {
					dispatch(
						manageOrganization("get", {
							companies: "companies",
							active: "active",
						})
					);
				}
				if (["company", "organization"]?.includes(userToken?.privilege)) {
					dispatch(
						manageOrganization("get", {
							companies: "companies",
							statusText: "activated",
							type:
								userToken?.privilege === "organization" ? "company" : "staff",
						})
					);
					dispatch(
						manageOrganization("get", {
							companies: "companies",
							statusText: "pending",
							type:
								userToken?.privilege === "organization" ? "company" : "staff",
						})
					);
				}
				dispatch(manageRawData("get"));
				dispatch(manageUsecases("get"));
				dispatch(manageRole());
			} else {
				// dispatch(RefreshToken());
				dispatch(getUserFail());
			}
		} catch (err) {
			// dispatch(RefreshToken());
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			dispatch(getUserFail());
			let error =
					Array.isArray(err.response?.data?.error) ||
					Array.isArray(err?.response?.data?.message),
				newErr = "";
			if (error) {
				newErr = err.response?.data?.error;
			} else {
				newErr = err?.response?.data?.message || err?.response?.data?.error;
			}
			dispatch(getErrorText(newErr));
		}
	}
};

export const logoutMain = () => async (dispatch) => {
  dispatch(logoutRawData());
  dispatch(logoutInvitations());
  dispatch(logoutWorkspace());
  dispatch(logoutUsecases());
  dispatch(logoutOrganization());
  dispatch(logoutMain());
  dispatch(logoutCompany());
  dispatch(logout());
};

export const MergedData = (data, payload) => {
  let ids = new Set(payload.map((d) => d._id));
  let updatateData = [...payload, ...data.filter((d) => !ids.has(d._id))];
  return updatateData?.sort((a, b) => a?.createdAt - b?.createdAt);
};

export const EditData = (data, payload) => {
  let updatateData =
    data?.length > 0
      ? data.map((item) => (item._id !== payload._id ? item : payload))
      : data;
  return updatateData;
};

export const DeleteData = (data, payload) => {
  let filterItem =
    data?.length > 0
      ? [...data.filter((item) => item._id !== payload._id)]
      : [];
  return filterItem;
};

export const RefreshToken = () => async (dispatch) => {
  let token = localStorage.getItem(TOKEN_USER);
  // console.log({ token });
  if (token) {
    // SetAuthToken(token);
    let userToken = JSON.parse(localStorage.getItem(TOKEN_USER));
    if (userToken?.refreshToken) {
      try {
        let res = await axios.post(`/auth/refresh-token`, {
          token: userToken?.refreshToken,
        });
        console.log({ data: res?.data }, "refresh");
        if (res?.data?.token) {
          // localStorage.setItem(TOKEN, res?.data?.token);
          // dispatch(loadUser());
        }
      } catch (err) {
        if (err) console.log({ error: err.response?.data, err });
        if (err?.response?.status === 429) toast.error(err?.response?.data);
        let error =
            Array.isArray(err.response?.data?.error) ||
            Array.isArray(err?.response?.data?.message),
          newErr = "";
        if (error) {
          newErr = err.response?.data?.error;
        } else {
          newErr = err?.response?.data?.message || err?.response?.data?.error;
        }
        console.log({ newErr });
      }
    }
  }
};

export const manageRole = () => async (dispatch) => {
  try {
    let res = await axios.get(`/api/v1/role`);
    dispatch(getRole(res?.data));
  } catch (err) {
    if (err) console.log({ error: err.response?.data, err });
    if (err?.response?.status === 429) toast.error(err?.response?.data);
    let error =
        Array.isArray(err.response?.data?.error) ||
        Array.isArray(err?.response?.data?.message),
      newErr = "";
    if (error) {
      newErr = err.response?.data?.error;
    } else {
      newErr = err?.response?.data?.message || err?.response?.data?.error;
    }
    console.log({ newErr });
  }
};
