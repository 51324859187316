import React from "react";
import Button from "../button/button";
import { BiUpload } from "react-icons/bi";
import { IoIosAddCircleOutline } from "react-icons/io";

function DefaultHeader({
  handleBulkUpload,
  title1,
  title2,
  mainTitle,
  button,
  addNew
}) {
  return (
    <div className="flex justify-between items-center py-2 my-4">
      <p className="text-xl font-bold capitalize">
        {mainTitle || "organizations"}
      </p>
      {button && (
        <div className="actions flex items-center gap-x-6">
          {title1 && (
            <Button
              buttonType={"secondary"}
              children={title1 || "Bulk Upload"}
              icon={<BiUpload size={16} />}
              onClick={handleBulkUpload}
            />
          )}
          {title2 && (
            <Button
              buttonType={"primary"}
              children={title2 || "Add Organization"}
              icon={<IoIosAddCircleOutline size={16} />}
              onClick={addNew}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default DefaultHeader;
