import { useNavigate, useSearchParams } from "react-router-dom";
import Email from "../assets/email.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { returnErrors } from "../data/Reducers/ErrorReducer";
import Button from "../components/button/button";

const VerifiedEmail = () => {
  let navigate = useNavigate();

  let [loading, setLoading] = useState(true),
    dispatch = useDispatch(),
    [getSearch] = useSearchParams(),
    [verified, setVerified] = useState(false);

  const onSubmit = async () => {
		setLoading(true);
		try {
			let res = await axios.post(
				`/api/v1/user/verify-email`,
				{
					email: getSearch?.get("email"),
				},
				{
					headers: {
						Authorization: getSearch?.get("token"),
					},
				}
			);
			console.log({ resp: res?.data });
			let hostname = window.location.hostname,
				newUrl = "";
			if (hostname?.startsWith("www")) hostname = hostname?.replace("www.", "");

			if (!hostname?.includes(".vercel.app")) {
				// Split the hostname by '.' to get subdomain
				const parts = hostname.split(".");

				// Check if there's a subdomain (more than 2 parts in the hostname)
				console.log({ parts });
				if (parts.length > (hostname?.includes("localhost") ? 1 : 2)) {
					// Subdomain is the first part of the hostname

					newUrl = `${window?.location?.protocol}//${
						res?.data?.data &&
						parts?.[0]?.toLowerCase() !== res?.data?.data?.toLowerCase()
							? `${res?.data?.data}.`
							: ""
					}${
						window.location?.hostname?.startsWith("www")
							? window.location?.hostname?.replace("www.", "")
							: window.location?.hostname
					}${window.location.port ? `:${window.location.port}` : ""}`;
				}
			}
			setVerified(true);
			if (!verified) toast.success(res?.data?.message);
			setLoading(false);
			setTimeout(() => {
				navigate("/signup/signup-success", {
					state: {
						email: getSearch?.get("email"),
						token: getSearch?.get("token"),
						url: newUrl,
					},
				});
			}, 1000);
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		if (getSearch?.get("email") && getSearch?.get("token")) {
			console.log("hi there");
			onSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSearch]);

	if (!getSearch?.get("email") && !getSearch?.get("token"))
		return navigate("/");

	return (
		<section className="min-h-screen flex flex-col items-center space-y-5 justify-center">
			<div className="fixed inset-0 bg-white"></div>
			<div
				className="fixed top-0 px-8 z-30 w-full mb-12 py-2"
				onClick={() => navigate("/")}>
				<img
					src={require("../assets/logo-black.png")}
					className="h-6 md:h-10"
					alt=""
				/>
			</div>
			<div className="flex flex-col px-5 md:px-8 lg:px-1 py-8 rounded-md w-10/12 lg:w-full max-w-lg relative z-10">
				<div className="font-medium self-center  text-gray-800 mb-2">
					<div className="flex flex-col items-center mb-5">
						<img src={Email} alt="Email" />
					</div>
					<h1 className="text-center text-2xl md:text-2xl xl:text-3xl font-bold">
						Verifying your{" "}
						<span className="text-bluerolodex ">email address</span>
					</h1>
				</div>
				<div className="self-center">
					<h2 className="text-center text-base md:text-md max-w-sm mx-auto lg:text-md xl:text-lg f-light">
						We have sent a verification link to{" "}
						<span className="font-bold"> {getSearch?.get("email")} </span>,{" "}
						{loading ? "verification in progress" : ""}
					</h2>
				</div>
				<div className="mt-5 md:mt-7 lg:mt-10">
					{/* <button
						type="submit"
						onClick={() => {
							console.debug("resend link");
							navigate("/signup/setup-workspace");
						}}
						className="w-full flex justify-center bg-lightblue hover:brightness-100  text-bluerolodex p-4  rounded-lg tracking-wide font-semibold cursor-pointer transition ease-in duration-500">
						Send verification link again
					</button> */}
					<Button
						type="submit"
						loading={loading}
						children={
							verified ? "Redirecting...." : loading ? "Verifying...." : "...."
						}
						// eslint-disable-next-line react/style-prop-object
						css="w-full flex justify-center bg-lightblue hover:brightness-100  text-bluerolodex p-4  rounded-lg tracking-wide font-semibold cursor-pointer transition ease-in duration-500"
						loadCss="text-bluerolodex"
						// onClick={onSubmit}
					/>
				</div>
			</div>
		</section>
	);
};

export default VerifiedEmail;
