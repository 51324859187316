import React, { useState } from "react";
import SmallNav from "../../components/small-nav/small-nav";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Button from "../../components/button/button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";

const Help = () => {
	let tabViews = ["Support Center"];
	let [active] = useState("Support Center");
	const navigate = useNavigate(),
		[loading, setLoading] = useState(false),
		dispatch = useDispatch(),
		[message, setMessage] = useState("");

	const methods = useForm({
		defaultValues: {
			name: "",
		},
	});

	const onSubmit = async data => {
		console.log({ data });
		if (!message) return;
		setLoading(true);
		try {
			let res = await axios.post(`/api/v1/feedback`, { ...data, message });
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			navigate("/help/success");
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
		setLoading(false);
	};

	return (
		<div className="mt-10">
			<div className="bg-white rounded-lg p-4 mb-10">
				{/* {["FOUNDATION"]?.includes(auth?.user?.idType) && ( */}
				<SmallNav menu={tabViews} active={active} />
				{/* )} */}

				<div className="max-w-xl mt-8">
					{active === "Support Center" && (
						<div>
							<h1>Send us your queries.</h1>
							<p>Reach out to us and we will respond as soon as possible </p>
							<FormProvider {...methods}>
								<form
									onSubmit={methods.handleSubmit(onSubmit)}
									className="space-y-8 mt-16">
									{/* Email */}
									<Controller
										name="fullname"
										rules={{ required: "This field is required" }}
										render={({ field: { onChange, name } }) => (
											<fieldset className="flex flex-col mb-6">
												<div className="flex items-center justify-between">
													<label
														className="mb-1 text-sm md:text-lg font-bold tracking-wide"
														htmlFor="">
														Full Name
													</label>
												</div>
												<input
													className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
													// name={name}
													// id={name}
													onChange={onChange}
													type={"text"}
													placeholder="Enter your name"
												/>
												{methods.formState.errors.fullname && (
													<span className="md:text-base font-bold text-red-600">
														This field is required
													</span>
												)}
											</fieldset>
										)}
									/>
									<Controller
										name="email"
										rules={{
											required: "This field is required",
											pattern: {
												value:
													/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/g,
												message: "You must enter a valid email address",
											},
										}}
										render={({ field: { onChange, name } }) => (
											<fieldset className="flex flex-col mb-6">
												<div className="flex items-center justify-between">
													<label
														className="mb-1 text-sm md:text-lg font-bold tracking-wide"
														htmlFor="">
														Email
													</label>
												</div>
												<input
													className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none"
													// name={name}
													// id={name}
													type={"email"}
													onChange={onChange}
													placeholder="Enter your contact email"
												/>
												{methods.formState.errors.email && (
													<span className="md:text-base font-bold text-red-600">
														This field is required
													</span>
												)}
											</fieldset>
										)}
									/>
									{/* <Controller
										name="message"
										rules={{ required: "This field is required" }}
										render={({ field: { onChange, name } }) => (
											<>
												<fieldset className="flex flex-col mb-6">
													<div className="flex items-center justify-between">
														<label
															className="mb-1 text-sm md:text-lg font-bold tracking-wide"
															htmlFor="">
															Query/Feedback
														</label>
													</div>
													<textarea
														className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none placeholder:text-lg"
														name={name}
														id={name}
														onChange={onChange}
														placeholder="Enter here"
														row={6}
														style={{
															resize: "none",
															height: "10rem",
														}}
													/>
													{methods.formState.errors.message && (
														<span className="md:text-base font-bold text-red-600">
															This field is required
														</span>
													)}
												</fieldset>
											</>
										)}
									/> */}
									<fieldset className="flex flex-col mb-6">
										<div className="flex items-center justify-between">
											<label
												className="mb-1 text-sm md:text-lg font-bold tracking-wide"
												htmlFor="">
												Type your message here
											</label>
										</div>
										<ReactQuill
											theme="snow"
											value={message}
											onChange={e => setMessage(e)}
											className="max-w-[600px]"
										/>
									</fieldset>
									<Button
										type="submit"
										children="Submit Query"
										// eslint-disable-next-line react/style-prop-object
										css="w-full flex justify-center mt-5 bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide f-light  shadow-lg cursor-pointer transition ease-in duration-500"
										loading={loading}
										buttonType={"primary"}
									/>
								</form>
							</FormProvider>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Help;
