import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/landing/Geo-Rolodex2.png";

export const MainSuccess = () => {
	const navigate = useNavigate(),
		{ state } = useLocation();

	useEffect(() => {
		if (state?.workspaceName) document.title = state?.workspaceName;
		if (state?.file?.url) {
			let link = document.querySelector("link[rel~='icon']");
			let link2 = document.querySelector("link[rel~='apple-touch-icon']");
			// let title = document.querySelector("title");
			if (!link) {
				link = document.createElement("link");
				link.rel = "icon";
				document.getElementsByTagName("head")[0].appendChild(link);
			}
			if (!link2) {
				link2 = document.createElement("link");
				link2.rel = "apple-touch-icon";
				document.getElementsByTagName("head")[0].appendChild(link2);
			}
			link.href = state?.file?.url;
			link2.href = state?.file?.url;
		}
	}, [state]);

	return (
		<section className="min-h-screen flex flex-col items-center justify-between py-10">
			<div
				className="fixed inset-0  bg-mainDark"
				style={{ background: state?.color || "" }}></div>

			<img
				src={
					state?.workspaceSubdomain?.includes("geo-rolodex")
						? logo || require("../../assets/brand-white.png")
						: state?.file?.url ||
						  logo ||
						  require("../../assets/brand-white.png")
				}
				alt=""
				className="h-auto w-52 mx-auto relative z-10 mb-12"
			/>
			<div className="flex flex-col px-5 md:px-8 py-8 rounded-md bg-white w-10/12 lg:w-full max-w-lg relative z-10">
				<div>
					<img
						src={require("../../assets/success-gif.gif")}
						alt=""
						className="mx-auto"
					/>
				</div>
				<h1 className="text-2xl md:text-2xl xl:text-3xl font-bold text-center">
					Awesome! Let's get started
				</h1>
				<p className="p-0.5 px-2 text-center mb-8 max-w-sm mx-auto text-gray-600">
					Your account is ready to go - you can now access your workspace.
				</p>
				<button
					style={{ background: state?.color || "" }}
					className="w-full flex justify-center bg-bluerolodex hover:brightness-100  text-white p-4  rounded-lg tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
					onClick={() => {
						let hostname = window.location.hostname,
							newUrl = "";
						if (hostname?.startsWith("www"))
							hostname = hostname?.replace("www.", "");

						if (!hostname?.includes(".vercel.app")) {
							// Split the hostname by '.' to get subdomain
							const parts = hostname.split(".");

							// Check if there's a subdomain (more than 2 parts in the hostname)
							console.log({ parts });
							if (parts.length > (hostname?.includes("localhost") ? 1 : 2)) {
								// Subdomain is the first part of the hostname

								newUrl = `${window?.location?.protocol}//${
									state?.workspaceSubdomain &&
									parts?.[0]?.toLowerCase() !==
										state?.workspaceSubdomain?.toLowerCase()
										? `${state?.workspaceSubdomain}.`
										: ""
								}${
									window.location?.hostname?.startsWith("www")
										? window.location?.hostname?.replace("www.", "")
										: window.location?.hostname
								}${
									window.location.port ? `:${window.location.port}` : ""
								}/login`;
								window.location.replace(newUrl);
							} else navigate("/login");
						} else navigate("/login");
					}}>
					Begin
				</button>
			</div>
			<img
				src={require("../../assets/logo-2.png")}
				alt=""
				className="h-6 md:h-10 mx-auto relative z-10 mt-12"
			/>
		</section>
	);
};

const Success = () => <MainSuccess />;

export default Success;
