import React, { useEffect, useState } from "react";
import Navbar from "../components/landing/Navbar";
import { LandingBtn } from "../components/button/button";
import bgwave from "../assets/bgwave.png";
// import imgone from "../assets/imgone.png";
import Looper from "../assets/Looper.png";
import Slides from "../components/landing/Slides";
import imone from "../assets/imone.png";
import imtwo from "../assets/imtwo.png";
import imthree from "../assets/imthree.png";
import Vecone from "../assets/Vecone.png";
import Vectwo from "../assets/Vectwo.png";
import Frameone from "../assets/Frameone.png";
import eyeimg from "../assets/eyeimg.png";
import imfive from "../assets/imfive.png";
import World from "../assets/World.png";
// import TestimonialSlider from "../components/landing/TestimonialSlider";
import FAQ from "../components/landing/Faq";
import Shadow from "../assets/Shadow.png";
import Inputs from "../components/landing/inputs";
import Footer from "../components/landing/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../data/Reducers/ErrorReducer";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

export const Landing = () => {
	const divStyle = {
		backgroundImage: `url(${bgwave})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
		width: "100%",
	};

	// const divStyles = {
	//   backgroundImage: `url(${Shadow})`,
	//   backgroundSize: "cover",
	//   backgroundPosition: "center",
	//   backgroundPositionY: "60%",
	//   width: "718px",
	//   height: "117px",
	// };

	useEffect(() => {
		AOS.init({
			duration: 3000,
		});
	}, []);

	let navigate = useNavigate();

	console.log({ wind: window.location });

	return (
		<div className=" overflow-x-hidden">
			<Navbar />
			<div
				style={divStyle}
				className="  lg:h-[700px] md:h-[1000px] h-[700px] bg-dark lg:px-16 md:px-10 px-5 lg:mb-64 md:mb-52 mb-28">
				<div className=" grid items-center justify-center">
					<div className=" flex justify-center  lg:mt-20 md:mt-24 mt-24">
						<p className=" Inter lg:text-[50px] md:text-[80px] text-[40px] text-[#fff] font-extrabold text-center ">
							Stakeholder <span className=" text-[#3A98FF]">Mapping Made </span>
							Simple
						</p>
					</div>
					<div className=" flex justify-center mt-5">
						<p className=" Inter text-sm text-[#fff] font-extralight lg:w-[500px] md:w-[460px] w-full text-center ">
							Welcome to Geo-Rolodex by Policy Vault, your comprehensive
							platform for streamlined stakeholder mapping and stakeholder
							management.
						</p>
					</div>

					<div
						data-aos="zoom-in"
						className=" lg:flex md:flex grid justify-center gap-5 lg:mt-10 md:mt-28 mt-20 Inter">
						<LandingBtn
							text="Get Started"
							bg={"bg-[#2658AB]"}
							css={
								" lg:w-[221.11px] md:w-[221.11px] w-full  h-[55px] rounded-md text-[#fff]"
							}
							onClick={() => {
								if (window.location?.origin?.includes("geo-rolodex.com")) {
									window.location.replace(
										"https://geo-rolodex.map.policyvault.africa/login"
									);
								} else if (
									window.location?.origin?.includes("map.policyvault.africa")
								) {
									window.location.replace(
										"https://geo-rolodex.map.policyvault.africa/login"
									);
								} else {
									navigate("/login");
								}
							}}
						/>
						<LandingBtn
							css={
								"text-[#fff] font-Inter lg:w-[221.11px] md:w-[221.11px] w-full  h-[55px] rounded-md border border-[#FFFFFF]"
							}
							text="Download User Guide"
							onClick={() =>
								window.open(
									"https://stakeholder-map.nyc3.cdn.digitaloceanspaces.com/Geo-Rolodex%20User%20Guide.pdf",
									"_blank"
								)
							}
						/>
					</div>
				</div>
				<div className=" grid justify-center items-center 2xl:mt-36  xl:mt-36 lg:m-36  md:mt-52 mt-20 lg:px-16 md:px-10 px-5 ">
					<div className=" grid justify-center">
						<div className="lg:w-[800px] md:w-[800px] w-[300px] ">
							<Slides />
						</div>
						{/* <img
            className=" lg:w-[800px] md:w-[800px] w-full"
            src={imgone}
            alt=""
          /> */}
					</div>
				</div>
			</div>

			<div
				name="about-us"
				className=" relative xl:mt-20 2xl:mt-20 lg:mt-0 md:mt-0 mt-0 lg:px-16 md:px-10 px-5">
				<img
					className=" absolute lg:block md:hidden hidden right-0 top-[-80px] w-[241.54px] h-[496px]"
					src={Looper}
					alt=""
				/>
				<div className=" grid items-center justify-center">
					<p
						data-aos="zoom-in"
						className=" text-center font-Inter lg:text-4xl md:text-3xl text-xl font-bold ">
						Why Geo-Rolodex?
					</p>
				</div>
				<div
					data-aos="fade-right"
					className=" grid items-center justify-center my-10">
					<p className=" text-center font-Inter font-light text-sm text-[#4B4B4B] lg:w-[580px] ">
						Geo-Rolodex is your comprehensive web-based solution for
						identifying, analyzing, and engaging critical stakeholders across a
						range of sectors, including public health, environmental management,
						agriculture, energy, and public policy.
					</p>
				</div>
				<div className=" grid items-center justify-center">
					<p
						data-aos="zoom-in"
						className=" text-center font-Inter text-base text[#38404A] lg:w-[700px] ">
						Our platform equips you with interactive mapping tools and verified
						data, ensuring you make informed decisions with the highest security
						standards.
					</p>
				</div>
				<div
					data-aos="fade-left"
					className=" grid items-center justify-center mt-8">
					<p className=" text-center font-Inter text-base text[#38404A] lg:w-[760px] ">
						Geo-Rolodex is designed to adapt and grow, supporting your evolving
						needs in geospatial stakeholder mapping.
					</p>
				</div>
			</div>

			<div
				name="user-guides"
				className=" grid justify-center items-center lg:px-16 md:px-10 px-5 pt-40 pb-20 ">
				<div className=" justify-center items-center grid">
					<p className="text-center font-Inter lg:text-4xl md:text-3xl text-xl font-bold">
						Get started in minutes
					</p>
				</div>
				<div className=" lg:flex md:flex grid lg:gap-0 md:gap-0 gap-5 pt-14">
					<div
						data-aos="fade-up"
						className=" bg-white rounded-lg border border-[#1B3C8E] lg:w-72 md:w-64 w-full p-5">
						<div>
							<img className=" w-14 h-14" src={imone} alt="" />
						</div>
						<div className=" mt-4 mb-2">
							<p className=" text-2xl font-bold text-[#12141D] font-Inter">
								Step 1: Access the Registration Page
							</p>
						</div>
						<div>
							<p className=" text-sm font-light text-[#12141D] font-Inter">
								Receive your invitation to join Geo-Rolodex. Use the provided
								link to set up your account.
							</p>
						</div>
					</div>
					<img
						data-aos="zoom-in"
						className=" h-20 mt-10 lg:block md:hidden hidden px-4"
						src={Vecone}
						alt=""
					/>
					<div
						data-aos="fade-down"
						className=" bg-white rounded-lg border border-[#1B3C8E] lg:w-72 md:w-64 w-full p-5 relative lg:top-16 md:top-16 top-0">
						<div>
							<img className=" w-14 h-14" src={imtwo} alt="" />
						</div>
						<div className=" mt-4 mb-2">
							<p className=" text-2xl font-bold text-[#12141D] font-Inter">
								Step 2: Update Profile
							</p>
						</div>
						<div>
							<p className=" text-sm font-light text-[#12141D] font-Inter">
								Complete your profile by filling in all relevant organizational
								and personal details.
							</p>
						</div>
					</div>
					<img
						data-aos="zoom-in"
						className=" h-20 mt-10 lg:block md:hidden hidden px-4"
						src={Vectwo}
						alt=""
					/>
					<div
						data-aos="fade-up"
						className=" bg-white rounded-lg border border-[#1B3C8E] lg:w-72 md:w-64 w-full p-5">
						<div>
							<img className=" w-14 h-14" src={imthree} alt="" />
						</div>
						<div className=" mt-4 mb-2">
							<p className=" text-2xl font-bold text-[#12141D] font-Inter">
								Step 3: Begin Mapping
							</p>
						</div>
						<div>
							<p className=" text-sm font-light text-[#12141D] font-Inter">
								Gain access to Geo-Rolodex and start mapping your stakeholders.
							</p>
						</div>
					</div>
				</div>
				<div className=" justify-center grid items-center mt-32">
					<p className="text-sm font-light text-[#12141D] font-Inter">
						For any assistance, please refer to the user guide available on our
						homepage.
					</p>
				</div>
			</div>

			<div className=" bg-[#F9FAFC] py-10 lg:px-16 md:px-10 px-5 ">
				<div className=" grid justify-center items-center">
					<p
						data-aos="zoom-in"
						className=" text-center lg:w-[920px] font-bold lg:text-4xl md:text-2xl text-xl font-Inter text-[#000]">
						Powerful Features with No Specialized Training Needed
					</p>
				</div>
				<div
					data-aos="fade-up"
					className=" grid justify-center items-center mt-5">
					<p className=" text-center lg:w-[820px] lg:text-sm md:text-base text-[13px] font-Inter text-[#3D3C3C]">
						Geo-Rolodex offers an intuitive platform designed to make geospatial
						stakeholder mapping simple and efficient. Below are the key features
						and benefits that ensure ease of use and powerful functionality.
					</p>
				</div>

				<div className=" my-20">
					<div className=" lg:flex md:grid grid justify-center items-end gap-20">
						<div className="lg:w-1/2 md:wfull w-full">
							<img
								data-aos="fade-right"
								className=" lg:h-[430px]"
								src={Frameone}
								alt=""
							/>
						</div>
						<div className=" lg:w-1/2 md:w-full w-full">
							<div className="">
								<p
									data-aos="zoom-in"
									className=" font-Inter text-[#3E76FE] text-base font-bold">
									START SIMPLE
								</p>
								<p
									data-aos="zoom-in"
									className=" text-[26px] font-semibold text-[#111827] font-Inter mt-6">
									Interactive Mapping and Visualization
								</p>
								<p
									data-aos="fade-left"
									className=" text-sm text-[#38404A] font-Inter mt-8 lg:w-[470px]">
									The platform is highly interactive. Users can interact with
									the map by zooming in/out, panning, and clicking on map
									elements to get more information. Verified organizations can
									even add and update their data points.
								</p>
							</div>
							<div data-aos="fade-left" className=" space-y-7 mt-10">
								<div className=" bg-[#F3F4F6] p-3 flex gap-2 rounded-lg ">
									<img className=" w-5 h-5" src={eyeimg} alt="" />
									<p className=" font-Inter text-[#38404A] text-sm">
										<span className=" text-sm font-bold">
											{" "}
											User-Friendly Navigation:
										</span>{" "}
										Easily zoom, pan, and click on map elements for detailed
										insights.
									</p>
								</div>
								<div className=" bg-[#F3F4F6] p-3 flex  gap-2  rounded-lg">
									<img className=" w-5 h-5" src={eyeimg} alt="" />
									<p className=" font-Inter text-[#38404A] text-sm">
										<span className=" text-sm font-bold">
											{" "}
											Custom Workflows:
										</span>{" "}
										Switch seamlessly between list and map views to fit your
										workflow needs.
									</p>
								</div>

								<div className=" bg-[#F3F4F6] p-3 flex gap-2 rounded-lg ">
									<img className=" w-5 h-5" src={eyeimg} alt="" />
									<p className=" font-Inter text-[#38404A] text-sm">
										<span className=" text-sm font-bold">
											{" "}
											• Dynamic Data Filters:{" "}
										</span>{" "}
										Identify key stakeholders relevant to your projects based on
										proximity, demographics, sector, and other criteria using
										dynamic mapping tools.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className=" py-10 lg:px-16 md:px-10 px-5">
				<div className=" lg:flex md:grid grid items-center justify-center gap-20">
					<div data-aos="fade-up" className=" lg:w-1/2 md:w-full w-full">
						<div>
							<p className=" font-Inter text-3xl font-bold text-[#111827] lg:w-[500px]">
								Comprehensive Stakeholder Insights
							</p>
							<div className=" space-y-8 mt-8">
								<div className=" bg-[#F3F4F6] p-3 flex gap-2 rounded-lg ">
									<img className=" w-5 h-5" src={eyeimg} alt="" />
									<p className=" font-Inter text-[#38404A] text-sm">
										<span className=" text-sm font-bold">
											{" "}
											In-Depth Use Cases:
										</span>{" "}
										Explore historical projects and services offered by
										stakeholder organizations to understand their impact and
										relevance.
									</p>
								</div>
								<div className=" bg-[#F3F4F6] p-3 flex gap-2 rounded-lg ">
									<img className=" w-5 h-5" src={eyeimg} alt="" />
									<p className=" font-Inter text-[#38404A] text-sm">
										<span className=" text-sm font-bold">
											{" "}
											Accessible Contact Information:
										</span>{" "}
										Access key personnel contacts within stakeholder
										organizations to facilitate meaningful engagements.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div data-aos="fade-down" className=" lg:w-1/2 md:w-full w-full">
						<img src={imfive} alt="" />
					</div>
				</div>
			</div>

			<div className=" bg-[#070D27] lg:pl-16 md:pl-10 pl-5 lg:pt-0 md:pt-5 pt-5">
				<div className=" lg:flex md:grid grid items-center">
					<div data-aos="zoom-in" className=" grid gap-16 lg:pr-0 md:pr-0 pr-5">
						<div className=" lg:w-[300px]">
							<p className=" font-Inter text-4xl font-extrabold text-[#fff]">
								500+
							</p>
							<p className=" font-Inter font-normal text-sm text-[#fff]">
								Stakeholder organizations primarily use geospatial technology
								for service delivery in low- and middle-income countries.
							</p>
						</div>
						<div className=" lg:w-[300px]">
							<p className=" font-Inter text-4xl font-extrabold text-[#fff]">
								600+
							</p>
							<p className=" font-Inter font-normal text-sm text-[#fff]">
								Documented historical projects across diverse sectors.
							</p>
						</div>
					</div>
					<div>
						<img data-aos="zoom-in" src={World} alt="" />
					</div>
				</div>
			</div>
			{/* <div className=" bg-[#F9FAFC] ">
        <div className=" py-20 lg:px-16 md:px-10 px-5">
          <TestimonialSlider />
        </div>
      </div> */}

			<div className=" bg-[#0F172A] py-16 lg:px-16 md:px-10 px-5">
				<div className=" flex justify-center">
					<p
						data-aos="zoom-in"
						className=" font-semibold text-[#fff] lg:text-5xl md:text-3xl text-2xl">
						Ready to Get Started?
					</p>
				</div>
				<div
					data-aos="fade-down"
					className=" flex font-Jakarta justify-center my-5">
					<p className=" text-[#fff] font-Jakarta w-[400px] text-center ">
						Discover how Geo-Rolodex can transform your stakeholder mapping and
						engagement efforts today.
					</p>
				</div>
				<div
					data-aos="zoom-in"
					className=" flex font-Jakarta justify-center gap-5 my-5">
					<LandingBtn
						css={
							"text-[#fff] font-Inter lg:w-[221.11px] md:w-[221.11px] w-full  h-[55px] rounded-md bg-[#2B59FF]"
						}
						text="Get Started"
						onClick={() => {
							if (window.location?.origin?.includes("geo-rolodex.com")) {
								window.location.replace(
									"https://geo-rolodex.map.policyvault.africa/login"
								);
							} else if (
								window.location?.origin?.includes("map.policyvault.africa")
							) {
								window.location.replace(
									"https://geo-rolodex.map.policyvault.africa/login"
								);
							} else {
								navigate("/login");
							}
						}}
					/>
					<LandingBtn
						css={
							"text-[#fff] font-Inter lg:w-[221.11px] md:w-[221.11px] w-full  h-[55px] rounded-md border border-[#FFFFFF]"
						}
						text="Download User Guide"
						onClick={() =>
							window.open(
								"https://stakeholder-map.nyc3.cdn.digitaloceanspaces.com/Geo-Rolodex%20User%20Guide.pdf",
								"_blank"
							)
						}
					/>
				</div>
				<div className=" flex font-Jakarta justify-center gap-5 pt-5">
					<p data-aos="zoom-in" className=" text-center text-[#fff]">
						No credit card required.
					</p>
				</div>
			</div>
			<div
				name="faq"
				className=" grid justify-center items-center relative py-16 lg:px-16 md:px-10 px-5">
				<div className=" absolute grid justify-center inset-3 top-48">
					<img className=" w-[718px] h-[117px]" src={Shadow} alt="" />
				</div>
				<div>
					<FAQ />
				</div>
			</div>

			<ContactComponent />
			<div className=" bg-[#14171F]">
				<div data-aos="fade-left">
					<Footer />
				</div>
			</div>
		</div>
	);
};

const MainLanding = () => {
	let navigate = useNavigate();
	useEffect(() => {
		navigate("/home");
	}, [navigate]);
	return <></>;
};

export default MainLanding;

const ContactComponent = () => {
  const [loading, setLoading] = useState(false),
    dispatch = useDispatch();

  const methods = useForm({
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = async (data) => {
    console.log({ data });
    setLoading(true);
    try {
      let res = await axios.put(`/api/v1/feedback`, {
        ...data,
        fullname: `${data?.firstName || ""} ${data?.lastName || ""}`,
      });
      console.log({ resp: res?.data });
      toast.success(res?.data?.message);
      methods?.reset({
        name: "",
        message: "",
        lastName: "",
        firstName: "",
        email: "",
        telephone: "",
        subject: "",
        company: "",
      });
    } catch (err) {
      if (err) console.log({ error: err.response?.data, err });
      if (err?.response?.status === 429) toast.error(err?.response?.data);
      let error = err.response?.data?.error;
      if (error && error?.length > 1) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
      } else {
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data?.error?.[0]?.message ||
            err?.message
        );
      }
    }
    setLoading(false);
  };
  return (
    <>
      <div
        name="contact"
        className=" bg-[#F9FAFC] py-16 lg:px-16 md:px-10 px-5"
      >
        <div data-aos="zoom-in" className=" grid justify-center items-center">
          <div className=" grid justify-center items-center">
            <p className=" text-center font-Inter text-3xl font-bold text-[#0C0D0D]">
              Need Assistance? Contact us directly for personalized support.
            </p>
          </div>

          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className="space-y-8 mt-16"
            >
              <div className=" grid justify-center items-center mt-10 gap-5">
                <div className=" flex items-center justify-center lg:w-[950px] gap-10">
                  <div className=" w-1/2">
                    <Controller
                      name="firstName"
                      rules={{ required: "This field is required" }}
                      render={({ field: { onChange, name, value } }) => (
                        <fieldset className="flex flex-col mb-6">
                          <Inputs
                            label="First Name"
                            placeHolder="Enter here"
                            name={name}
                            id={name}
                            onChange={onChange}
                            value={value}
                          />
                          {methods.formState.errors[name] && (
                            <span className="md:text-base font-bold text-red-600">
                              This field is required
                            </span>
                          )}
                        </fieldset>
                      )}
                    />
                  </div>
                  <div className=" w-1/2">
                    <Controller
                      name="lastName"
                      rules={{ required: "This field is required" }}
                      render={({ field: { onChange, name, value } }) => (
                        <fieldset className="flex flex-col mb-6">
                          <Inputs
                            label="Last Name"
                            placeHolder="Enter here"
                            name={name}
                            id={name}
                            onChange={onChange}
                            value={value}
                          />
                          {methods.formState.errors[name] && (
                            <span className="md:text-base font-bold text-red-600">
                              This field is required
                            </span>
                          )}
                        </fieldset>
                      )}
                    />
                  </div>
                </div>
                <div className=" flex items-center justify-center lg:w-[950px] gap-10">
                  <div className=" w-1/2">
                    <Controller
                      name="company"
                      rules={{ required: "This field is required" }}
                      render={({ field: { onChange, name, value } }) => (
                        <fieldset className="flex flex-col mb-6">
                          <Inputs
                            label="Company"
                            placeHolder="Enter here"
                            name={name}
                            id={name}
                            onChange={onChange}
                            value={value}
                          />
                          {methods.formState.errors[name] && (
                            <span className="md:text-base font-bold text-red-600">
                              This field is required
                            </span>
                          )}
                        </fieldset>
                      )}
                    />
                  </div>
                  <div className=" w-1/2">
                    <Controller
                      name="email"
                      rules={{
                        required: "This field is required",
                        pattern: {
                          value:
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/g,
                          message: "You must enter a valid email address",
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <fieldset className="flex flex-col mb-6">
                          <Inputs
                            label="Email Address"
                            placeHolder="Enter here"
                            name={name}
                            id={name}
                            type={"email"}
                            onChange={onChange}
                            value={value}
                          />
                          {methods.formState.errors.email && (
                            <span className="md:text-base font-bold text-red-600">
                              This field is required
                            </span>
                          )}
                        </fieldset>
                      )}
                    />
                  </div>
                </div>
                <div className=" flex items-center justify-center lg:w-[950px] gap-10">
                  <div className=" w-1/2">
                    <Controller
                      name="telephone"
                      rules={{ required: "This field is required" }}
                      render={({ field: { onChange, name, value } }) => (
                        <fieldset className="flex flex-col mb-6">
                          <Inputs
                            label="Phone Number"
                            placeHolder="Enter here"
                            name={name}
                            id={name}
                            onChange={onChange}
                            value={value}
                            type="tel"
                          />
                          {methods.formState.errors[name] && (
                            <span className="md:text-base font-bold text-red-600">
                              This field is required
                            </span>
                          )}
                        </fieldset>
                      )}
                    />
                  </div>
                  <div className=" w-1/2">
                    <Controller
                      name="subject"
                      rules={{ required: "This field is required" }}
                      render={({ field: { onChange, name, value } }) => (
                        <fieldset className="flex flex-col mb-6">
                          <Inputs
                            label="Subject"
                            placeHolder="Enter here"
                            name={name}
                            id={name}
                            onChange={onChange}
                            value={value}
                          />
                          {methods.formState.errors[name] && (
                            <span className="md:text-base font-bold text-red-600">
                              This field is required
                            </span>
                          )}
                        </fieldset>
                      )}
                    />
                  </div>
                </div>
                <div className=" flex items-center justify-center lg:w-[950px] gap-10">
                  <div className=" lg:w-1/2 md:w-full w-full">
                    <Controller
                      name="message"
                      rules={{ required: "This field is required" }}
                      render={({ field: { onChange, name, value } }) => (
                        <fieldset className="flex flex-col mb-6">
                          <Inputs
                            label="Description"
                            placeHolder="Enter here"
                            name={name}
                            id={name}
                            onChange={onChange}
                            value={value}
                            type={"textArea"}
                          />
                          {methods.formState.errors[name] && (
                            <span className="md:text-base font-bold text-red-600">
                              This field is required
                            </span>
                          )}
                        </fieldset>
                      )}
                    />
                  </div>
                  <div className=" w-1/2 lg:block md:hidden hidden"></div>
                </div>
                <div className=" flex items-center justify-center lg:w-[950px] gap-10">
                  <div className=" w-1/2">
                    <LandingBtn
                      text="Submit"
                      bg={"bg-[#2658AB]"}
                      css={" text-[#fff]  w-full h-[54px] rounded-md "}
                      loading={loading}
                      type="submit"
                    />
                  </div>
                  <div className=" w-1/2 lg:block md:hidden hidden"></div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

// import React, { useEffect } from "react";
// import Navbar from "../components/landing/Navbar";
// import { LandingBtn } from "../components/button/button";
// import bgwave from "../assets/bgwave.png";
// // import imgone from "../assets/imgone.png";
// import Looper from "../assets/Looper.png";
// import Slides from "../components/landing/Slides";
// import imone from "../assets/imone.png";
// import imtwo from "../assets/imtwo.png";
// import imthree from "../assets/imthree.png";
// import Vecone from "../assets/Vecone.png";
// import Vectwo from "../assets/Vectwo.png";
// import Frameone from "../assets/Frameone.png";
// import eyeimg from "../assets/eyeimg.png";
// import imfive from "../assets/imfive.png";
// import World from "../assets/World.png";
// import TestimonialSlider from "../components/landing/TestimonialSlider";
// import FAQ from "../components/landing/Faq";
// import Shadow from "../assets/Shadow.png";
// import Inputs from "../components/landing/inputs";
// import Footer from "../components/landing/Footer";
// import AOS from "aos";
// import "aos/dist/aos.css";

// const Landing = () => {
//   const divStyle = {
//     backgroundImage: `url(${bgwave})`,
//     backgroundSize: "cover",
//     backgroundPosition: "center",
//     width: "100%",
//     height: "100vh",
//   };

//   // const divStyles = {
//   //   backgroundImage: `url(${Shadow})`,
//   //   backgroundSize: "cover",
//   //   backgroundPosition: "center",
//   //   backgroundPositionY: "60%",
//   //   width: "718px",
//   //   height: "117px",
//   // };

//   useEffect(() => {
//     AOS.init({
//       duration: 3000,
//     });
//   }, []);

//   return (
//     <div className="">
//       <Navbar />
//       <div
//         style={divStyle}
//         className=" h-screen bg-dark lg:px-16 md:px-10 px-5"
//       >
//         <div className=" grid items-center justify-center">
//           <div className=" flex justify-center lg:mt-20 md:mt-24 mt-24">
//             <p className=" Inter lg:text-[50px] md:text-[80px] text-[40px] text-[#fff] font-extrabold text-center ">
//               Mapping <span className=" text-[#3A98FF]">meets </span>Simplicity
//             </p>
//           </div>
//           <div className=" flex justify-center mt-5">
//             <p className=" Inter text-sm text-[#fff] font-extralight lg:w-[500px] md:w-[460px] w-full text-center ">
//               Explore our comprehensive portal, designed to help you easily map
//               stakeholders within the geospatial industry.
//             </p>
//           </div>
//           <div
//             data-aos="zoom-in"
//             className=" flex justify-center lg:mt-5 md:mt-28 mt-20 Inter"
//           >
//             <LandingBtn
//               text="Get Started"
//               bg={"bg-[#2658AB]"}
//               css={" w-[268px] h-[54px] rounded-md text-[#fff]"}
//               onClick={() =>
//                 window.location.replace(
//                   "https://map.policyvault.africa/signup/setup-organization"
//                 )
//               }
//             />
//           </div>
//         </div>
//       </div>

//       <div className=" grid justify-center items-center relative lg:bottom-56 md:bottom-56 bottom-28 lg:h-64 md:h-64 h-32 lg:px-16 md:px-10 px-5 ">
//         <div className=" grid justify-center">
//           <div className="lg:w-[800px] md:w-[800px] w-[300px] ">
//             <Slides />
//           </div>
//           {/* <img
//             className=" lg:w-[800px] md:w-[800px] w-full"
//             src={imgone}
//             alt=""
//           /> */}
//         </div>
//       </div>

//       <div className=" relative lg:mt-10 md:mt-10 mt-0 lg:px-16 md:px-10 px-5">
//         <img
//           className=" absolute lg:block md:hidden hidden right-0 top-[-80px] w-[241.54px] h-[496px]"
//           src={Looper}
//           alt=""
//         />
//         <div className=" grid items-center justify-center">
//           <p
//             data-aos="zoom-in"
//             className=" text-center font-Inter lg:text-4xl md:text-3xl text-xl font-bold "
//           >
//             Why Geo-Rolodex?
//           </p>
//         </div>
//         <div
//           data-aos="fade-right"
//           className=" grid items-center justify-center my-10"
//         >
//           <p className=" text-center font-Inter font-light text-sm text-[#4B4B4B] lg:w-[580px] ">
//             Geo-Rolodex is a web-based platform designed to help you identify,
//             analyze, and engage stakeholders critical to your projects and
//             initiatives within the geospatial industry.
//           </p>
//         </div>
//         <div className=" grid items-center justify-center">
//           <p
//             data-aos="zoom-in"
//             className=" text-center font-Inter text-base text[#38404A] lg:w-[700px] "
//           >
//             Whether in public health, environmental management, agriculture,
//             energy, or public policy, Geo-Rolodex provides a portal to map
//             stakeholder relationships and make informed decisions.
//           </p>
//         </div>
//         <div
//           data-aos="fade-left"
//           className=" grid items-center justify-center mt-8"
//         >
//           <p className=" text-center font-Inter text-base text[#38404A] lg:w-[760px] ">
//             It offers an interactive map and verifiable information, combining
//             advanced visualization features with the highest security standards.
//           </p>
//         </div>
//       </div>

//       <div
//         name="user-guides"
//         className=" grid justify-center items-center lg:px-16 md:px-10 px-5 py-40 "
//       >
//         <div className=" lg:flex md:flex grid lg:gap-0 md:gap-0 gap-5">
//           <div
//             data-aos="fade-up"
//             className=" bg-white rounded-lg border border-[#1B3C8E] lg:w-72 md:w-64 w-full p-5"
//           >
//             <div>
//               <img className=" w-14 h-14" src={imone} alt="" />
//             </div>
//             <div className=" mt-4 mb-2">
//               <p className=" text-2xl font-bold text-[#12141D] font-Inter">
//                 Step 1: Login
//               </p>
//             </div>
//             <div>
//               <p className=" text-sm font-light text-[#12141D] font-Inter">
//                 Login to your Geo-Rolodex account using your credentials after
//                 verifying your information as an invited organization.
//               </p>
//             </div>
//           </div>
//           <img
//             data-aos="zoom-in"
//             className=" h-20 mt-10 lg:block md:hidden hidden px-4"
//             src={Vecone}
//             alt=""
//           />
//           <div
//             data-aos="fade-down"
//             className=" bg-white rounded-lg border border-[#1B3C8E] lg:w-72 md:w-64 w-full p-5 relative lg:top-16 md:top-16 top-0"
//           >
//             <div>
//               <img className=" w-14 h-14" src={imtwo} alt="" />
//             </div>
//             <div className=" mt-4 mb-2">
//               <p className=" text-2xl font-bold text-[#12141D] font-Inter">
//                 Step 2: Update Profile
//               </p>
//             </div>
//             <div>
//               <p className=" text-sm font-light text-[#12141D] font-Inter">
//                 Login to your Geo-Rolodex account using your credentials after
//                 verifying your information as an invited organization.
//               </p>
//             </div>
//           </div>
//           <img
//             data-aos="zoom-in"
//             className=" h-20 mt-10 lg:block md:hidden hidden px-4"
//             src={Vectwo}
//             alt=""
//           />
//           <div
//             data-aos="fade-up"
//             className=" bg-white rounded-lg border border-[#1B3C8E] lg:w-72 md:w-64 w-full p-5"
//           >
//             <div>
//               <img className=" w-14 h-14" src={imthree} alt="" />
//             </div>
//             <div className=" mt-4 mb-2">
//               <p className=" text-2xl font-bold text-[#12141D] font-Inter">
//                 Step 3: Begin Mapping
//               </p>
//             </div>
//             <div>
//               <p className=" text-sm font-light text-[#12141D] font-Inter">
//                 Login to your Geo-Rolodex account using your credentials after
//                 verifying your information as an invited organization.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div
//         name="about-us"
//         className=" bg-[#F9FAFC] py-10 lg:px-16 md:px-10 px-5 "
//       >
//         <div className=" grid justify-center items-center">
//           <p
//             data-aos="zoom-in"
//             className=" text-center lg:w-[920px] font-bold lg:text-4xl md:text-2xl text-xl font-Inter text-[#000]"
//           >
//             A platform created for your Geospatial Stakeholder Mapping and
//             Engagement
//           </p>
//         </div>
//         <div
//           data-aos="fade-up"
//           className=" grid justify-center items-center mt-5"
//         >
//           <p className=" text-center lg:w-[820px] lg:text-sm md:text-base text-[13px] font-Inter text-[#3D3C3C]">
//             You’re looking to identify, engage, and collaborate with various
//             organizations in your areas of interest. The Geo-Rolodex mapping
//             portal is designed to meet your needs, efficiently map and analyze
//             stakeholders without needing specialized training.
//           </p>
//         </div>

//         <div className=" my-20">
//           <div className=" lg:flex md:grid grid justify-center items-end gap-20">
//             <div className="lg:w-1/2 md:wfull w-full">
//               <img
//                 data-aos="fade-right"
//                 className=" lg:h-[430px]"
//                 src={Frameone}
//                 alt=""
//               />
//             </div>
//             <div className=" lg:w-1/2 md:w-full w-full">
//               <div className="">
//                 <p
//                   data-aos="zoom-in"
//                   className=" font-Inter text-[#3E76FE] text-base font-bold"
//                 >
//                   START SIMPLE
//                 </p>
//                 <p
//                   data-aos="zoom-in"
//                   className=" text-[26px] font-semibold text-[#111827] font-Inter mt-6"
//                 >
//                   Interactive Mapping and Visualization
//                 </p>
//                 <p
//                   data-aos="fade-left"
//                   className=" text-sm text-[#38404A] font-Inter mt-8 lg:w-[470px]"
//                 >
//                   The platform is highly interactive. Users can interact with
//                   the map by zooming in/out, panning, and clicking on map
//                   elements to get more information. Verified organizations can
//                   even add and update their data points.
//                 </p>
//               </div>
//               <div data-aos="fade-left" className=" space-y-7 mt-10">
//                 <div className=" bg-[#F3F4F6] p-3 flex gap-2 rounded-lg ">
//                   <img className=" w-5 h-5" src={eyeimg} alt="" />
//                   <p className=" font-Inter text-[#38404A] text-sm">
//                     <span className=" text-sm font-bold">
//                       {" "}
//                       Custom workflows.
//                     </span>{" "}
//                     Switch between list and map view with one click.
//                   </p>
//                 </div>
//                 <div className=" bg-[#F3F4F6] p-3 flex  gap-2  rounded-lg">
//                   <img className=" w-5 h-5" src={eyeimg} alt="" />
//                   <p className=" font-Inter text-[#38404A] text-sm">
//                     <span className=" text-sm font-bold">
//                       {" "}
//                       Data Filtering and Querying.
//                     </span>{" "}
//                     Apply filters or query the data to focus on specific
//                     attributes or categories.
//                   </p>
//                 </div>

//                 <div className=" bg-[#F3F4F6] p-3 flex gap-2 rounded-lg ">
//                   <img className=" w-5 h-5" src={eyeimg} alt="" />
//                   <p className=" font-Inter text-[#38404A] text-sm">
//                     <span className=" text-sm font-bold"> Plot your map:</span>{" "}
//                     Use the dynamic map to plot stakeholder attributes. Identify
//                     key stakeholders based on proximity, demographics, sector,
//                     and interest.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className=" py-10 lg:px-16 md:px-10 px-5">
//         <div className=" lg:flex md:grid grid items-center justify-center gap-20">
//           <div data-aos="fade-up" className=" lg:w-1/2 md:w-full w-full">
//             <div>
//               <p className=" font-Inter text-3xl font-bold text-[#111827] lg:w-[500px]">
//                 Discover critical stakeholders and their use cases effortlessly
//               </p>
//               <div className=" space-y-8 mt-8">
//                 <div className=" bg-[#F3F4F6] p-3 flex gap-2 rounded-lg ">
//                   <img className=" w-5 h-5" src={eyeimg} alt="" />
//                   <p className=" font-Inter text-[#38404A] text-sm">
//                     <span className=" text-sm font-bold">
//                       {" "}
//                       View stakeholder’s use cases.
//                     </span>{" "}
//                     Access historical projects of stakeholder organizations.
//                   </p>
//                 </div>
//                 <div className=" bg-[#F3F4F6] p-3 flex gap-2 rounded-lg ">
//                   <img className=" w-5 h-5" src={eyeimg} alt="" />
//                   <p className=" font-Inter text-[#38404A] text-sm">
//                     <span className=" text-sm font-bold">
//                       {" "}
//                       View stakeholder’s use cases.
//                     </span>{" "}
//                     Access historical projects of stakeholder organizations.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div data-aos="fade-down" className=" lg:w-1/2 md:w-full w-full">
//             <img src={imfive} alt="" />
//           </div>
//         </div>
//       </div>

//       <div className=" bg-[#070D27] lg:pl-16 md:pl-10 pl-5 lg:pt-0 md:pt-5 pt-5">
//         <div className=" lg:flex md:grid grid items-center">
//           <div data-aos="zoom-in" className=" grid gap-16 lg:pr-0 md:pr-0 pr-5">
//             <div className=" lg:w-[300px]">
//               <p className=" font-Inter text-4xl font-extrabold text-[#fff]">
//                 500+
//               </p>
//               <p className=" font-Inter font-normal text-sm text-[#fff]">
//                 Stakeholder organizations primarily use geospatial technology
//                 for service delivery in low- and middle-income countries.
//               </p>
//             </div>
//             <div className=" lg:w-[300px]">
//               <p className=" font-Inter text-4xl font-extrabold text-[#fff]">
//                 600+
//               </p>
//               <p className=" font-Inter font-normal text-sm text-[#fff]">
//                 Uses cases or historical projects of stakeholders across all
//                 sectors.
//               </p>
//             </div>
//           </div>
//           <div>
//             <img data-aos="zoom-in" src={World} alt="" />
//           </div>
//         </div>
//       </div>
//       <div className=" bg-[#F9FAFC] ">
//         <div className=" py-20 lg:px-16 md:px-10 px-5">
//           <TestimonialSlider />
//         </div>
//       </div>

//       <div className=" bg-[#0F172A] py-16 lg:px-16 md:px-10 px-5">
//         <div className=" flex justify-center">
//           <p
//             data-aos="zoom-in"
//             className=" font-semibold text-[#fff] lg:text-5xl md:text-3xl text-2xl"
//           >
//             Ready to get started?
//           </p>
//         </div>
//         <div
//           data-aos="fade-down"
//           className=" flex font-Jakarta justify-center my-5"
//         >
//           <p className=" text-[#fff] font-Jakarta w-[400px] text-center ">
//             Explore how Geo-Rolodex can transform your stakeholder mapping and
//             engagement efforts.
//           </p>
//         </div>
//         <div
//           data-aos="zoom-in"
//           className=" flex font-Jakarta justify-center gap-5 my-5"
//         >
//           <LandingBtn
//             css={
//               "text-[#fff] font-Inter lg:w-[221.11px] md:w-[221.11px] w-full  h-[55px] rounded-md bg-[#2B59FF]"
//             }
//             text="Get Started"
//             onClick={() =>
//               window.location.replace(
//                 "https://map.policyvault.africa/signup/setup-organization"
//               )
//             }
//           />
//           <LandingBtn
//             css={
//               "text-[#fff] font-Inter lg:w-[221.11px] md:w-[221.11px] w-full  h-[55px] rounded-md border border-[#FFFFFF]"
//             }
//             text="Download User Guide"
//           />
//         </div>
//         <div className=" flex font-Jakarta justify-center gap-5 pt-5">
//           <p data-aos="zoom-in" className=" text-center text-[#fff]">
//             No credit card required
//           </p>
//         </div>
//       </div>
//       <div
//         name="faq"
//         className=" grid justify-center items-center relative py-16 lg:px-16 md:px-10 px-5"
//       >
//         <div className=" absolute grid justify-center inset-3 top-48">
//           <img className=" w-[718px] h-[117px]" src={Shadow} alt="" />
//         </div>
//         <div>
//           <FAQ />
//         </div>
//       </div>

//       <div className=" bg-[#F9FAFC] py-16 lg:px-16 md:px-10 px-5">
//         <div data-aos="zoom-in" className=" grid justify-center items-center">
//           <p className=" text-center font-Inter text-3xl font-bold text-[#0C0D0D]">
//             Need any help? Fill form to contact us directly.
//           </p>
//           <div className=" grid justify-center items-center mt-10 gap-5">
//             <div className=" flex items-center justify-center lg:w-[950px] gap-10">
//               <div className=" w-1/2">
//                 <Inputs label="First Name" placeHolder="Enter here" />
//               </div>
//               <div className=" w-1/2">
//                 <Inputs label="Last Name" placeHolder="Enter here" />
//               </div>
//             </div>
//             <div className=" flex items-center justify-center lg:w-[950px] gap-10">
//               <div className=" w-1/2">
//                 <Inputs label="Company" placeHolder="Enter here" />
//               </div>
//               <div className=" w-1/2">
//                 <Inputs label="Email" placeHolder="Enter here" />
//               </div>
//             </div>
//             <div className=" flex items-center justify-center lg:w-[950px] gap-10">
//               <div className=" w-1/2">
//                 <Inputs label="Phone" placeHolder="Enter here" />
//               </div>
//               <div className=" w-1/2">
//                 <Inputs label="Subject" placeHolder="Enter here" />
//               </div>
//             </div>
//             <div className=" flex items-center justify-center lg:w-[950px] gap-10">
//               <div className=" lg:w-1/2 md:w-full w-full">
//                 <div className=" grid gap-2">
//                   <label
//                     className=" text-[#110E14] font-Inter font-normal text-base"
//                     htmlFor=""
//                   >
//                     Description
//                   </label>
//                   <textarea
//                     className=" w-full h-[183px] rounded-lg"
//                     name=""
//                     id=""
//                     placeholder="Enter here"
//                   ></textarea>
//                 </div>
//               </div>
//               <div className=" w-1/2 lg:block md:hidden hidden"></div>
//             </div>
//             <div className=" flex items-center justify-center lg:w-[950px] gap-10">
//               <div className=" w-1/2">
//                 <LandingBtn
//                   text="Get Started"
//                   bg={"bg-[#2658AB]"}
//                   css={" text-[#fff]  w-full h-[54px] rounded-md "}
//                 />
//               </div>
//               <div className=" w-1/2 lg:block md:hidden hidden"></div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className=" bg-[#14171F]">
//         <div data-aos="fade-left">
//           <Footer />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Landing;
